import { Component, OnInit } from '@angular/core';
import { UserauthenticationService } from './userauthentication.service';
import { AddtositeService } from '../addtosite/addtosite.service';
import * as _ from 'lodash';
import { SecureStorageService } from '../secure-storage.service';
import { CupService } from '../shared/services/cup.service';
declare var jQuery: any;
@Component({
  selector: 'app-userauthentication',
  templateUrl: './userauthentication.component.html',
  styleUrls: ['./userauthentication.component.css'],
  providers: [UserauthenticationService]
})
export class UserauthenticationComponent implements OnInit {
  deleteMessagePopupEnable: boolean;
  savedUser: any;
  constructor(private userauthenticationservice: UserauthenticationService,
    private addtositeservice: AddtositeService,
    private secureStorage:SecureStorageService,
    private cupservice: CupService
  ) { }
  users = { '0': [], '1': [], '2': [] };
  siteUsers: any;
  userDetails: any;
  unregisterdUsers: any;
  adminUsers: any;
  currentProviderUser = '';
  guId: string;
  message: any;
  userName: any;
  selectedUserForUnlock = '';
  selectedUserForCredReset = '';
  lockedUserNewPassword = '';
  loading = false;
  selectedAllUserSites: any = {};
  selected = { userName: '' };
  selectedUserSites = new Array<any>();
  actualUserSites = new Array<any>();
  actualFilteredUserSites = new Array<any>();
  updateMessage = '';
  failureMessage = '';
  activeUsers = [];
  inactiveUsers = [];
  allUserId = [];
  data = [];
  checkTab = 'newUser';
  private apiCallCounter: number = 0;
  handleResults(searchObj) {
    this.data = searchObj;
  }
  ngOnInit() {
    this.guId = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('userProfile'))['guId'];
    // this.userName = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'))[
    //   'userName'
    // ];
    this.userName = this.cupservice.getUserNameFromJWT();
    // this.getSiteUsers();
    this.getUserData(this.guId, '0');
    //this.getUserData(this.guId, '1');
    //this.getUserData(this.guId, '2');
    this.getUnregisterdUsers(this.guId);
    this.getUserDetails(this.userName);
    this.getAvaibleUserSites();
    this.getActiveInavtiveUsers(this.userName);
  }

 getActiveInavtiveUsers(username) {
  this.userauthenticationservice.getSiteUsers(
    username, (success: boolean, siteUserData: any) => {
      if (success) {
        siteUserData.map(site => {
          let cb = {};
          if (site.siteUsers) {
            cb = () => {
              site.siteUsers.map(user => {
                if (!this.allUserId.includes(user.userId)) {
                  this.allUserId.push(user.userId);
                  if (user.isActive) {
                    this.activeUsers.push(user);
                  } else {
                    this.inactiveUsers.push(user);
                  }
                }
              });
          this.activeUsers.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
          // this.secureStorage.setSecureInfoInSessionlStorage('activeUsers', JSON.stringify(this.activeUsers));
          this.userauthenticationservice.setActiveUsers(this.activeUsers);
          this.inactiveUsers.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
          // this.secureStorage.setSecureInfoInSessionlStorage('inactiveUsers', JSON.stringify(this.inactiveUsers));
              this.secureStorage.setSecureInfoInSessionlStorage('usersContentLoaded', "true");
              this.userauthenticationservice.setInactiveUsers(this.inactiveUsers);
            }
          }
          let call = () => this.getUserData(this.guId,'2', cb);
          this.getUserData(this.guId,'1,3', call);
        });
      } else {
        this.secureStorage.setSecureInfoInSessionlStorage('usersContentLoaded', "true");
      }
    }
  );
 }




  passRestrict(event: any) {
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  }
  
  getSiteUsers() {
    let checkValid = this.secureStorage.getSecureInfoInSessionStorage("usersContentLoaded")
    if (checkValid && checkValid === "true") {
      this.activeUsers = this.secureStorage.getSecureInfoInSessionStorage('activeUsers') ? JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('activeUsers')) : [];
      this.inactiveUsers = this.secureStorage.getSecureInfoInSessionStorage('inactiveUsers') ? JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('inactiveUsers')) : [];
      this.users = this.secureStorage.getSecureInfoInSessionStorage('users') ? _.merge(this.users, JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('users'))) : this.users;
    } else {
      setTimeout(() => {
        this.getSiteUsers();
      }, 1000);
    }
    // this.loading = true;
    // this.activeUsers = [];
    // this.inactiveUsers = [];
    // this.allUserId = [];
    // this.userauthenticationservice.getSiteUsers(
    // userName, (success: boolean, siteUserData: any) => {
    // if (success) {
    // this.loading = false;
    // this.siteUsers = siteUserData;
    // //console.log(this.siteUsers);
    // this.siteUsers.map(site => {
    // if (site.siteUsers) {
    // site.siteUsers.map(user => {
    // if (!this.allUserId.includes(user.userId)) {
    // this.allUserId.push(user.userId);
    // if (user.isActive) {
    // this.activeUsers.push(user);
    // } else {
    // this.inactiveUsers.push(user);
    // }
    // }
    // });
    // }
    // })
    // this.getUserData(this.guId, '1');
    // this.getUserData(this.guId, '2');
    // } else {
    // this.loading = false;
    // }
    // }
    // );
  }
  getUserDetails(userName: string) {
    //userName= this.secureStorage.getSecureInfoInSessionStorage(userName);
    this.userauthenticationservice.getUserDetails(
      userName, (success: boolean, userDetails: any) => {
        if (success) {
          this.loading = false;
          this.userDetails = userDetails;
        } else {
          this.loading = false;
        }
      }
    );
  }
  getUserData(guId: string, status: string,cb?, userRole = '') {
    status = status;
    this.incrementApiCallCounter();
    this.userauthenticationservice.getUsers(
      guId,
      status,
      '',
      (success: boolean, userData: any) => {
        // this.loading = false;
        if (success) {
          this.reconstructWithAdminFlag(userData['user']);
          this.users[status] = userData['user'];
          // this.secureStorage.setSecureInfoInSessionlStorage('users', JSON.stringify(this.users));
          if (status == "1,3") {
            userData['user'].map(user => {
              // user['firstName'] = user['firstName'].charAt(0).toUpperCase() + user['firstName'].slice(1);
              user['userId'] = user['userName'];
              user['name'] = user['firstName'] + ', ' + user['lastName'];
              if (!this.allUserId.includes(user.userId)) {
                this.allUserId.push(user.userId);
                this.activeUsers.push(user);
              }
            });
          } else if (status == "2") {
            userData['user'].map(user => {
              user['userId'] = user['userName'];
              user['name'] = user['firstName'] + ', ' + user['lastName'];
              if (!this.allUserId.includes(user.userId)) {
                this.allUserId.push(user.userId);
                this.inactiveUsers.push(user);
              }
            });
          }
          if (cb) {
            cb();
          } else {
            this.secureStorage.setSecureInfoInSessionlStorage('usersContentLoaded', "true");
          }
        } else {
          this.loading = false;
          this.secureStorage.setSecureInfoInSessionlStorage('usersContentLoaded', "true");
        }
        this.decrementApiCallCounter();
      }
    );
  }

  private incrementApiCallCounter() {
    this.apiCallCounter++;
    this.loading = true;
  }

  private decrementApiCallCounter() {
    this.apiCallCounter--;
    if (this.apiCallCounter === 0) {
      this.loading = false;
    }
  }
  getUnregisterdUsers(guId: string) {
    this.loading = true;
    this.userauthenticationservice.getUnregisterdUsers(
      guId,
      (success: boolean, userData2: any) => {
        if (success) {
          this.loading = false;
          this.unregisterdUsers = userData2['user'];
        } else {
          this.loading = false;
        }
      }
    );
  }
  reconstructWithAdminFlag(users: any) {
    for (let index = 0; index < users.length; index++) {
      const element = users[index];
      element['isAdmin'] =
        element['userRole'] != null && element['userRole'] === 'Admin'
          ? true
          : false;
    }
  }
  checkForProviderAdmin(user) {
    this.loading = true;
    this.userauthenticationservice.checkProviderAdmin(
      this.guId,
      user.userName,
      (success: boolean, userData: any) => {
        if (success) {
          this.userauthenticationservice.getUsers(
            this.guId,
            '1',
            'Admin',
            (success: boolean, userData1: any) => {
              if (success) {
                this.loading = false;
                this.adminUsers = userData1['user'];
                this.currentProviderUser = user;
                jQuery('#adminUsersModal').modal('show');
              }
            }
          );
        } else {
          this.loading = false;
          this.selectedUserSites = JSON.parse(JSON.stringify(this.selectedAllUserSites[user.userName]));
            for (let index = 0; index < this.selectedUserSites.length; index++) {
              const element = this.selectedUserSites[index];
              for (let j = 0; j < this.actualUserSites.length; j++) {
                const actualElement = this.actualUserSites[j];
                if (element['siteUid'] === actualElement['siteUid']) {
                this.selectedUserSites[index].ownSite = true; 
                }
              }
            }
          if (this.selectedUserSites.filter(e => e.ownSite === undefined).length > 0) {
            jQuery('#dontDeactivateModal').modal('show');
            user.status = "1";
            return;
          }
          else {
          this.updateStatus(user, 2);
          }
        }
      }
    );
  }
  updateProviderAdmin(user) {
    this.loading = true;
    this.userauthenticationservice.updateProviderAdmin(
      this.guId,
      user.userName,
      (success: boolean, userData: any) => {
        if (success) {
          this.loading = false;
          jQuery('#adminUsersModal').modal('hide');
          this.updateStatus(this.currentProviderUser, '2');
        } else {
          this.loading = false;
        }
      }
    );
  }
  updateStatus(user, status,openSites?) {
    if (status === '2') {
      user['userRole'] = 'null';
    } else {
      user['userRole'] = user['isAdmin'] ? 'Admin' : 'null';
    }
    user['status'] = parseInt(status);
    this.loading = true;

    user['userId']= user.userName;
    if(user['name'] == undefined){
      user['name'] = user.firstName + ' ' + user.lastName;
    }
    this.userauthenticationservice.updateStatus(
      user,
      (success: boolean, message: Array<any>) => {
        if (success) {
          this.loading = false;
          this.getUserData(this.guId, '0');
          // this.getUserData(this.guId, '1');
          // this.getUserData(this.guId, '2');
          if (status == '2') {
              let index = _.findIndex(this.activeUsers, ['userId', user.userId]);
              if(index >= 0){
                this.activeUsers.splice(index, 1);
                this.inactiveUsers.push(user);
                this.inactiveUsers.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
            }
            // this.updateSession();
          } else if (this.checkTab == 'activeUser' || this.checkTab == 'newUser') {
            let findElement = document.getElementById('activeUsers').classList.contains('active');
            let index = _.findIndex(this.activeUsers, ['userId', user.userId]);
            if(index >= 0){
             this.activeUsers.splice(index, 1);
            }
            this.activeUsers.push(user);
            this.activeUsers.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
            // this.updateSession();
          }
          else if (this.checkTab == 'inActiveUser' || status == '3') {
            let index = _.findIndex(this.inactiveUsers, ['userId', user.userId]);
            if(index >= 0){
             this.inactiveUsers.splice(index, 1);
            }
            this.activeUsers.push(user);
            this.activeUsers.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
            // this.updateSession();
          }
          if(openSites == undefined){
            this.activeUserOpen(user,true);
          }
          return;
        } else {
          this.loading = false;
          return;
        }
      }
    );
  }
  updateAssociateURData(user) {
    this.loading = true;
    this.userauthenticationservice.updateAssociateURData(
      user,
      (success: boolean, message: Array<any>) => {
        if (success) {
          this.loading = false;
          this.showAlertPopup('Email was successfully sent ', () => { });
          return;
        } else {
          this.loading = false;
          this.showAlertPopup(
            'Sorry something went wrong, please try again later. ',
            () => { }
          );
          return;
        }
      }
    );
  }
  deleteMessagePopup(user){
    this.savedUser = user;
    this.deleteMessagePopupEnable= true;
    jQuery("#delete-dialog").modal("show");
  }
  
  deletePendingUsers(){
    this.deleteMessagePopupEnable= false;
    this.loading = true;
    this.userauthenticationservice.updateAssociateURData2(this.savedUser ,this.userName).subscribe((response)=>{
      if(response.status === 200){
        this.userauthenticationservice.getUnregisterdUsers(
          this.savedUser.guId,
          (success: boolean, userData2: any) => {
            if (success) {
              this.loading = false;
              this.unregisterdUsers = userData2['user'];
              jQuery("#delete-dialog").modal("hide");
            } else {
              this.loading = false;
              jQuery("#delete-dialog").modal("hide");
            }
          }
        );
      }
     
    },(err)=>{
      this.loading = false;
    })

  }
  private showAlertPopup(message: string, callBack) {
    this.message = message;
    this.showPopup('sentEmailModal', callBack);
    return;
  }
  private showPopup(popupName: string, callBack) {
    jQuery('#' + popupName).modal('show');
    this[popupName + 'EventCallBack'] = callBack;
  }
  updateLockedPasswordPopup(user) {
    jQuery('#changePasswordModal').modal('show');
    this.selectedUserForCredReset = user['userName'];
    this.lockedUserNewPassword = '';
  }
  updateLockedPassword() {
    if (this.lockedUserNewPassword === '') {
      this.showAlertPopup(
        'Password field is required, please enter a temporary password to update.',
        () => { }
      );
      return;
    }
    this.loading = true;
    this.userauthenticationservice.updateLockedPassword(
      this.selectedUserForCredReset,
      this.lockedUserNewPassword,
      (success: boolean, response: any) => {
        if (success) {
          this.loading = false;
          jQuery('#changePasswordModal').modal('hide');

          if(response.status === "Success"){
            this.updateUerPasswordChangeTime(this.selectedUserForCredReset);
          this.showAlertPopup(
            'Temporary Password has been successfully updated.',
            () => {});
          }
          else{
            this.showAlertPopup("Sorry something went wrong, please try again later.", () => { });
          }  
          return;
        } else {
          this.loading = false;
          let respMessage = '';
          if(response.status === 400) {
            respMessage = "Password is invalid. Please enter a valid password.";
          }
          else {
            respMessage = "Sorry something went wrong, please try again later.";
          }
          this.showAlertPopup(respMessage, () => { });
          return;
        }
      }
    );
  }
  unlockUser(user) {
    this.loading = true;
    this.userauthenticationservice.unlockUser(
      user.userName,
      (success: boolean, message: string) => {
        if (success) {
          this.loading = false;
          this.updateLockedUerStatus(user);
          this.showAlertPopup(
            'User account has been successfully unlocked.',
            () => { }
          );
                   return;
        } else {
          this.loading = false;
          this.showAlertPopup(
            'Sorry something went wrong, please try again later.',
            () => { }
          );
          return;
        }
      }
    );
  }
  updateLockedUerStatus(user) {
    const statusPayload = {
      userName: user.userName,
      status: '1',
      unsuccessfulLoginAttempts: '0'
    };
    this.userauthenticationservice.updateLockedUerStatus(
      statusPayload,
      (success: boolean, message: string) => {
        if (success) {
          user.status = 1;
          return;
        }
      }
    );
  }
  updateUerPasswordChangeTime(userName) {
    const backDaysInsecs = 181 * 24 * 60 * 60 * 1000;
    const backTimeInSecs = new Date().getTime() - backDaysInsecs;
    const current_date = new Date(backTimeInSecs);
    const changePasswordTimePayload = {
      userName: userName,
      lastPasswordChange: this.getFormattedDateStr(current_date)
    };
    this.userauthenticationservice.updateUerPasswordChangeTime(
      changePasswordTimePayload,
      (success: boolean, message: string) => {
        if (success) {
          return;
        }
      }
    );
  }
  getFormattedDateStr(date: Date) {
    return (
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      date.getDate() +
      ' 00:00:00:000'
    );
  }
  parseResponse(xmlResponse) {
    let returnVal = 'false';
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');
    try {
      returnVal = xmlDoc.getElementsByTagName('ns:return')[0].childNodes[0]
        .nodeValue;
    } catch (e) { }
    return returnVal;
  }
  parseResponseByName(xmlResponse, tagName) {
    let returnVal = '';
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');
    try {
      returnVal = xmlDoc.getElementsByTagName(tagName)[0].childNodes[0]
        .nodeValue;
    } catch (e) { }
    return returnVal;
  }
  linkSitesPop(user) {
    this.updateMessage = null;
    this.failureMessage = null;
    this.selected.userName = user.userName;
    this.selectedUserSites = JSON.parse(JSON.stringify(this.selectedAllUserSites[user.userName]));
    this.actualFilteredUserSites = [];
    for (let i = 0; i < this.actualUserSites.length; i++) {
      if (!this.existsInSelected(this.actualUserSites[i].siteUid)) {
        this.actualFilteredUserSites.push(this.actualUserSites[i]);
      }
    }
     for (let index = 0; index < this.selectedUserSites.length; index++) {
      const element = this.selectedUserSites[index];
      for (let j = 0; j < this.actualUserSites.length; j++) {
        const actualElement = this.actualUserSites[j];
        if (element['siteUid'] === actualElement['siteUid']) {
          this.selectedUserSites[index].ownSite = true; 
        }
      }
     }
    //console.log(' after push selelcted users', this.selectedUserSites);
    jQuery('#linkSiteModal').modal('show');
    // this.selectedUserSites = JSON.parse(JSON.stringify(this.selectedAllUserSites[user.userName]));
    // this.getcurentUserSites(user);
  }
 
  getcurentUserSites(user,cb?) {
    this.loading = true;
    this.userauthenticationservice.getUserDetailsPost(
      user.userId,
      (success: boolean, userSites: any) => {
        if (success) {
          this.loading = false;
          // this.actualUserSites = userSites;

         user = Object.assign(user, userSites.user[0]);
         user['userName'] = user.userId;
          this.listUserSites(user,cb);
      // this.syncUsers();

        } else {
          this.loading = false;
        }
      }
    );
  }
  listUserSites(user: any,cb?) {
    this.updateMessage = '';
    this.failureMessage = '';
    this.selected.userName = user.userName;
    this.loading = true;
    this.actualFilteredUserSites = [];
    this.addtositeservice.listSites(
      user.userName,
      (success: boolean, userSites: any) => {
        if (success) {
          this.loading = false;
          if(Array.isArray(userSites)){
          this.selectedUserSites = userSites;
          this.selectedAllUserSites[user.userName] = JSON.parse(JSON.stringify(userSites));
          for (let index = 0; index < this.selectedUserSites.length; index++) {
            const element = this.selectedUserSites[index];
            for (let j = 0; j < this.actualUserSites.length; j++) {
              const actualElement = this.actualUserSites[j];
              if (element['siteUid'] === actualElement['siteUid']) {
              this.selectedUserSites[index].ownSite = true; 
              }
            }
          }
          this.actualFilteredUserSites = new Array<any>();
          for (let i = 0; i < this.actualUserSites.length; i++) {
            if (!this.existsInSelected(this.actualUserSites[i].siteUid)) {
              this.actualFilteredUserSites.push(this.actualUserSites[i]);
            }
          }
        }else{
          this.selectedUserSites = [];
          this.selectedAllUserSites[user.userName] = [];
        }
        if(cb){
          this.linkSitesPop(user);
        }
        } else {
          this.loading = false;
        }
      }
    );
  }
  existsInSelected(siteUid) {
    for (let j = 0; j < this.selectedUserSites.length; j++) {
      if (this.selectedUserSites[j].siteUid === siteUid) {
        return true;
      }
    }
    return false;
  }
  addSites(siteObj) {
    this.updateMessage = '';
    this.failureMessage = '';
    for (let index = 0; index < this.actualFilteredUserSites.length; index++) {
      const element = this.actualFilteredUserSites[index];
      if (element['siteUid'] === siteObj['siteUid']) {
        this.actualFilteredUserSites.splice(index, 1);
        if (this.selectedUserSites === undefined) {
          this.selectedUserSites = new Array<any>();
        }
        this.selectedUserSites.push(element);
        element['ownSite'] = true;
      }
    }
  }
  removeSites(siteObj) {
    this.updateMessage = '';
    this.failureMessage = '';
    for (let index = 0; index < this.selectedUserSites.length; index++) {
      const element = this.selectedUserSites[index];
      if (element['siteUid'] === siteObj['siteUid']) {
        this.selectedUserSites.splice(index, 1);
        this.actualFilteredUserSites.push(element);
      }
    }
  }
  selectionReset() {
    if (this.selectedUserSites && this.selectedUserSites.length > 0) {
      this.selectedUserSites.forEach(element => {
        this.actualFilteredUserSites.push(element);
      });
      this.selectedUserSites = undefined;
    }
    this.actualFilteredUserSites.sort((a, b) => (a.siteUid > b.siteUid) ? 1 : ((b.siteUid > a.siteUid) ? -1 : 0));
  }

  postSites() {
    this.loading = true;
    this.addtositeservice.deleteSites(
      this.selected.userName,
      (success: boolean, userSites: any) => {
        if (success) {
          this.addtositeservice.postSites(
            this.selected.userName,
            this.selectedUserSites,
            (success: boolean, userSites: any) => {
              if (success) {
                this.loading = false;
                this.listUserSites(this.selected);
                this.updateMessage = 'Updated Successfully';
              }
            }
          );
        } else {
          this.loading = false;
          this.failureMessage = 'Failed to update, Please try again.';
        }
      }
    );
  }
  activeUserOpen(siteUser,cb?) {
    if (!this.selectedAllUserSites[siteUser.userId]) {
      this.getcurentUserSites(siteUser,cb);
    }else{
    if(cb){
      this.linkSitesPop(siteUser);
    }
  }
  }
  // updateSession() {
  //   this.secureStorage.setSecureInfoInSessionlStorage('activeUsers', JSON.stringify(this.activeUsers));
  //   this.secureStorage.setSecureInfoInSessionlStorage('inactiveUsers', JSON.stringify(this.inactiveUsers));
  // }

  syncUsers(){
    this.loading = true;
    const allUsers = _.cloneDeep([...this.activeUsers,...this.inactiveUsers]);
    const activeUsersList = [];
    const inactiveUsersList = [];
    allUsers.map(us =>{
      if(us.status != undefined){
        if(String(us.status) ===  "1"){
          activeUsersList.push(us);
        }else{
          inactiveUsersList.push(us);
        }
      }else{
        if(us.isActive){
          activeUsersList.push(us);
        }else{
          inactiveUsersList.push(us);
        }
      }
    });
    this.activeUsers = activeUsersList;
    this.inactiveUsers = inactiveUsersList;
    this.activeUsers.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
    this.inactiveUsers.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
    // this.updateSession();
    this.loading = false;
  }

  getAvaibleUserSites() {
    this.loading = true;
    this.addtositeservice.listSites(this.userName, (success: boolean, userSites: any) => {
      if (success) {
        this.loading = false;
        this.actualUserSites = userSites;
      } else {
        this.loading = false;
      }
    });
  }
}
