<div id="main-wrapper" style="margin-bottom: 40px;">
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
  <div class="container-fluid" style="padding: 0;">
    <app-header (found)="handleResults($event)"></app-header>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/providerportal" href="#" style="color: #5009B5;">Providerportal</a>
        </li>
        <li class="breadcrumb-item">
          <a routerLink="/providerportal/site_Administration" href="#" style="color: #5009B5;">Manage Users</a>
        </li>
        <li class="breadcrumb-item active" style="font-family: Myriad Pro" aria-current="page">Attach to Site</li>
      </ol>
    </nav>
    <div class="row col-md-12 addtositePage">
      <app-search-results [resultGridList]="data"></app-search-results>
      <div class="col-md-12 userSites">
        <br>
        <div class="col-md-3 currentUserSiteList" *ngIf="actualUserSites.length >0 && false">
          <br>
          <p class="test">My Sites:</p>
          <ul class="list-group" *ngFor="let user of actualUserSites">
            <li class="list-group-item list-group-item-action listStyle" style="cursor: pointer; word-break: break-all; background-color: lightgray; border-color: black;">
              {{user['siteName']}}
            </li>
          </ul>
        </div>
        <div class="col-md-3 currentUserSiteList" *ngIf="actualUSerSitesIsEmpty">
          <br>
          <p class="test">You are not assigned to any Site.</p>
        </div>
        <div class="col-md-4 userList" *ngIf=" users['1'].length > 1">
          <br>
          <p class="test">Active Users:</p>
          <ul class="list-group" *ngFor="let user of users['1'] | sort :'name'">
            <li (click)="listUserSites(user.userName)" [ngClass]="{'activeClass': selected.userName == user.userName}"
              class="list-group-item list-group-item-action listStyle" style="cursor: pointer; word-break: break-all"
              *ngIf="user['userName'] !== userName">
              {{user.name}}</li>
          </ul>
        </div>
        <div class="col-md-4 userList" *ngIf=" users['1'].length === 1">
          <br>
          <p class="test">No Active Users</p>
        </div>
        <div class="col-md-8 siteList" *ngIf="selected['userName'] !== ''">
            <br>
            <div class="col-md-12">
                <div class="col-md-12" *ngIf="updateMessage !== ''">
                    <p class="row updateMessage"> {{updateMessage}}</p>
                  </div>
                  <div class="col-md-12" *ngIf="failureMessage !== ''">
                    <p class="row failureMessage"> {{failureMessage}}</p>
                  </div>
                  <div class="col-md-12 updateBtn" *ngIf="actualFilteredUserSites.length>0||selectedUserSites!=null">
                      <button (click)=postSites() type="button" class="btn btn-primary carelon-primary-btn">Update</button>
                    </div>
            </div>
              
              
          <br>
          <ng-container *ngIf="selectedUserSites.length>0">
            <div>
              <p>Click - sign to remove sites for user <b>{{selected.userName}}</b></p>
            </div>
            <br>
            <ul class="list-group" *ngFor="let sites of selectedUserSites">
              <a class="list-group-item list-group-item-action listStyle text-left">
                <div class="row">
                  <div class="col-md-11 col-xs-8">
                    {{sites.siteName}}
                  </div>
                  <div class="col-md-1 col-xs-4"  *ngIf="sites.ownSite">
                    <span (click)=removeSites(sites) class="glyphicon glyphicon-minus-sign pull-right" aria-hidden="true"
                      style="color: red; cursor: pointer;"></span>
                  </div>
                </div>
              </a>
            </ul>
          </ng-container>
          <ng-container *ngIf="selectedUserSites.length ===0">
            <p>No Sites Associated to the user <b>{{selected.userName}}</b></p>
          </ng-container>
          <ng-container *ngIf="actualFilteredUserSites.length > 0">
            <hr />
            <div *ngIf="selectedUserSites">
              <p>Click + sign to add below sites to user <b>{{selected.userName}}</b></p>
            </div>
            <br>
            <ul class="list-group" *ngFor="let sites of actualFilteredUserSites">
              <a class="list-group-item list-group-item-action listStyle">
                <div class="row">
                  <div class="col-md-11 col-xs-8">
                    {{sites.siteName}}
                  </div>
                  <div class="col-md-1 col-xs-4">
                    <span (click)=addSites(sites) class="glyphicon glyphicon-plus-sign pull-right" aria-hidden="true"
                      style="color: green; cursor: pointer;"></span>
                  </div>
                </div>
              </a>
            </ul>
          </ng-container>
          <br>
        </div>
        <div class="col-md-4 noSiteList" *ngIf="selected['userName'] === ''">
          <p class="row noSiteListText"> Please select a active user to list sites</p>
        </div>
        <br>
      </div>
    </div>
  </div>
  <br><br><br>
  <app-footer ></app-footer>
</div>