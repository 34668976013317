export const PROD_CONFIG = {
        email: "DL-ProvExpProviderRelations@caremore.com",
        email_subject: "Secure:",
        cup_base_url: "",
        GeneralReference_GUID: '{C6C0CEDA-A1FF-441A-B431-8B939A60AFBF}',
        ProviderCommunications_GUID: '{EFE7B04E-A676-4C1B-82B3-3932F405D2FF}',
        department: "providerportal.caremore.com/#",
        userIdleConfig: {
                idle: 7200,
                timeout: 60,
                ping: 60
        }
};