import { Injectable } from '@angular/core';

import { urls, props, payloads } from '../../common/model/properties';
import { CONFIG } from '../../../config/index';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SecureStorageService } from '../../secure-storage.service';

@Injectable()
export class FooterConnectService {

    constructor( private http: HttpClient ,
        private secureStorage:SecureStorageService) {

    }

    sendMailRequest( model, callBack: ( sucess: boolean, message: string ) => void ) {
       
        let payload: any;
        payload = {
            'body': model.message,
            'mailCC': '',
            'mailTo': model.selectedEnquiry.email,
            'phoneNumber': model.phoneNumber,
            'sender': model.name,
            'sendersEmail': model.email,
            'subject': CONFIG.email_subject + ' ' + model.subject
          };

        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +  ppjwt.access_token 
        });

        this.http.post( urls.sendEmail, payload, { headers: headers , responseType:'text'} )
            .subscribe(
            ( resp:any ) => {
                callBack( true, resp );
            },
            err => {
                callBack( false, 'Error while sending the Email. ' );
            }
            );
    }

    getEnquiryList( callBack: ( sucess: boolean, resp: string ) => void ) {

        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({'Content-Type': 'application/json',
        'Authorization': 'Bearer ' +  ppjwt.access_token});

        this.http.get( urls.enquiryList, { headers: headers } )
            .subscribe(
            ( resp:any ) => {
                callBack( true, resp );
            }
            );
    }


}
