import { Component, OnInit } from '@angular/core';
import { ChangePasswordService } from './changePassword.service';
import { HttpParams } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { FieldValidateService } from '../common_services/fieldvalidate.service';
import { SecureStorageService } from '../secure-storage.service';
import { CupService } from '../shared/services/cup.service';

declare var jQuery: any;

@Component({
    selector: 'app-changePassword',
    templateUrl: './changePassword.component.html',
    styleUrls: ['./changePassword.component.scss'],
    providers: [ChangePasswordService]
})
export class ChangePasswordComponent implements OnInit {

    oldCred = '';
    newCred: any = '';
    confirmCred: any = '';
    userName: any;
    LoginErrorMessage: any;
    questions: any;
    errorMessage: any;
    key: any;
    showFirstCred = true;
    showSecondCred = true;
    fieldValidateMsg = {};
    isFiledValid = {};
    userJson: any = '';
    showOldCred = false;
    showCred = false;
    showConfirmCred = false;
    loading = false;
    data = [];
    showSuccesImg:boolean = false;
    userNameFromToken: any = '';

    handleResults(searchObj) {
        this.data = searchObj;
    }
    constructor(
        private router: Router,private cupService: CupService,
        private route: ActivatedRoute,
        private changeCredService: ChangePasswordService,
        private fieldValidator: FieldValidateService,
        private secureStorage:SecureStorageService) { }

    ngOnInit() {
        // this.userJson = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        this.userNameFromToken = this.cupService.getUserNameFromJWT();
        this.resetInputValidations();
    }

    alertModalEventCallBack() { }

    togglePasswordShow(event: any, modelName: string) {
        this[modelName] = !this[modelName];
    }
    onSubmit() {
        if (this.oldCred === '' || this.newCred === '' || this.confirmCred === '') {
            this.setFieldValidateMsg(['oldCred', 'newCred', 'confirmCred']);
            this.checkForEmptyField(['oldCred', 'newCred', 'confirmCred']);
            return;
        }

        if (this.validatePassword()) {
            this.loading = true;
            this.changeCredService.submitPassword( this.oldCred, this.newCred, (success: boolean, response:any) => {
                if (success) {
                    this.loading = false;
                    if(response.status === "Success"){
                        // this.updateUerPasswordChangeTime();
                        this.showSuccesImg = true;
                        this.showAlertPopup('Your password has been changed successfully', () => {
                            this.router.navigate(['providerportal']);
                        });
                    }

                    else{
                        this.showAlertPopup('Sorry something went wrong, please try again later.', () => {
                        });
                    }

                    return;
                    
                } else {
                    this.showSuccesImg = false;
                    this.loading = false;
                    let respMessage = " ";
                    if(response.status === 401) {
                        respMessage = "Password is invalid. Please enter a valid password.";
                      }
                    else if(response.status === 400) {
                        respMessage = "This password has been used in recent history. Please choose a different password.";
                    }
                    else {
                        respMessage = "Sorry something went wrong, please try again later.";
                      }
            
                      this.showAlertPopup(respMessage, () => { });
                      return;
                }
            });
        }
    }
    validatePassword() {
        if (this.newCred === this.confirmCred) {
            if (this.fieldValidator.validateCred(this.newCred)) {
                return true;
            } else {
                this.fieldValidateMsg['newCred'] = 'Password does not meet requirements';
                this.fieldValidateMsg['confirmCred'] = 'Password does not meet requirements';
                this.setFieldValidates(['newCred', 'confirmCred']);
            }
        } else {
            this.fieldValidateMsg['newCred'] = 'Passwords Do not Match. Please verify';
            this.fieldValidateMsg['confirmCred'] = 'Passwords Do not Match. Please verify';
            this.setFieldValidates(['newCred', 'confirmCred']);
        }

        return false;
    }

    private showAlertPopup(message: string, callBack) {
        this.errorMessage = message;
        this.showPopup('alertModal', callBack);
        return;
    }

    private showPopup(popupName: string, callBack) {
        jQuery('#' + popupName).modal('show');
        this[popupName + 'EventCallBack'] = callBack;
    }

    private setFieldValidates(fieldNameArr: string[]) {
        for (const fieldName of fieldNameArr) {
            this.isFiledValid[fieldName] = false;
        }
    }

    showLoginPage(): void {
        this.router.navigate(['./login']);
    }

    isUndefinedOrEmpty(value: string) {
        if (value === undefined || value === '') {
            return true;
        }
        return false;
    }

    isUndefinedOrTrue(value: boolean) {
        if (value === undefined || value) {
            return true;
        }
        return false;
    }

    isUndefinedOrFalse(value: boolean) {
        if (value === undefined || value === false) {
            return true;
        }
        return false;
    }

    onInputFocus(event: any) {
        this.hideValidate(event.target.name);
    }

    hideValidate(inputName: string) {
        this.isFiledValid[inputName] = true;
    }

    private resetInputValidations() {
        const fieldNameArr = ['oldCred', 'newCred', 'confirmCred'];
        for (const fieldName of fieldNameArr) {
            this.isFiledValid[fieldName] = true;
        }
    }

    passRestrict(event: any) {
        const inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode === 32) {
            event.preventDefault();
        }
    }

    private setFieldValidateMsg(fieldNameArr: string[]) {
        for (const fieldName of fieldNameArr) {
            this.fieldValidateMsg[fieldName] = '';
        }
    }

    private checkForEmptyField(fieldNameArr: string[]) {
        for (const fieldName of fieldNameArr) {
            this.isFiledValid[fieldName] = this[fieldName] !== '' ? true : false;
        }
    }

    private showPopuup(message: string) {
        this.errorMessage = message;
        jQuery('#myModal').modal('show');
        return;
    }
    updateUerPasswordChangeTime() {
        const current_date = new Date();
        const changePasswordTimePayload = {
            // userName: this.userJson['userName'],  
            userName: this.userNameFromToken,
            lastPasswordChange: this.getFormattedDateStr(current_date)
        };
        this.changeCredService.updateUerPasswordChangeTime(changePasswordTimePayload, (success: boolean, message: string) => {
            if (success) {

                return;
            }
        });
    }

    getFormattedDateStr(date: Date) {
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' 00:00:00:000';
    }

    parseResponse(xmlResponse) {
        let returnVal = 'false';
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');
        try {
            returnVal = xmlDoc.getElementsByTagName('ns:return')[0].childNodes[0].nodeValue;
        } catch (e) { }
        return returnVal;
    }

    parseResponseByName(xmlResponse, tagName) {
        let returnVal = '';
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');
        try {
            returnVal = xmlDoc.getElementsByTagName(tagName)[0].childNodes[0].nodeValue;
        } catch (e) { }
        return returnVal;
    }
}
