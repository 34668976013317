<app-common></app-common>
<div class="top-panel">
	<div class="container-fluid top-panel bg-white">
		<div id="ucHeader_pnlBanner" class="row banner">

			<div class="col-9 col-sm-12">
				<div class="row align-items-center h-100 pl-3 pl-sm-0">
					<div class="col-12 col-sm-4 col-md-2 py-0 text-md-center">
						<img title="Logo" class="img img-fluid banner-logo" src="assets/images/Logo-carelon.svg"
							alt="Logo">
					</div>

				</div>
			</div>
		</div>
	</div>
</div>
<div class=" signinContainer">
	<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>

	<div class="row" >
		<div class="col-md-8 col-lg-9 p-t-16 ht-500">
			<div class="cover-img full-width"></div>
		</div>

		<div class="col-md-4 col-lg-3" style="padding: 16px">
			<mat-card class="sign-card-container ht-100%" >
				<div class="signin-label-header texfield-spacing">Upload Documents for Onboarding</div>

				<form action="#" class="validate-form frmForgotUsername" #appforgotpasswordform id="#forgotpasswordForm"
					(ngSubmit)="onSubmit()">

					<div [ngClass]="(isFieldValid['caseId'])?'wrap-input100 validate-input':'wrap-input100 validate-input alert-validate'"
						data-validate="caseId">
						<input (focus)="onInputFocus($event)" placeholder="Case ID" [(ngModel)]="model.caseId"
							#caseId="ngModel" id="caseId" name="caseId" type="text"
							[ngClass]="(model.caseId === '')?'input100':'input100 has-val'" required
							(keyup)="restrictToAlpha($event)" />
					</div>

					<div [ngClass]="(isFieldValid['passCode'])?'wrap-input100':'wrap-input100'"
						data-validate="passCode">
						<input (focus)="onInputFocus($event)" placeholder="Passcode" [(ngModel)]="model.credCode"
							#passCode="ngModel" id="passCode" name="passCode" type="text"
							[ngClass]="(model.credCode === '')?'input100':'input100 has-val'" />
					</div>
					<div class="container-login100-form-btn  p-t-40">
						<button type="submit"
							class="carelon-btn action-button contact100-form-btn next">Validate</button>
					</div>

				</form>
				<div class="texfield-spacing texfield-spacing-cont">
					Go back to? <span [routerLink]="''" class="hyper-link">Sign in</span>
				</div>
			</mat-card>
		</div>

	</div>
</div>
<!-- <app-footer ></app-footer> -->
<div class="modal fade" id="myModal" role="dialog">
	<div class="modal-dialog modal-sm" style="margin-top: 20%;">
		<div class="modal-content">
			<div class="modal-header" style="border-bottom: 0px;">
				<button type="button" class="close" data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<p>{{ errorMessage }}</p>
			</div>
			<div class="modal-footer" style="border-top: 0px;">
				<button type="button" class="contact100-form-btn center-block" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>