import { Injectable } from '@angular/core';


@Injectable()

export class PreferencesService {

    constructor() { }

    private storageKey = "";


    getStorageKey() {
        return this.storageKey;
    }

    setStorageKey(key: string) {
        this.storageKey = key;
    }   
}
