import { Injectable } from '@angular/core';
import { ConfigModel } from '../models/config.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ConfigProvider {
    static instance: ConfigProvider;
    private config: ConfigModel = null;

    public envConfig = 'assets/env.json';
    
    constructor(private http: HttpClient) {
        ConfigProvider.instance = this;
    }

    public getConfig(): ConfigModel {
        return this.config;
    }
    public getProps(){
        return  {
           
            cup_login_authorization: 'Basic ' + this.config.token,
            cup_login_JWT: 'Basic ' + this.config.cup_token,
            cupPortalHost: 'https://ca47dwvcup001/',
            practiceID: this.config.practiceID,
            errorMessage: this.config.errorMessage
        }
    }

    load() {
        console.log('loading environment config...');
        return new Promise((resolve, reject) => {
            this.http
                .get(this.envConfig)
                .subscribe((response:any) => {
                    this.config = response;
                    resolve(true);
                });
        });
    }
}
