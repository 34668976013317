import { Component, OnInit } from '@angular/core';
import { ResetpasswordService } from './resetpassword.service';
import { HttpParams } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { FieldValidateService } from '../common_services/fieldvalidate.service';
import { SecureStorageService } from '../secure-storage.service';
import { LocationStrategy } from '@angular/common';
import{ForgotpasswordquestionsService} from '../forgotpasswordquestions/forgotpasswordquestions.service';

declare var jQuery: any;

@Component({
    selector: 'app-forgotpassword',
    templateUrl: './resetpassword.component.html',
    styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {
    cred: any = '';
    confirmCred: any = '';
    userName: any;
    LoginErrorMessage: any;
    questions: any;
    errorMessage: any;
    key: any;
    fieldValidateMsg = {};
    isFiledValid = {};
    showCred = false;
    showConfirmCred = false;
    news = [];
    loading = false;
    paswordUpdateuserName:any;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private credService: ResetpasswordService,
        private fieldValidator: FieldValidateService,
        private secureStorage:SecureStorageService,
    private forgotpasswordquestionsService:ForgotpasswordquestionsService) { }

    ngOnInit() {
        this.forgotpasswordquestionsService.usernameFromUrl.subscribe(userName => {
           this.paswordUpdateuserName=userName;
        });
        
        this.route.params.subscribe(params => {
            if (params['key']) {
                this.key = params['key'];
            }
        });
        this.route.queryParamMap.subscribe(params => {
            if (params && params['params'] && (params['params']['confirmation'] || params['params']['username'])) {
                this.key = params['params']['confirmation'];
                // console.log('params--userName', params['userName']);
                // this.secureStorage.setSecureInfoInSessionlStorage('forgotPasswordUserName', params['params']['username']);
                this.paswordUpdateuserName=params['params']['username'];
                this.checkUsernaAvailability() ;
            }else{
                this.checkUsernaAvailability();
            }
        });

        this.resetInputValidations();
        this.getNews();
    }

    checkUsernaAvailability(){
        if(this.paswordUpdateuserName==""){
            this.router.navigate(['./login']);
        }else{
           return this.paswordUpdateuserName;
        }
    }
    togglePasswordShow(event: any, modelName: string) {
        this[modelName] = !this[modelName];
    }
    onSubmit() {
        
        if (this.cred === '' || this.confirmCred === '') {
            this.setFieldValidateMsg(['cred', 'confirmCred']);
            this.checkForEmptyField(['cred', 'confirmCred']);
            return;
        }

        if (this.validatePassword()) {
            this.loading = true;
            this.credService.submitPassword(this.key, this.cred).
                subscribe(
                    (resp) => {
                        this.errorMessage = 'Your password has been reset successfully ';
                        jQuery('#myModalSuccess').modal('show');
                        this.loading = false;
                        this.updateUerPasswordChangeTime();
                        sessionStorage.removeItem('forgotPasswordUserName');

                    },
                    err => {
                        this.errorMessage = 'Sorry something went wrong, please choose a different password or try again later.';
                        jQuery('#myModal').modal('show');
                        this.loading = false;
                        return;
                    }
                );
        }
    }
    validatePassword() {
        if (this.cred === this.confirmCred) {
            if (this.fieldValidator.validateCred(this.cred)) {
                return true;
            } else {
                this.fieldValidateMsg['cred'] = 'Password does not meet requirements';
                this.fieldValidateMsg['confirmCred'] = 'Password does not meet requirements';
                this.setFieldValidates(['cred', 'confirmCred']);
            }
        } else {
            this.fieldValidateMsg['cred'] = 'Passwords Do not Match. Please verify';
            this.fieldValidateMsg['confirmCred'] = 'Passwords Do not Match. Please verify';
            this.setFieldValidates(['cred', 'confirmCred']);
        }

        return false;
    }

    private setFieldValidates(fieldNameArr: string[]) {
        for (const fieldName of fieldNameArr) {
            this.isFiledValid[fieldName] = false;
        }
    }

    showLoginPage(): void {
        this.router.navigate(['./login']);
    }

    isUndefinedOrEmpty(value: string) {
        if (value === undefined || value === '') {
            return true;
        }
        return false;
    }

    isUndefinedOrTrue(value: boolean) {
        if (value === undefined || value) {
            return true;
        }
        return false;
    }

    isUndefinedOrFalse(value: boolean) {
        if (value === undefined || value === false) {
            return true;
        }
        return false;
    }

    onInputFocus(event: any) {
        this.hideValidate(event.target.name);
    }

    hideValidate(inputName: string) {
        this.isFiledValid[inputName] = true;
    }

    private resetInputValidations() {
        const fieldNameArr = ['password', 'confirmPassword'];
        for (const fieldName of fieldNameArr) {
            this.isFiledValid[fieldName] = true;
        }
    }

    private setFieldValidateMsg(fieldNameArr: string[]) {
        for (const fieldName of fieldNameArr) {
            this.fieldValidateMsg[fieldName] = '';
        }
    }

    private checkForEmptyField(fieldNameArr: string[]) {
        for (const fieldName of fieldNameArr) {
            this.isFiledValid[fieldName] = this[fieldName] !== '' ? true : false;
        }
    }

    private showPopuup(message: string) {
        this.errorMessage = message;
        jQuery('#myModal').modal('show');
        return;
    }
    getNews() {
        this.loading = true;
        this.credService.getNews((success: boolean, news: any) => {
            if (success) {
                this.news = news['providerPortalBroadcast'];
                this.loading = false;
            } else {
                this.loading = false;
            }
        });
    }
    updateUerPasswordChangeTime() {
        // const paswordUpdateuserName = this.secureStorage.getSecureInfoInSessionStorage('forgotPasswordUserName');
        const current_date = new Date();
        const changePasswordTimePayload = {
            userName: this.paswordUpdateuserName,
            lastPasswordChange: this.getFormattedDateStr(current_date)
        };
        this.credService.updateUerPasswordChangeTime(changePasswordTimePayload, (success: boolean, message: string) => {
            if (success) {
                return;
            }
        });
    }
    getFormattedDateStr(date: Date) {
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' 00:00:00:000';
    }

    ngOnDestroy() {
        this.forgotpasswordquestionsService.usernameFromUrl.unsubscribe();
    }


}
