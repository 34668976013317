import { Injectable } from '@angular/core';
import { ConfigProvider } from './configprovider';
import { environment } from '../../../environments/environment';
import { ConfigModel } from '../models/config.model';


@Injectable()

export class UrlconstantsService {
  private envConfig = 'env.json';
  private baseContext = '/cupauth/cup/api/';
  private messageContext = '/providerportalmsg/api/';
  private edhContext = '/edh/';
  private authContext = '/auth/oauth/token';
  private fileNetContext = '/EDSWebServicesREST/document/';
  private edh4Context = '/cupauth/edh4/';
  private officeContext = '/providerauth/providerportalmsg/api/';
  private baseUrl;
  private edhUrl;
  public authUrl;
  //public messageUrl;
  public fileNetUrl;
  public fileNetListUrl;
  private config: ConfigModel;
  //public officeUrl;
edh4Url;

  constructor(private configProvider:ConfigProvider) {
    this.config = this.configProvider.getConfig();
    this.authUrl = this.config.cup_base_url + this.authContext;
    this.baseUrl = this.config.cup_base_url + this.baseContext;
    this.edhUrl = this.config.cup_base_url + this.edhContext;
    this.edh4Url = this.config.cup_base_url +  this.edh4Context;
    //this.messageUrl = this.config.cup_base_url + this.messageContext;
    //this.officeUrl = this.config.cup_base_url + this.officeContext;
    this.fileNetUrl = '/eds/1.0/document/';
    this.fileNetListUrl = '/eds/1.0/listDocuments';
  }

  public getApiDomain() {
    return this.config.cup_base_url;
  }

  public getEdhDomain() {
    return this.config.cup_base_url;
  }

  /**Determine the respective URL's*/
  public fetchUrl() {

    const urls = {
      'envConfig': this.envConfig,
      'sendMessage': '/v1.0/sendmessage/',
      'getSentMessages': this.messageContext.concat('v1.0/sentmessage/'),
      'deleteMessages': this.messageContext.concat('v1.0/deletemessage/'),
      'getreceivedMessages': this.messageContext.concat('v1.0/receivedmessage/'),
      'replyMessage': this.messageContext.concat('v1.0/{messageUid}/'),
      'recipientTypes': this.messageContext.concat('v1.0/recipienttype/'),
      'messageApplications' : this.messageContext.concat('v1.0/applications'),

      'recipientgroup': '/v1.0/recipientgroup/',
      'recipient' : '/v1.0/recipient/',


      'loggedInUserTemplate': this.baseUrl.concat('v1.0/template/user/configuration'),
      'memberSearch': this.baseUrl.concat('v1.0/member/'),
      'memberQualityMeasure': this.edh4Url.concat('v1.1/qualityMeasure/'),
      'memberAccessPermisiion': this.baseUrl.concat('v1.0/member/accesspermission/'),
      'insuranceCarrier': this.baseUrl.concat('v1.0/lookup/client/'),
      'memberDetails': this.baseUrl.concat('v1.0/member/{memberid}/'),
      'providerId': this.baseUrl.concat('v1.0/provider/providerid/{provid}'),
      'statusAlert': this.baseUrl.concat('v1.0/member/{memberid}/notecategory'),
      'memberNote': this.baseUrl.concat('v1.0/member/{memberid}/note/'),
      'getEligibility': this.baseUrl.concat('v1.0/member/{memberid}/geteligibility/'),
      'submitEligibility': this.baseUrl.concat('v1.0/member/{memberid}/eligibility/'),

      'providerSearch': this.baseUrl.concat('v1.0/provider/'),
      'providerDetails': this.baseUrl.concat('v1.0/provider/{providerid}/'),
      'providerDashboard': '/providerauth/providerportal/v1.1/getCUPSiteuser',

      'diagnosis_codes': this.baseUrl.concat('v1.0/codes/diagnosis'),
      'procedure_codes': this.baseUrl.concat('v1.0/codes/procedure'),
      'modifier_codes': this.baseUrl.concat('v1.0/codes/modifier'),

      'planDetails': this.edhUrl.concat('v1.0/memberEligibility/{clientid}/{memberid}'),
      'qualityMeasures': 'NexGen/v1.0/getMemberMeasuresAmaze/{memberid}',
      'viewClaims': this.baseUrl.concat('v1.0/member/claims/'),
      'claimDetails': this.baseUrl.concat('v1.0/member/claimdetails/'),

      'ipa': this.baseUrl.concat('v1.0/lookup/region/'),
      'specialty': this.baseUrl.concat('v1.0/lookup/specialty/'),
      'product': this.baseUrl.concat('v1.0/lookup/product/'),
      'neighborhood': this.baseUrl.concat('v1.0/lookup/neighborhood/'),
      'neighborhoodByRegion': this.baseUrl.concat('v1.0/lookup/neighborhoodforregion/?regionuid={regionuid}'),
      'regionByNeighborhood': this.baseUrl.concat('v1.0/lookup/regionforneighborhood/?neighborhooduid={neighborhooduid}'),
      'zipcode': this.baseUrl.concat('v1.0/lookup/zipcodecity/?code={zipcodecity}'),
      // 'zipcode': this.baseUrl.concat('v1.0/lookup/zipcode/'),
      'statecode': this.baseUrl.concat('v1.0/lookup/state/'),
      'authCategory': this.baseUrl.concat('v1.0/lookup/authtypecategory/'),
      'authRequestedBy': this.baseUrl.concat('v1.0/lookup/requestbytype/?'),
      'authPlaceOfService': this.baseUrl.concat('v1.0/lookup/placeofservice/'),
      'authNoteType': this.baseUrl.concat('v1.0/lookup/notetype/?'),
      'noteSource': this.baseUrl.concat('v1.0/lookup/notesource'),
      'noteCategory': this.baseUrl.concat('v1.0/lookup/notecategory'),
      'client': this.baseUrl.concat('v1.0/lookup/client'),
      'lineofbusiness': this.baseUrl.concat('v1.0/lookup/lineofbusiness'),
      'queueNames': this.baseUrl.concat('v1.0/lookup/queueNames'),
      'authSupplementalSections': this.baseUrl.concat('v1.0/lookup/supplementalsections'),
      'authSupplementalDataTypes': this.baseUrl.concat('v1.0/lookup/supplementaldatatypes'),
      'userTemplateName': this.baseUrl.concat('v1.0/lookup/userauthtemplates/{userroleuid}'),
      'authDenialReasons' : this.baseUrl.concat('v1.0/lookup/authdenialreasons'),
      'authDenialReasonCodes' : this.baseUrl.concat('v1.0/lookup/authdenialreasoncodes'),
      'denialReason': this.baseUrl.concat('v1.0/authreasons/denialtext'),
      'denialAuthReason': this.baseUrl.concat('v1.0/lookup/authdenialreasons'),
      'getAuthReasons' : this.baseUrl.concat('v1.0/authorization/authreasons'),
      'submitAuthReason' : this.baseUrl.concat('v1.0/authreason'),
      'authSubmission': this.baseUrl.concat('v1.0/authorization/'),
      'denialText': this.baseUrl.concat('v1.0/authreasons/denialtext/authorization/{authid}'),
      'checkDuplicateAuth': this.baseUrl.concat('v1.0/authorization/checkDuplicate/'),
      'authDetails': this.baseUrl.concat('v1.0/authorization/{authid}/'),
      'authDiagnosisDetails': this.baseUrl.concat('v1.0/authorization/diagnosis/{authid}/?'),
      'authProcedureDetails': this.baseUrl.concat('v1.0/authorization/proceduere/{authid}/?'),
      'authSubmissionSave': this.baseUrl.concat('v1.0/authorization/'),
      'authStatus': this.baseUrl.concat('v1.0/lookup/authstatus/'),
      'providerNotFound': this.baseUrl.concat('v1.0/authorization/provideroverride/'),
      'generalAuthStatus': this.baseUrl.concat('v1.0/lookup/authstatus/'),
      'generalAuthReason': this.baseUrl.concat('v1.0/lookup/authreasons/{authReasonType}/'),
      'getAllAuthReasons': this.baseUrl.concat('v1.0/lookup/authreason/'),
      'dualview': this.baseUrl.concat('v1.0/member/{memberid}/dualview'),
      'memberAuthList': this.baseUrl.concat('v1.0/member/{memberid}/authorization'),
      'generalAuthList': this.baseUrl.concat('v1.0/general/authorization/view'),
      'authorizationList': this.baseUrl.concat('v1.0/authorization/'),
      'generalAuthSearch': this.baseUrl.concat('v1.0/general/authorization/search/'),
      'viewPendingWorklist': this.baseUrl.concat('v1.0/worklist/pending/'),
      'preferredLanguage': this.baseUrl.concat('v1.0/lookup/language'),
      'pendingAuthorization': this.baseUrl.concat('v1.0/worklist/pending/queue/'),
      'userAuthorization': this.baseUrl.concat('v1.0/user/{userid}/'),
      'authNoteAdd': this.baseUrl.concat('v1.0/authorization/{authUid}/authnote/'),
      'authNoteUpdate': this.baseUrl.concat('v1.0/authorization/authnote/{authNoteUid}/'),
      'authTemplateNameList': this.baseUrl.concat('v1.0/authorization/template/authtype/{authTypeid}/'),
      'authTemplateId': this.baseUrl.concat('v1.0/authorization/template/{templateId}/'),
      'viewTemplateList': this.baseUrl.concat('v1.0/authorization/authtemplate/'),
      // 'authNoteGet': this.baseUrl.concat('v1.0/authorization/authsummarynote/{authUid}/'),
      'supplementalAuth': this.baseUrl.concat('v1.0/supplemental/{authtypeuid}/{statusUid}/{authuid}/'),
      'supplementalAuthFetch': this.baseUrl.concat('v1.0/authorization/supplemental/{authuid}/'),
      'supplementalAuthUpdate': this.baseUrl.concat('v1.0/authorization/supplemental/'),
      'supplementalAuthSectionConfigFetch':  this.baseUrl.concat('v1.0/supplemental/section/configuration/'),
      'supplementalAuthSectionConfigDelete':  this.baseUrl.concat('v1.0/supplemental/section/configuration/inactive/'),
      'supplementalAuthCategory':  this.baseUrl.concat('v1.0/supplemental/category/{authtypeuid}'),
      'createSupplementalElement': this.baseUrl.concat('v1.0/authorization/supplementalelement/'),
      'supplementalElementFetch': this.baseUrl.concat('v1.0/authorization/authsupplementalelements/'),
      'protocolType': this.baseUrl.concat('v1.0/lookup/protocoltype'),
      'authGuideLines': this.baseUrl.concat('v1.0/lookup/authguidelines'),
      'protocolFilterElement': this.baseUrl.concat('v1.0/lookup/protocolelement/true/'),
      'letterFilterElement': this.baseUrl.concat('v1.0/lookup/protocolelement/false/'),
      'protocolFilterOperators': this.baseUrl.concat('v1.0/admin/protocolconfig/{protocolElementId}'),
      'protocolConfigSave': this.baseUrl.concat('v1.0/admin/protocol/'),
      'duplicateProtocolCheck': this.baseUrl.concat('v1.0/admin/checkduplicate/'),
      'protocolUpdateElements': this.baseUrl.concat('v1.0/lookup/protocoloutputelements/'),
      'protocolDetailsGet': this.baseUrl.concat('v1.0/admin/protocol/{protocolUid}'),
      'letterDetailsGet': this.baseUrl.concat('v1.0/admin/letter/{protocolUid}'),
      'searchProtocol': this.baseUrl.concat('v1.0/admin/protocolsearch/'),
      'fileUploadMetaData': this.baseUrl.concat('v1.0/fileupload/metadata/{authuid}'),
      'memberFileUploadMetaData': this.baseUrl.concat('v1.0/fileupload/member/{memuid}'),
      'userSiteType': this.baseUrl.concat('v1.0/lookup/sitetype'),
      // 'userSites': this.baseUrl.concat('v1.0/admin/usersites/'),
      'userSiteProviderDuplicateCheck': this.baseUrl.concat('v1.0/admin/usersite/checkduplicate/'),
      'userSiteSave': this.baseUrl.concat('v1.0/admin/usersite/'),
      'userSiteGet': this.baseUrl.concat('v1.0/admin/usersite/{siteUid}/'),
      'userSiteProviders': '/providerauth/providerportal/v1.1/getCUPSitesActiveUsers/',
      'templateUserType': this.baseUrl.concat('v1.0/lookup/usertype'),
      'templateUserRole': this.baseUrl.concat('v1.0/lookup/userrole'),
      'templateUserSite': this.baseUrl.concat('v1.0/lookup/site'),
      'authTemplate': this.baseUrl.concat('v1.0/authorization/template/'),
      'templateUserConfigElements': this.baseUrl.concat('v1.0/template/configuration'),
      'viewAuthGuidelines': this.baseUrl.concat('v1.0/admin/authguideline/search/'),
      'duplicateAuthGuideLineDescCheck': this.baseUrl.concat('v1.0/admin/checkduplicatedescription/'),
      'authGuidelinesSave': this.baseUrl.concat('v1.0/admin/authguideline/'),
      'userSiteDelete': this.baseUrl.concat('v1.0/admin/usersite/deletesite/'),
      'templateAccessPermision': this.baseUrl.concat('v1.0/template/submitconfiguration/'),
      'duplicateTemplate': this.baseUrl.concat('v1.0/template/duplicatetemplate/'),
      'authStatusUpdate': this.baseUrl.concat('v1.0/authorization/status/'),
      'questionDataTypes': this.baseUrl.concat('v1.0/lookup/datatype/'),
      'guidelineDetails': this.baseUrl.concat('v1.0/admin/authguideline/{guidelineUid}/'),
      'viewAccessPermissionTemplate': this.baseUrl.concat('v1.0/template/usertemplates/'),
      'viewAccessConfiguration': this.baseUrl.concat('v1.0/useraccesspermission/'),
      'deleteAccessPermissionTemplate': this.baseUrl.concat('v1.0/template/deleteconfiguration/'),
      'authGuideLineQuestions': this.baseUrl.concat('v1.0/admin/authguidelinequestion/{authUid}'),
      'authGuideLineQuestionsSave': this.baseUrl.concat('v1.0/admin/authguidelineresponse'),
     'deniedWorklist': this.baseUrl.concat('v1.0/worklist/denied'),
      'fetchAccessPermissionTemplate': this.baseUrl.concat('v1.0/template/user/usertemplates/'),
      'modifyAccessPermissionTemplate': this.baseUrl.concat('v1.0/template/configuration/'),
      'authGuidelineDelete': this.baseUrl.concat('v1.0/admin/authguideline/deleteguideline/'),
      'letteroutputelements': this.baseUrl.concat('v1.0/lookup/letteroutputelements/'),
      'recipienttype': this.baseUrl.concat('v1.0/lookup/recipienttype/'),
      'lettertemplates': this.baseUrl.concat('v1.0/lookup/lettertemplates/'),
      'adhocLettertemplates': this.baseUrl.concat('v1.0/lookup/adhoctemplates/'),
      'createAdhocLetter': this.baseUrl.concat('v1.0/authorization/adhocletter/'),
      'previewAdhocLetter': this.baseUrl.concat('v1.0/authorization/adhocletter/preview/'),
      'adhocLetterParameters': this.baseUrl.concat('v1.0/authorization/adhoctemplateparameter/{templateId}/'),
      'deliverychannel': this.baseUrl.concat('v1.0/lookup/deliverychannel/'),
      'duplicateLetterRuleNameUrlCheck': this.baseUrl.concat('v1.0/admin/letter/checkduplicate/'),
      'addUserConfiguration' : this.baseUrl.concat('v1.0/useraccesspermission/'),
      'editUserConfiguration' : this.baseUrl.concat('v1.0/useraccesspermission/{useruid}'),
      'internalExternalUser' : this.baseUrl.concat('v1.0/users/'),
      'searchQueue': this.baseUrl.concat('v1.0/admin/authqueue/search/'),
      'createQueue' : this.baseUrl.concat('v1.0/admin/authqueue/'),
      'deleteQueue' : this.baseUrl.concat('v1.0/admin/authqueue/{queueuid}/'),
      'authAttachmentType': this.baseUrl.concat('v1.0/lookup/authattachment/'),
      'memberAttachmentType': this.baseUrl.concat('v1.0/lookup/memberattachment/'),
      'userSitesSearch': this.baseUrl.concat('v1.0/admin/usersite/search/'),
      'adminQueues': this.baseUrl.concat('v1.0/admin/queues/'),
      'preferredcodes': this.baseUrl.concat('v1.0/preferredcodes/'),
      'allpreferredcodes': this.baseUrl.concat('v1.0/allpreferredcodes'),
      'authLetterStatus' : this.baseUrl.concat('v1.0/authorization/authletterstatus/{authuid}'),
      'externalUser': this.baseUrl.concat('v1.0/externalusers'),
      'externalProviderAccess': this.baseUrl.concat('v1.0/external/provideraccess/'),
      'deleteExternalProviderAccess': this.baseUrl.concat('v1.0/external/provideraccess/{useruid}'),
      'externalUserActiveSite': this.baseUrl.concat('v1.0/lookup/activesite'),
      'externalUserSubmit': this.baseUrl.concat('v1.0/externaluser/accesspermission'),
      'externalUserDuplicateCheck': this.baseUrl.concat('v1.0/external/duplicateaccess'),
      'protocolDepartment': this.baseUrl.concat('v1.0/lookup/department'),
      'letterWorklist': this.baseUrl.concat('v1.0/worklist/letter'),
      'copyAuthorization': this.baseUrl.concat('v1.0/authorization/copy/{oldauthuid}'),
      'authAuditHistory' : this.baseUrl.concat('v1.0/authorization/audit/{authuid}/'),
      'attachmentAuditLog': this.baseUrl.concat('v1.0/attachments/audit'),
      'authStatusConfig': this.baseUrl.concat('v1.0/authstatus/configuration'),
      'attachmentLog': this.baseUrl.concat('v1.0/attachments'),
      'deleteAttachmentLog': this.baseUrl.concat('v1.0/attachments/{documentKey}'),
      'lockAuth': this.baseUrl.concat('v1.0/userauthupdatelock/'),
      'viewCaseManagementWorklist': this.baseUrl.concat('v1.0/worklist/casemanagement/'),
      'letterErrorsList': this.baseUrl.concat('v1.0/admin/letter/errorletters/'),
      'letterResendOrRetry': this.baseUrl.concat('v1.0/authorization/resendletter/'),
      'letterSuppress': this.baseUrl.concat('v1.0/authletterstatu\s/update/'),
      'printLetters': this.baseUrl.concat('v1.0/worklist/letter/'),
      'printLettersDocument': this.baseUrl.concat('v1.0/authorization/print/'),
      'viewCaseManagementAuthList': this.baseUrl.concat('v1.0/worklist/concurrent/'),
      'authExtractOnDemand': this.baseUrl.concat('v1.0/authexport/{authuid}'),
      'searchInternalUsers': this.baseUrl.concat('v1.0/user/useraccesspermission/'),
      'clientAssociation': this.baseUrl.concat('v1.0/client/{useruid}'),
      'admitType': this.baseUrl.concat('v1.0/lookup/admittype'),
      'dischargeDisposition': this.baseUrl.concat('v1.0/lookup/dischargestatus'),
      'querytype': this.baseUrl.concat('v1.0/helpdesk/querytype/'),
      'sendquery': this.baseUrl.concat('v1.0/helpdesk/sendquery/'),
      'denialCode': this.baseUrl.concat('v1.0/lookup/denialcode'),
      'denialReasonLanguage': this.baseUrl.concat('v1.0/lookup/language'),
      'denialReasonSortPriority': this.baseUrl.concat('v1.0/denialreason/{sortpriority}'),
      'addDenialReason': this.baseUrl.concat('v1.0/denialreason/'),
      'viewDenialReason': this.baseUrl.concat('v1.0/denialreason/view'),
      'sendToChc': this.baseUrl.concat('v1.0/authorization/authletters/sendtochc/'),
      'denialReasonUpdatePriority': this.baseUrl.concat('v1.0/denialreason/duplicate/'),
      'providerGeocode' : this.baseUrl.concat('v1.0/provider/geocode/{memberuid}/?specialtyuid={specialtyuid}'),
      
      'getOutOfOfficeMessage': this.officeContext.concat('v1.0/outofoffice/{userId}'),
      'saveOutOfOfficeMessage': this.officeContext.concat('v1.0/outofoffice/'),
      'deleteOutOfOfficeMessage': this.officeContext.concat('v1.0/outofoffice/{userId}'),
      //QM URLs
      'qmDashboard':  this.edh4Url.concat('v1.1/qmDashboard/'),
      'qmMemberListView':  this.edh4Url.concat('v1.1/qmMemberListView/'),
      'errorLog': this.baseUrl.concat('v1.0/errorlog'),
      'newqmDashboard': this.edh4Url.concat('v1.2/qmDashboard/'),
      //'providerAffiliation': this.baseUrl.concat('v1.0/provider/affiliation/'),
      //'providerAffiliation': 'cupauth/cup/api/v1.0/provider/affiliation/',
      //'measureStarRating':'/providerdetails/getStarRating?measureId={measureId}&targetPercent={targetPercent}',
      };
    return urls;
  }
}


