<div id="main-wrapper">
	<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
	<div class="container-fluid" style="padding: 0;">
		<app-header (found)="handleResults($event)"></app-header>
		<div class="row col-md-12 set-pad" style="margin-top: 10%;">
			<div class="main-container">
				<app-search-results [resultGridList]="data"></app-search-results>
			</div>
			<!-- <div class="col-md-1 col-xs-2"></div> -->
			<div class="col-md-10 col-xs-10 faqContainer">
				<div class="row">
					<div class="col-12">
						<h2 class="section__heading header">
							Frequently Asked Questions
						</h2>
					</div>
				</div>
				<div class="col-md-12 text-left messages">
					<accordion>
						<accordion-group heading=" {{faq.question}} " *ngFor="let faq of faqs">
							<p class="broadcastBody" [innerHTML]="faq.answer"> </p>
						</accordion-group>
					</accordion>
				</div>
			</div>
			<div class="col-md-1 col-xs-2"></div>
		</div>
	</div>

	<br><br><br><br><br>
	<app-footer ></app-footer>
</div>