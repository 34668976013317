import { Injectable } from '@angular/core';
import { urls, payloads } from '../common/model/properties';
import { ConfigProvider } from '../shared/services/configprovider';
import { Observable, Observer } from 'rxjs';
import * as xml2js from 'xml2js';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SecureStorageService } from '../secure-storage.service';


@Injectable()
export class ValidatedocumentService {
token:any;  
props: any;  
a:any;
  constructor(private http: HttpClient, private configProvider: ConfigProvider ,
    private secureStorage:SecureStorageService) {
    this.props = configProvider.getProps();
   }
  public handleError(error: any, requestInfo? : any)  {
    try {
      if (!error) {
        return;
      }
      let errorMsg = error.body;
      switch (error.status) {
        case 500: {
          errorMsg = "server is down please try after some time";
        }
          break;
        case 404: {
          if (!error.body) {
            errorMsg = "method is not found";
          }
        }
          break;
        case 403: {
          if (!error.body) {
            errorMsg = "access denied";
          }
        }
          break;
        case 200: { // 200 with error
          let errorInfo = error;
          if (errorInfo.errorCode || errorInfo.errorDescription) {
            errorMsg = errorInfo.errorDescription || errorInfo.description;
          }
        }
          break
        default: {
          errorMsg = error.body;
        }
          break;
      }
     
      
    } catch(err) {

    }
  }  
  
  


  requestDocumnetType(caseId, callBack: (sucess: boolean, message: any) => void) {
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});

    this.http.get(urls.requestDocumentTypes+'caseId='+caseId,{headers: headers, observe:'response'}).subscribe(
        (resp:any) => { 
              if (resp.status === 200) {
                  if(resp['body'] === '')
                  {
                      callBack(false, resp);
                      //console.log("Empty Response") 
                  }
                  else{
                  callBack(true, resp);
                  //console.log(resp['_body'])
                  }   
              } else {
                  callBack(false, resp);
                  //console.log("Failed to process the request")
              }
          },
          
      );  
}
retrieveDocuments(appId,documentCode,keyValuePairList,callBack: (sucess: boolean, message: any) => void) {
    this.http.get(urls.filenetListURL+'appId='+appId+'&documentCode='+documentCode+'&keyValuePairList='+(JSON.stringify(keyValuePairList)),{observe:'response'}).subscribe(
        (resp) => { 
              if (resp.status === 200) {
                  if(resp['body'] === '')
                  {
                      callBack(false, resp);
                      //console.log("Empty Response") 
                  }
                  else{
                  callBack(true, resp);
                  //console.log(resp['_body'])
                  }   
              } else {
                  callBack(false, resp);
                  //console.log("Failed to process the request")
              }
          },
          
      );

        }
        public uploadFile(url: string, formData): Observable<any> {
            return Observable.create(observer => {
              let xhr: XMLHttpRequest = new XMLHttpRequest();
              xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                  if (xhr.status === 200) {
                    setTimeout(() => {
                      let xml = xhr.response;
                      xml2js.parseString(xml, { explicitArray: false }, function (err, result) {
                        observer.next(result);
                      });
                      observer.complete();
                    }, 1000);
                  } else {
                    observer.error(xhr.response);
                  }
                }
              };
              xhr.open("POST", url, true);
              xhr.send(formData);
            }).catch(error => {
              throw this.handleError(error, formData ? formData : url);      
            });
          }

          public retrieveData(method: string, url: string, body?: any): Observable<any> {
            if (method && (method.toUpperCase() == 'GET' || method && method.toUpperCase() == 'DELETE')) {
              return this.http[method](url)
                .map((response: any) => {
                  return response;
                });
            } else {
              return this.http[method](url, body)
                .map((response: any) => {
                  return response;
                });
            }
          }
          getBasicToken(): Observable<any> {
            const body = new HttpParams({
              fromObject:
              {'grant_type': 'client_credentials',
              
            }});
            const headers = new HttpHeaders({
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization': this.props.cup_login_authorization
            });
            return this.http.post(urls.loginUploadTokenURL, body.toString(), { headers: headers });
        }
    
    documentList(kvpl,access_token): Observable<any>{
      const headers = new HttpHeaders({
        'Authorization': 'Bearer '+access_token
      });
         
          const myParams = new HttpParams()
          .set( 'appId', 'PCS' )
          .set( 'documentCode', 'PROVIDER_DOC' )
          .set( 'keyValuePairList', JSON.stringify( kvpl ) );
          const options =  { headers: headers, params: myParams } ;
         
          return this.http.get( urls.documentList, options );
    }
    onBoardingChecklist(kvpl,access_token): Observable<any>{
      const headers = new HttpHeaders({
        'Authorization': 'Bearer '+access_token
      });

          headers.set( 'Authorization', 'Bearer '+access_token);
          const myParams = new HttpParams()
          .set( 'appId', 'PCS' )
          .set( 'documentCoder', 'Credentialing' )
          .set( 'keyValuePairList', JSON.stringify( kvpl ) );
          const options =  { headers: headers, params: myParams };
          
          return this.http.get( urls.uploadDocument, options );
    }
    pecosandCaqhFormDocument(kvpl,access_token): Observable<any>{
      const headers = new HttpHeaders({
        'Authorization': 'Bearer '+access_token
      });

          
      const myParams = new HttpParams()
      .set( 'appId', 'PCS' )
      .set( 'documentCoder', 'Credentialing' )
      .set( 'keyValuePairList', JSON.stringify( kvpl ) )
      const options = { headers: headers, params: myParams } ;
          return this.http.get( urls.uploadDocument, options );
    }
  getUploadedDoc(docID): Observable<any> {
      this.getBasicToken();
      let uploadDocUrl = urls.getUploadedDocURL.replace('docID', docID);
      const headers = new HttpHeaders({
        'Authorization': 'Bearer '+this.secureStorage.getSecureInfoInLocalStorage('access_token')
      });
      const options = { headers: headers} ;
      return this.http.get(uploadDocUrl,options);
  }
  deleteuploadedDoc(docID,providerName):Observable<any>{
    this.getBasicToken();
    let deleteDocUrl=urls.deleteuploadedDocURL;
    deleteDocUrl=deleteDocUrl.replace('docID', docID);
    deleteDocUrl=deleteDocUrl.replace('PROVIDER_NAME',providerName)
    const headers = new HttpHeaders({
      'Authorization': 'Bearer '+this.secureStorage.getSecureInfoInLocalStorage('access_token')
    });
   
    const options =  { headers: headers} ;
    return this.http.delete(deleteDocUrl,options);
  }

  getDocumentTypes(caseId: string): Observable<any> {
    const url = urls.requestDocumentTypes + 'caseId=' + caseId;
    const token: any = this.secureStorage.getSecureInfoInLocalStorage('clientCredentials_token');
    const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + token
    });

    return this.http.get(url, { headers: headers })
     
  }
}
