import { Component, OnInit } from '@angular/core';
import { BroadcastHistoryService } from './broadcast-history.service';
import { TruncatePipe } from './../truncate.pipe';


@Component({
  selector: 'app-broadcast-history',
  templateUrl: './broadcast-history.component.html',
  styleUrls: ['./broadcast-history.component.scss'],
  providers: [BroadcastHistoryService, TruncatePipe],
})
export class BroadcastHistoryComponent implements OnInit {
  loading = false;
  data = [];
  handleResults(searchObj) {
      this.data = searchObj;
  }
  constructor(private broadcastHistory: BroadcastHistoryService, ) { }

  broadcast = [];
  ngOnInit() {
     this.getBroadcastHistory();
  }
  getBroadcastHistory() {
    this.loading = true;
    this.broadcastHistory.getBroadcastHistory(( success: boolean, broadcast: any ) => {
        if ( success ) {
          this.loading = false;
          this.broadcast = broadcast['providerPortalBroadcast'];
        } else {
          this.loading = false;

        }
    } );

}
}
