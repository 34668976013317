<app-common></app-common>
<div class="container-fluid" [ngClass] = "{'disable-menu':isHeaderDisabled}">
  <div class="header-container">
    <div id="mySidenav" class="sidenav">
      <a href="javascript:void(0)" class="closebtn" (click)="closeNav()"
        >&times;</a
      >
      <div class="topnav">
        <div class="main-container fullHeight">
          <div class="fullHeight centerElementParent">
            <div class="headerTxt">
              <a
                class="navbar-brand text-hide"
                href="https://www.caremore.com/"
              >
                <img src="assets/images/logo.svg" alt="Carelon" />
              </a>
              <span class="header-title"> Provider Portal</span>
            </div>

            <div class="dropdown userDropDown" style="min-width: 140px">
              <a
                class="cool-link"
                href="#"
                class="dropdown-toggle userIcon"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <a class="userIcon1">
                  <span class="icon-group-text">{{ fullName }}</span>
                  <span class="caret"></span>
                </a>
              </a>
              <ul style="width: max-content" class="dropdown-menu">
                <li *ngIf="isExternalUser">
                  <a routerLink="/providerportal/accountProfile">
                    <span aria-hidden="true"></span>
                    <span class="icon-group-text">View Profile</span>
                  </a>
                </li>
                <li *ngIf="isExternalUser">
                  <a routerLink="/providerportal/changePassword">
                    <span aria-hidden="true"></span>
                    <span class="icon-group-text">Change Password</span>
                  </a>
                </li>
                <li class="divider"></li>
                <li *ngFor="let doc of userAgreementdocuments">
                  <a (click)="getDocument(doc)">
                    <span aria-hidden="true"></span>
                    <span class="icon-group-text textLink"
                      >End User Agreement</span
                    >
                  </a>
                </li>
                <li *ngFor="let doc of userPrivilegesdocuments">
                  <a (click)="getDocument(doc)">
                    <span aria-hidden="true"></span>
                    <span class="icon-group-text textLink"
                      >End User Privileges</span
                    >
                  </a>
                </li>
                <li>
                  <a routerLink="/providerportal/broadcastHistory">
                    <span aria-hidden="true"></span>
                    <span class="icon-group-text">Broadcast History</span>
                  </a>
                </li>
                <li class="divider"></li>
                <li>
                  <a href="javascript:void(0);" (click)="logoutAction()">
                    <span
                      class="glyphicon glyphicon-log-out"
                      aria-hidden="true"
                    ></span>
                    &nbsp;
                    <span class="icon-group-text">Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar-collapse" [ngClass]="{ show: collapse }">
        <ul class="nav navbar-nav navbar-rights rightNav">
          <!-- <li class="inboxIcon">
            <a
              class="cool-link"
              routerLink="/providerportal/messages"
              #rtl="routerLinkActive"
              routerLinkActive="active"
            >
              <a class="notification"
                ><span>Inbox </span>
                <span
                  class="notifi"
                  *ngIf="inboxUnReadMsgCount && inboxUnReadMsgCount != '0'"
                  >{{ inboxUnReadMsgCount }}</span
                >
              </a>
            </a>
          </li> -->
          <li class="dropdown documentsIcon">
            <a
              href="#"
              class="dropdown-toggle cool-link"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              #rtl="routerLinkActive"
              routerLinkActive="active"
            >
              <span class="icon-group-text">Documents</span>
              <span class="caret"></span>
            </a>
            <ul
              style="width: max-content"
              class="dropdown-menu documentMenu innerMenuHomepage"
            >
              <li>
                <a
                  style="white-space: normal"
                  routerLink="/providerportal/documents"
                  [queryParams]="{ documentCode: 'GEN_REF_DOC' }"
                  >General Reference</a
                >
              </li>
              <!-- <li>
                <a
                  style="white-space: normal"
                  routerLink="/providerportal/documents"
                  [queryParams]="{ documentCode: 'PROV_COMM_DOC' }"
                  >Provider Communication</a
                >
              </li> -->

              <li>
                <a
                  style="white-space: normal"
                  routerLink="/providerportal/documents"
                  [queryParams]="{ documentCode: 'CHANGE_HEALTH_CARE' }"                    
                  >Provider Payments</a>
              </li>
            </ul>
          </li>
          <li *ngIf="userProfile?.userRole === 'Admin'" class="manageUersIcon">
            <a
              class="cool-link"
              routerLink="/providerportal/site_Administration"
              #rtl="routerLinkActive"
              routerLinkActive="active"
            >
              <span class="icon-group-text">Manage Users</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="topnav mobTopNav">
      <span class="openIcon" (click)="openNav()"
        >&#9776;
        <span class="header-title">
          Provider Portal
          <span class="header-sub-title">
            Previous Login : {{ formatDate(userProfile?.lastLogin) }}
          </span>
        </span>
      </span>
      <div class="main-container fullHeight topBlock">
        <div class="fullHeight centerElementParent">
          <div class="centerElementChild headerTxt">
            <a
              class="navbar-brand text-hide fullHeight centerElementChild"
              href="https://www.caremore.com/"
            >
              <img src="assets/images/logo.svg" alt="Carelon"  style="width: 150px;"/>
            </a>
            <span class="header-title">
              Provider Portal
              <span class="header-sub-title">
                Previous Login : {{ formatDate(userProfile?.lastLogin) }}
              </span></span
            >
          </div>
          <!-- <div (click)="AlertAction()">
            <i class="fa fa-bell bell-con-move"
              ><span
                *ngIf="unReadAlertCount && unReadAlertCount != '0'"
                class="unread-message-count-gen"
                >{{unReadAlertCount }}</span
              ></i
            >
          </div> -->
          <!--search starts-->
          <div class="form-inline searchInputHeader">
            <form action="">
              <i class="glyphicon glyphicon-search searchBox-icon"></i>
              <input
                class="form-control searchBox"
                type="text"
                placeholder="Search by keyword"
                aria-label="Search"
                name="Search"
                [(ngModel)]="searchQuery"
                #searchQueryInput
               
                autocomplete="off"
              />
            </form>   
          </div>
          <!--search ends-->
         
          
            <div class="inboxIcon centerElementChild">
              <a
                class=""
                routerLink="/providerportal/messages"
                #rtl="routerLinkActive"
                routerLinkActive="active"
              >
                <a class="notification"
                  ><span>Inbox </span>
                  <span
                    class="notifi"
                    *ngIf="inboxUnReadMsgCount && inboxUnReadMsgCount != '0'"
                    >{{ inboxUnReadMsgCount }}</span
                  >
                </a>
              </a>
            </div>

            <div class="centerElementChild notification-bell">
                <i class="fa fa-bell-o bell-con-move " (click)="AlertAction()"
                  ><span 
                    *ngIf="unReadAlertCount && unReadAlertCount != '0'"
                    class="unread-message-count-gen"
                    >{{unReadAlertCount }}</span
                  ></i> <span (click)="AlertAction()" class="notification-text">Notifications</span>
            </div>

            <div
            class="dropdown centerElementChild"
            style="min-width: 140px;"
          >
 
            <a
              class="cool-link"
              href="#"
              class="dropdown-toggle userIcon"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <a class="userIcon1">
                <span class="icon-group-text">{{ fullName }}</span>
                <span class="caret"></span>
              </a>
            </a>
            <ul style="width: max-content" class="dropdown-menu">
              <li *ngIf="isExternalUser">
                <a routerLink="/providerportal/accountProfile">
                  <span aria-hidden="true"></span>
                  <span class="icon-group-text">View Profile</span>
                </a>
              </li>
              <li *ngIf="isExternalUser">
                <a routerLink="/providerportal/changePassword">
                  <span aria-hidden="true"></span>
                  <span class="icon-group-text">Change Password</span>
                </a>
              </li>
              <li class="divider" *ngIf="isExternalUser"></li>
              <li *ngFor="let doc of userAgreementdocuments">
                <a (click)="getDocument(doc)">
                  <span aria-hidden="true"></span>
                  <span class="icon-group-text textLink"
                    >End User Agreement</span
                  >
                </a>
              </li>
              <li *ngFor="let doc of userPrivilegesdocuments">
                <a (click)="getDocument(doc)">
                  <span aria-hidden="true"></span>
                  <span class="icon-group-text textLink"
                    >End User Privileges</span
                  >
                </a>
              </li>
              <li>
                <a routerLink="/providerportal/broadcastHistory">
                  <span aria-hidden="true"></span>
                  <span class="icon-group-text">Broadcast History</span>
                </a>
              </li>
              <li class="divider"></li>
              <li>
                <a href="javascript:void(0);" (click)="logoutAction()">
                  <span
                    class="glyphicon glyphicon-log-out"
                    aria-hidden="true"
                  ></span>
                  &nbsp;
                  <span class="icon-group-text">Logout</span>
                </a>
              </li>
            </ul>
          </div>

         

          
        </div>
      </div>
    </div>
    <div class="bottomnav">
      <div class="main-container">
        <ul class="nav navbar-nav navbar-rights pull-left leftNav mainMenu">
          <li>
            <a
              class="cool-link"
              routerLink="/providerportal/portal"
              #rtl="routerLinkActive"
              routerLinkActive="active"
            >
              Home
            </a>
          </li>
          <li>
            <a
              class="cool-link"
              routerLink="/providerportal/provider-dashboard"
              #rtl="routerLinkActive"
              routerLinkActive="active"
            >
              Quality Measures
            </a>
          </li>
          <li>
            <a
              class="cool-link"
              routerLink="/providerportal/provider-clinicalCommunications"
              #rtl="routerLinkActive"
              routerLinkActive="active"
            >
              Clinical Communication
            </a>
          </li>
          <li>
            <a
              class="cool-link"
              routerLink="/providerportal/pahaf"
              #rtl="routerLinkActive"
              routerLinkActive="active"
            >
              PAHAF
              <span
                *ngIf="unReadCountwithAppId && unReadCountwithAppId != '0'"
                class="unread-message-count"
                >{{unReadCountwithAppId }}</span>
            </a>
          </li>

          <li>
            <a
              class="cool-link"
              routerLink="/providerportal/patient-experience"
              #rtl="routerLinkActive"
              routerLinkActive="active"
            >
              Patient Experience
            </a>
          </li>

          <!-- <li>
            <a
              class="cool-link"
              routerLink="/providerportal/pcp-scorecard"
              #rtl="routerLinkActive"
              routerLinkActive="active"
            >
              PCP Score Card
            </a>
          </li> -->
          
          <!--search starts for mobile-->
          <li class="form-inline searchInputHeader mobSearchInput">
            <form action="">
              <i class="glyphicon glyphicon-search "></i>
              <input
                class="form-control searchBox"
                type="text"
                placeholder="Search by keyword"
                aria-label="Search"
                name="Search"
                [(ngModel)]="searchQuery"
                #searchQueryInputMob
                autocomplete="off"
              />
            </form>
          </li>
          <!--search ends for mobile-->
          <!-- <li>
						<div class="form-inline searchInputHeader">
							<form action="">
								<i class="glyphicon glyphicon-search whiteFont"></i>
								<input class="form-control  searchBox" type="text" placeholder="Search by keyword" aria-label="Search" name="Search" [(ngModel)]="searchQuery" (keyup)="searchKeyword(searchQuery)" autocomplete="off">
							</form>
						</div>
					</li> -->
        </ul>
        <div
          class="collapse navbar-collapse"
          [ngClass]="{ show: collapse }"
          id="bs-example-navbar-collapse-1"
        >
          <ul
            class="nav navbar-nav navbar-rights pull-right rightNav rightSection"
          >
         
            <!-- <li class="inboxIcon">
              <a
                class="cool-link"
                routerLink="/providerportal/messages"
                #rtl="routerLinkActive"
                routerLinkActive="active"
              >
                <a class="notification"
                  ><span>Inbox </span>
                  <span
                    class="notifi"
                    *ngIf="inboxUnReadMsgCount && inboxUnReadMsgCount != '0'"
                    >{{ inboxUnReadMsgCount }}</span
                  >
                </a>
              </a>
            </li> -->
            <!-- <li (click)="AlertAction()">
              <i class="fa fa-bell bell-con-move"
                ><span
                  *ngIf="unReadAlertCount && unReadAlertCount != '0'"
                  class="unread-message-count-gen"
                  >{{unReadAlertCount }}</span
                ></i
              >
            </li> -->
            <li
              *ngIf="userProfile?.userRole === 'Admin'"
              class="manageUersIcon"
            >
              <a
                class="cool-link"
                routerLink="/providerportal/site_Administration"
                #rtl="routerLinkActive"
                routerLinkActive="active"
              >
                <span class="icon-group-text">Manage Users</span>
              </a>
            </li>

            <li class="dropdown documentsIcon">
              <a
                href="#"
                class="dropdown-toggle cool-link"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                #rtl="routerLinkActive"
                routerLinkActive="active"
              >
                <span class="icon-group-text">Documents</span>
                <span class="caret"></span>
              </a>
              <ul style="width: max-content" class="dropdown-menu dMenu">
                <li>
                  <a
                    style="white-space: normal"
                    routerLink="/providerportal/documents"
                    [queryParams]="{ documentCode: 'GEN_REF_DOC' }"
                    >General Reference</a
                  >
                </li>
                <!-- <li>
                  <a
                    style="white-space: normal"
                    routerLink="/providerportal/documents"
                    [queryParams]="{ documentCode: 'PROV_COMM_DOC' }"
                    >Provider Communication</a
                  >
                </li> -->

                <li>
                  <a
                    style="white-space: normal"
                    routerLink="/providerportal/documents"
                    [queryParams]="{ documentCode: 'CHANGE_HEALTH_CARE' }"                    
                    >Provider Payments</a>
                </li>
                
              </ul>
            </li>
            
          </ul>
        </div>
      </div>
    </div>


  
<div>
  <div class="modal fade Timeout" id="logOutdialog" role="dialog">
    <div class="modal-dialog modal-sm" style="margin-top: 300px">
      <div class="modal-content">
        <div
          class="modal-header"
          style="border-bottom: 0px; background-color: #005b9c"
        >
          <p style="color: white; text-align: center">
            <span
              class="glyphicon glyphicon-hourglass"
              aria-hidden="true"
            ></span>
            &nbsp; Session Expiring!
          </p>
        </div>
        <div class="modal-body" style="text-align: center">
          <p>
            Your current session will be expiring in &nbsp;
            {{ counterBack }} seconds.
          </p>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>
    
   
    
    
    
    <script>
      $(document).on("show", ".accordion", function (e) {
        //$('.accordion-heading i').toggleClass(' ');
        $(e.target).prev(".accordion-heading").addClass("accordion-opened");
      });

      $(document).on("hide", ".accordion", function (e) {
        $(this)
          .find(".accordion-heading")
          .not($(e.target))
          .removeClass("accordion-opened");
        //$('.accordion-heading i').toggleClass('fa-chevron-right fa-chevron-down');
      });
    </script>
  </div>
</div>

<div class="modal fade alerts"  id="Alertdialog" role="dialog">

  <div class="modal-dialog modal-xl" style="margin-top: 6%; margin-left: 19%;">
    <div class="modal-content alertPopup">
      <div class="modal-header">
        <div class="row">
          <div class="col-md-9">
            <div class="row">Notification</div>
          </div>
          <div class="col-md-3">
            <button type="button" class="close" data-dismiss="modal"><img
                src="../../assets/images/Close.svg" alt="close"></button>
          </div>
        </div>
      </div>

      <div class="modal-body alertsBody">
        <div class="AlertView">
            <app-tab-selection-pane
              (newTabValues)="addItem($event)"
              [selectedTabIndex]="selectedTab"
              [cardData]="cardData"
              [unReadHighAlertCount]="unreadHighCount"
              [unReadMedAlertCount]="unreadMedCount"
              [unReadLowAlertCount]="unreadLowCount"
              (flagValues)="setFlag($event)"
            ></app-tab-selection-pane>
            <div class="cardScrollableArea">
              <!-- only high tab -->
              <div *ngIf="selectedTab === 0">
                <div>
                
                  <div *ngFor="let itemcardforeme of cardData 
                  ">
                    <div
                  >
                    <div *ngIf="itemcardforeme.Severity.toLowerCase() === 'high'">
                      <app-cards [item]="itemcardforeme" (click)="getStatusAlert(itemcardforeme)"></app-cards>
                    </div>
                  </div>
                    
                  </div>
                  <div *ngIf="noDataFoundFlag || noDataHighFoundFlag">
                    <p class="noAlerts">No Notifications Found</p>
                  </div>
                  <!-- <div  align="center" class="paginationTab"></div>
                    <div class="col-sm-12">
                      <pagination-controls
                        (pageChange)="p = $event"
                      ></pagination-controls>
                    </div>
                  </div> -->
                </div>
              </div>

              <!-- finish only emetab -->
              <!-- only nonemetab -->
              <div *ngIf="selectedTab === 1">
                <!-- <div>
                  <div class="fontFornonEme marginAfter">MEDIUM</div>
                </div> -->
                <div>
                  <div *ngFor="let itemcardfornon of cardData
                  ">
                    <div>
                    <div *ngIf="itemcardfornon.Severity.toLowerCase() === 'medium';else noMedumCard">
                    
                      <app-cards [item]="itemcardfornon" (click)="getStatusAlert(itemcardfornon)"></app-cards>
                      
                    </div>
                    </div>
                   
                  </div>
                  <div *ngIf="noDatamidFoundFlag|| noDataFoundFlag">
                    <p class="noAlerts">No Notifications Found</p>
                  </div>
                  <!-- <div  align="center" class="paginationTab">
                    <div class="col-sm-12">
                      <pagination-controls
                        (pageChange)="p1 = $event"
                      ></pagination-controls>
                    </div>
                  </div> -->
                </div>
                
              </div>
               <!-- finish only nonemetab -->
               <div *ngIf="selectedTab === 2">
                <!-- <div>
                  <div class="fontForlow marginAfter">LOW</div>
                </div> -->
                <div>
                  <div *ngFor="let itemcardfornon of cardData">
                    <div
                  >
                    <div *ngIf="itemcardfornon.Severity.toLowerCase() === 'low'">
                      <app-cards [item]="itemcardfornon" (click)="getStatusAlert(itemcardfornon)"></app-cards>
                    </div>
                    </div>
                  </div>
                </div>
                <div *ngIf=" noDatalowFoundFlag || noDataFoundFlag ">
                  <p class="noAlerts">No Notifications Found</p>
                </div>
                <!-- <div  align="center" class="paginationTab">
                  <div class="col-sm-12">
                    <pagination-controls
                      (pageChange)="p2 = $event"
                    ></pagination-controls>
                  </div>
                </div> -->
              </div>
            </div>

           
          </div>
      </div>
    </div>
  </div>
</div>
