<div id="main-wrapper" style="margin-bottom: 40px;">
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>
  <div class="container-fluid" style="padding: 0;">
    <app-header (found)="handleResults($event)"></app-header>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/providerportal" href="#" style="color: #5009B5;">Providerportal</a></li>
        <li class="breadcrumb-item"><a routerLink="/providerportal/site_Administration" href="#" style="color: #5009B5;">Site
            Administration</a></li>
        <li class="breadcrumb-item active" style="font-family: Myriad Pro" aria-current="page">View & Authenticate</li>
      </ol>
    </nav>
    <div class="row col-md-12 userAuthContainer">
      <div class="col-md-1 col-xs-2"></div>
      <div class="col-md-10 col-xs-12">
        <div class="col-md-12 faqMsg userAthmsg">
          <app-search-results [resultGridList]="data"></app-search-results>
          <tabset>
            <tab heading="New Users" (click)="checkTab='newUser'">
              <accordion *ngIf="users !== undefined">
                <accordion-group heading="{{user.firstName}} {{user.middleName}} {{user.lastName}}  ({{user.userName}})"
                  *ngFor="let user of users['0'] | sort :'firstName'">
                  <div class="row textFont">
                    <div class="col-md-2 col-xs-6 labelValue">
                      <b>User Name:</b>
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      {{user.userName}}
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      <b>NPI: </b>
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      {{ (!user.npi || user.npi === '' || user.npi === 'null') ? '-' : [user.npi]}}
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      <b> Tax ID: </b>
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      {{ (!user.taxId || user.taxId === '' || user.taxId === 'null') ? '-' : [user.taxId]}}
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      <b>Phone: </b>
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      {{user.phone}}
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      <b>Ext.: </b>
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      {{ (!user.extn || user.extn === '' || user.extn === 'null') ? '-' : [user.extn]}}
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      <b>Fax:</b>
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      {{ (!user.fax || user.fax === '' || user.fax === 'null') ? '-' : [user.fax]}}
                    </div>
                    <div class="col-md-12" style="padding: 0px;">
                      <div class="col-md-2 col-xs-6 labelValue">
                        <b> User Type: </b>
                      </div>
                      <div class="col-md-10 col-xs-6 labelValue">
                        {{user.regTypeDesc}}
                      </div>
                      <div class="col-md-2 col-xs-6 labelValue">
                        <b>Email:</b>
                      </div>
                      <div class="col-md-10 col-xs-6 labelValue">
                        {{ (!user.email || user.email === 'none@null.com' || user.email === 'null') ? '-' :
                        [user.email]}}
                      </div>
                      <div class="col-md-2 col-xs-6 labelValue">
                        <b>Address:</b>
                      </div>
                      <div class="col-md-10 col-xs-6 labelValue">
                        {{user.addressLine1}} {{user.addressLine2}} {{user.city}} {{user.state}} {{user.zip}}
                      </div>
                    </div>
                    </div>
                    <br>
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="user.isAdmin">
                      <label class="form-check-label" for="exampleCheck1">Give account operator access</label>
                    </div>
                    <br>
                    <div class="row">
                      <div class="col-md-12 text-center btn-block">
                        <button (click)="updateStatus(user, '1',false)" class="btn btn-primary carelon-primary-btn">Activate</button>
                        <button (click)="updateStatus(user, '2',false)" style="margin-left:20px;" class="btn carelon-secondary-btn">Deactivate</button>
                      </div>
                    </div>
                </accordion-group>
                <br>
              </accordion>
            </tab>
            <tab heading="Active Users" (click)="checkTab='activeUser'">
              <accordion *ngIf="activeUsers?.length">
                <accordion-group id="activeUsers" (isOpenChange)="activeUserOpen(user)" heading="{{user?.name}} ({{user?.userId}})"
                  *ngFor="let user of activeUsers" >
                  <div class="row textFont">
                    <div class="col-md-2 col-xs-6 labelValue">
                      <b>User Name:</b>
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      {{user?.userName}}
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      <b>NPI: </b>
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      {{ (!user?.npi || user?.npi === '' || user?.npi === 'null') ? '-' : [user?.npi]}}
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      <b> Tax ID: </b>
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      {{ (!user?.taxId || user?.taxId === '' || user?.taxId === 'null') ? '-' : [user?.taxId]}}
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      <b>Phone: </b>
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      {{user?.phone}}
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      <b>Ext.: </b>
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      {{ (!user?.extn || user?.extn === '' || user?.extn === 'null') ? '-' : [user?.extn]}}
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      <b>Fax:</b>
                    </div>
                    <div class="col-md-2 col-xs-6 labelValue">
                      {{ (!user?.fax || user?.fax === '' || user?.fax === 'null') ? '-' : [user?.fax]}}
                    </div>
                    <div class="col-md-12" style="padding: 0px;">
                      <div class="col-md-2 col-xs-6 labelValue">
                        <b> User Type: </b>
                      </div>
                      <div class="col-md-10 col-xs-6 labelValue">
                        {{user?.regTypeDesc}}
                      </div>
                      <div class="col-md-2 col-xs-6 labelValue">
                        <b>Email:</b>
                      </div>
                      <div class="col-md-10 col-xs-6 labelValue">
                        {{ (!user?.email || user?.email === 'none@null.com' || user?.email === 'null') ? '-' :
                        [user?.email]}}
                      </div>
                      <div class="col-md-2 col-xs-6 labelValue">
                        <b>Address:</b>
                      </div>
                      <div class="col-md-10 col-xs-6 labelValue">
                        {{user?.addressLine1}} {{user?.addressLine2}} {{user?.city}} {{user?.state}} {{user?.zip}}
                      </div>
                      <div class="col-md-2 col-xs-6 labelValue">
                        <b>Sites:</b>
                      </div>
                      <div class="col-md-10 col-xs-6 labelValue" *ngIf="selectedAllUserSites[user?.userId]">
                        <ng-container *ngFor="let sites of selectedAllUserSites[user.userId];let index = index">
                          <span class="px-1">{{sites.siteName}} <ng-container *ngIf="index != selectedAllUserSites[user?.userId].length-1">,</ng-container></span>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <br>
                  <div class="row adminAccess">

                  </div>
                  <div class="row" *ngIf="user?.status === 3">
                    <p style="color:red; margin-left: 15px;"><b>Note:</b> This user account is locked</p>
                  </div>
                  <div class="row adminAccess btn-block">
                    <button *ngIf="user?.status === 3" (click)="unlockUser(user)" class="btn btn-primary carelon-primary-btn">
                      <span>Unlock</span>
                    </button>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center btn-block">
                      <button *ngIf="(user?.userRole !== 'Admin' || user?.userRole == 'null')" (click)="user['isAdmin'] = (!user['isAdmin']); updateStatus(user, user?.status)"
                        class="btn btn-primary carelon-primary-btn">
                        <span> Give account operator access</span>
                      </button>
                      <button *ngIf="user?.userRole === 'Admin'" (click)="user['isAdmin'] = (!user['isAdmin']); updateStatus(user, user?.status)"
                        class="btn carelon-secondary-btn">
                        <span> Remove account operator access</span>
                      </button>
                      <button (click)="checkForProviderAdmin(user)" style="margin-left:20px;" class="btn carelon-secondary-btn">Deactivate</button>
                      <button (click)="updateLockedPasswordPopup(user)" style="margin-left:20px;" class="btn btn-primary carelon-primary-btn">Reset
                        user password</button>
                      <button (click)="linkSitesPop(user)" style="margin-left:20px;" class="btn btn-primary carelon-primary-btn">Add/Remove
                        Sites</button>
                    </div>
                  </div>


                </accordion-group>
                <br>
              </accordion>
            </tab>
            <tab heading="Inactive Users" (click)="checkTab='inActiveUser'">
              <accordion *ngIf="inactiveUsers?.length">
                <accordion-group (isOpenChange)="activeUserOpen(user)" heading="{{user?.name}} ({{user?.userId}})"
                  *ngFor="let user of inactiveUsers ">
                <!-- | sort :'firstName' -->

                  <div class="row textFont">
                    <div class="col-md-2 col-xs-6">
                      <b>User Name:</b>
                    </div>
                    <div class="col-md-2 col-xs-6">
                      {{user?.userName}}
                    </div>
                    <div class="col-md-2 col-xs-6">
                      <b>NPI: </b>
                    </div>
                    <div class="col-md-2 col-xs-6">
                      {{ (!user?.npi || user?.npi === '' || user?.npi === 'null') ? '-' : [user?.npi]}}
                    </div>
                    <div class="col-md-2 col-xs-6">
                      <b> Tax ID: </b>
                    </div>
                    <div class="col-md-2 col-xs-6">
                      {{ (!user?.taxId || user?.taxId === '' || user?.taxId === 'null') ? '-' : [user?.taxId]}}
                    </div>
                    <div class="col-md-2 col-xs-6">
                      <b>Phone: </b>
                    </div>
                    <div class="col-md-2 col-xs-6">
                      {{user?.phone}}
                    </div>
                    <div class="col-md-2 col-xs-6">
                      <b>Ext.: </b>
                    </div>
                    <div class="col-md-2 col-xs-6">
                      {{ (!user?.extn || user?.extn === '' || user?.extn === 'null') ? '-' : [user?.extn]}}
                    </div>
                    <div class="col-md-2 col-xs-6">
                      <b>Fax:</b>
                    </div>
                    <div class="col-md-2 col-xs-6">
                      {{ (!user?.fax || user?.fax === '' || user?.fax === 'null') ? '-' : [user?.fax]}}
                    </div>
                    <div class="col-md-2 col-xs-6">
                      <b> User Type: </b>
                    </div>
                    <div class="col-md-10 col-xs-6">
                      {{user?.regTypeDesc}}
                    </div>
                    <div class="col-md-2 col-xs-6">
                      <b>Email:</b>
                    </div>
                    <div class="col-md-10 col-xs-6">

                      {{ (!user?.email || user?.email === 'none@null.com' || user?.email === 'null') ? '-' :
                      [user?.email]}}
                    </div>
                    <br>
                    <br>
                    <br>
                    <div class="col-md-2 col-xs-6">
                      <b>Address:</b>
                    </div>
                    <div class="col-md-6 col-xs-6">
                      {{user?.addressLine1}} {{user?.addressLine2}} {{user?.city}} {{user?.state}} {{user?.zip}}
                    </div>
                  </div>
                  <br>
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="exampleCheck1" [(ngModel)]="user.isAdmin">
                    <label class="form-check-label" for="exampleCheck1">Give account operator access</label>
                  </div>

                  <br>
                  <div class="row">
                    <div class="col-md-12 text-center btn-block">
                      <button (click)="updateStatus(user, '1')" class="btn btn-primary carelon-primary-btn">Activate</button>

                    </div>
                  </div>
                </accordion-group>
                <br>
              </accordion>
            </tab>
            <tab heading="Pending Users" (click)="checkTab='pendingUser'">
              <accordion *ngIf="unregisterdUsers !== undefined">
                <accordion-group heading="{{user?.firstName}} {{user.middleName}} {{user.lastName}}" *ngFor="let user of unregisterdUsers | sort :'firstName'">
                  <div class="row textFont">
                    <div class="col-md-2 col-xs-6">
                      <b>Full Name:</b>
                    </div>
                    <div class="col-md-10 col-xs-6">
                      <p>{{user.firstName}} {{user.middleName}} {{user.lastName}}</p>

                    </div>
                    <div class="col-md-2 col-xs-6">
                      <b>Email:</b>
                    </div>
                    <div class="col-md-10 col-xs-6">
                      {{ (!user.email || user.email === 'none@null.com' || user.email === 'null') ? '-' :
                      [user.email]}}
                    </div>
                    <div class="col-md-2 col-xs-6">
                      <b>Address:</b>
                    </div>
                    <div class="col-md-10 col-xs-6">
                      {{user.addressLine1}} {{user.addressLine2}} {{user.city}} {{user.state}} {{user.zip}}
                    </div>
                    <div class="col-md-2 col-xs-6">
                      <b>NPI: </b>
                    </div>
                    <div class="col-md-2 col-xs-6">
                      {{ (!user.npi || user.npi === '' || user.npi === 'null') ? '-' : [user.npi]}}
                    </div>
                    <div class="col-md-2 col-xs-6">
                      <b> Tax ID: </b>
                    </div>
                    <div class="col-md-2 col-xs-6">
                      {{ (!user.taxId || user.taxId === '' || user.taxId === 'null') ? '-' : [user.taxId]}}
                    </div>
                    <div class="col-md-2 col-xs-6">
                      <b>Phone: </b>
                    </div>
                    <div class="col-md-2 col-xs-6">
                      {{user.phone}}
                    </div>
                    <br>
                  </div>
                  <br>
                  <br>
                  <div class="row">
                    <div class="col-md-12 text-center btn-block">
                      
                        <button (click)="updateAssociateURData(user)" class="btn btn-primary carelon-primary-btn">Resend
                          Registration </button>
                          <span   (click)="deleteMessagePopup(user)" style="cursor: pointer;">
                            <img class="deleteIcon" src="../../assets/images/icons/deleteIcon.svg" alt="Del Icon">
                          </span>
                     
                      
                    </div>
                  </div>
                </accordion-group>
                <br>
              </accordion>
            </tab>

          </tabset>
        </div>
      </div>
      <div class="modal fade" id="adminUsersModal" role="dialog">
        <div class="modal-dialog" style="margin-top: 10%;">
          <div class="modal-content">
            <div class="modal-header headerFont">
              <p>You are deactivating the primary account operator, designated by health plan. please choose from below
                a new primary account operator.</p>
            </div>
            <div class="modal-body listUsers">
              <ul class="list-group">
                <div *ngFor="let user of adminUsers">
                  <li class="list-group-item" *ngIf="user.userName !== currentProviderUser.userName">
                    <div class="row">
                      <div class="col-md-6">
                        {{user.userName}}
                      </div>
                      <div class="col-md-6">
                        <div class="col-md-4">
                          <button (click)="updateProviderAdmin(user)" class="btn btn-primary textFont">Make As Account
                            operator</button>
                        </div>
                      </div>
                    </div>
                  </li>
                </div>

              </ul>
            </div>
            <div class="modal-footer">
              <div class="container-contact100-form-btn">
                <button type="button" class="btn btn-primary textFont" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="modal fade" id="delete-dialog" role="dialog">
            <div class="modal-dialog" style="margin-top: 15%;">
                <div class="modal-content">
                    <div class="modal-header deleteHeader">
                        <!-- <button type="button"  class="close" data-dismiss="modal">&times;</button> -->
                        <b> Confirm Deletion </b>
                    </div>
                    <div class="modal-body deleteBody">
                        <span>Do you really want to delete this
                            user?</span>
                    </div>
                    <div class="modal-footer">
                        <div class="container-contact100-form-btn">
                            <button type="button"
                                class="btn btn-primary buttonInfo buttonWidth"
                                (click)="deletePendingUsers()">Yes</button>
                            <button type="button"
                                class="btn btn-secondary button-sections buttonWidth"
                                (click)="deleteMessagePopupEnable=false"
                                data-dismiss="modal">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <div class="modal fade" id="changePasswordModal" role="dialog">
        <div class="modal-dialog" style="margin-top: 10%">
          <div class="modal-content">
            <div class="modal-header">
              <p>To reset a password use the below form.</p>
            </div>
            <div class="modal-body textFont">
              <div class="form-group">
                <p style="text-align: left">User Name</p>
                <input [(ngModel)]="selectedUserForCredReset" name="userForPwdReset" readonly type="text" placeholder="User Name" />

                <p style="text-align: left">Password</p>
                <input [(ngModel)]="lockedUserNewPassword" minlength="8" maxlength="15" (keypress)="passRestrict($event)"
                  type="text" name="userNewPassword" placeholder="Enter Temporary Password" />
              </div>
              <div class="col-md-12 text-right">
                <button type="button" data-dismiss="modal" style="font-family: Myriad Pro; color: #5009B5" class="btn btn-link">Cancel</button>
                <!-- <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button> -->
                <button type="submit" class="bouton-contact" (click)="updateLockedPassword()">Submit</button>
              </div>
              <div class="row col-md-12  passwordMessage">
                <b class="note">Password Requirements:</b>
                <ul class="circle">
                  <li> Must be different than your previous three passwords</li>
                  <li> Must contain at least one lowercase letter, one uppercase letter, one numerical digit and any of the following special characters (!,@,#,$,%,^,*,.,_)</li>
                  <li> Must be between 8 and 15 characters.</li>

                </ul>

              </div>
            </div>
            <div class="modal-footer">
              <div class="form-group">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="sentEmailModal" role="dialog">
        <div class="modal-dialog" style="margin-top: 10%;">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body textFont" style=" text-align: center;">
              <p>{{message}}</p>
            </div>
            <div class="modal-footer">
              <div class="container-contact100-form-btn">
                <button type="button" class="btn btn-primary textFont" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="dontDeactivateModal" role="dialog">
        <div class="modal-dialog" style="margin-top: 10%;">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body textFont" style=" text-align: center;">
              <p>The staff member belongs to other provider sites and therefore you will not be able to deactivate the user. Please use Add/ remove sites to manage sites for which you are designated as Account operator. </p>
            </div>
            <div class="modal-footer">
              <div class="container-contact100-form-btn">
                <button type="button" class="btn btn-primary textFont" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="linkSiteModal" role="dialog">
        <div class="modal-dialog" style="margin-top: 6%;">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="pull-left">Site Links for {{selected.userName}}</h3>
              <div class="pull-right">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
              </div>
              <div class="clearfix"></div>
              <div class="col-md-7" *ngIf="updateMessage !== ''">
                <p class="row updateMessage text-success"> {{updateMessage}}</p>
              </div>
              <div class="col-md-7" *ngIf="failureMessage !== ''">
                <p class="row failureMessage text-danger"> {{failureMessage}}</p>
              </div>
            </div>
            <div class="modal-body textFont auth-modal-body">
              <div class="col-md-12">
              </div>
              <ng-container *ngIf="selectedUserSites?.length>0">
                <div style=" padding-left: 1rem;">
                  <h4>Linked Sites</h4>
                </div>
                <br>
                <ul class="list-group" *ngFor="let sites of selectedUserSites">
                  <a class="list-group-item list-group-item-action listStyle text-left">
                    <div class="row">
                      <div class="col-md-11 col-xs-8">
                        {{sites.siteName}}
                      </div>
                      <div class="col-md-1 col-xs-4" *ngIf="sites.ownSite">
                        <span (click)=removeSites(sites) class="glyphicon glyphicon-minus-sign pull-right" aria-hidden="true"
                          style="color: red; cursor: pointer;"></span>
                      </div>
                    </div>
                  </a>
                </ul>
              </ng-container>
              <ng-container *ngIf="selectedUserSites?.length ===0">
                <p>No Sites Associated to the user <b>{{selected.userName}}</b></p>
              </ng-container>
              <ng-container *ngIf="actualFilteredUserSites.length > 0">
                <hr />
                <div style=" padding-left: 1rem;" *ngIf="selectedUserSites">
                  <h4>Available Sites</h4>
                </div>
                <br>
                <ul class="list-group" *ngFor="let sites of actualFilteredUserSites">
                  <a class="list-group-item list-group-item-action listStyle">
                    <div class="row">
                      <div class="col-md-11 col-xs-8">
                        {{sites.siteName}}
                      </div>
                      <div class="col-md-1 col-xs-4">
                        <span (click)="addSites(sites)" class="glyphicon glyphicon-plus-sign pull-right" aria-hidden="true"
                          style="color: green; cursor: pointer;"></span>
                      </div>
                    </div>
                  </a>
                </ul>
              </ng-container>
            </div>
            <div class="modal-footer">
              <div class="container-contact100-form-btn">
                <button type="button" class="btn btn-primary buttonFont" data-dismiss="modal" (click)="selectionReset()">Cancel</button>
                <ng-container *ngIf="actualFilteredUserSites?.length>0||selectedUserSites!=null">
                  <button (click)="postSites()" type="button" class="btn btn-primary buttonFont">Submit</button>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1 col-xs-2">
      </div>
    </div>
  </div>
  <br><br><br><br><br>
  <app-footer ></app-footer>
</div>