import { Component, OnInit } from '@angular/core';
import { ForgotusernameService } from './forgotusername.service';
import { HttpParams } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FieldValidateService } from '../common_services/fieldvalidate.service';

declare var jQuery: any;

@Component({
    selector: 'app-forgotusername',
    templateUrl: './forgotusername.component.html',
    styleUrls: ['./forgotusername.component.scss'],
    providers: [ForgotusernameService]
})
export class ForgotusernameComponent implements OnInit{


    loading = false;

    public model =
        {
            firstName: '',
            lastName: '',
            email: ''
        };
    news = [];
    errorMessage = '';

    isValidUserName = true;

    isFiledValid = {};
    fieldValidateMsg = {};

    constructor(private forgotService: ForgotusernameService, private router: Router, private fieldValidator: FieldValidateService) { }

    ngOnInit() {
        this.resetInputValidations();
        this.getNews();
    }

    myModalEventCallBack() { }


    onInputFocus(event: any) {
        this.hideValidate(event.target.name);
    }

    hideValidate(inputName: string) {
        this.isFiledValid[inputName] = true;
    }

    onSubmit() {

        if (this.model.firstName === '' || this.model.lastName === '' ) {
            this.checkForEmptyField(['firstName', 'lastName']);
            return;
        }
        if (this.model.email === '' ) {
            this.showAlertPopup('User does not have an email address registered with the portal. Please contact our Provider Relations team for assistance.'+ "\n"
            +' Provider Relations Team'+ "\n"
            
            +' (888)291-1358, option 3, then option 5'+ "\n"
            
            +'ProviderRelations@carelon.com',() => { this.router.navigate(['login']); });
            return;
        }
        if (!this.fieldValidator.validateEmail(this.model.email)) {
            this.fieldValidateMsg['email'] = 'Email is not Valid. Please verify.';
            this.setFieldValidates(['email']);
            return;
        }
        this.loading = true;
        this.forgotService.requestRecoverUserName(
            this.model.firstName,
            this.model.lastName,
            this.model.email, (success: boolean, message: string) => {
                this.loading = false;
                this.showAlertPopup(message, () => { this.router.navigate(['login']); });
            });
    }

    private showAlertPopup(message: string, callBack) {
        this.errorMessage = message;
        this.showPopup('myModal', callBack);
        return;
    }

    private showPopup(popupName: string, callBack) {
        jQuery('#' + popupName).modal('show');
        this[popupName + 'EventCallBack'] = callBack;
    }

    private resetInputValidations() {
        const fieldNameArr = ['firstName', 'lastName', 'email'];
        for (const fieldName of fieldNameArr) {
            this.isFiledValid[fieldName] = true;
        }
    }

    private checkForEmptyField(fieldNameArr: string[]) {
        for (const fieldName of fieldNameArr) {
            this.isFiledValid[fieldName] = this.model[fieldName] !== '' ? true : false;
        }
    }

    private setFieldValidates(fieldNameArr: string[]) {
        for (const fieldName of fieldNameArr) {
            this.isFiledValid[fieldName] = false;
        }
    }

    private setFieldValidateMsg(fieldNameArr: string[]) {
        for (const fieldName of fieldNameArr) {
            this.fieldValidateMsg[fieldName] = '';
        }
    }


    showLoginPage(userName): void {
        this.router.navigate(['./login']);
    }

    isUndefinedOrEmpty(value: string) {
        if (value === undefined || value === '') {
            return true;
        }
        return false;
    }

    restrictToAlpha(event: any) {
        const inputValue = event.target.value;
        const numberValue = this.extractAlphaString(inputValue);

        event.target.value = numberValue;
    }

    extractAlphaString(value: string) {
        let newVal = '';
        for (let i = 0; i < value.length; i++) {
            const s: string = value.charAt(i);
            if (this.isAlpha(s)) {
                newVal += s;
            }
        }
        return newVal;
    }
    isAlpha(str: string) {
        return /^[a-zA-Z]+$/.test(str);
    }
    getNews() {
        this.loading = true;
        this.forgotService.getNews((success: boolean, news: any) => {
            if (success) {
                this.loading = false;
                this.news = news['providerPortalBroadcast'];
            } else {
                this.loading = false;
            }
        });

    }

}
