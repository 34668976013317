import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-inbox',
   templateUrl: './inbox.component.html',
   styleUrls: ['./inbox.component.css']
})
export class InboxComponent implements OnInit {

   constructor() { }
   compose = false;
   active = 'inbox';
   showBody = false;
   activeItem: any;
   data = [];
    handleResults(searchObj) {
        this.data = searchObj;
    }
   inboxData = [
      {
         'sentDate': '2018-10-05 14:07',
         'receivedDate': '2018-10-05',
         'senderUserId': 'caremore01',
         'subject': 'Test 02 Hello Hi caremore01, Please note that your password has been ',
         'messageBody': 'Hello Hi caremore01, Please note that your password has been successfully reset for the account caremore01 . '
            + 'Thanks ',
         'messageUid': 478,
         'toUsers': [
            {
               'recipientName': ' caremore01 (CUP) ',
               'recipientType': 'Provider',
               'applicationDescription': 'CUP'
            }
         ],
         'ccUsers': [
            {
               'recipientType': 'Provider',
               'applicationDescription': 'CUP'
            }
         ]
      }
   ];

   sentData = [
      {
         'sentDate': '2018-10-05 14:07',
         'subject': 'Test 02',
         'messageBody': 'Hello Hi caremore01, Please note that your password has been successfully reset for the account caremore01. ' +
            'Thanks ',
         'messageUid': 478,
         'toUsers': [
            {
               'recipientName': ' caremore01 (CUP) ',
               'recipientType': 'Provider'
            }
         ]
      },
      {
         'sentDate': '2018-10-02 15:47',
         'subject': 'Test Message Ignore',
         'messageBody': 'Test',
         'messageUid': 469,
         'toUsers': [
            {
               'recipientName': ' navateja (CUP) ',
               'recipientType': 'Caremore User'
            }
         ]
      }
   ];

   ngOnInit() {
   }

}
