import { Injectable } from '@angular/core';
import { TreeModule } from 'angular-tree-component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { urls } from '../../common/model/properties';
import { ConfigProvider } from '../../shared/services/configprovider';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as xml2js from 'xml2js';
import { SecureStorageService } from '../../secure-storage.service';
import { CupService } from 'src/app/shared/services/cup.service';

@Injectable()
export class TrainingMaterialService {
  config: any;
  props: any;
  epahafPdfData = new BehaviorSubject([]);
  progressObserver = new Subject<any>();

  constructor(private http: HttpClient,
    private cupService: CupService,
    private configProvider: ConfigProvider,
    private secureStorage:SecureStorageService
    ) { 
      this.config = configProvider.getConfig();
      this.props = configProvider.getProps();
     
    }
   


  getWorkFlowDocument( docID, docCode): Observable<any> {
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + ppjwt.access_token
     });
    let myParams = new HttpParams({fromObject:{'appId': 'PP','documentCode': 'PROV_DOC'}});
    const options = { headers: headers, params: myParams } ;
    const urlCall = ( docID === null ) ? urls.filenetURL : urls.filenetURL + '/' + docID;
    return this.http.get( urlCall, options );
  }

  listDocuments(kvpl) {
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + ppjwt.access_token
    });
    const myParams = new HttpParams()
    .set( 'appId', 'PP' )
    .set( 'documentCode', 'PROV_DOC')
    .set('keyValuePairList', JSON.stringify(kvpl))
    const options = { headers: headers, params: myParams };
    return this.http.get(urls.filenetListURL, options);
}

 /*Make request to upload file*/
  uploadFile(file,kvp): Observable<any> {

    const formData = new FormData();
    formData.append('content', file,`training_${Date.now()}.pdf`);
    // let username = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser')).userName
    let username = this.cupService.getUserNameFromJWT();
    formData.append('document', JSON.stringify({
      createdUser : username,
      appId : 'PP',
      documentCode : 'PROV_DOC',
      txnLog: false,
      keyValuePairList : kvp
    }));
    // const jwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const jwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const authHeader: string = 'Bearer ' + jwt.access_token;
    return Observable.create(observer => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const xml = xhr.response;
            xml2js.parseString(xml, { explicitArray: false }, function (err, result) {
              observer.next(result);
            });
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.upload.onprogress = (event) => {
        const progress = Math.round(event.loaded / event.total * 100);
        this.progressObserver.next(progress);
      };
      xhr.open('POST', urls.fileNetUrl, true);
      xhr.setRequestHeader('Authorization', authHeader);
      xhr.send(formData);
  });
}

}
