import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {
  
  @Input() item: any;
  openCardElement: boolean;
  buttonDisabled: boolean;
  showDetails : boolean = false;
  @Output() closeFunctionCalled = new EventEmitter<any>();
  @Output() markAsReadAlert = new EventEmitter<any>();

  
  constructor() { }

  ngOnInit() {
   // console.log('itemscard',this.item);
  }
  ngViewAfterInit() {
    console.log(this.item);

}
showfullContent() {
  this.showDetails = !this.showDetails;
}

callFunction(id) {
   console.log(id);
    this.closeFunctionCalled.emit(id);   
  }
  markAsRead(id){
    console.log(id);
    this.markAsReadAlert.emit(id);  
  }
}