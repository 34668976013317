<div id="main-wrapper">
	<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
	<div class="container-fluid" style="background-color:#eaf0f5; min-height:0px;padding-left: 0; padding-right: 0;">
		<app-header (found)="handleResults($event)"></app-header>
		<div class="limiter">
			<div class="main-container topSpace">
				<app-search-results [resultGridList]="data"></app-search-results>
			</div>

			<div class="row heading">
				<h3>Change Password</h3>
			</div>

			<div class="changePasswordContainer" >
				<div class="row col-md-12 mt-3">
					<div class="col-md-6 passwordEditor">
						<form class="login100-form validate-form ng-pristine ng-invalid ng-touched" novalidate="">

							<label><span class="red-color">*</span> Old Password</label>
							<div [ngClass]="(isFiledValid['oldCred'])?'wrap-input100 validate-input':'wrap-input100 validate-input alert-validate'"
								[attr.data-validate]="isUndefinedOrEmpty(fieldValidateMsg['oldCred']) ? 'Enter Old Password' : fieldValidateMsg['oldCred']">
								<span class="btn-show-pass">
									<i [ngClass]="isUndefinedOrFalse(showOldCred)?'zmdi zmdi-eye-off':'zmdi zmdi-eye'" (click)="togglePasswordShow($event, 'showOldCred')"></i>
								</span>
								<input [attr.type]="isUndefinedOrFalse(showOldCred) ? 'password' : 'text'" (keypress)="passRestrict($event)" (focus)="onInputFocus($event)" (focus)="onInputFocus($event)" [(ngModel)]="oldCred" #firstName="ngModel"
									id="oldPassword" name="oldPassword" type="password" [ngClass]="(oldCred === '')?'input100':'input100 has-val'" required />
								<span class="focus-input100" data-placeholder="Old Password"></span>
							</div>

							<label><span class="red-color">*</span> New Password</label>
							<div [ngClass]="(isFiledValid['newCred'])?'wrap-input100 validate-input':'wrap-input100 validate-input alert-validate'"
								[attr.data-validate]="isUndefinedOrEmpty(fieldValidateMsg['newCred']) ? 'Enter New Password' : fieldValidateMsg['newCred']">
								<span class="btn-show-pass">
									<i [ngClass]="isUndefinedOrFalse(showCred)?'zmdi zmdi-eye-off':'zmdi zmdi-eye'" (click)="togglePasswordShow($event, 'showCred')"></i>
								</span>
								<input [attr.type]="isUndefinedOrFalse(showCred) ? 'password' : 'text'" (keypress)="passRestrict($event)" minlength="8" maxlength="15" (focus)="onInputFocus($event)" (focus)="onInputFocus($event)" [(ngModel)]="newCred" #firstName="ngModel"
									id="newPassword" name="newPassword" type="password" [ngClass]="(newCred === '')?'input100':'input100 has-val'" required />
								<span class="focus-input100" data-placeholder="New Password"></span>
							</div>
							
							<label><span class="red-color">*</span> Confirm Password</label>
							<div [ngClass]="(isFiledValid['confirmCred'])?'wrap-input100 validate-input':'wrap-input100 validate-input alert-validate'"
								[attr.data-validate]="isUndefinedOrEmpty(fieldValidateMsg['confirmCred']) ? 'Enter New Password' : fieldValidateMsg['confirmCred']">
								<span class="btn-show-pass">
									<i [ngClass]="isUndefinedOrFalse(showConfirmCred)?'zmdi zmdi-eye-off':'zmdi zmdi-eye'" (click)="togglePasswordShow($event, 'showConfirmCred')"></i>
								</span>
								<input [attr.type]="isUndefinedOrFalse(showConfirmCred) ? 'password' : 'text'" (keypress)="passRestrict($event)" minlength="8" maxlength="15" (focus)="onInputFocus($event)" (focus)="onInputFocus($event)" [(ngModel)]="confirmCred" #firstName="ngModel"
									id="confirmPassword" name="confirmPassword" type="password" [ngClass]="(confirmCred === '')?'input100':'input100 has-val'" required />
								<span class="focus-input100" data-placeholder="Confirm New Password"></span>
							</div>
							
						</form>
					</div>

					<div class="col-md-6 passwordHints">
						<div class="row passwordMessage">
							<div class="heading">Password Requirements:</div>
								<ul class="messageList">
									<li>  Must be different than your previous three passwords</li>
									<li>  Must contain at least one lowercase letter, one uppercase letter, one numerical digit and any of the following special characters (!,@,#,$,%,^,*,.,_) </li>
									<li>  Must be between 8 and 15 characters.</li>
								</ul>
								
						</div>
					</div>
				</div>


				<div class="row">
					<div class="modal fade" id="alertModal" role="dialog">
						<div class="modal-dialog" style="margin-top: 15%;">
							<div class="modal-content alertPopup">
								<div class="modal-header">
									<div class="row">
										<div class="col-md-9">
	
										</div>
										<div class="col-md-3 pr-0">
											<button type="button" class="close" data-dismiss="modal" (click)="alertModalEventCallBack()">
												<img src="../../assets/images/Close.svg" alt="close"></button>
										</div>
									</div>
								</div>
								<div class="modal-body">
									<div class="row imgContainer" *ngIf="!showSuccesImg">
										<img src="../../assets/images/icons/Error.svg" alt="Success">
									</div>

									<div class="row imgContainer" *ngIf="showSuccesImg">
										<img src="../../assets/images/icons/Success.svg" alt="Success">
									</div>

									<p class="text">{{ errorMessage }}</p>

								</div>

								<div class="modal-footer">
									<div class="col-md-12 pl-0" align="center">
										<button type="button" data-dismiss="modal" (click)="alertModalEventCallBack()"
											class="btn carelon-primary-btn">Close</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row col-md-12 pl-0 actions">
				<div class="col-md-12 pl-0" align="end">
					<button type="button" routerLink="/providerportal/portal"
						class="btn carelon-secondary-btn">Cancel</button>

					<button class="btn btn-primary carelon-primary-btn" (click)="onSubmit()"
						type="button">Update Password</button>
				</div>
			</div>


		</div>
	</div>
	<br><br><br><br><br>
	<app-footer></app-footer>
</div>
