import { Component, OnInit } from '@angular/core';
import { AccountProfileService } from './accountProfile.service';
import { HttpParams } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { FieldValidateService } from '../common_services/fieldvalidate.service';
import { SecureStorageService } from '../secure-storage.service';
import { CupService } from '../shared/services/cup.service';

declare var jQuery: any;

@Component({
    selector: 'app-accountProfile',
    templateUrl: './accountProfile.component.html',
    styleUrls: ['./accountProfile.component.scss'],
})
export class AccountProfileComponent implements OnInit {

    userName: any;
    LoginErrorMessage: any;
    questions: any;
    errorMessage: any;
    fieldValidateMsg = {};
    isFiledValid = {};
    loading = false;
    // this.loading = false;

    public model: any;
    data = [];
    handleResults(searchObj) {
        this.data = searchObj;
    }
    constructor(private router: Router,
        private route: ActivatedRoute,private cupService: CupService,
        private accountProfileService: AccountProfileService,
        private fieldValidator: FieldValidateService,
        private secureStorage:SecureStorageService) { }

    ngOnInit() {
        // this.userName = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'))['userName'];
        this.userName = this.cupService.getUserNameFromJWT();
        this.getUserData();
    }
    togglePasswordShow(event: any, modelName: string) {
        this[modelName] = !this[modelName];
    }
    getUserData() {
        this.loading = true;
        this.accountProfileService.getUserProfile(this.userName, (success: boolean, data) => {
            if (success) {
                this.secureStorage.setSecureInfoInSessionlStorage('fullName', data['firstName'] + ' ' + data['middleName'] + ' ' + data['lastName']);
                this.secureStorage.setSecureInfoInSessionlStorage('userProfile', JSON.stringify({
                    city: data['city'], zip: data['zip'],
                    state: data['state'],
                    userRole: data['userRole'],
                    guId: data['guId'],
                    phone: data['phone'],
                    extn: data['extn'],
                    fax: data['fax'],
                    email: data['email'],
                    npi: data['npi'],
                    taxid: data['taxId'],
                    questions: data['securityQuestionsFlag'],
                    cred: data['lastPasswordChange'],
                    lastLogin: data['lastLogin']
                }));
                this.model = this.reModelData(data);
                this.loading = false;
            } else {
                this.loading = false;
                this.showAlertPopup(data, () => {
                    this.router.navigate(['providerportal']);
                });
            }
        });
    }

    private reModelData(data) {
        data['fullName'] = data['firstName'] + ' ' + data['middleName'] + ' ' + data['lastName'];
        data['fullAddress'] = this.getFullAddress(data);
        data['npi'] = data['npi'] === 'null' ? '-' : data['npi'];
        data['taxId'] = data['taxId'] === 'null' ? '-' : data['taxId'];
        data['fax'] = data['fax'] === 'null' ? '-' : data['fax'];
        data['extn'] = data['extn'] === 'null' ? '-' : data['extn'];

        return data;
    }

    private showAlertPopup(message: string, callBack) {
        this.errorMessage = message;
        this.showPopup('alertModal', callBack);
        return;
    }

    private showPopup(popupName: string, callBack) {
        jQuery('#' + popupName).modal('show');
        this[popupName + 'EventCallBack'] = callBack;
    }

    private setFieldValidates(fieldNameArr: string[]) {
        for (const fieldName of fieldNameArr) {
            this.isFiledValid[fieldName] = false;
        }
    }

    showLoginPage(): void {
        this.router.navigate(['./login']);
    }

    isUndefinedOrEmpty(value: string) {
        if (value === undefined || value === '') {
            return true;
        }
        return false;
    }

    isUndefinedOrTrue(value: boolean) {
        if (value === undefined || value) {
            return true;
        }
        return false;
    }

    isUndefinedOrFalse(value: boolean) {
        if (value === undefined || value === false) {
            return true;
        }
        return false;
    }

    onInputFocus(event: any) {
        this.hideValidate(event.target.name);
    }

    hideValidate(inputName: string) {
        this.isFiledValid[inputName] = true;
    }

    private setFieldValidateMsg(fieldNameArr: string[]) {
        for (const fieldName of fieldNameArr) {
            this.fieldValidateMsg[fieldName] = '';
        }
    }

    private checkForEmptyField(fieldNameArr: string[]) {
        for (const fieldName of fieldNameArr) {
            this.isFiledValid[fieldName] = this[fieldName] !== '' ? true : false;
        }
    }

    private showPopuup(message: string) {
        this.errorMessage = message;
        jQuery('#myModal').modal('show');
        return;
    }

    getNAOrValue(value) {

        if (!value || value === '') {
            return '-';
        }
    }

    getFullAddress(model) {

        let fullAddress = model.addressLine1;
        fullAddress += (model.addressLine2 !== '') ? ', ' + model.addressLine2 : '';
        fullAddress += model.city + ', ' + model.state + ', ' + model.zip;

        return fullAddress;
    }


}
