import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {saveAs} from 'file-saver';
import * as _ from 'lodash';
import { FileUploader } from 'ng2-file-upload';
import { urls } from '../common/model/properties';
import { ValidatedocumentService } from './validatedocument.service';
import { SecureStorageService } from '../secure-storage.service';
declare var jQuery: any;

@Component({
    selector: 'app-validateDocument',
    templateUrl: './validatedocument.component.html',
    styleUrls: ['./validatedocument.component.scss']
})


export class ValidatedocumentComponent implements OnInit{

  @ViewChild('fileInput') fileInput: FileUploader;
  public metaDataList = [];

    profileForm: FormGroup;
    error: string;
    fileList=[];
    uploadedDocument=[];
    status=[];
    intialDocumentType=[];
    selectedDocumentType: string;
    notes: string;
    placeholder='Select a Document type';
    isFileTypeSelected = false;
    documentType: any=[];
    fileValue = '1';
  
    fileUpload = {status: '', message: '', filePath: ''};
    
    responseModel: any;
    uploadModel: any;
    profile: any;
    caseId: any;
    providerName: any;
    providerType: any;
    stateCode:any;
     fileId: any;
    idVal: any;
    allStatus: any;
    allNotes: any;
    rejectionReason: any;
    documentTitle:any;
    progressPercent: number;
    disableUploadBtn: boolean;
    hideUploadBtn: boolean;
    uploadedFileName: any;
    access_token: any;
    documentTypeList: any=[];
    allDocumentTypeWithStatus: any=[];
    allDocumentTypes: any;
    removedDocumentType:any;
    documentId:any;
    loading = false;
    constructor(private fb: FormBuilder, private validateService: ValidatedocumentService, private http: HttpClient,
      private secureStorage:SecureStorageService) { }

    public uploader:FileUploader = new FileUploader({
        isHTML5: true
      });
  
     

    ngOnInit() {
      this.progressPercent = 0;
      this.hideUploadBtn = false;
      this.profileForm = this.fb.group({
        name: [''],
        profile: ['']
      });
      
      this.profile= JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('profileDetails'));
      let profileList;

      if(this.profile && this.profile.body) {
        profileList = this.profile.body;
      } else {
        profileList = this.profile;
      }

      profileList.keyValuePairList.forEach(element => {
        if(element.key==='CmAcmCaseIdentifier'){
          //console.log(element.value);
          this.caseId= element.value;
        }
        if(element.key==='CPMS_ProviderFullName'){
          this.providerName=element.value;
        }
        if(element.key==='CPMS_ProviderState'){
          this.stateCode=element.value;
        }
        if(element.key==='CPMS_NewHireType'){
          this.providerType=element.value;
        }
      });
      
      
      this.validateService.getDocumentTypes(this.caseId).subscribe(data => {

          this.responseModel=data;
          //console.log(this.responseModel.guid);
          for(let i=0;i<this.responseModel.documentList.length;i++){
            if(this.intialDocumentType.length>0){
              if(this.intialDocumentType.find(e=> e.name !== this.responseModel.documentList[i])){
                
                this.documentType.push({name:  this.responseModel.documentList[i]});
               
             }
            }
            else{
              this.documentType.push({name:  this.responseModel.documentList[i]});
            }
           }
          this.getBasicToken(); 

        });

    }
    clearProgressBar() {
      this.progressPercent =0;
      this.disableUploadBtn = true;
    }
getBasicToken(){
  this.validateService.getBasicToken().subscribe(resp=>{ 
    this.access_token=resp.access_token;
  // console.log('Inside Basic Token'+)
    this.secureStorage.setSecureInfoInLocalStorage('access_token',this.access_token);
    this.listDocuments(); 
  })
}
 listDocuments(){
  let keyvaluePairWrList=[];
      
  let documentResponse={keyValuePairListWrapperlist:[]};
  const kvpl = [
    { 'key': 'caseid', 'value': this.caseId }
];
this.validateService.documentList(kvpl,this.access_token).subscribe(res => {
  documentResponse = res;
  //console.log(documentResponse);
  this.allDocumentTypeWithStatus=[];
  keyvaluePairWrList=documentResponse.keyValuePairListWrapperlist;
        keyvaluePairWrList.forEach(element=>{
          this.idVal=null;
          this.documentTitle=null;
          this.allStatus=null;
          this.allNotes=null;
          this.rejectionReason=null;
          
          element.keyvaluePairWr.forEach(ele => {
           if(ele.key==='Id'){
               this.idVal = ele.value;
            }
           if(ele.key==='documentname'){
             this.documentTitle=ele.value;
           }
           if(ele.key==='status'){
             this.allStatus=ele.value; 
            }
          if(ele.key==='notes'){
            this.allNotes=ele.value;
          }
          if(ele.key==='rejectionreason'){
              this.rejectionReason=ele.value;
              //console.log(this.rejectionReason)
          }
          
          if(ele.key==='documenttype'){
             // console.log(ele.value)
             this.allDocumentTypes=ele.value
                this.intialDocumentType.push({name: ele.value})
             }
          if(this.idVal!==null && this.documentTitle!==null && this.allStatus!==null ){
            this.uploadedDocument.push({file: this.documentTitle, id: this.idVal, status: this.allStatus, notes: this.allNotes,reason: this.rejectionReason,type: this.allDocumentTypes});
            this.allDocumentTypeWithStatus.push({status: this.allStatus,documentType: this.allDocumentTypes});
            //console.log('this.uploaedDoc',this.uploadedDocument);
            this.idVal=null;
            this.documentTitle=null;
            this.allStatus=null;
          }   
          });
        })
        
        //this.reloadDocumentType();
        //console.log(this.allDocumentTypeWithStatus);
});
 }
/*  reloadDocumentType(){
  for(let i=0;i<this.allDocumentTypeWithStatus.length;i++){
    if(this.allDocumentTypeWithStatus[i].status==='Pending' || this.allDocumentTypeWithStatus[i].status==='Accepted' || this.allDocumentTypeWithStatus[i].status==='Awaiting'){
      this.documentType=this.documentType.filter(e=>e.name!==this.allDocumentTypeWithStatus[i].documentType)
    }
  }
 } */
    changeFileType(event) { 
     // console.log(this.selectedValue)
     if(this.selectedDocumentType){
      this.isFileTypeSelected=true;
     } else {
      this.isFileTypeSelected=false;
     }
  }
  
    onSelectedFile(event) {
        for (let j = 0; j < this.uploader.queue.length; j++) {
            const formData = new FormData();
            let fileItem = this.uploader.queue[j]._file;
            
            //console.log(fileItem.name);
            formData.append('file', fileItem);
            formData.append('fileSeq', 'seq'+j);
            formData.append('name', this.profileForm.get('name').value);
            formData.append('profile', this.profileForm.get('profile').value);
            this.fileList.push({name: fileItem.name, file: fileItem, notes: null});  
            
            //this.documentType=this.documentType.filter(e=>(e.name)!==this.selectedDocumentType);

            this.documentTypeList.push({name: fileItem.name,type: this.selectedDocumentType})

            //console.log(this.documentTypeList);
        }
        //this.isFileTypeSelected = true;
        //console.log('this.fileList',this.fileList);
        this.uploader.clearQueue();
      }
      
  handleClick(event) {
    event.target.disabled = 'true'
    //console.log('event disabled',event.target.disabled = 'true');
    this.uploadedFileName = event.target.parentElement.parentElement.firstElementChild.textContent
  }
     
      upload(file, event){
        event.target.disabled = 'true'
        this.uploadedFileName = event.target.parentElement.parentElement.firstElementChild.firstElementChild.textContent;
        event.target.parentElement.parentElement.style.display = "none";
        jQuery('#progressBarModal').modal('show');
        let documentMetaDataList = [];
        let fileItem = file;
        this.allDocumentTypeWithStatus=[];
        documentMetaDataList = this.metaDataList.map(x => Object.assign({}, x));
        let documentName=this.documentTypeList.find(element=>element.name === fileItem.name);
        let documentNotes=this.fileList.find(element=>element.name === fileItem.name);
        if(this.notes!==''){  
          documentMetaDataList.push({key: 'caseid', value: this.caseId},
                                { key: 'documentname', value: fileItem.name},
                                { key: 'status', value: 'Pending'},
                                { key: 'documenttype', value: documentName.type},
                                { key: 'notes', value: documentNotes.notes});
          //this.uploadedDocument.unshift({file: fileItem.name, id: this.idVal, status: 'Pending', notes: documentNotes.notes,reason: ''});
          this.allDocumentTypeWithStatus.push({status: 'Pending',documentType: documentName.type});
        }
        else{
          documentMetaDataList.push({key: 'caseid', value: this.caseId},
                                { key: 'documentname', value: fileItem.name},
                                { key: 'documenttype', value: documentName.type},
                                { key: 'status', value: 'Pending'});
         // this.uploadedDocument.unshift({file: fileItem.name, id: this.idVal, status: 'Pending'});
          this.allDocumentTypeWithStatus.push({status: 'Pending',documentType: documentName.type});
        }
        const document = {
          appId: 'PCS',
          documentCode: 'PROVIDER_DOC',
          txnLog: false,
          createdUser: 'PCS user 1',
          keyValuePairList: documentMetaDataList
        };
        const formData = new FormData();
        
        formData.append('content', file);
        formData.append('document',JSON.stringify(document));
        this.validateService.getBasicToken();
        this.documentId=null;
        const headers = new HttpHeaders({
          'Authorization': 'Bearer '+this.secureStorage.getSecureInfoInLocalStorage('access_token')
        });
        this.http.post(urls.uploadDocument, formData,{ headers: headers})
          .subscribe(
              (resp) => {
                this.uploadModel=resp;
                this.documentId=this.uploadModel.docId;
                this.progressPercent = 100;
                this.uploadedDocument.unshift({file: fileItem.name, id: this.documentId, status: 'Pending', notes: documentNotes.notes,reason: '',type: documentName.type});
              },
              () => {
              jQuery('#progressBarModal').modal('hide');
            }
          );
        }
        
    
        getUploadedDocument(doc) {
        
          this.validateService.getUploadedDoc(doc).subscribe(res => {
            const responseData = res;
            let docContent = _.find(responseData.docContent);
            if(docContent!=null){
              if (navigator.appVersion.toString().indexOf('.NET') > 0) {
              const blob = this.binary64ToBlob(responseData.docContent, responseData.fileType, 512);
              saveAs(blob, responseData.filename);
              } 
              else 
              {
              const blob = this.b64toBlob(responseData.docContent, responseData.fileType, 512);
              const urlBlob = URL.createObjectURL(blob);
              window.open(urlBlob, '_blank', 'location=yes,height=570,width=520');
              }
            }
            else {
              jQuery('#docErrorModal').modal('show');
            }
          });
        }
    
        binary64ToBlob(binary64String, contentType, sliceSize) {
          contentType = contentType || '';
          sliceSize = sliceSize || 512;
          const byteCharacters = atob(binary64String);
          const byteArrays = [];
          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }
          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        }
      
        b64toBlob(b64Data, contentType, sliceSize) {
          contentType = contentType || '';
          sliceSize = sliceSize || 512;
          const byteCharacters = atob(b64Data);
          const byteArrays = [];
          for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }
          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        }
      onDelete(file){
        let documentMetaDataList = [];
        documentMetaDataList = this.metaDataList.map(x => Object.assign({}, x));
       
          documentMetaDataList.push({key: 'caseid', value: this.caseId},
                                { key: 'status', value: 'Pending'});

        
        const document = {
          appId: 'PCS',
          documentCode: 'PROVIDER_DOC',
          txnLog: false,
          createdUser: 'PCS user 1',
          keyValuePairList: documentMetaDataList
        };
        const formData = new FormData();
        
        formData.append('content', file);
        formData.append('document',JSON.stringify(document));
      }
      

      remove(name){  

        this.fileList=this.fileList.filter(file =>(file.name!==name) );
      }

      showDeleteModal(itemId) {
        this.fileId = itemId;
        jQuery('#deleteModal').modal('show');
      }
    
      deleteUploadedDocument(doc,providerName){
        providerName=this.providerName
        this.validateService.deleteuploadedDoc(doc,providerName).subscribe(res => {
        const responseData = res;
        this.uploadedDocument = this.uploadedDocument.filter(file =>(responseData.docID !== file.id));
      });
      }

      onBoardingChecklist(){
        this.loading=true;
        const kvpl = [
          { 'key': 'statecode', 'value': this.stateCode },
          { 'key': 'ProviderType', 'value': this.providerType },
          { 'key': 'documentType', 'value': 'OnboardingChecklistTemplate' }
        ];
      this.validateService.onBoardingChecklist(kvpl,this.access_token).subscribe(res =>{
        let response=res;
        //console.log(response);
        let docContent = _.find(response.docContent);
            if(docContent!=null){
              if (navigator.appVersion.toString().indexOf('.NET') > 0) {
              const blob = this.binary64ToBlob(response.docContent, response.fileType, 512);
              saveAs(blob, response.filename);
              } 
              else 
              {
              const blob = this.b64toBlob(response.docContent, response.fileType, 512);
              const urlBlob = URL.createObjectURL(blob);
              window.open(urlBlob, '_blank', 'location=yes,height=570,width=520');
              this.loading=false;
              }
            }
            else {
              this.loading=false;
              jQuery('#docErrorModal').modal('show');
            }
      });
      }
      pecosFormDocument(){
        this.loading=true;
        const kvpl = [
          { 'key': 'documentType', 'value': 'PECOS FORM' }
      ];
      this.validateService.pecosandCaqhFormDocument(kvpl,this.access_token).subscribe(res =>{
        let response=res;
        //console.log(response);
        let docContent = _.find(response.docContent);
            if(docContent!=null){
              if (navigator.appVersion.toString().indexOf('.NET') > 0) {
              const blob = this.binary64ToBlob(response.docContent, response.fileType, 512);
              saveAs(blob, response.filename);
              } 
              else 
              {
              const blob = this.b64toBlob(response.docContent, response.fileType, 512);
              const urlBlob = URL.createObjectURL(blob);
              window.open(urlBlob, '_blank', 'location=yes,height=570,width=520');
              this.loading=false;
              }
            }
            else {
              this.loading=false;
              jQuery('#docErrorModal').modal('show');
            }
      });
      }
      caqhFormDocument(){
        this.loading=true;
        const kvpl = [
          { 'key': 'documentType', 'value': 'CAQH FORM' }
      ];
      this.validateService.pecosandCaqhFormDocument(kvpl,this.access_token).subscribe(res =>{
        let response=res;
        //console.log(response);
        let docContent = _.find(response.docContent);
            if(docContent!=null){
              if (navigator.appVersion.toString().indexOf('.NET') > 0) {
              const blob = this.binary64ToBlob(response.docContent, response.fileType, 512);
              saveAs(blob, response.filename);
              } 
              else 
              {
              const blob = this.b64toBlob(response.docContent, response.fileType, 512);
              const urlBlob = URL.createObjectURL(blob);
              window.open(urlBlob, '_blank', 'location=yes,height=570,width=520');
              this.loading=false;
              }
            }
            else {
              this.loading=false;
              jQuery('#docErrorModal').modal('show');
            }
      });
      }
  
    onSubmit() {
        for (let j = 0; j < this.uploader.queue.length; j++) {
            const formData = new FormData();
            let fileItem = this.uploader.queue[j]._file;
            //console.log(fileItem.name);
            formData.append('file', fileItem);
            formData.append('fileSeq', 'seq'+j);
            formData.append( 'dataType', this.profileForm.controls.type.value);
            formData.append('name', this.profileForm.get('name').value);
            formData.append('profile', this.profileForm.get('profile').value);
        }
    }
}