import { Injectable } from '@angular/core';

@Injectable()
export class CupDateService {

  private dateObj = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    private month = this.dateObj.getUTCMonth() + 1;
    private day = this.dateObj.getUTCDate();
    private year = this.dateObj.getUTCFullYear();

  constructor() { }

  /*Convert date format*/
  // public convertDateFormat(str) {
  //   let date = new Date(str),
  //     mnth = ('0' + (date.getMonth() + 1)).slice(-2),
  //     day = ('0' + date.getDate()).slice(-2);
  //   return [date.getFullYear(), mnth, day].join('-');
  // }

  public convertDateFormat(str) {
    try{
    if (str) {
      var month = str.slice(5, 7);
      var date = str.slice(8, 10);
      var year = str.slice(0, 4);
      var totalDate = month +'/'+ date + '/' + year;
      return totalDate;
    } else {
      return '';
    }
    }catch(err){
    throw err;
    }
  }

  /*Compare two dates */
  public compareTwoDates(dateOne, dateTwo) {
    if (dateOne && dateTwo) {
      const startValue = dateOne.split('-');
      const endValue = dateTwo.split('-');
      const convertedStartDate = new Date(parseInt(startValue[0]),
        parseInt(startValue[1]) - 1,
        parseInt(startValue[2]));
      const convertedEndDate = new Date(parseInt(endValue[0]),
        parseInt(endValue[1]) - 1,
        parseInt(endValue[2]));
      if (convertedEndDate.getTime() < convertedStartDate.getTime()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  /*Valid date format mm-dd-yyyy- Input to be passed date object*/
  public getValidDateTime(dateObj: any): string {
    if (dateObj) {
      const date = new Date(dateObj),
        year = date.getFullYear(),
        month = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2);
      return year + '-' + month + '-' + day;
    } else {
      return '';
    }
  }

  /*Get timestamp- Input to be passed date object*/
  public getTimeStamp(dateObj: any): string {
    if (dateObj) {
      const date = new Date(dateObj),
        year = date.getFullYear(),
        month = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2),
        hours = ('0' + date.getHours()).slice(-2),
        minutes = ('0' + date.getMinutes()).slice(-2);
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
    } else {
      return '';
    }
  }
}
