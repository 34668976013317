import { Pipe, PipeTransform } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';

interface DropDown {
    id: string;
    value: string;
}

@Pipe({ name: 'selectOption' })
export class AutoCompletePipe implements PipeTransform {

  public transform(option: DropDown[]): SelectItem[] {
    if (!option) { return undefined; }
    return option.map(p => ({ label: p.value, value: {id: p.id, value: p.value} }));
  }
}
