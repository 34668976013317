import { Injectable } from '@angular/core';


import { urls, props, payloads } from '../../common/model/properties';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SecureStorageService } from '../../secure-storage.service';

@Injectable()
export class FooterFaqService {

    constructor( private http: HttpClient ,
        private secureStorage:SecureStorageService) {
    }
    getFaqs( callBack: ( sucess: boolean, message: string ) => void ) {

        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' +  ppjwt.access_token 
        });

        this.http.get( urls.getFaqs, { headers: headers } )
            .subscribe(
            ( resp:any ) => {
                callBack( true, resp );
            },
            err => {
                callBack( false, 'Error while sending the Email. ' );
            }
            );
    }


}
