import { Component, OnInit } from '@angular/core';
import { ForgotpasswordquestionsService } from './forgotpasswordquestions.service';
import { HttpParams } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

declare var jQuery: any;

@Component({
    selector: 'app-forgotpassword',
    templateUrl: './forgotpasswordquestions.component.html',
    styleUrls: ['./forgotpasswordquestions.component.scss']
})
export class ForgotpasswordquestionsComponent implements OnInit {
    userName: any;
    LoginErrorMessage: any;
    questionResponse: any;
    errorMessage: any;
    news = [];
    loading = false;
    constructor(private router: Router, private route: ActivatedRoute, private questionService: ForgotpasswordquestionsService) { }

    ngOnInit() {
        this.checkUserLogin();
        this.questionResponse = { questions: [] };
        this.route.params.subscribe(params => {
            this.userName = params['userName'];
        });
        this.loadQuestions(this.userName);

        const questions = this.questionResponse.questions;
        this.getNews();

    }
    checkUserLogin() {
    }
    onInputFocus(event: any, index: number) {
        this.hideValidate(event.target.name, index);
    }
    hideValidate(inputName: string, index: number) {
        const questions = this.questionResponse.questions;
        questions[index].isValidAnswer = true;
    }
    onSubmit() {
        this.errorMessage = '';
        this.loading = true;
        const questions = this.questionResponse.questions;
        let hasAllAnswers = true;
        for (let i = 0; i < questions.length; i++) {
            if (questions[i].answer === undefined || questions[i].answer === '') {
                questions[i].isValidAnswer = false;
                hasAllAnswers = false;
                this.loading = false;
            }
        }
        if (!hasAllAnswers) {
            return;
        }
        this.questionService.validateQuestion(this.questionResponse).
            subscribe(
                (resp) => {
                    this.showNewPasswordPage(resp['key']);
                    this.questionService.setUserNameFromUrl(this.userName);
                    this.loading = false;
                    return;
                },
                err => {
                    this.errorMessage = 'Failed to Validate Questions';
                    jQuery('#myModal').modal('show');
                    this.loading = false;
                    return;
                }
            );
    }

    loadQuestions(userName) {
        this.loading = true;
        this.questionService.getSecurityQuestions(userName).
            subscribe(
                (resp) => {
                    this.questionResponse = resp;
                    this.loading = false;
                },
                err => this.LoginErrorMessage = 'Failed to load Questions for user : ' + userName
            );
            this.loading = false;
    }

    showNewPasswordPage(key): void {
        this.router.navigate(['./resetpassword', { key: key }]);
    }
    getNews() {
        this.loading = true;
        this.questionService.getNews((success: boolean, news: any) => {
            if (success) {
                this.news = news['providerPortalBroadcast'];
                this.loading = false;
            } else {
                this.loading = false;
            }
        });

    }
}
