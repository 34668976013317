import { Component, OnInit } from '@angular/core';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { UploaddocumentService } from './uploaddocument.service'
import { SecureStorageService } from '../secure-storage.service';

declare var jQuery: any;
@Component({
    selector: 'app-uploadDocument',
    templateUrl: './uploaddocument.component.html',
    styleUrls: ['./uploaddocument.component.scss']
})

export class UploaddocumentComponent implements OnInit{

    loading = false;
    responseModel: any
    public model =
    {
        caseId: '',
        credCode: ''
    };
    
    errorMessage = '';
    isFieldValid = {};
    constructor(private uploadService:UploaddocumentService , private router: Router,
        private secureStorage:SecureStorageService){

    }

    ngOnInit() {
        this.responseModel={ keyValuePairList : [], errCode:'', errMessage:'', guid:''};
        this.resetInputValidations();
    }

    onInputFocus(event: any) {
        this.hideValidate(event.target.name);
    }
    hideValidate(inputName: string) {
        this.isFieldValid[inputName] = true;
    }

    onSubmit(){
        if (this.model.caseId =='' || this.model.credCode =='') {
            this.showAlertPopup('Please provide  the case ID and passcode received',() => { this.router.navigate(['uploaddocument']);});
            this.loading= false;
            return;
       }

        this.loading=true;

        // Temparary code to be Commented because as of now uplaod doc for onbording functionality is removed for now


        // if(this.model.caseId!=='' && this.model.credCode!==''){
        //     this.uploadService.requestProfileWithBothfields(
        //         this.model.caseId,
        //         this.model.credCode,(success: boolean, message: any) => {
        //         this.responseModel=message;
        //            if(this.responseModel.guid === null){
        //             this.showAlertPopup('Please check the case ID/ passcode received',() => { this.router.navigate(['uploaddocument']);});
    
        //            }else{
        //             this.loading = false;
        //             this.secureStorage.setSecureInfoInSessionlStorage('profileDetails',JSON.stringify(message));
        //             this.router.navigate(['validatedocument']); 
        //            }
        //         });
        // }
        // else if(this.model.caseId==='' || this.model.credCode===''){
        //     this.showAlertPopup('Please provide  the case ID and passcode received',() => { this.router.navigate(['uploaddocument']);});
        // } 
    }
    private showAlertPopup(message: string, callBack) {
        this.errorMessage = message;
        this.showPopup('myModal', callBack);
        return;
    }

    private showPopup(popupName: string, callBack) {
        jQuery('#' + popupName).modal('show');
        this[popupName + 'EventCallBack'] = callBack;
    }
    private checkForEmptyField(fieldNameArr: string[]) {
        for (const fieldName of fieldNameArr) {
            this.isFieldValid[fieldName] = this.model[fieldName] !== '' ? true : false;
        }
    }
    myModalEventCallBack() { }
    private resetInputValidations() {
        const fieldNameArr = ['caseId', 'passCode'];
        for (const fieldName of fieldNameArr) {
            this.isFieldValid[fieldName] = true;
        }
    }
    restrictToAlpha(event: any) {
        const inputValue = event.target.value;
        const numberValue = this.extractAlphaString(inputValue);

        event.target.value = numberValue;
    }

    extractAlphaString(value: string) {
        let newVal = '';
        for (let i = 0; i < value.length; i++) {
            const s: string = value.charAt(i);
            if (this.isAlpha(s)) {
                newVal += s;
            }
        }
        return newVal;
    }
    isAlpha(str: string) {
        return /^[a-zA-Z0-9_]+$/.test(str);
    }
}