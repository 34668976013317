import { Component, OnInit, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { GlobalService } from '../shared/services/global.service';
import { UserIdleService } from 'angular-user-idle';
import { CupService } from '../shared/services/cup.service';
import { HeaderService } from '../header/header.service';
import { ProviderportalService } from '../providerportal/providerportal.service';
import { Router } from '@angular/router';
import { CupDateService } from '../shared/services/cup-date.service';
declare var jQuery: any;
import { saveAs } from 'file-saver';
import { DocumentsService } from '../documents/documents.service';
//import { element } from 'protractor';
import * as _ from 'lodash';
import { AlertServiceService } from '../shared/services/alert-service.service';
import { SecureStorageService } from '../secure-storage.service';
import { AccountProfileService } from '../accountProfile/accountProfile.service';
@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css']
})
export class SearchResultsComponent extends HeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public resultGridList: Array<any> = [];
  userProfile: any;
  loading = false;
  keywordsMetaData: any[] = [
    {
      'keywordName': 'Home',
      'link': '/providerportal/portal',
      'admin': 'false'
    },
    {
      'keywordName': 'Quality Measures',
      'link': '/providerportal/provider-dashboard',
      'admin': 'false'
    },
    {
      'keywordName': 'Clinical Communication',
      'link': '/providerportal/provider-clinicalCommunications',
      'admin': 'false'
    },
    {
      'keywordName': 'Manage Users',
      'link': '/providerportal/site_Administration',
      'admin': 'true'
    },
    {
      'keywordName': 'General Reference Documents',
      'link': '/providerportal/documents?documentCode=GEN_REF_DOC',
      'admin': 'false'
    },
    {
      'keywordName': 'View/Authenticate',
      'link': '/providerportal/site_Administration/view_authenticate',
      'admin': 'true'
    },
    {
      'keywordName': 'Attach to site',
      'link': '/providerportal/site_Administration/attach_to_site',
      'admin': 'true'
    },
    {
      'keywordName': 'View Profile',
      'link': '/providerportal/accountProfile',
      'admin': 'false'
    },
    {
      'keywordName': 'Edit Profile',
      'link': '/providerportal/accountProfile/edit',
      'admin': 'false'
    },
    {
      'keywordName': 'Change Password',
      'link': '/providerportal/changePassword',
      'admin': 'false'
    },
    {
      'keywordName': 'Broadcast History',
      'link': '/providerportal/broadcastHistory',
      'admin': 'false'
    },
    {
      'keywordName': 'View Claims by Provider',
      'link': '/providerportal/claimsummary',
      'admin': 'false'
    },
    {
      'keywordName': 'Inbox',
      'link': '/providerportal/messages',
      'admin': 'false'
    },
    {
      'keywordName': 'Provider Reference Documents',
      'link': '/providerportal/documents?documentCode=PROV_COMM_DOC',
      'admin': 'false'
    },
    {
      'keywordName': 'FAQ',
      'link': '/providerportal/faq',
      'admin': 'false'
    },
    {
      'keywordName': 'Help',
      'link': '/providerportal/connect',
      'admin': 'false'
    },
    {
      'keywordName': 'PAHAF',
      'link': '/providerportal/pahaf',
      'admin': 'false'
    },
    {
      'keywordName': 'Provider Payments',
      'link': '/providerportal/documents?documentCode=CHANGE_HEALTH_CARE',
      'admin': 'false'
    },

    // {
    //   'keywordName': 'PCP Score Card',
    //   'link': '/providerportal/pcp-scorecard',
    //   'admin': 'false'
    // },
    {
      'keywordName': 'View Eligibility Report',
      'link': 'providerportal/report',
      'admin': 'false'
    },
    {
      'keywordName': 'Provider Training and Attestation',
      'link': '/providerportal/provider-training',
      'admin': 'false'
    },
    {
      'keywordName': 'View Careplan Document',
      'link': '/providerportal/care-plan-document',
      'admin': 'false'
    },
    {
      'keywordName': 'Patient Experience',
      'link': '/providerportal/patient-experience',
      'admin': 'false'
    },
  ];
  otherLinks: any[] = [
    {
      'keywordName': 'View Assigned Members',
      'clickNumber': '1;',
    },
    {
      'keywordName': 'View Claims by Member',
      'clickNumber': '5',
    },
    {
      'keywordName': 'View Prior Auths',
      'clickNumber': '4',
    },
    {
      'keywordName': 'Submit New Auth or Referral',
      'clickNumber': '6',
    },
    {
      'keywordName': 'View Diagnosis and Procedure Codes',
      'clickNumber': '7',
    },
    {
      'keywordName': 'View Medical Providers',
      'clickNumber': '5',
    }
  ];
  documentRetrivalMap: any[] = [
    {
      'keywordName': 'End User Agreement',
      'clickNumber': 1,
    },
    {
      'keywordName': 'End User Privileges',
      'clickNumber': 2,
    },
    {
      'keywordName': 'End User License Agreement',
      'clickNumber': 3,
    },
  ];
  fileNetDocuments: any[] = [];
  userState: any;
  clientAccessStates: Array<any> = [];
  nodes: any;
  isTreeLoaded: boolean;
  docSearchAPICall: any;
  constructor(globalSrv: GlobalService,
    userIdle: UserIdleService,
    cupService: CupService,
    currentService: HeaderService,
    alertService: AlertServiceService,
    providerportalService: ProviderportalService,
    router: Router,
    cupDateService: CupDateService,
    public secureStorage:SecureStorageService,
    private documentsService: DocumentsService,
    accountProfileService: AccountProfileService,
     ) {
    super(alertService,accountProfileService,globalSrv, userIdle, cupService, currentService, providerportalService, router, cupDateService,secureStorage);
  }

 async ngOnInit() {
    this.userProfile = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('userProfile'));
    if (this.userProfile['userRole'] !== 'Admin') {
      this.keywordsMetaData = this.keywordsMetaData.filter(s => s.admin === 'false');
    }
    if (this.secureStorage.getSecureInfoInSessionStorage('userProfile')) {
      this.userState = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('userProfile')).state;
    }
    this.getClientDetails();
  }
 
  ngOnChanges(changes: SimpleChanges) {
    this.loading = true;
    if (changes.resultGridList) {
      if(changes.resultGridList.currentValue && changes.resultGridList.currentValue.length > 0){
        this.getDocuments(changes.resultGridList.currentValue);

      }else{
        
      }
    }
  }
  
  modalClose() {
    jQuery('.memberModal').modal('hide');
    window.open('../CUPPortal/#/singlesignon', '_new');
    this.secureStorage.setSecureInfoInLocalStorage('targetUrl', 'member');
  }
  navigate(url) {
    this.router.navigateByUrl(url);
  }
  documents(num: Number) {
    if (num === 1) {
      const docId = '{5A97A8D0-C043-C8FF-874E-6D278DD00000}';
      const docClass = 'USER_AGREEMENT_DOC';
      this.getUserDocument(docId, docClass);
    }
    if (num === 2) {
      const docId = '{F3F4F46D-C828-C6D1-8702-6D278E700000}';
      const docClass = 'USER_AGREEMENT_DOC';
      this.getUserDocument(docId, docClass);
    }
    if (num === 3) {
      this.getLicenseDocument();
    }
  }
  template(num) {
    if (num === '1') {
      this.secureStorage.setSecureInfoInLocalStorage('targetUrl', 'member');
    }
    if (num === '5') {
      event.preventDefault();
      jQuery('#viewMemberClaimsModal').modal('show');
    }
    if (num === '4') {
      this.secureStorage.setSecureInfoInLocalStorage('targetUrl', 'general/view-authorization');
    }
    if (num === '6') {
      event.preventDefault();
      jQuery('#submitMemberAuthModal').modal('show');
    }
    if (num === '7') {
      this.secureStorage.setSecureInfoInLocalStorage('targetUrl', 'general/diagnosis-search');
    }
  }
  getUserDocument(docId, docClass) {
    this.currentService.getDocument(docId, docClass, null).subscribe(res => {
      const responseData = res;
      const state = _.find(responseData.keyValuePairList, ['key', 'State']);
      if (state && (state.value === this.userState ||
         this.clientAccessStates.includes(state.value)) ||
         docClass === 'GEN_REF_DOC' || docClass === 'USER_AGREEMENT_DOC') {
        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
          const blob = this.binary64ToBlob(responseData.docContent, responseData.fileType, 512);
          saveAs(blob, responseData.filename);
          this.loading = false;
        } else {
          const blob = this.b64toBlob(responseData.docContent, responseData.fileType, 512);
          const urlBlob = URL.createObjectURL(blob);
          window.open(urlBlob, '_blank', 'location=yes,height=570,width=520');
          this.loading = false;
        }
      } else {
        jQuery('#errorModal').modal('show');
        this.loading = false;
      }
    });
  }
  getClientDetails() {
    this.providerportalService.getClientDetails().subscribe((response:any) => {
      if (response && response.length) {
        response.map(data => {
          if (data.market) {
            data.market.map(row => {
              this.clientAccessStates.push(row.stateCode)
            })
          }
        })
      }
    })
  };
  getLicenseDocument() {
    this.loading = true;
    this.currentService.getDocumentForLicense().subscribe(res => {
      const responseData = res;
      if (navigator.appVersion.toString().indexOf('.NET') > 0) {
        const blob = this.binary64ToBlob(responseData.docContent, responseData.fileType, 512);
        saveAs(blob, responseData.filename);
        this.loading = false;
      } else {
        const blob = this.b64toBlob(responseData.docContent, responseData.fileType, 512);
        const urlBlob = URL.createObjectURL(blob);
        window.open(urlBlob, '_blank', 'location=yes,height=570,width=520');
        this.loading = false;
      }
    });
  }
  async getDocuments(userEnteredKeyword) {
    this.loading = true;
    this.fileNetDocuments = [];
    let duplicates = [];
    
    try{
    let res:any= await this.documentsService.listDocumentsForSearch(userEnteredKeyword);
    // const regEx = new RegExp('userEnteredKeyword','g');
      const documents = res.keyValuePairListWrapperlist;
      if (documents && documents.length > 0) {
        this.loading = true;
        var tempDocs = [];
        let filteredData = [];
        let results = [];
        documents.map((elements: any) => {
          if (elements.keyvaluePairWr && elements.keyvaluePairWr.length > 0) {
            let docName = '';
            let DocumentGUID = '';
            let DocumentClass = '';
            elements.keyvaluePairWr.map(doc => {
              if (doc.key === 'DocumentTitle' && doc.value) {
                docName = doc.value;
              }
              if (doc.key === 'Id' && doc.value) {
                DocumentGUID = doc.value;
              }
              if (doc.key === 'DocumentClass' && doc.value) {
                DocumentClass = doc.value;

              }
              if(!duplicates.includes(docName)){
                duplicates.push(docName);
              tempDocs.push({ docName: docName, DocumentGUID: DocumentGUID, DocumentClass: DocumentClass });
              }
             // this.loading = false;
            });
         
          }
        });
       
       
        this.fileNetDocuments=tempDocs;

        
        this.loading = false;
    
      } else {
        this.loading = false;
        this.fileNetDocuments = [];
      }
    }catch(error){
      this.fileNetDocuments = [];
      this.loading = false;
    }
    
  }
  ngOnDestroy() {
    this.loading = false;
    //this.docSearchAPICall.unsubscribe();
  }
}
