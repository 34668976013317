import { InboxComponent } from './inbox/inbox.component';
import { NgModule, Component } from '@angular/core';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ForgotusernameComponent } from './forgotusername/forgotusername.component';
import { UploaddocumentComponent } from './uploaddocument/uploaddocument.component';
import { ValidatedocumentComponent } from './validatedocument/validatedocument.component';
import { ForgotpasswordquestionsComponent } from './forgotpasswordquestions/forgotpasswordquestions.component';
import { SignupConfirmationComponent } from './signup-form/confirmation/confirmation.component';
import { FooterFaqComponent } from './footer/faq/faq.component';
import { FooterConnectComponent } from './footer/connect/connect.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { RouterModule, Routes,  } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { AccountProfileComponent } from './accountProfile/accountProfile.component';
import { ChangePasswordComponent } from './changePassword/changePassword.component';
import { SignupFormComponent } from './signup-form/signup-form.component';
import { DocumentsComponent } from './documents/documents.component';
import { AccountProfileEditComponent } from './accountProfile/accountProfileEdit/accountProfileEdit.component';
import { BroadcastHistoryComponent } from './broadcast-history/broadcast-history.component';
import { FrontdeskComponent } from './frontdesk/frontdesk.component';
import { AddtositeComponent } from './addtosite/addtosite.component';
import { UserauthenticationComponent } from './userauthentication/userauthentication.component';
import { LoginGuard } from './guards/login.guard';

const appRoutes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: SigninComponent },
    { path: 'changeProfile', component: SigninComponent },
    { path: 'changeProfileWithData', component: SigninComponent },
    { path: 'providerportal/accountProfile',
        component: AccountProfileComponent,
        data: {
            breadcrumbs: 'Providerportal /Account Profile'
        },
        canActivate: [LoginGuard]
    },
    { path: 'providerportal/accountProfile/edit',
        component: AccountProfileEditComponent,
        data: {
            breadcrumbs: 'Providerportal /Account Profile /Edit'
        }, canActivate: [LoginGuard]
    },
    { path: 'providerportal/changePassword', component: ChangePasswordComponent, canActivate: [LoginGuard] },
    { path: 'home', component: SigninComponent },
    { path: 'providerportal',
        loadChildren: ()=>import('./providerportal/providermodule.module').then(m=>m.ProviderModule),
        //  './providerportal/providermodule.module#ProviderModule',
        canActivate: [LoginGuard]
    },
    { path: 'forgotpassword', component: ForgotpasswordComponent },
    { path: 'forgotusername', component: ForgotusernameComponent },
    { path: 'uploaddocument', component: UploaddocumentComponent},
    {path: 'validatedocument', component: ValidatedocumentComponent},
    { path: 'sign-up', component: SignupFormComponent },
    { path: 'forgotpasswordquestions',
        component: ForgotpasswordquestionsComponent
    },
    { path: 'resetpassword', component: ResetpasswordComponent },
    {  path: 'providerportal/faq',
        component: FooterFaqComponent,
        data: {
            breadcrumbs: 'Providerportal/FAQ'
        }, canActivate: [LoginGuard]
    },
    { path: 'providerportal/connect', component: FooterConnectComponent },
    { path: 'accountrecoveryendpoint/confirmregistration.do',
        component: SignupConfirmationComponent
    },
    { path: 'providerportal/documents', component: DocumentsComponent, canActivate: [LoginGuard] },
    { path: 'providerportal/inbox', component: InboxComponent, canActivate: [LoginGuard] },
    {  path: 'providerportal/broadcastHistory',
        component: BroadcastHistoryComponent,
        canActivate: [LoginGuard]
    },
    { path: 'providerportal/messages',
        loadChildren: ()=>import('../app/messagesmodule/authmessages.module').then(m=>m.AuthMessagesModule),
        //'./messagesmodule/authmessages.module#AuthMessagesModule',
        canActivate: [LoginGuard]
    },
    { path: 'providerportal/site_Administration', component: FrontdeskComponent, canActivate: [LoginGuard] },
    { path: 'providerportal/site_Administration/attach_to_site',
        component: AddtositeComponent,
        data: {
            breadcrumbs: 'Providerportal/site_Administration/attach_to_site'
        },
        canActivate: [LoginGuard]
    },
    { path: 'providerportal/site_Administration/view_authenticate',
        component: UserauthenticationComponent,
        data: {
            breadcrumbs: 'Providerportal/site_Administration/view_authenticate'
        }
    },
    { path: '**', redirectTo: 'login' },

];

@NgModule({
    
    declarations: [
    ],
    imports: [
        RouterModule.forRoot(appRoutes, { useHash: true })
    ],
    providers: [
        LoginGuard
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
