<div id="main-wrapper" *ngIf="isLoaded" class="editProfilePage">

	<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
	<app-header (found)="handleResults($event)"></app-header>

	<div class="container">
		<div class="row containerTop">
			<app-search-results [resultGridList]="data"></app-search-results>

			<div class="row heading">
				<h3>Edit Account Profile</h3>
			</div>

			<div class="row subHeading">
				<h5>Personal Information</h5>
			</div>

			<div class="row col-md-12 pl-0">
				<div class="col-md-4 pl-0">
					<input1-component [model]="inputComps.get('firstName')" [isMandatory]='true'></input1-component>
				</div>
				<div class="col-md-4">
					<input1-component [model]="inputComps.get('middleName')"></input1-component>
				</div>
				<div class="col-md-4">
					<input1-component [model]="inputComps.get('lastName')" [isMandatory]='true'></input1-component>
				</div>
			</div>

			<div class="row col-md-12 pl-0 mb-2">
				<div class="col-md-4 pl-0">
					<input1-component [model]="inputComps.get('npi')" [isMandatory]='true'></input1-component>
				</div>
				<div class="col-md-4">
					<input1-component [model]="inputComps.get('taxId')" [isMandatory]='true'></input1-component>
				</div>
			</div>

			<div class="row subHeading">
				<h5>Contact Details</h5>
			</div>

			<div class="row col-md-12 pl-0">
				<div class="col-md-4 pl-0">
					<input1-component [model]="inputComps.get('addressLine1')" [isMandatory]='true'></input1-component>
				</div>
				<div class="col-md-4">
					<input1-component [model]="inputComps.get('addressLine2')"></input1-component>
				</div>
				<div class="col-md-4">
					<input1-component [model]="inputComps.get('state')" [isMandatory]='true'></input1-component>
				</div>
			</div>

			<div class="row col-md-12 pl-0">
				<div class="col-md-4 pl-0">
					<input1-component [model]="inputComps.get('city')" [isMandatory]='true'
						[dataSource]="cityDataSource"></input1-component>
				</div>
				<div class="col-md-4">
					<input1-component [model]="inputComps.get('zip')" [isMandatory]='true' [dataSource]="zipDataSource">
					</input1-component>
				</div>
				<div class="col-md-4">
					<input1-component [model]="inputComps.get('phone')" [isMandatory]='true'></input1-component>
				</div>
			</div>

			<div class="row col-md-12 pl-0 mb-2">
				<div class="col-md-4 pl-0">
					<input1-component [model]="inputComps.get('extn')"></input1-component>
				</div>
				<div class="col-md-4">
					<input1-component [model]="inputComps.get('fax')"></input1-component>
				</div>
				<div class="col-md-4">
					<input1-component [model]="inputComps.get('email')" [isMandatory]='true'></input1-component>
				</div>
			</div>

			<div class="row subHeading">
				<h5>Security Questions</h5>
			</div>

			<div class="row col-md-12 pl-0">

				<div class="col-md-4 pl-0">
					<div class="form-group" *ngIf="inputComps.get('question1') && inputComps.get('question1').isSelect">
						<span class="red-color">*</span> <label>{{inputComps.get('question1').label}}</label>
						<div [ngClass]="inputComps.get('question1').isInputValid ? 'validate-input' : 'validate-input alert-validate'"
							[attr.data-validate]="inputComps.get('question1').fieldValidateMsg">
							<select type="text" class="form-control" [(ngModel)]="inputComps.get('question1').value"
								[attr.maxlength]="inputComps.get('question1').length">
								<option *ngFor="let option of inputComps.get('question1').options"
									[value]="option.securityQuestion">{{option.securityQuestion}}</option>
							</select>
						</div>
					</div>
				</div>

				<div class="col-md-4">
					<div class="form-group" *ngIf="inputComps.get('answer1') && !inputComps.get('answer1').isSelect">
						<span class="red-color">*</span> <label>{{inputComps.get('answer1').label}}</label>
						<div [ngClass]="inputComps.get('answer1').isInputValid ? 'validate-input' : 'validate-input alert-validate'"
							[attr.data-validate]="inputComps.get('answer1').fieldValidateMsg">
							<!-- [readonly]="!inputComps.get('question1').value||inputComps.get('question1').value==questionOneSelected" -->
							<input (keyup)="inputComps.get('answer1').restrictKeys($event)" type="text"
								class="form-control" [(ngModel)]="inputComps.get('answer1').value"
								[attr.maxlength]="inputComps.get('answer1').length">
						</div>
					</div>
				</div>
			</div>

			<div class="row col-md-12 pl-0">
				<div class="col-md-4 pl-0">
					<div class="form-group" *ngIf="inputComps.get('question2') && inputComps.get('question2').isSelect">
						<span class="red-color">*</span> <label>{{inputComps.get('question2').label}}</label>
						<div [ngClass]="inputComps.get('question2').isInputValid ? 'validate-input' : 'validate-input alert-validate'"
							[attr.data-validate]="inputComps.get('question2').fieldValidateMsg">
							<select type="text" class="form-control" [(ngModel)]="inputComps.get('question2').value"
								[attr.maxlength]="inputComps.get('question2').length">
								<option *ngFor="let option of inputComps.get('question2').options"
									[value]="option.securityQuestion">{{option['securityQuestion']}}</option>
							</select>
						</div>
					</div>
				</div>

				<div class="col-md-4">
					<div class="form-group" *ngIf="inputComps.get('answer2') && !inputComps.get('answer2').isSelect">
						<span class="red-color">*</span> <label>{{inputComps.get('answer2').label}}</label>
						<div [ngClass]="inputComps.get('answer2').isInputValid ? 'validate-input' : 'validate-input alert-validate'"
							[attr.data-validate]="inputComps.get('answer2').fieldValidateMsg">
							<!-- [readonly]="!inputComps.get('question2').value||inputComps.get('question2').value==questionTwoSelected" -->
							<input (keyup)="inputComps.get('answer2').restrictKeys($event)" type="text"
								class="form-control" [(ngModel)]="inputComps.get('answer2').value"
								[attr.maxlength]="inputComps.get('answer2').length">
						</div>
					</div>
				</div>
			</div>

			<div class="row col-md-12 pl-0 actions">
				<div class="col-md-12 pl-0" align="end">
					<button type="button" (click)="redirectToAccountProfile()"
						class="btn carelon-secondary-btn">Cancel</button>

					<button class="btn btn-primary carelon-primary-btn" (click)="validateInputs()"
						type="submit">Update</button>
				</div>
			</div>



			<!----Alert modal for successful Update  -->
			<div class="row">
				<div class="modal fade" id="alertModal" role="dialog">
					<div class="modal-dialog" style="margin-top: 15%;">
						<div class="modal-content alertPopup">
							<div class="modal-header">
								<div class="row">
									<div class="col-md-9">

									</div>
									<div class="col-md-3">
										<button type="button" class="close" (click)="closeModal()"
											data-dismiss="modal"><img src="../../../assets/images/Close.svg"
												alt="close"></button>
									</div>
								</div>
							</div>
							<div class="modal-body">
								<div class="row imgContainer" *ngIf="!showContact">
									<img src="../../../assets/images/icons/Success.svg" alt="Success">
								</div>
								<p class="text">{{ message }} <a *ngIf="showContact" (click)="closeModel()"
										routerLink="/providerportal/connect"> Click here to
										report</a></p>
							</div>
							<div class="modal-footer">
								<div class="col-md-12 pl-0" align="center">
									<button type="button" data-dismiss="modal" (click)="alertModalEventCallBack()"
										class="btn carelon-primary-btn">Close</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!--Enter Password Modal-->

				<div class="modal fade" id="signInModal" role="dialog">
					<div class="modal-dialog " style="margin-top: 15%;">
						<div class="modal-content passwordPopup">
							<div class="modal-header ">
								<div class="row">
									<div class="col-md-9">
										<div class="row">Please Enter Password</div>
									</div>
									<div class="col-md-3">
										<button type="button" class="close" (click)="closeModal()"
											data-dismiss="modal"><img src="../../../assets/images/Close.svg"
												alt="close"></button>
									</div>
								</div>

							</div>

							<div class="modal-body">
								<div class="row">
									<div class="col-md-9 col-xs-12 pl-0">
										<form>
											<div class="form-control"
												[ngClass]="(isValidCred)?'wrap-input100 validate-input mb-0':'wrap-input100 validate-input mb-0 alert-validate'"
												data-validate="Enter password">
												<span class="btn-show-pass"> <i
														[ngClass]="(showCred)?'zmdi zmdi-eye-off':'zmdi zmdi-eye'"
														(click)="togglePasswordShow($event)"></i>
												</span> <input [attr.type]="showCred ? 'password' : 'text'"
													(focus)="onInputFocus($event)" (keypress)="passRestrict($event)"
													[(ngModel)]="UserCred" id="password" name="cred" type="password"
													[ngClass]="(UserCred === '')?'input100':'input100 has-val'"
													required /> <span class="focus-input120"
													data-placeholder="Password"></span>
											</div>
										</form>
									</div>
									<div class="col-md-3 col-xs-12"></div>
								</div>
							</div>

							<div class="modal-footer">

								<div class="col-md-12 pl-0" align="end">
									<button type="button" data-dismiss="modal"
										class="btn carelon-secondary-btn">Cancel</button>

									<button class="btn btn-primary carelon-primary-btn" (click)="validatePassword()"
										type="button">Update</button>
								</div>

							</div>
						</div>
					</div>
				</div>


				<!---Address verification Modal-->

				<div class="modal fade" id="uspsModel" role="dialog">
					<div class="modal-dialog modal-lg" style="margin-top: 10%;">
						<div class="modal-content">
							<div class="modal-header addressPopup">
								<div class="row">
									<div class="col-md-9">
										<div class="row">Address Verification</div>
									</div>
									<div class="col-md-3">
										<button type="button" class="close" data-dismiss="modal"><img
												src="../../../assets/images/Close.svg" alt="close"></button>
									</div>
								</div>
							</div>

							<div class="modal-body adressPopupBody">
								<div class="row text">
									The United States Postal Office records show this address may improve the ability to
									communicate.
								</div>

								<div class="row col-md-12 mt-3">
									<div class="col-md-6" [ngClass]="{'selected': selectedUserAddressCard }">
										<div class="card" (click)="selectedUserAddressCard = true">
											<div class="card-header row m-0">
												<div class="col-md-9 ">
													You Entered
												</div>
												<div class="col-md-3 cardSelectedImage">
													<img src="../../../assets/images/Tick.svg" alt="Tick Mark">
												</div>

											</div>
											<div class="card-body">
												<p class="card-text">{{inputComps.get('addressLine1').value}} <br>
													{{inputComps.get('addressLine2').value}} <br>
													{{inputComps.get('city').value}}, {{inputComps.get('state').value}}
													{{inputComps.get('zip').value}} <br>
												</p>
											</div>
										</div>
									</div>

									<div *ngIf="uspsAddressModel">
										<div class="col-md-6" [ngClass]="{'selected': !selectedUserAddressCard }">
											<div class="card" (click)="selectedUserAddressCard = false ">
												<div class="card-header row m-0">
													<div class="col-md-9 ">
														USPS Recommends
													</div>
													<div class="col-md-3 cardSelectedImage">
														<img src="../../../assets/images/Tick.svg" alt="Tick Mark">
													</div>

												</div>
												<div class="card-body">
													<p class="card-text">{{uspsAddressModel.address1}} <br>
														{{uspsAddressModel.address2}} <br>
														{{uspsAddressModel.city}}, {{uspsAddressModel.state}}
														{{uspsAddressModel.zip}} <br>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
							<div class="modal-footer addressModalFooter">
								<div class="col-md-11 pl-0" align="end">
									<button type="button" data-dismiss="modal"
										class="btn carelon-secondary-btn">Cancel</button>

									<button data-dismiss="modal" class="btn btn-primary carelon-primary-btn"
										(click)="UpdateUserSelectedAddress()" type="button">Use Selected</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!----Error Message modal-->

				<div class="modal fade" id="errorModalSameQuestion" role="dialog">
					<div class="modal-dialog" style="margin-top: 15%;">
						<div class="modal-content alertPopup">
							<div class="modal-header">
								<div class="row">
									<div class="col-md-9">

									</div>
									<div class="col-md-3">
										<button type="button" class="close" (click)="closeModal()"
											data-dismiss="modal"><img src="../../../assets/images/Close.svg"
												alt="close"></button>
									</div>
								</div>
								<div class="modal-body">
									<div class="row imgContainer" *ngIf="!showContact">
										<img src="../../../assets/images/icons/Error.svg" alt="Success">
									</div>
									<p class="text">{{ message }}</p>
								</div>
								<div class="modal-footer">
									<div class="col-md-12 pl-0" align="center">
										<button type="button" data-dismiss="modal"
											class="btn carelon-primary-btn">Close</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


			</div>
		</div>
		<br><br>
		
	</div>
	<br><br><br><br>
	<app-footer></app-footer>
</div>