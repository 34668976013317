  <div class="container supplemental-auth col-md-12">
  <div class="row cup-member-header margin-top" *ngIf="!sendMailScreen">
    <div class="col-md-4 pull-left cup-back-button margin-top">
      <a *ngIf="fromPendingWorkList  && !isMemberAttachments && !casemanagementWorkList" href="javascript:void(0);" routerLinkActive="active"
        queryParamsHandling="preserve" (click)="navigateFromAttachments()">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;BACK TO PENDING AUTH DETAILS
      </a>
      <a *ngIf="fromDeniedWorkList  && !isMemberAttachments && !casemanagementWorkList" href="javascript:void(0);" routerLinkActive="active"
        queryParamsHandling="preserve" (click)="navigateFromAttachments()">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;BACK TO DENIED AUTH DETAILS
      </a>
      <a *ngIf="!fromPendingWorkList && !fromDeniedWorkList && !isMemberAttachments && !casemanagementWorkList" href="javascript:void(0);"
        routerLinkActive="active" queryParamsHandling="preserve" (click)="navigateFromAttachments()">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;BACK TO AUTH SUMMARY
      </a>
      <a *ngIf="isMemberAttachments && !fromPendingWorkList && !fromDeniedWorkList && !casemanagementWorkList" href="javascript:void(0);"
        routerLinkActive="active" queryParamsHandling="preserve" (click)="navigateFromAttachments()">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;BACK TO SEARCH
      </a>
      <a *ngIf="!fromPendingWorkList && !fromDeniedWorkList && !isMemberAttachments && casemanagementWorkList" href="javascript:void(0);"
        routerLinkActive="active" queryParamsHandling="preserve" (click)="navigateFromAttachments()">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;BACK TO AUTH MODIFY
      </a>
    </div>
  </div>

  <div class="row margin-left-right">
    <div class="col-md-16">
      <div class="tab" role="tabpanel">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs cup-detail-header" role="tablist" *ngIf="!sendMailScreen">
          <li role="presentation" class="active">
            <a href="#Section" aria-controls="home" role="tab" data-toggle="tab">{{panelName}} ATTACHMENTS</a>
          </li>
        </ul>
        <ul class="auth-no" *ngIf="!sendMailScreen">
          <li *ngIf="!isMemberAttachments" role="presentation" class="active">Authorization No&nbsp;
            <span class="rounded-color">AUT{{authId}}</span>
          </li>
        </ul>
        <div class="tab-content">
          <div role="tabpanel" class="tab-pane active attachment-section" id="Section">
            <div class="panel-group" class="section-config">
              <div class="panel panel-default">
                <div class="panel-heading heading-section-border" *ngIf="!sendMailScreen">
                  <h2 class="panel-title pull-left">UPLOAD NEW ATTACHMENT</h2>
                  <a *ngIf="openedPanel" id="attachmentExpand" class="pull-left accordion-icon" data-toggle="collapse" data-parent="#supplementalSection"
                    href="#attachmentContent" (click)="toggleAccordion()">
                    <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
                  </a>
                  <a *ngIf="!openedPanel" id="attachmentCollapse" class="pull-left accordion-icon" data-toggle="collapse" data-parent="#supplementalSection"
                    href="#attachmentContent" (click)="toggleAccordion()">
                    <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>
                  </a>
                  <span class="clearfix"></span>
                </div>
                <div id="attachmentContent" class="panel-collapse collapse" [ngClass]="{'in': sendMailScreen}">
                  <div>
                    <div class="row" style="margin:0px;" *ngIf="sendMailScreen && duplicateFileDetails.isDuplicate">
                      <div class="col-md-16 text-left attachment-msg">
                        <p class="required">{{duplicateFileDetails.duplicateFileMessage}}</p>
                      </div>
                    </div>
                    <div class="row" style="margin:0px;">
                      <div *ngIf="!isMemberAttachments">
                        <div class="col-md-16 border-for-panel">
                          <div class="col-md-16">
                            <form [formGroup]="attachmentForm" (ngSubmit)="onUpload(attachmentForm)" autocomplete="off" novalidate>
                              <div class="row" style="margin:0px;">
                                <div class="form-group col-md-6" [ngClass]="{'invalid': formSubmitted && fileMandatoryError, 'text-left': sendMailScreen}">
                                  <label style="font-size: 16px">
                                    <span class="required">* </span>FILE NAME</label>
                                  <div class="file-upload">
                                    <p-fileUpload #fileInput [showUploadButton]="false" [multiple]="false" customUpload="true" (uploadHandler)="onFileUpload($event)"
                                      (onSelect)="onSelectingFile($event)" [showCancelButton]="false" chooseLabel="Choose file" class="custom-auto-complete"
                                      name="file[]" accept=".doc,.docx,.xls,.xlsx,.txt,.pdf,image/jpeg,image/jpg,image/png,image/bmp,image/gif,image/tif,image/tiff"
                                      maxFileSize="30000000" invalidFileSizeMessageSummary="{0}" invalidFileSizeMessageDetail="The file size should be lesser than 30MB"
                                      invalidFileTypeMessageSummary="{0}" invalidFileTypeMessageDetail="The file format is not valid. Please choose a file with valid format.">
                                    </p-fileUpload>
                                    <div class="uploadWarn">
                                      <span *ngIf="formSubmitted && fileMandatoryError">Please select a file</span>
                                    </div>
                                    <p class="disclaimer-for-attach">
                                      <i class="fa fa-info-circle"></i> Permitted document types are Word (.doc, .docx, .txt), Excel (.xls, .xlsx), Picture
                                      (.jpg, .jpeg, .bmp, .png, .tif, .tiff, .gif) and .PDF files</p>
                                    <p class="disclaimer-for-size">
                                      <i class="fa fa-info-circle"></i> File size limit is 30 MB</p>
                                  </div>
                                </div>
                                <div class="form-group col-md-10" [ngClass]="{'invalid': formSubmitted && attachmentForm.controls['descriptionFormControl'].hasError('required'), 'text-left': sendMailScreen}">
                                  <label style="font-size: 16px">
                                    <span class="required">* </span>DESCRIPTION</label>
                                  <textarea class="form-control textFont" rows="3" formControlName="descriptionFormControl" maxlength="255" placeholder="Enter Description"></textarea>
                                  <div class="uploadWarn">
                                    <span *ngIf="formSubmitted && attachmentForm.controls['descriptionFormControl'].hasError('required')">Please enter a value</span>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                              </div>
                              <div class="row">
                                <div class="col-md-16 cup-panel-controls">
                                  <div class="text-center section-padding">
                                    <button *ngIf="!sendMailScreen" type="submit" class="btn btn-primary">Upload
                                      <span>
                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                      </span>
                                    </button>
                                    <button *ngIf="sendMailScreen" type="submit" class="btn btn-primary buttonInfo buttonWidth">Add
                                      <span>
                                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                      </span>
                                    </button>
                                    <button type="button" class="btn btn-secondary button-sections buttonWidth c-black" [disabled]="validationFlag" (click)="onClear()">Clear
                                      <span>
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="isMemberAttachments">
                      <div class="col-md-16 border-for-panel">
                        <div class="col-md-16">
                          <form [formGroup]="attachmentForm" (ngSubmit)="onUpload(attachmentForm)" autocomplete="off" novalidate>
                            <div class="row">
                              <div class="form-group col-md-6" [ngClass]="{'invalid': formSubmitted && fileMandatoryError}">
                                <label>
                                  <span class="required">* </span>FILE NAME</label>
                                <div class="file-upload">
                                  <p-fileUpload #fileInput [showUploadButton]="false" multiple="false" customUpload="true" (uploadHandler)="onFileUpload($event)"
                                    (onSelect)="onSelectingFile($event)" [showCancelButton]="false" chooseLabel="Choose file"
                                    name="file[]" accept=".doc,.docx,.xls,.xlsx,.txt,.pdf,image/jpeg,image/jpg,image/png,image/bmp,image/gif,image/tif,image/tiff"
                                    maxFileSize="30000000" invalidFileSizeMessageSummary="{0}" invalidFileSizeMessageDetail="The file size should be lesser than 30MB"
                                    invalidFileTypeMessageSummary="{0}" invalidFileTypeMessageDetail="The file format is not valid. Please choose a file with valid format.">
                                  </p-fileUpload>

                                </div>
                                <p class="disclaimer-for-attach">
                                  <i class="fa fa-info-circle"></i> Permitted document types are Word (.doc, .docx, .txt), Excel (.xls, .xlsx), Picture
                                  (.jpg, .jpeg, .bmp, .png, .tif, .tiff, .gif) and .PDF files</p>
                                <p class="disclaimer-for-size">
                                  <i class="fa fa-info-circle"></i> File size limit is 30 MB</p>
                                <span *ngIf="formSubmitted && fileMandatoryError">Please select a file</span>
                              </div>
                              <div class="form-group col-md-10" [ngClass]="{'invalid': formSubmitted && attachmentForm.controls['descriptionFormControl'].hasError('required')}">
                                <label>
                                  <span class="required">* </span>DESCRIPTION</label>
                                <textarea class="form-control" rows="3" formControlName="descriptionFormControl" maxlength="255" placeholder="Enter Description"></textarea>
                                <span *ngIf="formSubmitted && attachmentForm.controls['descriptionFormControl'].hasError('required')">Please enter a value</span>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-5" [ngClass]="{'invalid': formSubmitted && attachmentForm.controls['attachmentTypeFormControl'].hasError('required')}">
                                <label>
                                  <span class="required">* </span>ATTACHMENT TYPE</label>
                                <cup-autocomplete [autoCompleteDynamic]="false" class="form-control input-underline" [colWidth]="14" formControlName="attachmentTypeFormControl"
                                  [control]="attachmentForm.controls.attachmentTypeFormControl" [autoCompleteOptions]="attachmentTypeData"
                                  (autoCompleteModelChange)="onTypeChange($event)" [autoCompletePlaceholder]="placeholder"></cup-autocomplete>
                                <span *ngIf="formSubmitted && attachmentForm.controls['attachmentTypeFormControl'].hasError('required')">Please select a value</span>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-16 cup-panel-controls">
                                <div class="text-center section-padding">
                                  <button type="submit" class="btn btn-primary">Upload
                                    <span>
                                      <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                    </span>
                                  </button>
                                  <button type="button" class="btn btn-secondary" [disabled]="validationFlag" (click)="onClear()">Clear
                                    <span>
                                      <i class="fa fa-times" aria-hidden="true"></i>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div *ngIf="!sendMailScreen">
              <h3 class="pane-header">VIEW ATTACHMENTS</h3>
              <div class="row">
                <div class="col-md-16 section-padding">
                  <cup-grid [gridRows]="attachmentGridRows" [gridColumns]="attachmentGridColumns" [pageFromParent]="pages" [totalRecords]="totalRecords"
                    [isLazyLoad]="isServerSidePagination" [isAttachmentGrid]="isAttachmentGrid" (deleteAttachmentClicked)="deleteAttachedFile($event)"
                    (downloadAttachmentClicked)="downloadFiles($event,true)" (previewAttachmentClicked)="downloadFiles($event,false)"
                    [isMemberAttachments]="isMemberAttachments"></cup-grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--Delete prompt-->
<p-dialog header="ALERT" [(visible)]="fileToBeDeleted.deleteAttachment" modal="modal" width="300" [responsive]="true">
  <div *ngIf="fileToBeDeleted.deleteAttachment" id="general-auth-search-dialog" class="dialog-content general-auth-search-dialog">
    <div class="col-md-16">
      <div>
        <span>Do you really want to delete the attachment?</span>
      </div>
      <div class="cup-panel-controls dialog-section-padding">
        <button type="submit" class="btn btn-primary" (click)="deleteSelectedFile()">Yes
          <span>
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
          </span>
        </button>
        <button type="button" class="btn btn-secondary" (click)="fileToBeDeleted.deleteAttachment=false">No
          <span>
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </button>
      </div>
    </div>
  </div>
</p-dialog>

<!--Upload Progress Bars-->
<p-dialog header="Upload Attachments" *ngIf="!sendMailScreen" [(visible)]="progressBar.isUploading" class="no-cancel" modal="modal"
  width="300" [responsive]="true">
  <div *ngIf="progressBar.isUploading" id="general-auth-search-dialog" class="dialog-content general-auth-search-dialog">
    <div class="row">
      <div class="col-md-16 upload-message" [ngClass]="{'not-successful': progressBar.uploadFailed}">
        <span *ngIf="progressBar.uploadSuccessful">File is uploaded successfully</span>
        <span *ngIf="progressBar.uploadFailed">File is not uploaded successfully. Please retry</span>
      </div>
    </div>
    <div class="col-md-16 upload-progress-bar" [ngClass]="{'not-successful': progressBar.uploadFailed, 'successful':progressBar.uploadSuccessful}">
      <div class="progressbar-wrapper">
        <p-progressBar [value]="progressBar.uploadedPercent" [showValue]="false"></p-progressBar>
      </div>
      <div class="file-name">
        <span>{{progressBar.fileName}}</span>
      </div>
      <div class="cup-panel-controls">
        <button type="submit" class="btn btn-primary" (click)="progressBar.isUploading=false"> OK</button>
      </div>
    </div>
  </div>
</p-dialog>

<!--Duplicate file Message-->
<p-dialog *ngIf="!sendMailScreen" header="ALERT" [(visible)]="duplicateFileDetails.isDuplicate" class="no-cancel" modal="modal"
  width="300" [responsive]="true">
  <div *ngIf="duplicateFileDetails.isDuplicate" id="general-auth-search-dialog" class="dialog-content general-auth-search-dialog">
    <div class="col-md-16">
      <div>
        <span>{{duplicateFileDetails.duplicateFileMessage}}</span>
      </div>
      <div class="dialog-padding">
        <button type="button" class="btn btn-primary" (click)="duplicateFileDetails.isDuplicate=false">OK</button>
      </div>
    </div>
  </div>
</p-dialog>