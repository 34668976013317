import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AddtositeService } from './addtosite.service';
import { SecureStorageService } from '../secure-storage.service';
import { CupService } from '../shared/services/cup.service';
import { UserauthenticationService } from '../userauthentication/userauthentication.service';

declare var jQuery: any;

@Component({
  selector: 'app-addtosite',
  templateUrl: './addtosite.component.html',
  styleUrls: ['./addtosite.component.scss']
})
export class AddtositeComponent implements OnInit {

  selected = { 'userName': '' };

  selectedUserSites = new Array<any>();
  actualUserSites = new Array<any>();
  actualFilteredUserSites = new Array<any>();
  updateMessage = '';
  failureMessage = '';
  loading = false;
  activeUsers=[];
  actualUSerSitesIsEmpty:boolean = false;

  constructor(private addtositeservice: AddtositeService, private cupService: CupService,
    private secureStorage:SecureStorageService,private userauthenticationservice: UserauthenticationService,) { }

  users = { '1': [] };
  guId: string;
  userName: any;
  data = [];
  handleResults(searchObj) {
    this.data = searchObj;
  }

  ngOnInit() {
    this.actualUSerSitesIsEmpty=false;
     this.userauthenticationservice.activeUsersChanged.subscribe(users => {
      this.activeUsers = users;
      
    });
    this.guId = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('userProfile'))['guId'];
    // this.userName = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'))['userName'];
    this.userName = this.cupService.getUserNameFromJWT();
    this.getUserData(this.guId, '1');
    this.getcurentUserSites();
  }

  getUserData(guId: string, status: string, userRole = '') {
    this.loading = true;
    this.addtositeservice.getUsers(guId, status, userRole, (success: boolean, userData: any) => {
      if (success) {
        this.loading = false;
        // this.users[status] = userData['user'];
        this.getSiteUsers(userData['user']);
        this.loading = false;
      } else {
        this.loading = false;
      }
    });
  }

  getSiteUsers(users) {
    let checkValid = this.secureStorage.getSecureInfoInSessionStorage("usersContentLoaded")
    console.log(checkValid)
    if (checkValid && checkValid === "true") {
      let activeUsers = checkValid ? this.activeUsers : [];
      let allUsers = [...users,...activeUsers];
      this.users['1'] = [];
      let array = [];
      allUsers.map(user =>{
        if(user['name'] == undefined){
          user['name'] = user.firstName + ', ' + user.lastName
        }else{
          user['userName'] = user.userId;
        }
        if(!array.includes(user['userName'])){
          array.push(user['userName']);
          this.users['1'].push(user);
        }
      }); 
      //console.log(this.users['1'])
    } else {
      setTimeout(() => {
        this.getSiteUsers(users);
      }, 1000);
    }
  }
  listUserSites(userId: string) {
    this.updateMessage = '';
    this.failureMessage = '';
    this.selected.userName = userId;
    this.addtositeservice.listSites(userId, (success: boolean, userSites: any) => {
      if (success) {
        this.loading = false;
        this.selectedUserSites = userSites;
        for (let index = 0; index < this.selectedUserSites.length; index++) {
          const element = this.selectedUserSites[index];
          for (let j = 0; j < this.actualUserSites.length; j++) {
            const actualElement = this.actualUserSites[j];
            if (element['siteUid'] === actualElement['siteUid']) {
              this.selectedUserSites[index].ownSite = true; 
            }
          }
         }
        this.actualFilteredUserSites = new Array<any>();
        for (let i = 0; i < this.actualUserSites.length; i++) {
          if (!this.existsInSelected(this.actualUserSites[i].siteUid)) {
            this.actualFilteredUserSites.push(this.actualUserSites[i]);
          }
        }
        if(this.actualFilteredUserSites.length == 0){
          this.actualUSerSitesIsEmpty=true;
          this.loading = false;
        }else{
          this.loading = false;
          this.actualUSerSitesIsEmpty=false;
        }
      } else {
        this.loading = false;
      }
    });
  }

  existsInSelected(siteUid) {
    for (let j = 0; j < this.selectedUserSites.length; j++) {
      if (this.selectedUserSites[j].siteUid === siteUid) {
        return true;
      }
    }
    return false;
  }

  getcurentUserSites() {
    this.loading = true;
    this.addtositeservice.listSites(this.userName, (success: boolean, userSites: any) => {
      if (success) {
        this.loading = false;
        this.actualUserSites = userSites;
      } else {
        this.loading = false;
      }
    });
  }

  noSitesMessage() {
  }

  addSites(siteObj) {
    for (let index = 0; index < this.actualFilteredUserSites.length; index++) {
      const element = this.actualFilteredUserSites[index];
      if (element['siteUid'] === siteObj['siteUid']) {
        this.actualFilteredUserSites.splice(index, 1);
        if (this.selectedUserSites === undefined) {
          this.selectedUserSites = new Array<any>();
        }
        this.selectedUserSites.push(element);
      }
    }
  }

  removeSites(siteObj) {
    for (let index = 0; index < this.selectedUserSites.length; index++) {
      const element = this.selectedUserSites[index];
      if (element['siteUid'] === siteObj['siteUid']) {
        this.selectedUserSites.splice(index, 1);
        this.actualFilteredUserSites.push(element);
      }
    }
  }

  postSites() {
    this.loading = true;
    this.addtositeservice.deleteSites(this.selected.userName, (success: boolean, userSites: any) => {
      if (success) {
        this.addtositeservice.postSites(this.selected.userName, this.selectedUserSites, (success: boolean, userSites: any) => {
          if (success) {
            this.loading = false;
            this.listUserSites(this.selected.userName);
            this.updateMessage = 'Updated Successfully';
          }
        });
      } else {
        this.loading = false;
        this.failureMessage = 'Failed to update, Please try again.';
      }
    });
  }
}
