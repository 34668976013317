import { Injectable } from '@angular/core';


@Injectable()
export class FieldValidateService {

    constructor(  ) { }


    validateEmail( email: string ) {
        if ( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test( email ) ) {
            return ( true );
        }
        return false;
    }
    validatePhone( phone: string ) {
        let newVal = '';
        for ( let i = 0; i < phone.length; i++ ) {
            const s: string = phone.charAt( i );
            if ( !isNaN( Number( s ) ) && s !== ' ' ) {
                newVal += s;
            }
        }

        const phoneno = /^\d{10}$/;
        if ( newVal.length === 10 ) {
            return true;
        }
        return false;
    }
    validateZip( zip: string ) {
        const zipPattern = /^\d{5}$/;
        if ( zip.match( zipPattern ) ) {
            return true;
        }
        return false;
    }

    validateUserName( userName: string ) {
        const userNamePattern = /^[a-zA-Z][a-zA-Z0-9]{4,}$/;
        if ( userName.match( userNamePattern ) ) {
            return true;
        }
        return false;
    }

    validateNumeric( value: string, length: number ) {
        const numberPattern = '^\\d{' + length + '}$';
        const regEx = new RegExp(numberPattern);
        if ( value.match( regEx ) ) {
            return true;
        }
        return false;
    }

    validateCred(cred: string) {
        const credPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*._])(?=.{8,15})/;
        if (credPattern.test(cred)) {
            return true;
        }
        return false;
    }
    validateSameNumber(value) {
        for (let i = 1; i < value.length; i++) {
          if (value[0] != value[i]) {
            return false;
          }
        }
        return true;
      }

}
