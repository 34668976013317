import { Component, OnInit, OnDestroy } from '@angular/core';
import { SigninService } from './signin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ProviderportalService } from '../providerportal/providerportal.service';
import { AccountProfileService } from '../accountProfile/accountProfile.service';
import { Observable, interval } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';
declare var jQuery: any;
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfigProvider } from '../shared/services/configprovider';
import { SecureStorageService } from '../secure-storage.service';
import { PreferencesService } from '../shared/services/preferences.service';
import { RsaService } from '../common_services/encryptutil.service';
import { saveAs } from 'file-saver';


@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss'],
    providers: [SigninService],
})
export class SigninComponent implements OnInit, OnDestroy {
    ONE_MIN_IN_MILLI_SECS = 60000;
    tokenRefreshingTimeMins = 2;
    tokenRefreshDeltaTimeMins = 6;
    count: any;
    userMessage: any;
    UserName: any = '';
    UserCred: any = '';
    errorMessage: any = '';
    mailSent: any;
    desc1: any;
    isValidUserName = true;
    isValidCred = true;
    isValidCaptcha = true;
    isLicenseAcceptedError = false;
    licenseAccpetedFlag = false;
    showCred = true;
    refreshTokenPP: any;
    subscription: any;
    refreshTokenCUP: any;
    loading = false;
    news = [];
    wrongPassowrdAttemts: any = 0;
    captchaForm: FormGroup;
    config:any;
    unsuccessfulLoginAttempts: any;
    failedLoginAttempts: any = 0;
    clrIntrval;
    switchProfileRouteData;
    constructor(
        private fb: FormBuilder,
        private userIdle: UserIdleService,
        private _signinservice: SigninService,
        private accountProfileService: AccountProfileService,
        private router: Router,
        private providerportalService: ProviderportalService,
        private configProvider:ConfigProvider,
        private secureStorage:SecureStorageService,
        private preferencesService: PreferencesService,
        private rsaService: RsaService,
        private activatedRoute:ActivatedRoute) { 
            this.config = this.configProvider.getConfig();
            this.unsuccessfulLoginAttempts = this.config.unsuccessfulLoginAttempts;
        }

    onInputFocus(event: any) {
        this.hideValidate(event.target.name);
    }
    ngOnInit() {
        this.captchaForm = this.fb.group({
            captcha: [true, Validators.required]
        });
        this.captchaForm.controls.captcha.valueChanges.subscribe(() => {
            if (this.captchaForm.controls.captcha.valid) {
                this.isValidCaptcha = true;
            }
        });
        this.removeSessionData(true);
        this.providerportalService.getTokenClearTimeOut().subscribe(() => {
            if (this.refreshTokenPP) {
                this.refreshTokenPP.unsubscribe();
            }
            if (this.refreshTokenCUP) {
                this.refreshTokenCUP.unsubscribe();
            }
            this.removeSessionData(false);
        });

        this.getNews();
        if(this.router.url&& this.router.url.startsWith('/changeProfileWithData')){
           let param = this.activatedRoute.snapshot.queryParams;
           if(param.hasOwnProperty('cupToken') && param.hasOwnProperty('providerToken')){
        //    this.switchProfileRouteData = param;
            this.router.navigate([],{state:param})
           }
           this.switchProfileRouteData = this.router.getCurrentNavigation().extras.state;
           if(this.switchProfileRouteData){
            this.switchProfileApiData();
           }
        //    //console.log(this.switchProfileRouteData)
        }
    }
    removeSessionData(clear?) {
        sessionStorage.clear();
        if (clear && clear == true) {
            const accessToken = this.secureStorage.getSecureInfoInLocalStorage('clientCredentials_token');
            localStorage.clear();
            this.secureStorage.setSecureInfoInLocalStorage('clientCredentials_token', accessToken);
        }
    }
    hideValidate(inputName: string) {
        if (inputName === 'userName') {
            this.isValidUserName = true;
        } else if (inputName === 'password') {
            this.isValidCred = true;
        }
    }
    togglePasswordShow() {
        this.showCred = !this.showCred;
    }
    passRestrict(event: any) {
        if (event.keyCode === 32) {
            event.preventDefault();
        }
    }
    async switchProfileApiData(){
        let resp2:any;
        let resp3:any;
        let loginresp4:any;
        let providerToken = JSON.parse(this.switchProfileRouteData.providerToken);
        let cupToken = JSON.parse(this.switchProfileRouteData.cupToken);
        let token = JSON.parse(atob(providerToken['access_token'].split('.')[1]));
        this.UserName = token['user_name'];
        const jwt_expire_date = this.getCurrentExpiryDateInMilliSeconds(providerToken.expires_in);
        this.secureStorage.setSecureInfoInLocalStorage('currentUser', JSON.stringify({ access_token: providerToken.access_token, refresh_token: providerToken.refresh_token, jwt_expire_date: jwt_expire_date }));

        let loginresp3 = await this.accountProfileService.getUserProfile2(
            this.UserName
          );
        //   resp2 = JSON.parse(loginresp3._body);
        resp2 = loginresp3
          //console.log("rsep2", resp2);
          resp3 = resp2.user[0];
          // //console.log('resp3',resp3);
          if (resp3) {
            this.secureStorage.setSecureInfoInSessionlStorage(
              "fullName",
              resp3.firstName +
                " " +
                resp3.middleName +
                " " +
                resp3.lastName
            );
            this.providerportalService.setUserData(resp3);
            this.secureStorage.setSecureInfoInSessionlStorage(
              "userProfile",
              JSON.stringify({
                city: resp3.city,
                zip: resp3.zip,
                state: resp3.state,
                userRole: resp3.userRole,
                guId: resp3.guId,
                phone: resp3.phone,
                extn: resp3.extn,
                fax: resp3.fax,
                email: resp3.email,
                npi: resp3.npi,
                taxid: resp3.taxId,
                questions: resp3.securityQuestionsFlag,
                cred: resp3.lastPasswordChange,
                lastLogin: resp3.currentLogin,
                loginTimePayloadTime: resp3.lastLogin,
                userClass: resp3.userClass,
              })
            );
            this.secureStorage.setSecureInfoInSessionlStorage("dnotShowBroadcast", "false");
            // this.updateUerLoginTime();
            // this._signinservice.dataCreationEpahaf(this.UserName).subscribe();
            //  this._signinservice.dataCreationEpahaf(this.UserName,async (success: boolean, message: string) => {
                // if (success) {
                    try {
                        this.doPostLoginProcess(
                            this.UserName,
                            providerToken
                          );
                        loginresp4 = cupToken;
                        this.authenticateAndNavigateToCup2(
                          loginresp4
                        );
                        this.loading = false;
                      } catch (e) {
                        this.loading = false;
                        const desc = e.error_description;
                        //console.log(desc);
                        const guId = JSON.parse(
                          this.secureStorage.getSecureInfoInSessionStorage("userProfile")
                        ).guId;
                        if (["nosite", "new", "unknown"]) {
                          if (guId) {
                            this.desc1 = `User verification in progress. Please contact your account operator to add you to the provider site
                              through site administration. If the account operator is unable to resolve the issue
                              then please contact Caremore Health Provider Relations at (888)291-1358 or send an email to ProviderRelations@caremore.com.`;
                          } else {
                            this.desc1 = `User verification in progress. Please contact Caremore Health Provider Relations at (888)291-1358 or send an email to
                              ProviderRelations@caremore.com.`;
                          }
                        } else if (desc === "locked") {
                          if (guId) {
                            this.desc1 = `User account locked. Please contact your account operator to unlock you through site administration.
                              If the account operator is unable to resolve the issue then please contact Caremore Health Provider Relations at (888)291-1358 or
                              send an email to ProviderRelations@caremore.com.`;
                          } else {
                            this.desc1 = `User account locked. Please contact Caremore Health Provider Relations at (888)291-1358 or send an
                              email to ProviderRelations@caremore.com.`;
                          }
                        } else if (desc === "deactive") {
                          if (guId) {
                            this.desc1 = `User account deactivated. Please contact your account operator to activate you through site
                              administration. If the account operator is unable to resolve the issue then please contact Caremore Health Provider Relations at
                              (888)291-1358 or send an email to ProviderRelations@caremore.com.`;
                          } else {
                            this.desc1 = `User account deactivated. Please contact Caremore Health Provider Relations at (888)291-1358 or send an email to
                              ProviderRelations@caremore.com.`;
                          }
                        }
                        this.secureStorage.setSecureInfoInSessionlStorage("userLogin", "true");
                        this.secureStorage.setSecureInfoInSessionlStorage("userError", "true");
                        this.secureStorage.setSecureInfoInSessionlStorage(
                          "userErrorMessage",
                          this.desc1
                        );
                        this.router.navigateByUrl('providerportal/portal');
                      }
                    return;
            //   }
            
            // });
           
          }
    }

    async getAndSetPreferences(accessToken){
        const res = await this.providerportalService.getPrefference(accessToken).toPromise();
      
          if(res && Array.isArray(res)){
           res.forEach(element => {
            if (element.key === 'storageKey') {
              this.preferencesService.setStorageKey(element.value);
            }
           })
          }
      
        this.rsaService.setKey(this.preferencesService.getStorageKey());
    }

    async login() {
        this.loading = true;
        if (this.UserName === '' || this.UserCred === '' || this.captchaForm.controls.captcha.invalid || !this.licenseAccpetedFlag) {
            if (this.UserName === '') {
                this.isValidUserName = false;
            }
            if (this.UserCred === '') {
                this.isValidCred = false;
            }
            if (!this.licenseAccpetedFlag) {
                this.isLicenseAcceptedError = true;
            }
            if (this.captchaForm.controls.captcha.invalid) {
                this.isValidCaptcha = false;
            }
            this.loading = false;
            return;
        }
       
        let resp3: any;
        let resp2: any;
        let loginresp4;
        try {
          this.providerportalService.loginNew(this.UserName, this.UserCred).subscribe(async success=>{
            await this.getAndSetPreferences(success.token.access_token);
            this.doPostLoginProcess(this.UserName,success.token);
            let resp3 = success.providerUser;
                       this.secureStorage.setSecureInfoInSessionlStorage(
                        "fullName",
                        resp3.firstName +
                          " " +
                          resp3.middleName +
                          " " +
                          resp3.lastName
                      );
                      this.providerportalService.setUserData(resp3);
            this.secureStorage.setSecureInfoInSessionlStorage(
              "userProfile",
              JSON.stringify({
                city: resp3.city,
                zip: resp3.zip,
                state: resp3.state,
                userRole: resp3.userRole,
                guId: resp3.guId,
                phone: resp3.phone,
                extn: resp3.extn,
                fax: resp3.fax,
                email: resp3.email,
                npi: resp3.npi,
                taxid: resp3.taxId,
                questions: resp3.securityQuestionsFlag,
                cred: resp3.lastPasswordChange,
                lastLogin: resp3.currentLogin,
                loginTimePayloadTime: resp3.lastLogin,
                userClass: resp3.userClass,
              })
            );
            this.secureStorage.setSecureInfoInSessionlStorage("dnotShowBroadcast", "false");
            this.authenticateAndNavigateToCup2(success.cupToken);
          },err=>{
            let resp3 = err?.providerUser;
            let guId;
            if(resp3?.guId){
              guId = resp3.guId;
            }
            if(resp3){
              this.secureStorage.setSecureInfoInSessionlStorage(
                  "fullName",
                  resp3.firstName +
                    " " +
                    resp3.middleName +
                    " " +
                    resp3.lastName
                );
                this.providerportalService.setUserData(resp3);
      this.secureStorage.setSecureInfoInSessionlStorage(
        "userProfile",
        JSON.stringify({
          city: resp3.city,
          zip: resp3.zip,
          state: resp3.state,
          userRole: resp3.userRole,
          guId: resp3.guId,
          phone: resp3.phone,
          extn: resp3.extn,
          fax: resp3.fax,
          email: resp3.email,
          npi: resp3.npi,
          taxid: resp3.taxId,
          questions: resp3.securityQuestionsFlag,
          cred: resp3.lastPasswordChange,
          lastLogin: resp3.currentLogin,
          loginTimePayloadTime: resp3.lastLogin,
          userClass: resp3.userClass,
        })
      );
            }
            if(err.error.status == "Inactive User"){
              if (guId) {
                this.desc1 = `User account deactivated. Please contact your account operator to activate you through site
                  administration. If the account operator is unable to resolve the issue then please contact Carelon Health Provider Relations at
                  (888)291-1358 or send an email to CarelonHealth.ProviderRelations@carelon.com.`;
              } else {
                this.desc1 = `User account deactivated. Please contact Carelon Health Provider Relations at (888)291-1358 or send an email to
                  CarelonHealth.ProviderRelations@carelon.com.`;
              }
              // this.secureStorage.setSecureInfoInSessionlStorage("userLogin", "true");
              // this.secureStorage.setSecureInfoInSessionlStorage("userError", "true");
              // this.secureStorage.setSecureInfoInSessionlStorage(
              //   "userErrorMessage",
              //   this.desc1
              // );
              this.loading = false;
              this.userMessage = this.desc1;
              jQuery("#userMessageModal").modal("show");
            }
            else if(err.error.status == "New In Active User"){
              this.loading = false;
              this.userMessage = err.error.message;
              jQuery("#userMessageModal").modal("show");
            }
            else if(err.error.status =="Account Locked"){
              if (guId) {
                this.desc1 = `User account locked. Please contact your account operator to unlock you through site administration.
                  If the account operator is unable to resolve the issue then please contact Carelon Health Provider Relations at (888)291-1358 or
                  send an email to CarelonHealth.ProviderRelations@carelon.com.`;
              } else {
                this.desc1 = `User account locked. Please contact Carelon Health Provider Relations at (888)291-1358 or send an
                  email to CarelonHealth.ProviderRelations@carelon.com.`;
              }
              // this.secureStorage.setSecureInfoInSessionlStorage("userLogin", "true");
              // this.secureStorage.setSecureInfoInSessionlStorage("userError", "true");
              // this.secureStorage.setSecureInfoInSessionlStorage(
              //   "userErrorMessage",
              //   this.desc1
              // );
              this.loading = false;
              this.userMessage = this.desc1;
              jQuery("#userMessageModal").modal("show");
            }
            else if(err.error.hasOwnProperty('token')){
                this.doPostLoginProcess(this.UserName,err.error.token);
                let resp3 = err.error.providerUser;
                this.providerportalService.setUserData(resp3);
                this.secureStorage.setSecureInfoInSessionlStorage(
                  "userProfile",
                  JSON.stringify({
                    city: resp3.city,
                    zip: resp3.zip,
                    state: resp3.state,
                    userRole: resp3.userRole,
                    guId: resp3.guId,
                    phone: resp3.phone,
                    extn: resp3.extn,
                    fax: resp3.fax,
                    email: resp3.email,
                    npi: resp3.npi,
                    taxid: resp3.taxId,
                    questions: resp3.securityQuestionsFlag,
                    cred: resp3.lastPasswordChange,
                    lastLogin: resp3.currentLogin,
                    loginTimePayloadTime: resp3.lastLogin,
                    userClass: resp3.userClass,
                  })
                );
                this.secureStorage.setSecureInfoInSessionlStorage("dnotShowBroadcast", "false");
                if (guId) {
                  this.desc1 = `User verification in progress. Please contact your account operator to add you to the provider site
                    through site administration. If the account operator is unable to resolve the issue
                    then please contact Carelon Health Provider Relations at (888)291-1358 or send an email to CarelonHealth.ProviderRelations@carelon.com.`;
                } else {
                  this.desc1 = `User verification in progress. Please contact Carelon Health Provider Relations at (888)291-1358 or send an email to
                    CarelonHealth.ProviderRelations@carelon.com.`;
                }
                this.secureStorage.setSecureInfoInSessionlStorage("userLogin", "true");
                this.secureStorage.setSecureInfoInSessionlStorage("userError", "true");
                this.secureStorage.setSecureInfoInSessionlStorage(
                  "userErrorMessage",
                  this.desc1
                );
                this.router.navigateByUrl('providerportal/portal');
            }
            else if(err.error.status == "Invalid credentials"){
              this.loading = false;
              this.userMessage = err.error.message;
              jQuery("#userMessageModal").modal("show");
            }
            else if(err.error.status == "User name not found"){
                this.loading = false;
              this.userMessage = err.error.message;
              jQuery("#userMessageModal").modal("show");
            }
            else{
              this.loading = false;
              jQuery('#exceptionModal').modal('show');
              return;  
            }
          });
          
            
                // this.providerportalService
                //   .providerGetToken(this.UserName, this.UserCred)
                //   .subscribe(
                //     async (resp) => {
                //       //console.log("firstresp", resp);
                //       this.doPostLoginProcess(
                //         this.UserName,
                //         resp
                //       );
                //       let loginresp3 = await this.accountProfileService.getUserProfilePost(
                //         this.UserName
                //       );

                //       if(!loginresp3) {                         
                //             this.loading = false;
                //             jQuery('#exceptionModal').modal('show');
                //             return;                                    
                //       }

                //     //   resp2 = JSON.parse(loginresp3._body);
                //     resp2 = loginresp3
                //       //console.log("rsep2", resp2);
                //       resp3 = resp2.user[0];
                //       // //console.log('resp3',resp3);
                //       if (resp3) {
                //         this.secureStorage.setSecureInfoInSessionlStorage(
                //           "fullName",
                //           resp3.firstName +
                //             " " +
                //             resp3.middleName +
                //             " " +
                //             resp3.lastName
                //         );
                //         this.secureStorage.setSecureInfoInSessionlStorage(
                //           "userProfile",
                //           JSON.stringify({
                //             address1: resp3.addressLine1,
                //             address2: resp3.addressLine2,
                //             city: resp3.city,
                //             zip: resp3.zip,
                //             state: resp3.state,
                //             userRole: resp3.userRole,
                //             guId: resp3.guId,
                //             phone: resp3.phone,
                //             extn: resp3.extn,
                //             fax: resp3.fax,
                //             email: resp3.email,
                //             npi: resp3.npi,
                //             taxid: resp3.taxId,
                //             questions: resp3.securityQuestionsFlag,
                //             cred: resp3.lastPasswordChange,
                //             lastLogin: resp3.currentLogin,
                //             loginTimePayloadTime: resp3.lastLogin,
                //             userClass: resp3.userClass,
                //           })
                //         );
                //         this.secureStorage.setSecureInfoInSessionlStorage("dnotShowBroadcast", "false");
                //         this.updateUerLoginTime();
                //         // this._signinservice.dataCreationEpahaf(this.UserName).subscribe();
                //         //  this._signinservice.dataCreationEpahaf(this.UserName,async (success: boolean, message: string) => {
                //             // if (success) {
                //                 try {
                //                     loginresp4 = await this.providerportalService.cupLoginJWTToken2(
                //                       this.UserName,
                //                       this.UserCred
                //                     );
                //                     this.authenticateAndNavigateToCup2(
                //                       loginresp4
                //                     );
                //                     this.loading = false;
                //                   } catch (e) {
                //                     this.loading = false;
                //                     const desc = e.error_description;
                //                     //console.log(desc);
                //                     const guId = JSON.parse(
                //                       this.secureStorage.getSecureInfoInSessionStorage("userProfile")
                //                     ).guId;
                //                     if (["nosite", "new", "unknown"]) {
                //                       if (guId) {
                //                         this.desc1 = `User verification in progress. Please contact your account operator to add you to the provider site
                //                           through site administration. If the account operator is unable to resolve the issue
                //                           then please contact Carelon Health Provider Relations at (888)291-1358 or send an email to CarelonHealth.ProviderRelations@carelon.com.`;
                //                       } else {
                //                         this.desc1 = `User verification in progress. Please contact Carelon Health Provider Relations at (888)291-1358 or send an email to
                //                           CarelonHealth.ProviderRelations@carelon.com.`;
                //                       }
                //                     } else if (desc === "locked") {
                //                       if (guId) {
                //                         this.desc1 = `User account locked. Please contact your account operator to unlock you through site administration.
                //                           If the account operator is unable to resolve the issue then please contact Carelon Health Provider Relations at (888)291-1358 or
                //                           send an email to CarelonHealth.ProviderRelations@carelon.com.`;
                //                       } else {
                //                         this.desc1 = `User account locked. Please contact Carelon Health Provider Relations at (888)291-1358 or send an
                //                           email to CarelonHealth.ProviderRelations@carelon.com.`;
                //                       }
                //                     } else if (desc === "deactive") {
                //                       if (guId) {
                //                         this.desc1 = `User account deactivated. Please contact your account operator to activate you through site
                //                           administration. If the account operator is unable to resolve the issue then please contact Carelon Health Provider Relations at
                //                           (888)291-1358 or send an email to CarelonHealth.ProviderRelations@carelon.com.`;
                //                       } else {
                //                         this.desc1 = `User account deactivated. Please contact Carelon Health Provider Relations at (888)291-1358 or send an email to
                //                           CarelonHealth.ProviderRelations@carelon.com.`;
                //                       }
                //                     }
                //                     this.secureStorage.setSecureInfoInSessionlStorage("userLogin", "true");
                //                     this.secureStorage.setSecureInfoInSessionlStorage("userError", "true");
                //                     this.secureStorage.setSecureInfoInSessionlStorage(
                //                       "userErrorMessage",
                //                       this.desc1
                //                     );
                //                     this.router.navigateByUrl('providerportal/portal');
                //                   }
                //                 return;
                //         //   }
                        
                //         // });
                       
                //       }
                //     },
                //     (err) => {
                //       this.loading = false;
                //       this.userMessage = err.error_description;
                //       //console.log(this.userMessage);
                //       if (this.userMessage) {
                //         jQuery("#userMessageModal").modal("show");
                //       } else {
                //         jQuery("#errorMessageModal").modal("show");
                //       }
                //     }
                //   );
               
            
        } catch (err) {
            this.loading = false;
            if ((err.status !== undefined && err.status === 500)) {
                if (err.error !== undefined) {
                    const faultValue = this.parseResponseByName(err.error, 'soapenv:Text');
                    if (faultValue.indexOf('not confirmed yet') !== -1) {
                        this.loading = false;
                        jQuery('#inactiveModal').modal('show');
                        return;
                    } else if ((faultValue.indexOf('Account is locked for user') !== -1)) {
                        this.loading = false;
                        this.updateLockedUerStatus();
                        this.userMessage = 'Your account has been locked, Please contact your account operator or Provider Relations team at (888) 291-1358 or send an e-mail to CarelonHealth.ProviderRelations@carelon.com for further assistance.';
                        jQuery('#userMessageModal').modal('show');
                        return;
                    }
                }
            }
        }

    }
    authenticateAndNavigateToCup2(loginresp4) {
        this.loading = false;
        this.secureStorage.setSecureInfoInSessionlStorage('userLogin', 'true');
        this.secureStorage.setSecureInfoInSessionlStorage('userError', 'false');

        const jwtToken = loginresp4;
        const jwt_expire_date = this.getCurrentExpiryDateInMilliSeconds(jwtToken.expires_in);
        this.secureStorage.setSecureInfoInLocalStorage('jwt_expire_date', jwt_expire_date);
        this.secureStorage.setSecureInfoInLocalStorage('jwt', JSON.stringify(loginresp4));
        this.secureStorage.setSecureInfoInLocalStorage('jwt_expires_in', String(new Date().getTime() + (loginresp4.expires_in * 1000)));
        localStorage.removeItem('jwt_token_request_init');
        this.providerportalService.setLogin();
        this.router.navigateByUrl('providerportal/portal');
    }
    parseResponse(xmlResponse) {
        let returnVal = 'false';
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');
        try {
            returnVal = xmlDoc.getElementsByTagName('ns:return')[0].childNodes[0].nodeValue;
        } catch (e) { }
        return returnVal;
    }
    parseResponseByName(xmlResponse, tagName) {
        let returnVal = 'false';
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlResponse, 'text/xml');
        try {
            returnVal = xmlDoc.getElementsByTagName(tagName)[0].childNodes[0].nodeValue;
        } catch (e) { }
        return returnVal;
    }
    doPostLoginProcess(userName, resp): void {
        // this.userIdle.startWatching();
        const jwt_expire_date = this.getCurrentExpiryDateInMilliSeconds(resp.expires_in);
        this.secureStorage.setSecureInfoInLocalStorage(
            'currentUser',
            JSON.stringify({
                access_token: resp.access_token,
                refresh_token: resp.refresh_token,
                jwt_expire_date:jwt_expire_date
            }));
        // this.subscribeForProviderTokenRefresh();
    }
    // subscribeForProviderTokenRefresh() {
        // //console.log("this.tokenRefreshingTimeMins-->" ,this.tokenRefreshingTimeMins,this.ONE_MIN_IN_MILLI_SECS)
        // this.refreshTokenPP = Observable.interval(this.tokenRefreshingTimeMins * this.ONE_MIN_IN_MILLI_SECS)
        //     .subscribe((f) => {
        //         //console.log("IUUUUUUUUU")
        //         const current_date = new Date();
        //         const jwt_expire_date = new Date(JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser')).jwt_expire_date);
        //         //console.log(jwt_expire_date,current_date)
        //         //console.log(jwt_expire_date < current_date)

        //         if (jwt_expire_date < current_date) {
        //             this.providerportalService.providerRefreshToken().subscribe(res => {
        //                 const jwtToken: any = res;
        //                 const jwt_expire_date = this.getCurrentExpiryDateInMilliSeconds(jwtToken.expires_in);

        //                 this.secureStorage.setSecureInfoInSessionlStorage('currentUser', JSON.stringify({ userName: this.UserName, access_token: res.access_token, refresh_token: res.refresh_token, jwt_expire_date: jwt_expire_date }));
        //             }, err => {
        //                 this.refreshTokenPP.unsubscribe();
        //                 jQuery('#tokenExpiryModal').modal('show');
        //             });
        //          }
        //     });
    // }
    
    // //console.log("this.tokenRefreshingTimeMins-->" ,this.tokenRefreshingTimeMins,this.ONE_MIN_IN_MILLI_SECS)
    // this.refreshTokenPP = Observable.interval(this.tokenRefreshingTimeMins * this.ONE_MIN_IN_MILLI_SECS)
    // .subscribe((f) => {
    // //console.log("IUUUUUUUUU")
    // const current_date = new Date();
    // const jwt_expire_date = new Date(JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser')).jwt_expire_date);
    // //console.log(jwt_expire_date,current_date)
    // //console.log(jwt_expire_date < current_date)
    // if (jwt_expire_date < current_date) {
    // this.providerportalService.providerRefreshToken().subscribe(res => {
    // const jwtToken: any = res;
    // const jwt_expire_date = this.getCurrentExpiryDateInMilliSeconds(jwtToken.expires_in);
    // this.secureStorage.setSecureInfoInSessionlStorage('currentUser', JSON.stringify({ userName: this.UserName, access_token: res.access_token, refresh_token: res.refresh_token, jwt_expire_date: jwt_expire_date }));
    // }, err => {
    // this.refreshTokenPP.unsubscribe();
    // jQuery('#tokenExpiryModal').modal('show');
    // });
    // }
    // });
    // }
    getCurrentExpiryDateInMilliSeconds(jwt_expires_in: any): any {
        const currentTimeInMilliSecs = new Date().getTime();
        const expiresInMilliSecs = jwt_expires_in * 1000;
        const jwt_expires_from_now = Number(currentTimeInMilliSecs + expiresInMilliSecs);
        const jwt_expire_date = new Date(jwt_expires_from_now - this.tokenRefreshDeltaTimeMins * this.ONE_MIN_IN_MILLI_SECS);
        return jwt_expire_date;
    }

    // subscribeForCupTokenRefresh() {
    //     const jwtToken = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('jwt'));
    //     if (jwtToken) {
    //         this.refreshTokenCUP = Observable.interval(this.tokenRefreshingTimeMins * this.ONE_MIN_IN_MILLI_SECS)
    //             .subscribe((f) => {
    //                 const current_date = new Date();
    //                 const jwt_expire_date = new Date(this.secureStorage.getSecureInfoInSessionStorage('jwt_expire_date'));
    //                 if (jwt_expire_date < current_date) {
    //                     this.providerportalService.cupLoginJWTRefreshToken().subscribe(res => {
    //                         const jwtToken = res;
    //                         const jwt_expire_date = this.getCurrentExpiryDateInMilliSeconds(jwtToken.expires_in);
    //                         this.secureStorage.setSecureInfoInSessionlStorage('jwt_expire_date', jwt_expire_date);
    //                         this.secureStorage.setSecureInfoInSessionlStorage('jwt', JSON.stringify(res));
    //                         this.secureStorage.setSecureInfoInSessionlStorage('jwt_expires_in', String(new Date().getTime() + (res.expires_in * 1000)));
    //                     });
    //                 }
    //             });
    //     }
    // }
    subscribeForCupTokenRefresh() {
        const jwtToken = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('jwt'));
        if (jwtToken) {
            this.refreshTokenCUP = interval(this.tokenRefreshingTimeMins * this.ONE_MIN_IN_MILLI_SECS)
                .subscribe(() => {
                        const current_date = new Date();
                        const jwt_expire_date = new Date(this.secureStorage.getSecureInfoInLocalStorage('jwt_expire_date'));
                        if (jwt_expire_date < current_date) {
                            this.providerportalService.cupLoginJWTRefreshToken().subscribe((res: any) => {
                                const jwtToken = res;
                                const jwt_expire_date = this.getCurrentExpiryDateInMilliSeconds(jwtToken.expires_in);
                                // this.secureStorage.setSecureInfoInSessionlStorage('jwt_expire_date', jwt_expire_date);
                                // this.secureStorage.setSecureInfoInSessionlStorage('jwt', JSON.stringify(res));
                                // this.secureStorage.setSecureInfoInSessionlStorage('jwt_expires_in', String(new Date().getTime() + (res.expires_in * 1000)));
                                this.secureStorage.setSecureInfoInLocalStorage('jwt_expire_date', jwt_expire_date);
                                this.secureStorage.setSecureInfoInLocalStorage('jwt', JSON.stringify(res));
                                this.secureStorage.setSecureInfoInLocalStorage('jwt_expires_in', String(new Date().getTime() + (res.expires_in * 1000)));
                            });
                        }
                    });
        }
    }
    ngOnDestroy() {
    }
    stop() {
        this.userIdle.stopTimer();
    }
    stopWatching() {
        this.userIdle.stopWatching();
    }
    startWatching() {
        // this.userIdle.startWatching();
    }
    restart() {
        this.userIdle.resetTimer();
    }
    resend() {
        this._signinservice.resendConfirmationCode(this.UserName, (success: boolean) => {
            if (success) {
                this.mailSent = true;
            }
        });
    }

   getNews() {
        this._signinservice.getNews().subscribe((Response) => {
            const res:any= (Response);
            if (res.status ==="SUCCESS") {
            this.news = res['providerPortalBroadcast'];
            } else if (res.status === 500 ) {
                jQuery('#timeOutModal').modal('show');

            }
        }, 
        );
    }
    getDocument() {
        this._signinservice.getDocument().subscribe(res => {
            const responseData = res;
            if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                const blob = this.binary64ToBlob(responseData.docContent, responseData.fileType, 512);
                saveAs(blob, responseData.filename);
            } else {
                const blob = this.b64toBlob(responseData.docContent, responseData.fileType, 512);
                const urlBlob = URL.createObjectURL(blob);
                window.open(urlBlob, '_blank', 'location=yes,height=570,width=520');
            }
        });
    }
    binary64ToBlob(binary64String, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        const byteCharacters = atob(binary64String);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    updateLockedUerStatus() {
        const statusPayload = {
            userName: this.UserName,
            status: '3'
        };
        this._signinservice.updateLockedUerStatus(statusPayload, (success: boolean) => {
            if (success) {
                return;
            }
        });
    }
    updateUerLoginTime() {
        const loginTimePayload = {
            userName: this.UserName,
            lastLogin: JSON.parse(
                this.secureStorage.getSecureInfoInSessionStorage("userProfile")
              ).loginTimePayloadTime
        };
        this._signinservice.updateUerLoginTime(loginTimePayload, (success: boolean) => {
            if (success) {
                return;
            }
        });
    }
    getFormattedDateStr(date: Date) {
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' +
            date.getMinutes() + ':' + date.getSeconds() + ':' + date.getMilliseconds();
    }

    updateFailedLoginAttempts(){
        const statusPayload = {
            userName: this.UserName,
            unsuccessfulLoginAttempts: this.failedLoginAttempts
        };
        this._signinservice.updateLockedUerStatus(statusPayload, (success: boolean) => {
            if (success) {
                return;
            }
        });
    }
}
