import { Injectable } from '@angular/core';
import { urls, props } from '../common/model/properties';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SecureStorageService } from '../secure-storage.service';

@Injectable()
export class AddtositeService {

  constructor(private http: HttpClient,
    private secureStorage:SecureStorageService) { }

  getUsers(guId: string, status: string, userRole: string, callBack: (sucess: boolean, message: string) => void) {

    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({'Authorization': 'Bearer ' + ppjwt.access_token});
    let authUserListUrl = urls.authUserList;
    //authUserListUrl = authUserListUrl.replace('GU_ID', guId);
    authUserListUrl = authUserListUrl.replace('STATUS',  (status === '1' ? '1,3' : status));
    if (userRole !== '') {
      authUserListUrl = authUserListUrl + '&' + 'userRole=' + userRole;
    }

    this.http.get(authUserListUrl, { headers: headers })
      .subscribe(
        (resp:any) => {
          callBack(true, resp);
        },
        err => {
          callBack(false, null);
        }
      );
  }

  listSites(userName, callBack: (sucess: boolean, message: string) => void) {

    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    // headers.set( 'Accept', 'application/json' );
    const headers = new HttpHeaders({'Authorization': 'Bearer ' + ppjwt.access_token});
    const  payload={
      "managedUserName":userName
    }
    const url=urls.getManagedUserSites;
    this.http.post(url,payload, { headers: headers })
      .subscribe(
        (resp) => {
          if (resp['sites'] === undefined) {
            resp['sites']  = new Array<any>();
          }
          callBack(true, resp['sites']);
        },
        err => {
          callBack(false, 'Error while getting sites ');
        }
      );
  }

  postSites(userId, sites: Array<any>, callBack: (sucess: boolean, message: any) => void) {

    const payload = {
      userId: userId,
      sites : new Array<any>()
    };

    const siteList = new Array<any>();
    for (let index = 0; index < sites.length; index++) {

      const site = {
        siteUid: sites[index].siteUid,
        isActive: true
     };
      siteList.push(site);
    }

    payload['sites'] = siteList;


    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));

    const headers = new HttpHeaders({'Authorization': 'Bearer ' + ppjwt.access_token});
    this.http.post(urls.addCupSite, payload, { headers: headers })
      .subscribe(
        (resp) => {
          callBack(true, resp);
        },
        err => {
          callBack(false, err);
        }
      );
  }

  deleteSites(userId, callBack: (sucess: boolean, message: string) => void) {

    // const headers = new Headers();
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    // headers.set( 'USERNAME', userId );
    // headers.set('Authorization', 'Bearer ' + ppjwt.access_token);
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + ppjwt.access_token
    });
    const  payload={
      "managedUserName":userId
    }
    const url=urls.deleteManagedUserSites;
    this.http.post(url,payload, { headers: headers })
      .subscribe(
        (resp:any) => {
          callBack(true, resp);
        },
        err => {
          callBack(false, 'Error while getting sites ');
        }
      );
  }

}
