import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'numFormat'
})
export class NUmberPipe implements PipeTransform {
  transform(value): any {
    return Math.round(value);
  }
}

@Pipe({
  name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {
  transform(list, splitTo): any {
    return list.length > splitTo ? list.slice(0, splitTo) : list;
  }
}
