import {Injectable} from '@angular/core';
import {ConfigModel} from '../models/config.model';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { CONFIG } from '../../../config';
import { urls } from '../../common/model/properties';
import { Observable, Subject, of } from 'rxjs';
import { SecureStorageService } from '../../secure-storage.service';
import { PreferencesService } from './preferences.service';
import { RsaService } from 'src/app/common_services/encryptutil.service';
import { ProviderportalService } from 'src/app/providerportal/providerportal.service';

@Injectable()
export class GlobalService {

    unreadCount = new Subject();
    private config: ConfigModel = null;
    clrIntrval;
    
    constructor(private http: HttpClient,
        private preferencesService: PreferencesService,
    private rsaService: RsaService,
        private secureStorage:SecureStorageService) {
    }

    public setUnreadCount(count: string) {
        this.unreadCount.next(count);

    }

    public getConfig(): ConfigModel {
        return this.config;
    }

    load() {
        return new Promise((resolve, reject) => {
            this.http
                .get('env.json')
                .subscribe((response:any) => {
                    this.config = response;
                    resolve(true);
                });
        });
    }
    getPrefference(access_token : any) {
        const endPoint = urls.getPrefference
    
        const accessToken: string = 'Bearer ' + access_token;
    
        const securityHeaders = new HttpHeaders({
          'Accept': 'application/json',
          'Authorization': accessToken,
        });
    
        const options = { headers: securityHeaders};
    
        return this.http.get(endPoint, options)  
      }
    async setAccesToken() {
        if(this.secureStorage.getSecureInfoInLocalStorage('currentUser')){
            try{
                const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
                const res = await this.getPrefference(ppjwt?.access_token).toPromise();
                if(res && Array.isArray(res)){
                    res.forEach(element => {
                     if (element.key === 'storageKey') {
                       this.preferencesService.setStorageKey(element.value);
                     }
                    })
                   }
                   this.rsaService.setKey(this.preferencesService.getStorageKey());
            }
            catch(e){
                console.log(e);
            }
            return of(true);
           
        }else{
            return of(true);
        }
    }

    // async setAccesToken() {
    //     const response = await this.getAccessToken().toPromise();
    //     if(response && response['access_token']) {

    //         this.secureStorage.setSecureInfoInLocalStorage("clientCredentials_token", response['access_token']);
    //         this.getNewAccesstoken(response['expires_in'])
    //     } 

    //     return of(true);
    // }

    // getNewAccesstoken(expireTime:number){
    //     clearInterval(this.clrIntrval);
    //     this.clrIntrval = setInterval(()=>{
    //         this.getAccessToken().subscribe(response => {
    //             if(response && response['access_token']) {
    //                 this.secureStorage.setSecureInfoInLocalStorage("clientCredentials_token", response['access_token']);
    //                 this.getNewAccesstoken(response['expires_in'])
    //             }
    //         } );
             
    //     }, expireTime * 1000)
    // }

    // getAccessToken() {
    //     const apiKey: any = "Basic "+ CONFIG.clientcredential_token;
    //     const securityHeaders = new HttpHeaders({
    //         'Content-Type': 'application/x-www-form-urlencoded',
    //         'Authorization': apiKey,
    //     });
    //     const options = { headers: securityHeaders };
      
    //     const body = new HttpParams()
    //         .set('grant_type', 'client_credentials');
        
    //     return this.http.post(urls.getAccessToken, body.toString(), options);
    //   }
}
