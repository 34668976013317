import { HttpParams } from '@angular/common/http';
import { Component, HostListener, Input, AfterContentInit, OnInit } from '@angular/core';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { UserIdleService } from "angular-user-idle";
import { Subscription, of } from 'rxjs';

import { ProviderportalService } from './providerportal/providerportal.service';
import { UserauthenticationService } from './userauthentication/userauthentication.service';
import { SecureStorageService } from './secure-storage.service';
import { AccountProfileService } from './accountProfile/accountProfile.service';
import { CupService } from './shared/services/cup.service';
import { PreferencesService } from './shared/services/preferences.service';
import { RsaService } from './common_services/encryptutil.service';
import { delay } from 'rxjs/operators';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [UserauthenticationService],
})
export class AppComponent implements AfterContentInit, OnInit {
  private tokenData: { access_token: string; refresh_token: string } = {
    access_token: null,
    refresh_token: null
  };
  private subscription: Subscription;
  private flag = true;
  timerHasStarted: boolean = true;
  isLoggedIn: boolean = true;


  @HostListener("document:keydown", ["$event"])
  isKeyDownPressed(event) {
    if (!event) {
      event = window.event;
    }

    if (this.isLoggedIn && this.timerHasStarted) {
      this.providerportalService.setCloseModal(true);
      this.userIdle.stopTimer();
      this.timerHasStarted = true;
    }
  }
  
  @HostListener("document:click", ["$event"])
  isMouseClick(event) {
    if (!event) {
      event = window.event;
    }
    if (this.isLoggedIn && this.timerHasStarted) {
      this.providerportalService.setCloseModal(true);
      this.userIdle.stopTimer();
      this.timerHasStarted = true;
    }
  }
  @HostListener("document:mousemove", ["$event"])
  isMouseMove(event) {
    if (!event) {
      event = window.event;
    }

    if (this.isLoggedIn && this.timerHasStarted) {
      this.providerportalService.setCloseModal(true);
      this.userIdle.stopTimer();
      this.timerHasStarted = true;
    }
  }
  @HostListener("document:wheel", ["$event"])
  onMouseWheelMove(event) {
    if (!event) {
      event = window.event;
    }
    if (this.isLoggedIn && this.timerHasStarted) {
      this.providerportalService.setCloseModal(true);
      this.userIdle.stopTimer();
      this.timerHasStarted = true;
    }
  }

  @HostListener("document:keydown", ["$event"])
  onKeyDown(event: any) {
    if (!event) {
      event = window.event;
    }

    var keyCode = event.keyCode;

    if (
      keyCode == 8 &&
      (event.target || event.srcElement).tagName != "TEXTAREA" &&
      (event.target || event.srcElement).tagName != "INPUT"
    ) {
      if (navigator.userAgent.toLowerCase().indexOf("msie") == -1) {
        event.stopPropagation();
      } else {
        event.returnValue = false;
      }
       return false;
    }
  }
  constructor(private userIdle: UserIdleService,
    private preferencesService: PreferencesService,
    private rsaService: RsaService,
    private providerportalService: ProviderportalService,
    private userauthenticationservice: UserauthenticationService,
    private accountProfileService: AccountProfileService,
    private cupService: CupService,
    private router: Router,
    private secureStorage:SecureStorageService) {
    this.secureStorage.setSecureInfoInSessionlStorage('usersContentLoaded', "false");
  }
  activeUsers = [];
  inactiveUsers = [];
  allUserId = [];
  users = { '0': [], '1': [], '2': [] };
  async ngAfterContentInit() {
    console.log("ngAfterViewContentLoaded");
    await of(1).pipe(delay(1000)).toPromise();
    if (this.secureStorage.getSecureInfoInLocalStorage('currentUser')) {
      this.getUsers();
    }
    this.providerportalService.afterLogin().subscribe(resp => {
      if (resp) {
        this.getUsers();
      }
    })
    await of(1).pipe(delay(1000)).toPromise();
  }
  async ngOnInit() {

   if(this.rsaService.getKey() == undefined && this.secureStorage.getSecureInfoInLocalStorage('currentUser') != null ){
    await this.getAndSetPreferences();
   }

  }

  async getAndSetPreferences(){
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const res = await this.providerportalService.getPrefference(ppjwt?.access_token).toPromise();
  
      if(res && Array.isArray(res)){
       res.forEach(element => {
        if (element.key === 'storageKey') {
          this.preferencesService.setStorageKey(element.value);
        }
       })
      }
  
    this.rsaService.setKey(this.preferencesService.getStorageKey());
  }

  async getUsers() {
    await of(1).pipe(delay(1000)).toPromise();
    this.activeUsers = [];
    this.inactiveUsers = [];
    this.allUserId = [];
    let userName;
    if (this.secureStorage.getSecureInfoInLocalStorage('currentUser')) {
      userName= this.cupService.getUserNameFromJWT();
    }
   
    if (userName) {
      this.setUserProfileData(userName);
      this.userauthenticationservice.getSiteUsers(
        userName, (success: boolean, siteUserData: any) => {
          if (success) {
            siteUserData.map(site => {
              let cb = {};
              if (site.siteUsers) {
                cb = () => {
                  site.siteUsers.map(user => {
                    if (!this.allUserId.includes(user.userId)) {
                      this.allUserId.push(user.userId);
                      if (user.isActive) {
                        this.activeUsers.push(user);
                      } else {
                        this.inactiveUsers.push(user);
                      }
                    }
                  });
              this.activeUsers.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
              this.userauthenticationservice.setActiveUsers(this.activeUsers);
              // this.secureStorage.setSecureInfoInSessionlStorage('activeUsers', JSON.stringify(this.activeUsers));
              // this.inactiveUsers.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
              // this.secureStorage.setSecureInfoInSessionlStorage('inactiveUsers', JSON.stringify(this.inactiveUsers));
                  this.secureStorage.setSecureInfoInSessionlStorage('usersContentLoaded', "true");
                }
              }
              let call = () => this.getUserData('2', cb);
              this.getUserData('1,3', call);
            });
          } else {
            this.secureStorage.setSecureInfoInSessionlStorage('usersContentLoaded', "true");
          }
        }
      );
    }
  }
  setUserProfileData(uName:any) {
    this.accountProfileService.getUserProfile(uName, (success: boolean, data) => {
        if (success) {
            // console.log("User data is",data.state);
            this.secureStorage.setSecureInfoInSessionlStorage('fullName', data['firstName'] + ' ' + data['middleName'] + ' ' + data['lastName']);
            this.secureStorage.setSecureInfoInSessionlStorage('userProfile', JSON.stringify({
              city: data['city'], zip: data['zip'],
              state: data['state'],
              userRole: data['userRole'],
              guId: data['guId'],
              phone: data['phone'],
              extn: data['extn'],
              fax: data['fax'],
              email: data['email'],
              npi: data['npi'],
              taxid: data['taxId'],
              questions: data['securityQuestionsFlag'],
              cred: data['lastPasswordChange'],
              lastLogin: data['lastLogin']
          }));
            this.providerportalService.setUserData(data);
          }
          else{
            if(data == "401"){
              this.router.navigateByUrl('login');
            }
          }
      })
    }
  async getUserData(status: string, cb?) {
    status = status;
    let guId;
    if(this.secureStorage.getSecureInfoInSessionStorage('userProfile')){}
    else{
      let loginresp3 = await this.accountProfileService.getUserProfile2(
        ""
      );
      let resp3 = loginresp3.user[0];
      this.providerportalService.setUserData(resp3);
          this.secureStorage.setSecureInfoInSessionlStorage(
            "userProfile",
            JSON.stringify({
              city: resp3.city,
              zip: resp3.zip,
              state: resp3.state,
              userRole: resp3.userRole,
              guId: resp3.guId,
              phone: resp3.phone,
              extn: resp3.extn,
              fax: resp3.fax,
              email: resp3.email,
              npi: resp3.npi,
              taxid: resp3.taxId,
              questions: resp3.securityQuestionsFlag,
              cred: resp3.lastPasswordChange,
              lastLogin: resp3.currentLogin,
              loginTimePayloadTime: resp3.lastLogin,
              userClass: resp3.userClass,
            }));
    }
    if(this.secureStorage.getSecureInfoInSessionStorage('userProfile')){
    guId = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('userProfile'))['guId'];
    this.userauthenticationservice.getUsers(
      guId,
      status,
      '',
      (success: boolean, userData: any) => {
        if (success) {
          this.reconstructWithAdminFlag(userData['user']);
          this.users[status] = userData['user'];
          this.secureStorage.setSecureInfoInSessionlStorage('users', JSON.stringify(this.users));
          if (status == "1,3") {
            userData['user'].map(user => {
              // user['firstName'] = user['firstName'].charAt(0).toUpperCase() + user['firstName'].slice(1);
              user['userId'] = user['userName'];
              user['name'] = user['firstName'] + ', ' + user['lastName'];
              if (!this.allUserId.includes(user.userId)) {
                this.allUserId.push(user.userId);
                this.activeUsers.push(user);
              }
            });
          } else if (status == "2") {
            userData['user'].map(user => {
              user['userId'] = user['userName'];
              user['name'] = user['firstName'] + ', ' + user['lastName'];
              if (!this.allUserId.includes(user.userId)) {
                this.allUserId.push(user.userId);
                this.inactiveUsers.push(user);
              }
            });
          }
          if (cb) {
            cb();
          } else {
            this.secureStorage.setSecureInfoInSessionlStorage('usersContentLoaded', "true");
          }
        } else {
          this.secureStorage.setSecureInfoInSessionlStorage('usersContentLoaded', "true");
        }
      }
    );
    }
  }
  reconstructWithAdminFlag(users: any) {
    for (let index = 0; index < users.length; index++) {
      const element = users[index];
      element['isAdmin'] =
        element['userRole'] != null && element['userRole'] === 'Admin'
          ? true
          : false;
    }
  }
}
