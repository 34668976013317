import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { urls } from '../common/model/properties';
import { SecureStorageService } from '../secure-storage.service';

@Injectable()
export class UploaddocumentService {

  constructor(private http: HttpClient,
    private secureStorage:SecureStorageService) { }


    // Temparary code to be Commented because as of now uplaod doc for onbording functionality is removed for now

//   requestProfileWithBothfields(caseId, passCode, callBack: (sucess: boolean, message: any) => void) {
//     // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
//     const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
//     const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});

//     this.http.get(urls.requestProfile+'caseId='+caseId+'&passcode='+passCode,{headers: headers, observe:'response'}).subscribe(
//         (resp) => { 
//             //console.log(resp)
//               if (resp.status === 200) {
//                   if(resp['body'] === '')
//                   {
//                       callBack(false, resp);
//                       //console.log("Empty Response") 
//                   }
//                   else{
//                   callBack(true, resp);
//                   //console.log(resp['_body'])
//                   }   
//               } else {
//                   callBack(false, resp);
//                   //console.log("Failed to process the request")
//               }
//           },
          
//       );  
// }
}
