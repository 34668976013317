  <div class="cup-autocomplete">
    <!--Dynamic Dropdown-->
    <p-dropdown #dynamicDropDown *ngIf="autoCompleteDynamic && _autoCompleteOptions.length" [dropDownIcon] = "fa"  [options]="_autoCompleteOptions | selectOption" [ngClass]="currentValue ? 'noclass' : 'placeholder' "
      class="auto-complete width-{{colStyle.id}}" [placeholder] = "autoCompletePlaceholder" (onChange)="onChange($event,_autoCompleteModel)" 
      [(ngModel)]="_autoCompleteModel"  (ngModelChange)="onModelChange(_autoCompleteModel)" [style]="{'width':colStyle.colWidth}" filter="true" [disabled]="isDisabled">
    <ng-template let-item pTemplate="selectedItem">
        <span style="vertical-align:middle">{{item.label}}</span>
    </ng-template>
    <ng-template let-option pTemplate="item">
      <div class="ui-helper-clearfix"  style="white-space: normal">
        <div>{{option?.value?.value}}</div>
      </div>
    </ng-template>
  </p-dropdown>
    <a [ngStyle] = "hideCloseBtn ? {'display':'block'} :  {'display':'none'}" href="javascript:void(0);" class="clear-value close-btn-align" *ngIf="currentValue && autoCompleteDynamic && !isDisabled" (click)="clearFilter(dynamicDropDown)"><i class="fa fa-times"></i></a>
    
    <!--Static Dropdown-->
    <p-dropdown #staticDropDown *ngIf="!autoCompleteDynamic" [options]="_autoCompleteOptions | selectOption" [ngClass]="currentValue ? 'noclass' : 'placeholder' "
      class="auto-complete width-{{colStyle.id}}" [placeholder] = "autoCompletePlaceholder" (onChange)="onChange($event,_autoCompleteModel)"
      [(ngModel)]="_autoCompleteModel" (ngModelChange)="onModelChange(_autoCompleteModel)" [style]="{'width':colStyle.colWidth}" [disabled]="isDisabled">
    </p-dropdown>
    <a [ngStyle] = "hideCloseBtn ? {'display':'block'} :  {'display':'none'}" href="javascript:void(0);" class="clear-value  close-btn-align" *ngIf="currentValue && !autoCompleteDynamic && !isDisabled" (click)="clearFilter(staticDropDown)"><i class="fa fa-times"></i></a>
  </div>