import { Component, OnInit, AfterViewChecked, ViewChild, ChangeDetectorRef, AfterViewInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { CupService } from '../../shared/services/cup.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UrlconstantsService } from '../services/urlconstants.service';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import * as FileSaver from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';
import { urls } from '../../common/model/properties';
import { SecureStorageService } from '../../secure-storage.service';
import { FileUpload } from 'primeng/fileupload/fileupload';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
  selector: 'cup-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.css']
})
export class AttachmentComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('fileInput') fileInput: FileUpload;
  @Input() sendMailScreen: boolean;
  @Output() uploadClicked = new EventEmitter<boolean>();
  @Output() selectedFile = new EventEmitter<any>();
  public attachmentForm: FormGroup;
  public authId: string;
  public authTypeUId: string;
  public authType: string;
  public currentPath: string;
  public fromPendingWorkList = false;
  public memberId: string;
  public selectedMemberDetails: any;
  public openedPanel = false;
  public authorizationAttachments = true;
  public attachmentGridColumns = [];
  public attachmentTypeData = [];
  public attachmentTypeModel: string;
  public placeholder = 'Select Attachment Type';
  public formSubmitted = false;
  public totalRecords = 0;
  public pages = 1;
  public isServerSidePagination = true;
  public AttachmentsGridTitle = 'VIEW ATTACHMENTS';
  public isAttachmentGrid = true;
  public urlConstant: any = this.urlconstantsService.fetchUrl();
  public attachmentGridRows = [];
  public uploadedFiles = [];
  public metaData: any;
  public metaDataList = [];
  public fileMandatoryError = false;
  public gridOperations = {
    restrictViewAuth: false,
    restrictDeleteAuth: false,
    restrictViewMember: false,
    restrictDeleteMember: false,
  };
  public isMemberAttachments = false;
  public panelName = 'MEMBER';
  public documentCode = 'MEMBER_DOC';
  public fileToBeDeleted = {
    details: '',
    deleteAttachment: false
  };
  public progressBar = {
    isUploading: false,
    uploadedPercent: 0,
    fileName: '',
    uploadSuccessful: false,
    uploadFailed: false
  };
  public tempFileMsg = [];
  public duplicateFileDetails = {
    isDuplicate: false,
    duplicateFileMessage: 'The selected file already exists. Please choose another file'
  };
  public localUrl = '';
  public initiatePreview = false;
  public fileDetailsForMessage = [];
  private filesAlreadyAttached = [];
  private previewAttachmentClickSubscription: any;
  private deleteAttachmentClickSubscription: any;
  public fromDeniedWorkList = false;
  public casemanagementWorkList = false;
  private priorAuthNumber = '';

  constructor(private cupService: CupService, private urlconstantsService: UrlconstantsService,
    private router: Router, private location: Location, private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder, private cdr: ChangeDetectorRef,
    private secureStorage:SecureStorageService) {
    this.getRouteParams();
    this.determineBackButton();
    this.attachmentColumns();
    // this.attachmentType();
    this.createAttachmentForm();
    this.progressBarStatus();
    this.listenIfPreviewDeleteClicked();
    this.clearAttachedMails();
    this.forwardAttachment();
  }
  /*Route params*/
  private getRouteParams() {
    if (this.router.url === '/home/member/member-attachments') {
      this.isMemberAttachments = true;
      this.selectedMemberDetails = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('memberDetails'));
      this.casemanagementWorkList = true;
    } else {
      this.panelName = 'AUTHORIZATION';
      this.documentCode = 'AUTH_DOC';
      this.casemanagementWorkList = false;
    }
    this.activatedRoute.params.subscribe((params: Params) => {
      this.authId = params['authid'];
      this.authTypeUId = params['authtypeuid'];
    });
    if (this.router.url.indexOf('case-auth-attachments') != -1) {
      this.casemanagementWorkList = true;
    } else {
      this.casemanagementWorkList = false;
    }
  }
  /*Back Button Change based on components*/
  private determineBackButton() {
    this.currentPath = this.router.url;
    if (this.currentPath.includes('/home/worklist/auth-attachments')) {
      this.fromPendingWorkList = true;
    } else if (this.currentPath.includes('/home/worklist/denied-auth-attachments')) {
      this.fromDeniedWorkList = true;
    } else {
      this.fromPendingWorkList = false;
    }
  }

  /*Create Form*/
  public createAttachmentForm() {
    this.attachmentForm = this.fb.group({
      attachmentTypeFormControl: [''],
      descriptionFormControl: ['', Validators.required]
    });
  }

  /*Dropdown*/
  private attachmentType() {
    const url = this.isMemberAttachments ? this.urlConstant.memberAttachmentType : this.urlConstant.authAttachmentType;
    this.cupService.retrieveData('get', url).subscribe(data => {
      this.attachmentTypeData = data;
    });
  }

  /*Panel Toggle*/
  private toggleAccordion() {
    this.openedPanel = !this.openedPanel;
  }

  /*Back to Pending Auth Review*/
  private navigateFromAttachments() {
    // let queryParamsPbj = { queryParams : {source : this.cupNavigationService.queryParams}, skipLocationChange: true};
    if (this.isMemberAttachments) {
      this.router.navigate(['/home/member/member-search'], { skipLocationChange: true });
    } else {
      // this.router.navigateByUrl(this.cupNavigationService.previousState,{skipLocationChange: true});
    }
  }

  /*Load member*/
  private loadMember() {
    const url = this.urlConstant.authDetails.replace('{authid}', this.authId);
    this.cupService.retrieveData('get', url).subscribe(data => {
      this.memberId = data.member.id;
      this.cupService.retrieveData('get', this.urlConstant.memberDetails.replace('{memberid}', this.memberId)).subscribe(member => {
        this.selectedMemberDetails = member;
      });
    });
  }

  /*Meta Data for files*/
  private loadMetaData() {
    let url = '';
    if (this.isMemberAttachments) {
      url = this.urlConstant.memberFileUploadMetaData.replace('{memuid}', this.selectedMemberDetails.id);
    } else {
      url = this.urlConstant.fileUploadMetaData.replace('{authuid}', this.authId);
    }

    this.cupService.retrieveData('get', url).subscribe(data => {
      this.metaData = data;
      this.priorAuthNumber = this.metaData['priorAuthNumber'];
      this.retrieveFiles();
      const keys = Object.keys(this.metaData);
      if (this.isMemberAttachments) {
        keys.forEach(element => {
          if (typeof (this.metaData[element]) !== 'object') {
            //let memberElement = '';
            this.metaDataList.push({ key: element, value: this.metaData[element] });
          }
        });
      } else {
        keys.forEach(element => {
          if (typeof (this.metaData[element]) !== 'object') {
            let authElement = '';
            element === 'memId' ? authElement = 'memberID' : element === 'referredfromproviderid' ?
              authElement = 'referredFromProviderId' : element === 'referredtoProviderId' ? authElement = 'referredToId' :
                authElement = '';
            this.metaData[element] = element === 'authCreatedDate' ? this.getValidDate(this.metaData[element]) : this.metaData[element];
            this.metaDataList.push({ key: authElement ? authElement : element, value: this.metaData[element] });
          } else {
            const innerKeys = Object.keys(this.metaData[element]);
            innerKeys.forEach(innerElement => {
              let newElement = '';
              innerElement === 'code' ? newElement = element + 'Code' : innerElement === 'referredtoProviderId' ?
                newElement = 'referredToId' : newElement = '';
              this.metaDataList.push({ key: newElement ? newElement : innerElement, value: this.metaData[element][innerElement] });
            });
          }
        });
      }
    }, error => {
      this.retrieveFiles();
    });
  }

  /*Fetch the files to show in grid*/
  public retrieveFiles() {
    let keyValue = [];
    if (this.isMemberAttachments) {
      keyValue = [{
        key: 'memberId',
        value: this.selectedMemberDetails.memberId
      },
      {
        key: 'clientId',
        value: this.selectedMemberDetails.clientId
      }];
    } else {
      if (this.priorAuthNumber) {
        keyValue.push({
          key: 'priorAuthNumber',
          value: this.priorAuthNumber ? this.priorAuthNumber : ''
        });
      } else {
        keyValue.push({
          key: 'authNumber',
          value: 'AUT' + this.authId
        });
      }
    }

    const url = urls.fileNetListUrl +
      '?appId=CUP&documentCode=' +
      this.documentCode +
      '&multiple=true&keyValuePairList=' +
      encodeURIComponent(JSON.stringify(keyValue));
    this.cupService.isLoading(true);
    this.cupService.retrieveData('get', url).subscribe(response => {
      if (response.keyValuePairListWrapperlist && response.keyValuePairListWrapperlist.length) {
        this.manipulateGridRows(response);
        this.cupService.isLoading(false);
      } else {
        this.attachmentGridRows = [];
        this.cupService.isLoading(false);
      }
    }, error => {
      this.cupService.isLoading(false);
    });
  }

  /*Manipulate response to bind to grid*/
  private manipulateGridRows(rows) {
    const formGridObjList = [];
    let formGridObj = [];
    rows.keyValuePairListWrapperlist.forEach(element => {
      formGridObj = [];
      element.keyvaluePairWr.forEach(ele => {
        ele.value = ele.key === 'fileSizeInBytes' || ele.key === 'fileSize' ? this.formatBytes(ele.value) : ele.value;
        const temp = { [ele.key]: ele.value };
        formGridObj.push(temp);
      });
      formGridObjList.push(formGridObj);
    });
    const tempList = [];
    formGridObjList.forEach(element => {
      const temp = [];
      const mergeElements = {};
      element.forEach(ele => {
        Object.assign(mergeElements, ele);
      });
      tempList.push(mergeElements);
      this.denyGridOperations(tempList);
      this.attachmentGridRows = tempList;
      this.cupService.isLoading(false);
    });
  }

  /*Enable / Disable grid operations based on permissions*/
  private denyGridOperations(tempList) {
    if (!this.isMemberAttachments) {
      if (!this.gridOperations.restrictViewAuth) {
        tempList.forEach(ele => {
          ele.authDownloadPreviewDenied = true;
        });
      }
      if (!this.gridOperations.restrictDeleteAuth) {
        tempList.forEach(ele => {
          ele.authDeleteDenied = true;
        });
      }
    } else {
      if (!this.gridOperations.restrictViewMember) {
        tempList.forEach(ele => {
          ele.memberDownloadPreviewDenied = true;
        });
      }
      if (!this.gridOperations.restrictDeleteMember) {
        tempList.forEach(ele => {
          ele.memberDeleteDenied = true;
        });
      }
    }
  }

  /*Load grid columns*/
  private attachmentColumns() {
    this.attachmentGridColumns = [
      { style: { 'width': '20px' } },
      { field: 'DocumentTitle', header: 'File Name', style: { 'width': '200px', 'word-wrap': 'break-word' } },
      { field: 'fileDescription', header: 'Description', style: { 'width': '200px', 'word-wrap': 'break-word' }, sortable: 'true' },
      { field: 'fileSizeInBytes', header: 'File Size', style: { 'width': '100px' }, sortable: 'true' },
      { field: 'attachmentType', header: 'Attachment Type', style: { 'width': '120px' }, sortable: 'true' },
      { field: 'CreatedUser', header: 'Uploaded By', style: { 'width': '150px' }, sortable: 'true' },
      { field: 'CreatedDate', header: 'Uploaded Date', style: { 'width': '100px' }, sortable: 'true' }
    ];
  }

  /*Dropdown value change*/
  private onTypeChange(selectedValue) {
    if (typeof (selectedValue) === 'object') {
      this.attachmentForm.controls.attachmentTypeFormControl.patchValue(selectedValue);
      this.attachmentTypeModel = selectedValue.value;
    }
  }

  /*On Select*/
  private onSelectingFile(event) {
    this.fileMandatoryError = false;

    // this.tempFileMsg ? this.tempFileMsg : [];
    if (event.files && event.files.length > 0) {
      if (this.fileInput.msgs.length === 0) {
        this.fileInput.files = [];
        this.fileInput.files.push(event.files[0]);
        this.fileInput.msgs = [];
        this.tempFileMsg = [];
        this.testIE();
      } else {
        const temp = this.fileInput.msgs;
        this.tempFileMsg = this.fileInput.msgs;
        this.fileInput.msgs = [];
        this.fileInput.msgs = temp;
        this.fileInput.files = [];
      }
    } else {
      this.fileInput.msgs = this.tempFileMsg;
    }
  }
 
 private testIE(){
  // if (!!window['MSInputMethodContext'] && !!document['documentMode']) {
  //    if(this.fileInput && this.fileInput.fileinput) {
  //       this.fileInput.fileinput.nativeElement = null;
  //    }
  //  }
 }

  /*On Upload*/
  private onUpload(formValues) {
    this.formSubmitted = true;
    this.fileInput.upload();
    if (this.attachmentForm.valid && !this.fileMandatoryError) {
      this.formSubmitted = false;
      
    }
  }

  /*On Clear*/
  public onClear(fromAuthMessage?: boolean) {
    this.formSubmitted = false;
    this.fileMandatoryError = false;
    this.attachmentForm.reset();
    this.fileInput.clear();
    this.testIE();
    this.fileInput.msgs = [];
    this.duplicateFileDetails.isDuplicate = false;
    if (fromAuthMessage) {
      this.filesAlreadyAttached = [];
      this.fileDetailsForMessage = [];
    }
  }


  /*Upload files*/

  // Uploading all the attached files
  public uploadAttachedFiles(appId, documentCode): Observable<any> {

    let fileDetails = this.fileDetailsForMessage;
    // If no file attached
    if (!(fileDetails && fileDetails.length)) {
      return;
    }
    // to remove already saved files present
    fileDetails = fileDetails.filter(x => !x.docId);
    const fileDataList = fileDetails.map(x => {
      if (x.files && x.files.length > 0) {
        const obj = {
          description: x.description,
          // attachedType: x.attachedType,
          file: x.files[0],
        };
        return obj;
      }
    });

    const files: any[] = [];
    fileDataList.forEach(fileData => {
      const file = fileData.file;
      file.fileName = file.name;
      file.curentDate = this.getValidDate(Date.now());
      file.description = fileData.description;
      // file.attachedType = fileData.attachedType;
      // file.fileSize = this.formatBytes(file.size);
      file.fileSize = file.size;
      file.uploadedBy = this.cupService.getUserNameFromJWT();
      files.push(file);
    });

    const filesUploadObervable: Array<Observable<any>> = [];

    {
      const document = {
        appId: appId,
        documentCode: documentCode,
        createdUser: this.cupService.getUserNameFromJWT(),
        txnLog: false,
        keyValuePairList: [],
      };

      files.forEach(file => {
        let documentMetaDataList = [];
        documentMetaDataList = this.metaDataList.map(x => Object.assign({}, x));
        documentMetaDataList.push({ key: 'fileDescription', value: file.description },
          { key: 'attachmentType', value: 'Other' });

        document.keyValuePairList = documentMetaDataList;

        const formData = new FormData();
        formData.append('content', file);
        formData.append('document', JSON.stringify(document));
        const uploadObservable = this.cupService.uploadFile(urls.uploadEdsDoc, formData).pipe(map((response) => {
          const parseResponce=JSON.parse(response);
          console.log(parseResponce);
          const docIdFromFileNet = parseResponce.docId;
          if (docIdFromFileNet) {
            return {
              docId: docIdFromFileNet,
              fileName: file.fileName,
              fileSize: file.fileSize
            };

          } else {
            return -1; // represent not uploaded
          }
        }, error => {

        }));
        filesUploadObervable.push(uploadObservable);
      });

    }

    return forkJoin(filesUploadObervable);

  }
  private onFileUpload(event) {
    if (this.sendMailScreen) {
      this.constructFileObject(event);
    } else {
      let documentMetaDataList = [];
      this.progressBar = {
        isUploading: false,
        uploadedPercent: 0,
        fileName: '',
        uploadSuccessful: false,
        uploadFailed: false
      };
      const fileToBeUploaded = event.files;
      if (fileToBeUploaded.length && this.attachmentForm.valid) {
        this.fileMandatoryError = false;
        fileToBeUploaded.forEach(file => {
          file.fileName = file.name;
          file.curentDate = this.getValidDate(Date.now());
          file.description = this.attachmentForm.controls.descriptionFormControl.value ?
            this.attachmentForm.controls.descriptionFormControl.value : '';
          file.attachedType = this.attachmentForm.controls.attachmentTypeFormControl.value ?
            this.attachmentForm.controls.attachmentTypeFormControl.value.value : '';
          file.fileSize = this.formatBytes(file.size);
          file.uploadedBy = this.cupService.getUserNameFromJWT();
        });
        documentMetaDataList = this.metaDataList.map(x => Object.assign({}, x));
        documentMetaDataList.push({ key: 'fileDescription', value: fileToBeUploaded[0].description },
          { key: 'attachmentType', value: fileToBeUploaded[0].attachedType });
        const document = {
          appId: 'CUP',
          documentCode: this.documentCode,
          createdUser: this.cupService.getUserNameFromJWT(),
          txnLog: false,
          keyValuePairList: documentMetaDataList
        };
        const formData = new FormData();
        formData.append('content', fileToBeUploaded[0]);
        formData.append('document', JSON.stringify(document));
        this.progressBar.fileName = fileToBeUploaded[0].name;
        this.duplicateFileDetails.isDuplicate = false;
        this.attachmentGridRows.forEach(ele => {
          if (!this.duplicateFileDetails.isDuplicate) {
            let fileName = fileToBeUploaded[0].name ? fileToBeUploaded[0].name.trim() : '';
            let fileDesc = fileToBeUploaded[0].description ? fileToBeUploaded[0].description.trim() : '';
            let fileNameElement = ele.DocumentTitle ? ele.DocumentTitle.trim() : '';
            let fileDescElement = ele.fileDescription ? ele.fileDescription.trim() : '';
            fileNameElement = fileNameElement.toLowerCase();
            fileDescElement = fileDescElement.toLowerCase();
            fileName = fileName.toLowerCase();
            fileDesc = fileDesc.toLowerCase();

            this.duplicateFileDetails.isDuplicate = (fileDescElement === fileDesc) && (fileNameElement === fileName) ? true : false;
          }
        });
        if (!this.duplicateFileDetails.isDuplicate) {
          this.cupService.uploadFile(urls.uploadEdsDoc, formData).subscribe((response) => {
            const parseResponce=JSON.parse(response);
            parseResponce.docId ? this.progressBar.uploadSuccessful = true : this.progressBar.uploadFailed = true;
            if (this.progressBar.uploadSuccessful) {
              this.retrieveFiles();
              this.attachmentForm.reset();
              this.fileInput.clear();
              this.testIE();
              this.formSubmitted = false;
              if (!this.isMemberAttachments) {
                // this.createAuditLog(fileToBeUploaded[0].name, this.auditConstants.upload, fileToBeUploaded[0].fileSize);
                this.createAttachmentLog(fileToBeUploaded[0].name, response.AddDocResponse.docId, fileToBeUploaded[0].fileSize);
              }
            }
          }, error => {
            this.progressBar.uploadFailed = true;
          });
        }
      } else if (!fileToBeUploaded.length) {
        this.fileMandatoryError = true;
        this.formSubmitted = true;
      }
    }
  }

  /*Construct file object for send mail screen*/
  private constructFileObject(event) {
    if (event.files && event.files.length > 0) {
      if (this.fileInput.msgs.length === 0) {
        if (this.attachmentForm.valid) {
          this.formSubmitted = false;
          this.filesAlreadyAttached = Object.assign([], this.fileDetailsForMessage);
          event.files[0].blobUrl = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(event.files[0])));
          this.localUrl = event.files[0].blobUrl;
          const obj = {
            fileName: event.files[0].name, blobUrl: this.localUrl,
            size: this.formatBytes(event.files[0].size),
            type: event.files[0].type,
            description: this.attachmentForm.controls.descriptionFormControl.value ?
              this.attachmentForm.controls.descriptionFormControl.value : '',
            attachedType: this.attachmentForm.controls.attachmentTypeFormControl.value ?
              this.attachmentForm.controls.attachmentTypeFormControl.value.value : '',
            files: event.files
          };
          this.checkDuplicateForMessageAttachments(obj);
        } else {
          this.formSubmitted = true;
        }
      }
    } else if (!event.files.length) {
      this.fileMandatoryError = true;
      this.formSubmitted = true;
    }
  }

  /*Download or Preview Files*/
  public downloadFiles(event, isDownload?: boolean) {
    this.cupService.isShowSpinner(true);
    this.cupService.downloadFile(this.urlconstantsService.fileNetUrl + event.Id + '?appId=CUP&documentCode=' + this.documentCode)
      .subscribe(fileData => {
        const blob = this.convertBytes(fileData.docContent, fileData.fileType);
        FileSaver.saveAs(blob, fileData.filename);
        this.cupService.isShowSpinner(false);
        if (!isDownload) {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
            window.navigator.msSaveOrOpenBlob(blob, fileData.filename);
          } else if (fileData.filename.indexOf('.doc') === -1 && fileData.filename.indexOf('.docx') === -1 &&
            fileData.filename.indexOf('.xls') === -1 && fileData.filename.indexOf('.xlsx') === -1) {
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
          }
        }
        if (!this.isMemberAttachments) {
          // this.createAuditLog(fileData.filename, this.auditConstants.download, event.fileSizeInBytes);
        }
      }, error => {
        this.cupService.isShowSpinner(false);
      });
  }

  /*Events to emit on click of preview or delete for mail attachment*/
  private listenIfPreviewDeleteClicked() {
    this.previewAttachmentClickSubscription = this.cupService.previewAttachment.subscribe(preview => {
      this.initiatePreview = preview;
      if (this.initiatePreview) {
        this.previewMailAttachment(preview);
      }
    });
    this.deleteAttachmentClickSubscription = this.cupService.deleteAttachment.subscribe(deleteAttachment => {
      if (deleteAttachment) {
        this.deleteAttachment(deleteAttachment);
      }
    });
  }

  /*Preview mail attachment*/
  private previewMailAttachment(event) {
    if (event) {
      // this.sanitizer.bypassSecurityTrustResourceUrl(event.changingThisBreaksApplicationSecurity);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        this.getBlobFromUrl(event.changingThisBreaksApplicationSecurity); // for IE
      } else {
        window.open(event.changingThisBreaksApplicationSecurity, '_blank');
      }
    }
  }

  /*Delete mail attachment*/
  private deleteAttachment(event) {
    const originalFiles = this.fileDetailsForMessage;
    this.fileDetailsForMessage = [];
    if (originalFiles && originalFiles.length) {
      originalFiles.forEach(val => {
        if (!event.messageAttachmentUid) {
          if (event.blobUrl !== val.blobUrl) {
            this.fileDetailsForMessage.push(val);
          }
          this.filesAlreadyAttached = this.fileDetailsForMessage;
          this.selectedFile.emit(this.fileDetailsForMessage);
        } else {
          if (event.messageAttachmentUid !== val.messageAttachmentUid) {
            this.fileDetailsForMessage.push(val);
          }
          this.filesAlreadyAttached = this.fileDetailsForMessage;
          this.selectedFile.emit(this.fileDetailsForMessage);
        }
      });
    }
  }
  /*Clear attachments on click of clear button from auth messages*/
  private clearAttachedMails() {
    this.cupService.clearMailAttachment.subscribe(clear => {
      if (clear) {
        this.fileDetailsForMessage = [];
      }
    });
  }

  /*Duplicate check for mail attachments*/
  private checkDuplicateForMessageAttachments(file) {
    const newFile = file;
    this.duplicateFileDetails.isDuplicate = false;
    newFile.fileName = newFile.fileName ? newFile.fileName.trim() : '';
    newFile.description = newFile.description ? newFile.description.trim() : '';
    newFile.description = newFile.description.toLowerCase();
    newFile.fileName = newFile.fileName.toLowerCase();
    const originalFiles = this.filesAlreadyAttached;
    if (originalFiles && originalFiles.length > 0) {
      originalFiles.forEach(ele => {
        let name = ele.fileName ? ele.fileName.trim() : '';
        let desc = ele.description ? ele.description.trim() : '';
        name = name.toLowerCase();
        desc = desc.toLowerCase();
        if (newFile.description === desc && newFile.fileName === name) {
          this.duplicateFileDetails.isDuplicate = true;
        }
      });
    }
    if (!this.duplicateFileDetails.isDuplicate) {
      this.uploadClicked.emit(true);
      this.fileDetailsForMessage.push(file);
      this.selectedFile.emit(this.fileDetailsForMessage);
      this.onClear();
    } else {
      this.fileDetailsForMessage = this.filesAlreadyAttached;
    }
  }
  /*on click of forward attachment*/
  public forwardAttachment() {
    this.cupService.replyClicked.subscribe(forwardattachmentData => {
      this.fileDetailsForMessage = [];
      if (forwardattachmentData && forwardattachmentData.length > 0) {
        forwardattachmentData.forEach((element, index) => {
          this.fileDetailsForMessage.push(forwardattachmentData[index]);
          this.filesAlreadyAttached = Object.assign([], this.fileDetailsForMessage);
          this.selectedFile.emit(this.fileDetailsForMessage);
        });
      }
    });
  }
  /*on click of delete show dialog*/
  public deleteAttachedFile(event) {
    this.fileToBeDeleted.deleteAttachment = true;
    this.fileToBeDeleted.details = event;
  }

  /*on click of Yes in delete dialog*/
  private deleteSelectedFile() {
    this.fileToBeDeleted.deleteAttachment = false;
    this.deleteFiles(this.fileToBeDeleted.details);
  }

  /*Delete a file*/
  private deleteFiles(event) {
    const requestBody = this.urlconstantsService.fileNetUrl + event.Id +
      '?appId=CUP&documentCode=' + this.documentCode + '&deletedByUser=' + this.cupService.getUserNameFromJWT();
    this.cupService.retrieveData('delete', requestBody).subscribe(fileData => {
      this.retrieveFiles();
      if (!this.isMemberAttachments) {
        // this.createAuditLog(event.DocumentTitle, this.auditConstants.delete);
        this.createDeleteAttachmentLog(event.Id);
      }
    });
  }

  /*Convert Bytes to Blob*/
  public convertBytes(b64Data, contentType, sliceSize?: any) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }


  /*Get date in valid format*/
  public getValidDate(dateObj: any) {
    if (dateObj) {
      const date = new Date(dateObj),
        year = date.getFullYear(),
        month = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2);
      return month + '/' + day + '/' + year;
    } else {
      return '';
    }
  }

  /*Get size in valid format*/
  private formatBytes(bytes) {
    if (bytes === '0' || bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024,
      dm = 2,
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  /*Progress Bar Status*/
  private progressBarStatus() {
    this.cupService.progressObserver.subscribe(
      data => {
        this.progressBar.isUploading = true;
        this.progressBar.uploadedPercent = data;
      });
  }

  /*Check permissions*/
  private checkUserPermissions() {
    // this.cupUserAuthorizationService.userTemplate.authEntityId.forEach(template => {
    //   if (template === this.authConstant.viewAuthAttachments) {
    //     this.gridOperations.restrictViewAuth = true;
    //   }
    //   if (template === this.authConstant.deleteAttachments) {
    //     this.gridOperations.restrictDeleteAuth = true;
    //   }
    // });
    //  this.cupUserAuthorizationService.userTemplate.member.forEach(template => {
    //   if (template.entityUid === this.memberConstant.deleteMemberAttachments) {
    //     this.gridOperations.restrictDeleteMember = true;
    //   }
    //   if (template.entityUid === this.memberConstant.viewMemberAttachments) {
    //     this.gridOperations.restrictViewMember = true;
    //   }
    //  });
  }

  /*Get blob details from the url for IE to preview*/
  private getBlobFromUrl(url) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onreadystatechange = () => {
      if (xhr.status === 200) {
        window.navigator.msSaveOrOpenBlob(xhr.response, 'new');
      }
    };
    xhr.send();
  }

  /*Create Audit Log*/
  private createAuditLog(fileName, eventTypeUid, fileSize?: any) {
    const url = this.urlConstant.attachmentAuditLog;
    const size = fileSize ? ' - ' + fileSize : '';
    const auditLogData = {
      authUid: this.authId,
      authEventTypeUid: eventTypeUid,
      field: null,
      fromValue: null,
      toValue: fileName + size,
    };
    this.cupService.retrieveData('post', url, auditLogData).subscribe(response => {
    }, error => {
    });
  }

  private createAttachmentLog(fileName, docId, fileSize) {
    const obj = {
      authUid: this.authId,
      fileName: fileName,
      fileSize: fileSize,
      archivalDocumentId: docId
    };
    this.cupService.retrieveData('post', this.urlConstant.attachmentLog, obj).subscribe(response => {
    }, error => {
    });
  }

  /*Create Delet Attachment log*/
  private createDeleteAttachmentLog(documentId) {
    const url = this.urlConstant.deleteAttachmentLog.replace('{documentKey}', documentId);
    this.cupService.retrieveData('delete', url).subscribe(response => {
      // if success makes an entry in delete attachment log
    }, error => {
    });
  }
  ngOnInit() {
    if (!this.isMemberAttachments && !this.sendMailScreen) {
      this.loadMember();
    }
    if (!this.sendMailScreen) {
      this.loadMetaData();
    }
    this.checkUserPermissions();
  }

  ngOnDestroy(): void {
    if (this.previewAttachmentClickSubscription) {
      this.previewAttachmentClickSubscription.unsubscribe();
    }

    if (this.deleteAttachmentClickSubscription) {
      this.deleteAttachmentClickSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    // if (this.fileInput && this.fileInput.fileinput.nativeElement) {
    //   this.fileInput.fileinput.nativeElement.multiple = true;
    // }
  }

}
