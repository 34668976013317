import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SecureStorageService } from '../secure-storage.service';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(public router: Router,
    private secureStorage:SecureStorageService) { }
  canActivate(): boolean {
    console.clear();
    // const userDetails = this.secureStorage.getSecureInfoInSessionStorage('currentUser');
    const userDetails = this.secureStorage.getSecureInfoInLocalStorage('currentUser');
    if (!userDetails) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
