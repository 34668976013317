import { Injectable } from '@angular/core';
import { TreeModule } from 'angular-tree-component';
import { BehaviorSubject, Observable, of, forkJoin, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { urls } from '../../common/model/properties';
import { ConfigProvider } from '../../shared/services/configprovider';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import * as xml2js from 'xml2js';
import { SecureStorageService } from '../../secure-storage.service';
import { CupService } from 'src/app/shared/services/cup.service';

@Injectable()
export class EpahafService {
  config: any;
  props: any;
  poteiltialDataAPiFailed: boolean = false;
 epahafPdfData = new BehaviorSubject([])
 epahafMemberData: any = new BehaviorSubject([]);
 progressObserver = new Subject<any>();
 

  constructor(private http: HttpClient, private cupService: CupService,
    private configProvider: ConfigProvider,
    private secureStorage:SecureStorageService
    ) { 
      this.config = configProvider.getConfig();
      this.props = configProvider.getProps();
     
    }
    public getPahafDetailsforEachMembers(id) {
     
      // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
      const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
      const headers = new HttpHeaders({ 
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + ppjwt.access_token
    });
     
    let myParams = new HttpParams()
    .append('memberId', id);
  
      return forkJoin([
        this.http
          .get(urls.getPotenitalData, { headers: headers, params: myParams })
          .pipe(catchError((err) => of(err.status))),
        this.http
          .get(urls.getMemberMeasuresPahaf, { headers: headers, params: myParams })
          .pipe(catchError((err) => of(err.status))),
      ]);
    }
    getPoteintialDataforPahaf(id):Observable<any>{
      
      // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
      const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
      const headers = new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + ppjwt.access_token
      });
      let myParams = new HttpParams()
      .append('memberId', id);
      return this.http.get(urls.getPotenitalData, { headers: headers, params: myParams }).pipe(catchError((error) => {
      this.poteiltialDataAPiFailed = true;
        console.log(error);
        return of(({ error: error }));
    }));         
    

    }
    getpahafmembermeasures(id):Observable<any>{
      
      // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
      const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
      const headers = new HttpHeaders({
        'Accept': 'application/json' ,
        'Authorization': 'Bearer ' + ppjwt.access_token
      });
      let myParams = new HttpParams()
      .append('memberId', id);
      return this.http.get(urls.getMemberMeasuresPahaf, { headers: headers, params: myParams }).pipe(catchError((error) => {
        console.log(error);
        return of(({ error: error }));
    }));         
    

    }
    getReadStatus(AlertId){
     
      // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
      const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
      const headers = new HttpHeaders({
        'Accept': 'application/json' ,
        'Authorization': 'Bearer ' + ppjwt.access_token
      });
      const payload = {
          "alertId":AlertId
      }
      // let alertStatusUrl = urls.getAlertReadStatus as any;
      // alertStatusUrl = alertStatusUrl.replace('alertId',AlertId );
      return this.http.post(urls.getAlertReadStatus,payload,{headers: headers});
  
  }

    
  getMemberdata(name, date): Observable<any> {
    // const token: any = this.secureStorage.getSecureInfoInLocalStorage('clientCredentials_token');
    // const headers = new HttpHeaders({
    //   'Accept': 'application/json',
    //   'Authorization': 'Bearer ' + token
    // });
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});
    let myParams = new HttpParams()
    .append('datelimit', date);
    return this.http.get(urls.getmemberData, { headers: headers, params: myParams });
 }

 getMemberdatas(name): Observable<any> {
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});
let myParams = new HttpParams()
.append('userName', name);
  //myParams.append('datelimit', date);
  return this.http.get(urls.getMemberPCP, { headers: headers, params: myParams });
}

getNewMemberdatas(name): Observable<any> {
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});
let myParams = new HttpParams()
  // .append('userName', name);
  //myParams.append('datelimit', date);
  return this.http.get(urls.getNewMemberPCP, { headers: headers, params: myParams });
}

getNewMemberdatasChart(name): Observable<any> {
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});
let myParams = new HttpParams()
// .append('userName', name);
  //myParams.append('datelimit', date);
  return this.http.get(urls.getNewMemberPCPChart, { headers: headers, params: myParams });
}

//  getApimToken(userName,cred){
//   const body = new URLSearchParams('', this.queryEncoder);
//   body.set('grant_type', 'password');
//   body.set('username', userName);
//   body.set('password', cred);
//   const headers = new Headers();
//   headers.set('Content-Type', 'application/x-www-form-urlencoded');
//   headers.set('Authorization', this.props.cup_login_authorization);
//   return this.http.post(urls.apimUrl, body.toString(), { headers: headers }).map(
//       response => response.json());
//  }
listofepahafDoc(kvpl){
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + ppjwt.access_token
   });
         
         let myParams = new HttpParams({fromObject:{'appId': 'EP','documentCode': 'MDI_DOC'}});
         if ( kvpl !== null ) {
          myParams = new HttpParams({fromObject:{'appId': 'EP','documentCode': 'MDI_DOC','keyValuePairList': JSON.stringify( kvpl )}});
         }
         const options = { headers: headers, params: myParams } ;
        // const urlCall = ( docID === null ) ? urls.filenetURL : urls.filenetURL + '/' + docID;
         return this.http.get( urls.fileNetListUrl, options );
}
getDocument( docID, docCode, kvpl ): Observable<any> {
  
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({
    'Authorization': 'Bearer ' + ppjwt.access_token
});
let myParams = new HttpParams({fromObject:{'appId': 'EP','documentCode': docCode}});
  if ( kvpl !== null ) {
    myParams = new HttpParams({fromObject:{'appId': 'EP','documentCode': docCode,'keyValuePairList': JSON.stringify( kvpl ) }});
  }
  const options = { headers: headers, params: myParams } ;
  const urlCall = ( docID === null ) ? urls.filenetURL : urls.filenetURL + '/' + docID;
  return this.http.get( urlCall, options );
}
getUserCompleteStatus(id):Promise<any>{
  
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({
    'Accept': 'application/json' ,
    'Authorization': 'Bearer ' + ppjwt.access_token
});
let myParams = new HttpParams()
.append('memberId', id);
const payload = {
  'memberId' : id };
//.append('userName', name);
  //return this.http.get(urls.getEpahafStatus, { headers: headers, params: myParams,observe:'response' }).toPromise();
  return this.http.post(urls.getEpahafStatus, payload , { headers: headers,observe:'response' }).toPromise();

 }
 getProviderBasicDetails(): Observable<any> {
  // TODO: send the message _after_ fetching the heroes
  
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({
    'Accept': 'application/json' ,
    'Authorization': 'Bearer ' + ppjwt.access_token
});
  return this.http.post(urls.getSiteUsers,null, { headers: headers });
  // this.add('HeroService: fetched heroes');
  // return of(HEROES);
}

getEligibilityDetails(name) :Observable<any>{
  
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({
    'Accept': 'application/json' ,
    'Authorization': 'Bearer ' + ppjwt.access_token
});
let myParams = new HttpParams()
  // .append('userName', name);
  //myParams.append('datelimit', date);
  return this.http.get(urls.geteligibiREportDetails, { headers: headers, params: myParams });

}

setEpahafpdData(pdfData: any) {
  this.epahafPdfData.next(pdfData)
}

getpahafconfiginfo() :Observable<any>{
  
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({
    'Accept': 'application/json' ,
    'Authorization': 'Bearer ' + ppjwt.access_token
});
  return this.http.get(urls.getpahafconfiginfo, { headers: headers});
}

getMemberMeasureDetails(prv_no, provider_id): Observable<any> {
  
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({
    'Accept': 'application/json' ,
    'Authorization': 'Bearer ' + ppjwt.access_token
});
 const payload = { 
    'providerId': provider_id,
    'regionCode': prv_no
 };
  //myParams.append('datelimit', date);
  return this.http.post(urls.getMemberMeasureDetails,payload, { headers: headers }).pipe(
    map((res) => ({ sucess: true, prvno : prv_no, prvid: provider_id, result: res})),
    catchError(error => of({ sucess: false,prvno : prv_no, prvid: provider_id, result: {}}))
    );
  }
  
  setEpahafMemberData(data: any) {
    this.epahafMemberData.next(data)
  }
  getWorkFlowDocument( docID, docCode): Observable<any> {
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + ppjwt.access_token
     });
    let myParams = new HttpParams({fromObject:{'appId': 'PP','documentCode': docCode}});
    const options = { headers: headers, params: myParams } ;
    const urlCall = ( docID === null ) ? urls.filenetURL : urls.filenetURL + '/' + docID;
    return this.http.get( urlCall, options );
  }

  listDocuments(kvpl) {
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + ppjwt.access_token
    });
    const myParams = new HttpParams()
    .set( 'appId', 'PP' )
    .set( 'documentCode', 'GEN_REF_DOC')
    .set('keyValuePairList', JSON.stringify(kvpl))
    const options = { headers: headers, params: myParams };
    return this.http.get(urls.filenetListURL, options);
}

getListOfFileNetDocuments(kvpl,documentCode,appId) {
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + ppjwt.access_token
  });
  let payload={
    "documentCode":documentCode,
    "keyValuePairList": JSON.stringify(kvpl)
  }
 
  const options = { headers: headers};
  return this.http.post(urls.carePlanDocDownload,payload, options);
}
uploadFile(file,kvp): Observable<any> {
  const formData = new FormData();
  //formData.append('content', file,`training_${Date.now()}.pdf`);
  formData.append('content', file,);
  // let username = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser')).userName
  let username = this.cupService.getUserNameFromJWT();
  formData.append('document', JSON.stringify({
    createdUser : username,
    appId : 'EP',
    documentCode : 'MDI_DOC',
    txnLog: false,
    keyValuePairList : kvp
  }));
  //console.log("form data from service file :",formData);
  // const jwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const jwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const authHeader: string = 'Bearer ' + jwt.access_token;
  return Observable.create(observer => {
    const xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
        
          // const xml = xhr.response;
          // xml2js.parseString(xml, { explicitArray: false }, function (err, result) {
            observer.next(xhr.response);
          // });
          observer.complete();
        } else {
          observer.error(xhr.response);
        }
      }
    };
    xhr.upload.onprogress = (event) => {
      const progress = Math.round(event.loaded / event.total * 100);
      this.progressObserver.next(progress);
    };
    xhr.open('POST', urls.uploadEdsDoc, true);
    xhr.setRequestHeader('Authorization', authHeader);
    xhr.send(formData);
});
}

deleteuploadedDoc(docID,prvName) {
  // let username = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser')).userName
  let username=this.cupService.getUserNameFromJWT();
  let deleteDocUrl=urls.deleteUploadDocument;
  let payload={
    "docID":docID,
  }
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + ppjwt.access_token
  });

  const options = { headers: headers };
  //console.log("options in service file :",options)
  return this.http.post(deleteDocUrl,payload, options);
}

saveDocumentDetails(Docdetails){
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({
    'Accept': 'application/json' ,
    'Authorization': 'Bearer ' + ppjwt.access_token
  });
  const payload = Docdetails;
  return this.http.post(urls.saveDocuments,payload,{headers: headers});

}

deleteDocumentDetails(docID,prvName) {
  // let username = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser')).userName
  let username = this.cupService.getUserNameFromJWT();
  let saveDocUrl=urls.saveDocuments;
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + ppjwt.access_token
  });

  const options = { headers: headers };
  return this.http.delete(saveDocUrl, options);
}
readReceiptForCareplan(file,kvp,docCode,appId,docId): Observable<any> {
  const formData = new FormData();
  //formData.append('content', file,`training_${Date.now()}.pdf`);
  // formData.append('content', file,);
  // let username = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser')).userName
  let username = this.cupService.getUserNameFromJWT();
  formData.append('document', JSON.stringify({
    lastUpdatedUser : username,
    appId : appId,
    documentCode : docCode,
    txnLog: false,
    keyValuePairList : kvp
  }));
  //console.log("form data from service file :",formData);
  // const jwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const jwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const authHeader: string = 'Bearer ' + jwt.access_token;
  return Observable.create(observer => {
    const xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const xml = xhr.response;
          xml2js.parseString(xml, { explicitArray: false }, function (err, result) {
            observer.next(result);
          });
          observer.complete();
        } else {
          observer.error(xhr.response);
        }
      }
    };
    xhr.upload.onprogress = (event) => {
      const progress = Math.round(event.loaded / event.total * 100);
      this.progressObserver.next(progress);
    };
    xhr.open('PUT', `${urls.fileNetUrl}${docId}`, true);
    xhr.setRequestHeader('Authorization', authHeader);
    xhr.send(formData);
});
}

}
