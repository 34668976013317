import { Injectable } from '@angular/core';
import { FieldValidateService } from '../common_services/fieldvalidate.service';
import { urls, props, payloads} from '../common/model/properties';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SecureStorageService } from '../secure-storage.service';


@Injectable()
export class FrontdeskService {

  SKIP_NPI_VALIDATION = false;
  SKIP_TAXID_VALIDATION = false;
    encodedAddress1: any;
    encodedAddress2: any;

  constructor(  private http: HttpClient, private fieldValidator: FieldValidateService ,
    private secureStorage:SecureStorageService ) {  }


  validateNPI( npivalue, callBack: ( success: boolean, message: string ) => void ) {

    if ( npivalue === '' ) {
        callBack( true, '' );
        return;
    }
    if ( this.fieldValidator.validateNumeric( npivalue.toString(), 10 ) ) {
        if ( this.SKIP_NPI_VALIDATION ) {
            callBack( true, '' );
            return;
        }
        const headers = new HttpHeaders({
        });

        this.http.get( urls.altIdNews + npivalue, { headers: headers }).subscribe( (data:any) => {
            if ( data.validityFlag === 1 ) {
                callBack( true, '' );
            } else {
                callBack( false, 'Invalid NPI' );

            }
        }, ( err ) => {
            callBack( false, err );
        } );

    } else {
        callBack( false, 'The length of NPI should be 10 digits' );
    }
}

validateTaxId( taxId, callBack: ( sucess: boolean, message: string ) => void ) {

    if ( taxId === '' ) {
        callBack( true, '' );
        return;
    }
    if ( this.fieldValidator.validateNumeric( taxId, 9 ) ) {
        if ( this.SKIP_TAXID_VALIDATION ) {
            callBack( true, '' );
            return;
        }

        const headers = new HttpHeaders({
        });

        this.http.get( urls.altIdNews + taxId, { headers: headers }).subscribe( (data:any) => {
            if ( data.validityFlag == 1 ) {
                callBack( true, '' );
            } else {
                callBack( false, 'Invalid Tax ID' );
            }
        }, ( err ) => {
            callBack( false, err );
        } );
    } else {
        callBack( false, 'The length of TaxId should be 9 digits' );
    }
}
doUspsValidation( model, callBack: ( sucess: boolean, message ) => void ) {

     // usps address validation for #
//      let payLoad = payloads.uspsPayLoad;

//         if (model.address1.indexOf('#') != -1 || model.address2.indexOf('#') != -1) {
//             this.encodedAddress1 = model.address1.replace('#', '');
//             this.encodedAddress2 = model.address2.replace('#', '');
//             payLoad = payLoad.replace('#ADDRESS2#', this.encodedAddress1);
//             payLoad = payLoad.replace('#ADDRESS1#', this.encodedAddress2);
//         }else{
//             payLoad = payLoad.replace('#ADDRESS2#', model.address1);
//             payLoad = payLoad.replace('#ADDRESS1#', model.address2);
//         }
//   payLoad = payLoad.replace( '#CITY#', model.city );
//   payLoad = payLoad.replace( '#STATE#', model.state );
//   payLoad = payLoad.replace( '#ZIP#', model.zip );

let payload = {
    "address1" :  model.address1.replace('#', ''),
    "address2" : model.address2.replace('#', ''),
    "city" : model.city,
    "state" : model.state,
    "zip5" : model.zip
    }
  const headers = new HttpHeaders({
    // 'Content-Type': 'text/xml;charset=UTF-8',
    // 'Accept': 'application/xml'
  });
        
        this.http.post(urls.uspsValidateUrl , payload,{headers:headers,responseType:'text'})
      .subscribe(
      ( response ) => {
          const addressModel = this.parseUspsAdress( response );
          if ( addressModel ) {
              callBack( true, addressModel );
          } else {
              callBack( false, addressModel );
          }
      },
      err => {
          callBack( false, err );
      }
      );
}
parseUspsAdress( uspsResponse ) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString( uspsResponse, 'text/xml' );
  try {
      const addressModel = {};
      const addressElement = xmlDoc.getElementsByTagName( 'Address' )[0];
      if ( !addressElement.getElementsByTagName( 'Error' )[0] ) {
          addressModel['address1'] = this.extractValueFromNode( addressElement, 'Address2' );
          addressModel['address2'] = this.extractValueFromNode( addressElement, 'Address1' );
          addressModel['city'] = this.extractValueFromNode( addressElement, 'City' );
          addressModel['state'] = this.extractValueFromNode( addressElement, 'State' );
          addressModel['zip'] = this.extractValueFromNode( addressElement, 'Zip5' );
          return addressModel;
      }
  } catch ( e ) { }
}

extractValueFromNode( xmlDoc, key ) {
  let value = '';
  try {
      value = xmlDoc.getElementsByTagName( key )[0].textContent;
  } catch ( e ) { }
  return value;
}

addStaff( addStaffDataPayload, callBack: ( sucess: boolean, message: string ) => void ) {

 
//   const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' +  ppjwt.access_token
});

  this.http.post( urls.addAdminStaffDataUrl, addStaffDataPayload, { headers: headers ,responseType:'text'} )
  .subscribe(
          ( resp ) => {
              callBack( true, resp );
          },
          err => {
              callBack( false, err );
          }
  );
}

getRegistertationTypes(callback) {
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    // const headers = new HttpHeaders({
    //     'Content-Type': 'application/json' ,
    //     'Authorization': 'Bearer ' +  ppjwt.access_token
    // });
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});
    this.http.get(urls.getRegistertationTypes, { headers: headers }).subscribe(
        ( resp ) => {
         callback(resp);
        },
        err => {
        }
);
}

getStatesList() {
    

    const token: any = this.secureStorage.getSecureInfoInLocalStorage('clientCredentials_token');
    const headers = new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
    });
    let path = urls.getStates;
    return this.http.get(path, { headers: headers })
}
}
