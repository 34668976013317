import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js'
import { RsaService } from './common_services/encryptutil.service';

@Injectable()
export class SecureStorageService {
  exclusionList = ['elementHeight','memberDetails','memberSelected'];
  sessionStorageData =  {};
    constructor(private util:RsaService) { }
 
  setSecureInfoInLocalStorage(key, value) {
   
        localStorage.setItem(key, value)
      
  }
  setSecureInfoInSessionlStorage(key, value) {
    if(this.exclusionList.includes(key)){
     // this.sessionStorageData[key] = `${value}`
     sessionStorage.setItem(key, value);
      
    }else{
      sessionStorage.setItem(key, this.util.aesencryptForStore(value));
    }
     
  }
  getSecureInfoInLocalStorage(key) {
    let val = localStorage.getItem(key);
    
      return val;
   
  }
  getSecureInfoInSessionStorage(key) {
    if(this.exclusionList.includes(key)){
      // if(this.sessionStorageData.hasOwnProperty(key)){
      //   return this.sessionStorageData[key];
      // }
      // else{
      //   return null;
      // }
      return sessionStorage.getItem(key);
     }
     else{
      let data = sessionStorage.getItem(key);
      if(data){
        return this.util.aesdecryptForStore(data);
      }
      else{
        return data;
      }
     }
    
    }
    
  //   let val = sessionStorage.getItem(key)
  //  return val;
  
}

