import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { urls } from '../../common/model/properties';
import { SecureStorageService } from '../../secure-storage.service';

@Injectable()
export class PcpScorecardService {

  constructor(private http: HttpClient,
    private secureStorage:SecureStorageService) { }


  getPCPScoreCardDetails(id): Observable<any> {
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
          'Accept': 'application/json' ,
          'Authorization': 'Bearer ' + ppjwt.access_token
     });
    let myParams = new HttpParams()
    .append('prvId', id);
    //myParams.append('datelimit', date);
    return this.http.get(urls.getPCPScoreCard+'/'+id, { headers: headers });
  }
  

}
