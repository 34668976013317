import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'memberSearchFilter'
})
export class MemberSearchFilterPipe implements PipeTransform {

  transform(memberIdKeys: any[], searchInfo: { searchMemberText: string, qmDataGrpByMember: any }): any[] {
    if (!memberIdKeys) return [];
    if (!searchInfo || !searchInfo.searchMemberText) return memberIdKeys;

    let allMatchedRecords = [];
    if (searchInfo.qmDataGrpByMember && Array.isArray(searchInfo.qmDataGrpByMember)) {
      Object.keys(searchInfo.qmDataGrpByMember).forEach(memberId => {
        let member = searchInfo.qmDataGrpByMember[memberId];

        if (member && member.membername) {
          let isMatched = member.membername.toLowerCase().includes(searchInfo.searchMemberText.toLowerCase());
          if (isMatched) {
            allMatchedRecords.push(memberId);
          }
        }
      });
    }

    return allMatchedRecords;
    
  }

}
