import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SecureStorageService } from '../../secure-storage.service';
import { Observable, Subject, of } from 'rxjs';
import { urls } from '../../common/model/properties';
import { catchError } from 'rxjs/operators';
import * as xml2js from 'xml2js';
import { CupService } from 'src/app/shared/services/cup.service';

@Injectable()
export class PatientSurveyResultService {
  progressObserver = new Subject<any>();

  constructor(private http: HttpClient,private cupService: CupService,
    private secureStorage:SecureStorageService) { }


    getGroupAffiliationsDetails(siteId): Observable<any> {
      // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
      const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
      const headers = new HttpHeaders({
            'Accept': 'application/json' ,
            'Authorization': 'Bearer ' + ppjwt.access_token
       });
      let myParams = new HttpParams()
      .append('siteUid', siteId); 
      return this.http.get(urls.getProviderAffiliationsWRTsite+ siteId, { headers: headers });
    }

    getDropDownList(type): Observable<any> {
      // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
      const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
      const headers = new HttpHeaders({
            'Accept': 'application/json' ,
            'Authorization': 'Bearer ' + ppjwt.access_token
       });
      let myParams = new HttpParams()
      .append('dropDownType', type);       
      //console.log("type---:",urls.getDropDownList);
      return this.http.get(urls.getDropDownList, { headers: headers, params: myParams});
    }

    saveDetails(details): Observable<any> {
      // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
      const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
      const headers = new HttpHeaders({
        'Accept': 'application/json' ,
        'Authorization': 'Bearer ' + ppjwt.access_token
      });
      const payload = details;
      return this.http.post(urls.postPatientDetails,payload,{headers: headers}).
      pipe(catchError((err) => of(err)))
      //pipe(catchError((error) => {      
          //console.log(error);
          //return Observable.of(({ error: error }));
      //}));         
     
    }

    getPatientSurveyDetails(siteData) { 
      let dataitems = siteData.join(',');
      // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
      const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
      const headers = new HttpHeaders({
            'Accept': 'application/json' ,
            'Authorization': 'Bearer ' + ppjwt.access_token
       });
      let myParams = new HttpParams()
      //.append('providerName', ppjwt.userName);  
      .append('siteUids', dataitems);
      //let myParams = new HttpParams({fromObject:{'siteUids': siteData }});     
      //console.log("type---:",urls.getDropDownList);
      //return this.http.get(urls.getPatientDetails, { headers: headers, params: myParams});
      return this.http.get(urls.getPatientDetails, { headers: headers, params: myParams});

    }

    uploadFile(file,kvp): Observable<any> {
      const formData = new FormData();
      //formData.append('content', file,`training_${Date.now()}.pdf`);
      formData.append('content', file,);
      // let username = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser')).userName
      let username = this.cupService.getUserNameFromJWT();
      formData.append('document', JSON.stringify({
        createdUser : username,
        appId : 'PP',
        documentCode : 'SURVEY_DOCS',
        txnLog: false,
        keyValuePairList : kvp
      }));
      //console.log("form data from service file :",formData);
      // const jwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
      const jwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
      const authHeader: string = 'Bearer ' + jwt.access_token;
      return Observable.create(observer => {
        const xhr: XMLHttpRequest = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              // const xml = xhr.response;
              // xml2js.parseString(xml, { explicitArray: false }, function (err, result) {
                observer.next(xhr.response);
              // });
              observer.complete();
            } else {
              observer.error(xhr.response);
            }
          }
        };
        xhr.upload.onprogress = (event) => {
          const progress = Math.round(event.loaded / event.total * 100);
          this.progressObserver.next(progress);
        };
        xhr.open('POST', urls.uploadEdsDoc, true);
        xhr.setRequestHeader('Authorization', authHeader);  
        // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');    
        // xhr.setRequestHeader('Content-Type', "multipart/form-data");    
        xhr.send(formData);
    });
    }


    deleteuploadedDoc(docID) {
      let username = this.cupService.getUserNameFromJWT();
      let deleteDocUrl=urls.deletePEUploadDocument;
      let payload={
        "docID":docID,
      }
      const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
      const headers = new HttpHeaders({
          'Authorization': 'Bearer ' + ppjwt.access_token
      });
    
      const options = { headers: headers };
      //console.log("options in service file :",options)
      return this.http.post(deleteDocUrl,payload, options);
    }

    getDocument( docID, kvpl ): Observable<any> {
      // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
      const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
      const headers = new HttpHeaders({
            'Authorization': 'Bearer ' + ppjwt.access_token
       });
      let myParams = new HttpParams({fromObject:{'appId': 'PP','documentCode': 'SURVEY_DOCS'}});
      
      if ( kvpl !== null ) {
        myParams = new HttpParams({fromObject:{'appId': 'PP','documentCode': 'SURVEY_DOCS','keyValuePairList': JSON.stringify( kvpl ) }});
      }
      const options = { headers: headers, params: myParams } ;
      const urlCall = ( docID === null ) ? urls.filenetURL : urls.filenetURL + '/' + docID;
      return this.http.get( urlCall, options );
    }

}
