import {Injectable} from '@angular/core';
import { urls, props, payloads } from '../common/model/properties';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SecureStorageService } from '../secure-storage.service';

@Injectable()
export class BroadcastHistoryService {

  constructor(private http: HttpClient,
    private secureStorage:SecureStorageService) { }

  getBroadcastHistory( callBack: ( sucess: boolean, message: string ) => void ) {
   

    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' +  ppjwt.access_token
    });

    this.http.get( urls.getBroadcastHistory, { headers: headers } )
        .subscribe(
        ( resp:any ) => {
            callBack( true, resp );
        }
        );
}
}
