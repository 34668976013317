export enum ParentClaimsScreen { DirectMenu = 1, AuthSummary = 2, ModifyAuth = 3, PendingAuth = 4, DeniedAuth = 5, ConcurrentAuth = 6 }

export enum AuthTypes { ServiceRequest = 1, Inpatient = 2, Retro = 3, Outpatient = 4 }
export enum AuthTypeId { Retro = 1, Inpatient = 2, ServiceRequest = 3, Outpatient = 4 }
export enum ParentMessageScreen { Member = 1, Auth = 2, General = 3, Claims = 4, Claimworklist = 5 }

export enum languageId { English = 123, Spanish = 402 }
export enum authStatusDropDown { Approved = 1, Cancelled = 2, Denied = 3, Pended = 4 }
export enum ApiCallStatus { NotStarted = 0, Progress = 1, Completed = 2, Started = 3 }

export enum AuthRequestedBy { Provider = 1, Member = 2, AppointmentofRepresentative = 3, PowerOfAttorney = 4, Beneficiary = 5 }
export enum AuthCategory { Expedited = 1, Retro = 2, Standard = 3 }

export enum UserType { InternalUser = 2, Provider = 3 }

export enum LetterRecipientType { Member = 1, Member_Pcp = 2, Referred_From_Provider = 3, Referred_To_Provider = 4, Facility_Provider = 5 }

export enum CallNotificationStatus { Successful = 1, Unsuccessful = 2, GoodFaith = 3 }
export enum ScreenTypes { Search = 1, View = 2, searchPanel = 3 }

export enum ErrorType { FormSubmission_Error = 0, BusinessLogic_Error = 1, API_Error = 2 }

export enum Search_Panels {
    MemberSearch = 1,
    ProviderSearch = 2,
    GeneralAuthSearch = 3,
    MemberAuthSearch = 4,
    DiagnosisSearch = 5,
    ProcedureSearch = 6,
    ModifierSearch = 7,
    UserAccessPermissionSearch = 8,
    deniedWorklistSearch = 9
}
