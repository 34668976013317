import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: "memberFilterArray"
})
export class MemberFilterArrayPipe implements PipeTransform {
    transform(list, splitTo?): any {
        let soryByStatus = list.sort((a, b) => {
            if (a.status == "MET" && b.status == "NOT MET") {
                return 0;
            }
            return -1;
        });

        let soryByNotMet = (<any[]>soryByStatus).filter(x => x.status == "NOT MET").sort((a, b) => {
            if (a.membername > b.membername) {
                return 0;
            }
            return -1;
        });

        let soryByMet = (<any[]>soryByStatus).filter(x => x.status == "MET").sort((a, b) => {
            if (a.membername > b.membername) {
                return 0;
            }
            return -1;
        });

        return [].concat(soryByNotMet, soryByMet);
    }
}