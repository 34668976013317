import { Component, OnInit, OnDestroy, Input, OnChanges, EventEmitter, Output, SimpleChanges, PipeTransform, Pipe, ViewChild, AfterViewInit} from '@angular/core';
import { HeaderService } from '../header/header.service';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { ProviderportalService } from '../providerportal/providerportal.service';
import * as _ from 'lodash';
import { UserIdleService } from 'angular-user-idle';
import { CupService } from '../shared/services/cup.service';
import { GlobalService } from '../shared/services/global.service';
import { urls, payloads } from '../common/model/properties';
import { Observable, fromEvent, interval, timer } from 'rxjs';
import { CupDateService } from '../shared/services/cup-date.service';
import { AlertServiceService } from '../shared/services/alert-service.service';
import { isArray } from 'util';
import { SecureStorageService } from '../secure-storage.service';
import { debounceTime, take, tap } from 'rxjs/operators';
import { saveAs } from "file-saver";
import { AccountProfileService } from '../accountProfile/accountProfile.service';


declare var jQuery: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    //providers: [HeaderService],
})
export class HeaderComponent implements OnInit, OnDestroy,  OnChanges , AfterViewInit {
    isHeaderDisabled = false;
    @Output() public found = new EventEmitter<any>();
    isFinalActionSubscription: any;
    isCupAppOpened: boolean = false;
    ONE_MIN_IN_MILLI_SECS = 60000;
    tokenRefreshingTimeMins = 2;
    tokenRefreshDeltaTimeMins = 6;
    refreshTokenPP: any;
    refreshTokenCUP: any;
    refreshTokenCUP_Error_Retry: any;
    maxApiRetryAttempts = 3;
    results: Object;
    @Input('inboxUnReadMsgCount')inboxUnReadMsg:any;
    clickOnBell: boolean;
    userName: any;
    cardData= [];
    unReadAlertCount:any;
    saveAlertId: any;
    indicationFlag: boolean;
    p: number = 1;
    p1: number = 1;
    p2: number = 1;
    countSubscription: any;
    unReadCountwithAppId: any;
    noCardFoundforHigh: boolean;
    noCardFoundforMed: boolean;
    noCardFoundforlow: boolean;
    noDatamidFoundFlag: any;
    noDatalowFoundFlag: any;
    noDataHighFoundFlag: any;
    constructor(
        private alertService: AlertServiceService,
        private accountProfileService: AccountProfileService,
        private globalSrv: GlobalService,
        private userIdle: UserIdleService,
        private cupService: CupService,
        public currentService: HeaderService,
        public providerportalService: ProviderportalService,
        public router: Router,
        private cupDateService : CupDateService,
        public secureStorage:SecureStorageService) {
        this.subscribeForCupApplicationLogOut();
        this.found.emit(this.results); 
        this.unReadAlertCount = this.secureStorage.getSecureInfoInSessionStorage('count');
        this.unReadCountwithAppId = this.secureStorage.getSecureInfoInSessionStorage('appCount');
    }
    headerInfo: any = '';
    count: any;
    userJson: any = '';
    errorMessage: any = '';
    showSection = 'tabSection';
    tokenModel = {};
    isLoggedIn = true;
    fullName: any;
    closeModalRef: any;
    counterBack;
    counterTemp = 61;
    selectedTab = 0;
    inboxUnReadMsgCount = '0';
    userProfile: any;
    userAgreementdocuments: [any];
    userPrivilegesdocuments: [any];
    documentCode = 'USER_AGREEMENT_DOC';
    loading = false;
    collapse = false;
    token_updated_already = "Token Updated Already";
    isExternalUser:boolean;
    searchQuery: any;
    subscription: any;
    eachTabFlag: boolean;
    specificTabFlag: any;
    noDataFoundFlag: any;
    unreadHighCount = 0;
    unreadMedCount = 0;
    unreadLowCount = 0;
    allUnreadAlertCount: any;
    @ViewChild('searchQueryInput') searchQueryInput;
    @ViewChild('searchQueryInputMob') searchQueryInputMob;
    user$:any;
    
  addItem(newItem: any) {
    this.selectedTab = newItem.index;
    ////console.log("input from tab", newItem);
  }
  addMarkAsRead(item: any) {
    //console.log("readCardId", item);
  }
  setFlag(flags: any) {
    this.eachTabFlag = flags.someFlag;
    this.specificTabFlag = flags.everyFlag;
    this.noDataFoundFlag = flags.noCardFound;
    //console.log("this.eachTabFlag", flags);
  }
      
    restartUserIdle() {
        this.userIdle.stopWatching();
        this.userIdle.startWatching();
    }

    logOut() {
        this.providerportalService.setTokenClearTimeOut(false);
        this.userIdle.stopWatching();
        jQuery('#logOutdialog').modal('hide');
        this.secureStorage.setSecureInfoInLocalStorage('finalActionPerformed', "true");
        this.router.navigateByUrl('login');
    }
    subscribeForCupApplicationLogOut() {
        if (this.isFinalActionSubscription) {
            this.isFinalActionSubscription.unsubscribe();
        }
        this.isFinalActionSubscription = interval(1000).subscribe(() => {
            if (this.secureStorage.getSecureInfoInLocalStorage('jwt')) {
                this.isCupAppOpened = true;
            }
            let finalActionPerformed = this.secureStorage.getSecureInfoInLocalStorage('finalActionPerformed');
            if (this.isCupAppOpened && !this.secureStorage.getSecureInfoInLocalStorage('jwt') && !finalActionPerformed) {
                this.logOut();
            }
        });
    }
    openNav() {
        document.getElementById("mySidenav").style.width = "80%";
    }
    closeNav() {
        document.getElementById("mySidenav").style.width = "0";
    }
    ngAfterViewInit(): void {
      const keyup$ =  fromEvent(this.searchQueryInput.nativeElement, 'keyup');
      keyup$
      .pipe(tap((e)=>console.log(e)),
      debounceTime(500))
      .subscribe((event:any) => {
        this.searchKeyword(event.target.value);
      });

      const keyup2$ =  fromEvent(this.searchQueryInputMob.nativeElement, 'keyup');
      keyup2$
      .pipe(tap((e)=>console.log(e)),
      debounceTime(500))
      .subscribe((event:any) => {
        this.searchKeyword(event.target.value);
      });
    }
    async ngOnInit() {
        // this.userName = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'))['userName'];
        this.userName = this.cupService.getUserNameFromJWT();
        localStorage.removeItem('jwt_token_request_init');
        // this.userJson = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        this.userJson = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        this.userProfile = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('userProfile'));
        try{
           this.isExternalUser = true;
            
            if(this.userProfile['userClass'] == "I"){
                this.isExternalUser = false;
            }
        }
        catch(e){

        }
       
        this.fullName = this.secureStorage.getSecureInfoInSessionStorage('fullName');
        if(this.userProfile == null){
            let loginresp3 = await this.accountProfileService.getUserProfile2(
                ""
              );
              let resp3 = loginresp3.user[0];
                this.userProfile = resp3;
                try{
                    this.fullName =resp3['firstName'] + ' ' + resp3['middleName'] + ' ' + resp3['lastName']
                    this.secureStorage.setSecureInfoInSessionlStorage('fullName', this.fullName);

                }
                catch(e){

                }
                this.isExternalUser = true;
                if(this.userProfile['userClass'] == "I"){
                    this.isExternalUser = false;
                }
        }
        this.userIdle.startWatching();
        try {
            this.alertService.setInterValCAllingForAlert();
        } catch (error) {
            console.log(error);
        }
        this.userIdle.onTimerStart().subscribe(count => {
            this.count = count;
            this.counterBack = this.counterTemp - this.count;
            if (count === 1) {
                jQuery('#logOutdialog').modal('show');
            }
        });
        this.closeModalRef = this.providerportalService.getCloseModal().subscribe(data => {
            jQuery('#logOutdialog').modal('hide');
        });
        this.userIdle.onTimeout().subscribe(() => {
            let finalActionPerformed = this.secureStorage.getSecureInfoInLocalStorage('finalActionPerformed');
            if (finalActionPerformed) { // User session will be retained when CUP 'finalActionPerformed' identified
                this.restartUserIdle();
                //this.subscribeForCupApplicationLogOut();
            } else {
                this.logOut();
            }

            /* const now = Date.now() - 60000;
            const diff = finalActionPerformed - now;
   

            if (diff < 0) {

                this.providerportalService.setTokenClearTimeOut(false);
                this.userIdle.stopWatching();
                jQuery('#logOutdialog').modal('hide');
                this.router.navigateByUrl('login');
            } else {

                this.userIdle.stopWatching();
                this.userIdle.startWatching();
            } */
        });

        
            this.initGeneralUnreadMessageCount();
            //this.initunReadAlertCount();
            this.listUserAgreementDocuments();
            this.listUserPrivilegesDocuments();
            this.subscribeForProviderTokenRefresh();

            this.currentService.hideMenuSubject.subscribe(data=>{            
                this.isHeaderDisabled = data;            
            })

           
       
        this.allUnreadAlertCount = this.alertService.allCount;
        this.unreadHighCount = this.alertService.unreadHighCountwithoutId;
        this.unreadMedCount = this.alertService.unreadMedCountwithoutId;
        this.unreadLowCount = this.alertService.unreadLowCountwithoutId;
        this.subscription = this.alertService.message.subscribe(
            (message) => {
              let incomingAlerts = _.cloneDeep(message);
            if(incomingAlerts && isArray(incomingAlerts) && incomingAlerts.length){
             let newAlerts =  _.differenceBy(incomingAlerts, this.cardData, 'AlertId');
             newAlerts.forEach((temp) => {
               if (temp.IsUnRead === "1") {
                temp.newelyAdded = true
               }
              
            } );
            if (newAlerts && newAlerts.length) {
              setTimeout(() => {
                this.cardData = [...newAlerts, ...this.cardData ];
                if( this.cardData &&  this.cardData.length >0 ){
                this.noDataFoundFlag = false;
                let filteredhighCardData = this.cardData.filter(
                    (x) => x.Severity.toLowerCase() === "high"
                  );
                 this.noDataHighFoundFlag= _.isEmpty(filteredhighCardData);
                let filteredMidCardData = this.cardData.filter(
                    (x) => x.Severity.toLowerCase() === "medium"
                  );
                 this.noDatamidFoundFlag= _.isEmpty(filteredMidCardData);
                 let filteredLowCardData = this.cardData.filter(
                    (x) => x.Severity.toLowerCase() === "low"
                  );
                 this.noDatalowFoundFlag= _.isEmpty(filteredLowCardData);
                }
              }, 10);
            }
            
            //console.log(isUnReadValue);
          }
            }
          );
        this.countSubscription = this.alertService.allUnreadserviceAlertCount.subscribe((e:any)=>{
            this.unReadAlertCount = e.mainCount;
            this.unReadCountwithAppId  = e.allCountwithSev;
            this.unreadHighCount = e.highcountwithoutId;
            this.unreadMedCount = e.medcountwithoutId;
            this.unreadLowCount = e.lowCountwithoutId;
            // console.log('unReadAlertCount from header',this.unReadAlertCount);
            // console.log('unReadCountwithAppId from header',this.unReadCountwithAppId);
        });
       

    }

    ngOnChanges(changes: SimpleChanges){
        //console.log('inboxUnReadMsgHeaderComponent',this.inboxUnReadMsg);
        this.inboxUnReadMsgCount = this.inboxUnReadMsg;
    }

    logoutAction() {
        this.alertService.clearData();
        // if(this.alertService.message){
        // this.alertService.message.unsubscribe();
        // }
        sessionStorage.clear();

        const accessToken = this.secureStorage.getSecureInfoInLocalStorage('clientCredentials_token');
        localStorage.clear();
        this.secureStorage.setSecureInfoInLocalStorage('clientCredentials_token', accessToken);

        if (this.refreshTokenCUP_Error_Retry) {
            this.refreshTokenCUP_Error_Retry.unsubscribe();
        }
        this.providerportalService.setTokenClearTimeOut(true);
        this.router.navigateByUrl('login');
    }
    Countinue() {
        this.userIdle.stopTimer();
        jQuery('#logOutdialog').modal('hide');
    }
    ngOnDestroy() {
        if (this.isFinalActionSubscription) {
            this.isFinalActionSubscription.unsubscribe();
        }
       
        if (this.countSubscription) {
            this.countSubscription.unsubscribe();
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.closeModalRef) {
            this.closeModalRef.unsubscribe();
            jQuery('#logOutdialog').modal('hide');
        }
        if(this.user$){
            this.user$.unsubscribe();
        }
    }

    public initGeneralUnreadMessageCount() {
        const url = urls.unreadMessageConut.concat('?entity=' + '' + '&entityId=');
        this.unreadMessagesCount(url);
        interval(600000)
            .subscribe(() => {
                this.unreadMessagesCount(url);
            });
        this.globalSrv.unreadCount.subscribe((nextValue: string) => {
            // this.inboxUnReadMsgCount = nextValue;
        });
    }

    private unreadMessagesCount(url) {
        this.cupService.retrieveData('get', url).subscribe(data => {
            if (data) {
                this.inboxUnReadMsgCount = data;
            } else {
                this.inboxUnReadMsgCount = '0';
            }
        });
    }


    listUserAgreementDocuments() {
        const kvpl = [
            { 'key': 'FunctionType', 'value': 'ProviderPortal-UserAgreement' }, { 'key': 'UserType', 'value': 'All' }
        ];
        this.currentService.listDocuments(this.documentCode, kvpl, 1, 100).subscribe(res => {
            this.userAgreementdocuments = res.keyValuePairListWrapperlist;
        });
    }

    listUserPrivilegesDocuments() {
        const kvpl = [
            { 'key': 'FunctionType', 'value': 'ProviderPortal-UserPrivileges' }, { 'key': 'UserType', 'value': 'All' }
        ];
        this.currentService.listDocuments(this.documentCode, kvpl, 1, 100).subscribe(res => {
            this.userPrivilegesdocuments = res.keyValuePairListWrapperlist;
        });
    }

    getvalue(value, key) {
        for (let i = 0; i < value.keyvaluePairWr.length; i++) {
            if (value.keyvaluePairWr[i].key === key) {
                return value.keyvaluePairWr[i].value;
            }
        }
    }
    getTodayDate() {
        return new Date();
    }

    getDocument(doc) {
        const docId = this.getvalue(doc, 'Id');
        const docClass = this.getvalue(doc, 'DocumentClass');
        this.loading = true;
        this.currentService.getDocument(docId, docClass, null).subscribe(res => {
            const responseData = res;
            if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                const blob = this.binary64ToBlob(responseData.docContent, responseData.fileType, 512);
                saveAs(blob, responseData.filename);
                this.loading = false;
            } else {
                const blob = this.b64toBlob(responseData.docContent, responseData.fileType, 512);
                const urlBlob = URL.createObjectURL(blob);
                window.open(urlBlob, '_blank', 'location=yes,height=570,width=520');
                this.loading = false;
            }
        });
    }

    binary64ToBlob(binary64String, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        const byteCharacters = atob(binary64String);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    formatDate(date) {
        return date ? date.substring(0, date.length - 3) : '';
    }
    

    subscribeForProviderTokenRefresh() {
        this.refreshTokenPP = interval(this.tokenRefreshingTimeMins * this.ONE_MIN_IN_MILLI_SECS)
            .subscribe((f) => {
                const current_date = new Date();
                // const jwt_expire_date = new Date(JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser')).jwt_expire_date);
                const jwt_expire_date = new Date(JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser')).jwt_expire_date);
                if (jwt_expire_date < current_date) {
                    this.providerportalService.providerRefreshToken().subscribe((res:any) => {
                        const jwtToken: any = res;
                        const jwt_expire_date = this.getCurrentExpiryDateInMilliSeconds(jwtToken.expires_in);

                        // this.secureStorage.setSecureInfoInSessionlStorage('currentUser', JSON.stringify({ userName: this.userJson.userName, access_token: res.access_token, refresh_token: res.refresh_token, jwt_expire_date: jwt_expire_date }));
                        this.secureStorage.setSecureInfoInLocalStorage('currentUser', JSON.stringify({ access_token: res.access_token, refresh_token: res.refresh_token, jwt_expire_date: jwt_expire_date }));
                    }, err => {
                        this.refreshTokenPP.unsubscribe();
                        jQuery('#tokenExpiryModal').modal('show');
                    });
                }
            });
    }

    getCurrentExpiryDateInMilliSeconds(jwt_expires_in: any): any {
        const currentTimeInMilliSecs = new Date().getTime();
        const expiresInMilliSecs = jwt_expires_in * 1000;
        const jwt_expires_from_now = Number(currentTimeInMilliSecs + expiresInMilliSecs);
        const jwt_expire_date = new Date(jwt_expires_from_now - this.tokenRefreshDeltaTimeMins * this.ONE_MIN_IN_MILLI_SECS);
        //console.log("jwt_expire_date", jwt_expire_date);
        return jwt_expire_date;
    }
      subscribeForCupTokenRefresh() {
        const jwtToken = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('jwt'));
        if (jwtToken) {
            this.refreshTokenCUP = interval(this.tokenRefreshingTimeMins * this.ONE_MIN_IN_MILLI_SECS)
                .subscribe((f) => {
                    const current_date = new Date();
                    const jwt_expire_date = new Date(this.secureStorage.getSecureInfoInLocalStorage('jwt_expire_date'));
                    if (jwt_expire_date < current_date) {
                        this.providerportalService.cupLoginJWTRefreshToken().subscribe((res:any) => {
                            const jwtToken = res;
                            const jwt_expire_date = this.getCurrentExpiryDateInMilliSeconds(jwtToken.expires_in);
                            /* this.secureStorage.setSecureInfoInSessionlStorage('jwt_expire_date', jwt_expire_date);
                            this.secureStorage.setSecureInfoInSessionlStorage('jwt', JSON.stringify(res));
                            this.secureStorage.setSecureInfoInSessionlStorage('jwt_expires_in', String(new Date().getTime() + (res.expires_in * 1000))); */

                            this.secureStorage.setSecureInfoInLocalStorage('jwt_expire_date', jwt_expire_date);
                            this.secureStorage.setSecureInfoInLocalStorage('jwt', JSON.stringify(res));
                            this.secureStorage.setSecureInfoInLocalStorage('jwt_expires_in', String(new Date().getTime() + (res.expires_in * 1000)));
                        });
                    }
                }, err => {
                    this.refreshTokenCUP_Error_Retry = timer(0, 1000).pipe(take(this.maxApiRetryAttempts)).subscribe(data => {
                        let tryCount = data + 1;
                        let tokenMatchFound_While_Compare_Using_LocalStorage = false;
                        let currentJwt = this.secureStorage.getSecureInfoInLocalStorage("jwt") ? JSON.parse(this.secureStorage.getSecureInfoInLocalStorage("jwt")) : '';
                        if (jwtToken && jwtToken.access_token && currentJwt && currentJwt.access_token) {
                            const tokenUsedForRefresh = JSON.stringify(jwtToken.access_token);
                            const tokenInCurrentStorage = JSON.stringify(currentJwt.access_token);
                            if (tokenUsedForRefresh == tokenInCurrentStorage) {
                                tokenMatchFound_While_Compare_Using_LocalStorage = true;
                            }
                        }
                        let sessionExpired =  tokenMatchFound_While_Compare_Using_LocalStorage;
                        if (tryCount == this.maxApiRetryAttempts || !sessionExpired) {
                            if (this.refreshTokenCUP) {
                                this.refreshTokenCUP.unsubscribe();
                            }

                            if (this.refreshTokenCUP_Error_Retry) {
                                this.refreshTokenCUP_Error_Retry.unsubscribe();
                            }

                            if(sessionExpired) {
                                jQuery('#tokenExpiryModal').modal('show'); 
                            }
                        }
                    });
                });
        }
    }
   searchKeyword(searchQuery) : any {
        this.found.emit(searchQuery); 
    }
    public AlertAction(){
        this.clickOnBell = true;
        this.getAllAlerts();
        setTimeout(() => {
            jQuery('#Alertdialog').modal('show');
        },100);
      
    }

    getAllAlerts() {
        this.cardData = this.alertService.allCardData;
        if( this.cardData &&  this.cardData.length >0 ){
            this.noDataFoundFlag = false;
            let filteredhighCardData = this.cardData.filter(
                (x) => x.Severity.toLowerCase() === "high"
              );
             this.noDataHighFoundFlag= _.isEmpty(filteredhighCardData);
            let filteredMidCardData = this.cardData.filter(
                (x) => x.Severity.toLowerCase() === "medium"
              );
             this.noDatamidFoundFlag= _.isEmpty(filteredMidCardData);
             let filteredLowCardData = this.cardData.filter(
                (x) => x.Severity.toLowerCase() === "low"
              );
             this.noDatalowFoundFlag= _.isEmpty(filteredLowCardData);
            } else {
                this.noDataFoundFlag = true;  
            }
    }

    getStatusAlert(alertMetaData) {
        
        this.alertService.getReadStatus(alertMetaData.AlertId).subscribe((response):any=>{
          let indexToDel;
          if(response){
            this.alertService.getAllCounts();
            let CloneOfMainCardArray = this.alertService.allCardData;
            CloneOfMainCardArray.forEach((alert, index) => {
              if (alert.AlertId === alertMetaData.AlertId) {
                alert.IsUnRead = '0';
               
                if (alert.open) {
                    alert.open = false;
                  } else {
                    alert["open"] = true;
                  }
                  if(!alert.isCardOpened) {
                    alert["isCardOpened"] = true;
                  } 
                  
                  indexToDel = index;
              }
            });
            
          //  setTimeout(() => {
            this.cardData = CloneOfMainCardArray;
            this.alertService.setCardData(CloneOfMainCardArray);
              this.alertService.emmitCardDataChange(CloneOfMainCardArray);
             
            // For del of alert// this.cardData = CloneOfMainCardArray.splice(indexToDel, 1);
         //   }, 10);
          }
        })
        
      }

}