<div id="main-wrapper">
	<app-header (found)="handleResults($event)"></app-header>
	<div class="col-md-1 col-lg-1"></div>
	<div class="col-md-10 col-lg-10 col-sm-12">
		<div class="inboxSubsection">
			<hr>
			<div class="row">
				<app-search-results [resultGridList]="data"></app-search-results>
				<div class="col-sm-3 col-md-2 label1">
					<br> <a (click)="compose=true;" title="Compose" class="btn btn-compose compose"><i class="glyphicon glyphicon-edit"></i> Compose </a>
					<hr>
					<ul class="nav nav-pills nav-stacked">
						<li style="cursor: pointer" [ngClass]="{'active':active==='inbox'}"><a [ngClass]="{'badgeBackgroud':active==='inbox'}" (click)="compose=false;active='inbox';showBody=false;"><span
								class="badge pull-right">32</span> Inbox </a></li>
						<li style="cursor: pointer" [ngClass]="{'active':active==='sent'}"><a [ngClass]="{'badgeBackgroud':active==='sent'}" (click)="compose=false;active='sent';showBody=false;">Sent Mail</a></li>
						<li><a href="#"><span class="badge pull-right">3</span>Trash</a></li>
					</ul>
					<br> <br>
				</div>
				<div class="col-sm-9 col-md-10 col-xs-12 inboxSection">
					<div *ngIf="compose==false" class="fullbadge">
						<div class="row">
							<div class="col-md-6 text-left">
								<span *ngIf="active==='inbox'" class="headername">Inbox </span> <span *ngIf="active==='sent'" class="headername">Sent </span>
							</div>
							<div class="col-md-6 text-right">
								<nav aria-label="Page navigation example">
									<ul class="pagination" style="margin: 0px;" *ngIf="showBody==false;">
										<li class="page-item"><a class="page-link" href="#">Previous</a></li>
										<li class="page-item"><a class="page-link" href="#">Next</a></li>
									</ul>
								</nav>
							</div>
						</div>
						<hr>
						<div class="row">
							<div class="col-md-12" *ngIf="active==='inbox'">
								<div class="tab-content" *ngIf="showBody==false">
									<div class="tab-pane fade in active" id="home">
										<div class="list-group">
											<a *ngFor="let item of inboxData" class="list-group-item">
												<div  class="row">
													<div class="col-md-3 text-left">
														<div class="row">
															<div class="col-md-2">
																<input type="checkbox"/>
															</div>
															<div (click)="showBody=true;activeItem=item;" class="col-md-10 text-left">
																<b>{{item.toUsers[0].recipientName}}</b>
															</div>
														</div>
													</div>
													<div (click)="showBody=true;activeItem=item;" class="col-md-2 text-left view">{{item.subject}}</div>
													<div (click)="showBody=true;activeItem=item;" class="col-md-2 text-left">
														<div class="view">{{item.messageBody}}</div>
													</div>
													<div (click)="showBody=true;activeItem=item;" class="col-md-5 text-right">
														<span class="badge">{{item.receivedDate | date}}</span>
													</div>
												</div>
											</a>
										</div>
									</div>
								</div>
								<div class="tab-content" style="height: 300px" *ngIf="showBody==true">
									<div class="row">
										<div class="col-md-6 text-left">
											<button (click)="showBody=false;" type="button" class="btn btn-link">Back</button>
										</div>
										<div class="col-md-6 text-right">
										<span class="pull-right badge"> {{activeItem?.receivedDate | date}} </span>
											<button (click)="showBody=false;" type="button" class="btn btn-link"> <i class="fa fa-reply"></i></button>
											<button (click)="showBody=false;" type="button" class="btn btn-link"> <i class="fa fa-reply-all"></i></button>
											<button (click)="showBody=false;" type="button" class="btn btn-link"> <i class="fa fa-mail-forward"></i></button>
											
										</div>
										
									</div>
									<br>
									<div class="row">
									<div class="col-md-12">
									<div class="col-md-12 text-left">
										<span class="subjectheader"> {{activeItem?.senderUserId}}: {{activeItem?.subject}} </span> 
										</div>
										<!-- <div class="col-md-3 text-right">
										<span class="pull-right badge"> {{activeItem?.receivedDate | date}} </span>
										</div> --> 
									</div>
									</div>
									<br>
									<div class="row">
										<div class="col-md-12 openview">{{activeItem?.messageBody}}</div>
									</div>
								</div>
							</div>
							<div class="col-md-12" *ngIf="active==='sent'">
								<div class="tab-content" *ngIf="showBody==false">
									<div class="tab-pane fade in active" id="home">
										<div class="list-group">
											<a (click)="showBody=true;activeItem=item;" *ngFor="let item of sentData" class="list-group-item"> <span class="glyphicon glyphicon-star-empty"></span> &nbsp; <span class="name">{{item.toUsers[0].recipientName}}</span>
												<span class="sub"> {{item.subject}}</span> <span class="text-muted view">{{item.messageBody}}</span> <span class="badge">{{item.sentDate|date}}</span> <span class="pull-right"><span
													class="glyphicon glyphicon-paperclip"> </span></span>
											</a>
										</div>
									</div>
								</div>
								<div class="tab-content" style="height: 300px" *ngIf="showBody==true">
									<div class="row">
										<div class="col-md-12 text-left">
											<button (click)="showBody=false;" type="button" class="btn btn-link">Back</button>
										</div>
									</div>
									<div class="row">
										<div class="col-md-12 openview">{{activeItem?.messageBody}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="compose==true" class="fullbadge">
						<form role="form" class="form-horizontal">
							<div class="form-group">
								<label class="col-lg-2 control-label composetext">To</label>
								<div class="col-lg-10">
									<input data-toggle="modal" data-target="#exampleModal" autocomplete="off" type="text" placeholder="" id="inputEmail1" class="form-control composetext">
								</div>
							</div>
							<div class="form-group">
								<label class="col-lg-2 control-label composetext">Cc / Bcc</label>
								<div class="col-lg-10">
									<input type="text" placeholder="" id="cc" class="form-control composetext">
								</div>
							</div>
							<div class="form-group">
								<label class="col-lg-2 control-label composetext">Subject</label>
								<div class="col-lg-10">
									<input type="text" placeholder="" id="inputPassword1" class="form-control composetext">
								</div>
							</div>
							<div class="form-group">
								<label class="col-lg-2 control-label composetext">Message</label>
								<div class="col-lg-10">
									<textarea rows="10" cols="30" class="form-control composetext" id="" name=""></textarea>
								</div>
							</div>
							<div class="form-group">
								<div class="col-lg-offset-2 col-lg-10">
									<span class="btn green fileinput-button"> <i class="fa fa-plus fa fa-white"></i> <span>Attachment</span> <input type="file" name="files[]" multiple="">
									</span>
									<button class="btn btn-send" type="submit">Send</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="modal fade" id="exampleModal" role="dialog">
				<div class="modal-dialog modal-xl" style="margin-top: 300px;">
					<div class="modal-content">
						<div class="modal-header" style="border-bottom: 0px;">
							<div class="modal-body text-center">
								<p>hello</p>
								<br>
								<button type="button" class="contact100-form-btn center-block" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-md-1 col-lg-1"></div>
	<app-footer></app-footer>
</div>