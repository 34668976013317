import {
  Component,
  OnInit,
  Input,
  ViewChild,
  EventEmitter,
  Output, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewChecked, NgZone, AfterViewInit
} from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CupService } from '../services/cup.service';
import { CupDateService } from '../services/cup-date.service';
import { SecureStorageService } from '../../secure-storage.service';
import { LazyLoadEvent } from 'primeng/api/public_api';

@Component({
  selector: 'cup-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dt') dataTable: any;
  @Input() gridRows: any;
  @Input() gridColumns: any;
  @Input() currentPath: string;
  @Input() showViewHeader: boolean;
  @Input() showEditHeader: boolean;
  @Input() showViewEditHeader: boolean;
  @Input() showSupplementalHeader: boolean;
  @Input() showMemberActive: boolean;
  @Input() showMapViewHeader: boolean;
  @Input() gridTitle: string;
  @Input() masterProviderGrid: boolean;
  @Input() totalRecords: number;
  public _pageFromParent: number;
  @Input()
  set pageFromParent(value) {
    this._pageFromParent = value;
    this.totalPagesFromChild = value;
  }
  get pageFromParent() {

    return this._pageFromParent;
  }
  @Input() sortOrder : number = -1;
  @Input() dataKey: string;
  @Input() isLazyLoad: boolean;
  @Input() rowClicked: boolean;
  @Input() deniedReasonSearch: boolean;
  @Input() isViewClaims: boolean;
  @Input() showProvider: boolean;
  @Input() showProviderMap: boolean;
  @Input() isAddViewNote: boolean;
  @Input() showNote: boolean;
  @Input() isViewEligibilityHist: boolean;
  @Input() authorizationAttachments: boolean;
  @Input() hideHeaderTab: boolean;
  @Input() hideTabAlone: boolean;
  @Input() generalViewAuth: boolean;
  @Input() isAuthLetterGrid: boolean;
  @Input() rowPerPage = 10;
  @Input() rowPerPageList: any = [10, 20, 30];
  @Input() addAuthNote: boolean;
  @Input() pendingAuthorizations: boolean;
  @Input() showAgingRedColor: boolean;
  @Input() supplementalSectionConfig: boolean;
  @Input() authStatusConfig: boolean;
  @Input() viewAuthLock: boolean;
  @Input() viewTemplateConfig: boolean;
  @Input() supplementalDataConfig: boolean;
  @Input() supplementalDataListConfig: boolean;
  @Input() internalExternalUserAccess: boolean;
  @Input() isAttachmentGrid: boolean;
  @Input() isAttachmentWithServerSidePagniation: boolean;
  @Input() isDeleteColumnPresent: boolean;
  @Input() memberType: string;
  @Input() denyEditMemberNotesAccess: boolean;
  @Input() viewAccessTemplate: boolean;
  @Input() viewUserAccess: boolean;
  @Input() isMemberAttachments: boolean;
  @Input() showAttachmentDelete = true;
  @Input() providerAccessConfig: boolean;
  @Input() providerAccessExternalGrid: boolean;
  @Input() showCheckbox: boolean;
  @Input() showEditableCheckbox: boolean;
  @Input() considerRowClickForCheckBox = true;
  @Input() headerText: any;
  @Input() showPrintIcon: boolean;
  @Input() headerTextPrintIcon: any;
  @Input() headerTextEditableCheckbox: any = '';
  @Input() letterWorkList: boolean;
  @Input() adhocLetter: boolean;
  @Input() protocolGrid: boolean;
  @Input() isAuthAuditGrid: boolean;
  @Input() isPendingWorklist: boolean;
  @Input() authMessageScreen: boolean;
  @Input() totalAuth: any;
  @Input() rowGroupMode: string = undefined;
  @Input() rowGroupField: string = undefined;
  @Input() expandableRowGroups = false;
  @Input() isLetterErrorList = false;
  @Input() viewDeniedAuth = false;
  @Input() claimDetailsFooterData: any;
  @Input() viewDenialReason: any;
  @Input() nwstatus: boolean;
  @Input() gridStatusText: string;

  @Output() mapOutput = new EventEmitter<boolean>();
  @Output() eventOutput = new EventEmitter<boolean>();
  @Output() eventinternalExternalUserEditOutput = new EventEmitter<any>();
  @Output() eventDenialReasonSearch = new EventEmitter<any>();
  @Output() eventEditOutput = new EventEmitter<boolean>();
  @Output() printOutput = new EventEmitter<boolean>();
  @Output() supplementDeleteClicked = new EventEmitter<boolean>();
  @Output() authStatusDeleteClicked = new EventEmitter<boolean>();
  @Output() downloadAttachmentClicked = new EventEmitter<boolean>();
  @Output() previewAttachmentClicked = new EventEmitter<boolean>();
  @Output() deleteAttachmentClicked = new EventEmitter<boolean>();
  @Output() deleteRecordClicked = new EventEmitter<any>();
  @Output() eventAddMemberNotesOutput = new EventEmitter<boolean>();
  @Output() onPageChanged = new EventEmitter<any>();
  @Output() onMemberTypeFilter = new EventEmitter<any>();
  @Output() authRecordSelected = new EventEmitter<any>();
  @Output() onCheckBoxClicked = new EventEmitter<any>();
  @Output() selectAllCheckbox = new EventEmitter<any>();
  @Output() onRowClicked = new EventEmitter<any>();
  @Output() replyClicked = new EventEmitter<any>();
  @Output() removeLockClicked = new EventEmitter<any>();
  @Output() siteViewClicked = new EventEmitter<any>();
  @Input() selectionType: string = 'single';

  public spinnerSubscription: any;
  public loading: boolean = true;
  selectedUser: any;
  totalPages = 1;
  totalPagesFromChild = 1;
  public pageNumber = 1;
  public showView = true;

  // public rowPerPage = 10;
  public termedMemberForm: FormGroup;
  public memberTypesList = {};
  public showEdit = false;
  public totalBilled: number;
  public totalCoPay: number;
  public totalPayAmount: number;
  private isMapClicked = false;
  public currentDate = new Date(Date.now());
  sortField:any;
  spinnerClass:any;
  constructor(private router: Router, private cupService: CupService, private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    public cupDateService: CupDateService,
    private secureStorage:SecureStorageService) {
    this.showTermedMembers();
    this.loadingSpinner();
  }
// 
  ngAfterViewInit() {
    this.loading = false;
  }
  /*Show termed members*/
  private showTermedMembers() {
    this.memberTypesList = [
      { id: 0, value: 'All' }, { id: 1, value: 'Active Members' }, { id: 2, value: 'Termed Members' }
    ];
    this.termedMemberForm = this.fb.group({
      memberTypeFormControl: ['']
    });
    this.termedMemberForm.controls.memberTypeFormControl.patchValue({ id: 1, value: 'Active Members' });
  }
  onRowExpand(event:any){}
  onRowCollapse(event:any){}
  onSort(event:any){}
  onFilterTable(event:any){}
  
  /*Lazy load*/
  loadGridRecords_Lazy(event: LazyLoadEvent) {
    const pageIndex = event.first / event.rows + 1;
    const pageNo = pageIndex === 0 ? 1 : pageIndex;
    const obj = {
      page: pageNo,
      event: event
    };

    // if (this.totalRecords != 0)
    let pages = Math.ceil(this.totalRecords / event.rows);
    if (pages !== this.pageFromParent) {
      pages = this.pageFromParent;
    }

    this.totalPages = pages;
    this.onPageChanged.emit(obj);
  }

  memberTypeChange(event) {
    this.onMemberTypeFilter.emit(event);

  }
  /* Set Specific Page */
  public setCurrentPage(page: number) {
    this.dataTable.reset();
    const paging = {
      first: ((page - 1) * this.dataTable.rows),
      rows: this.dataTable.rows
    };
    this.dataTable.onPageChange(paging);
    this.dataTable.scrollHeight = '500px';
  }

  /* Display the current page in the input box */
  public paginate(event) {
    const pageIndex = event.first / event.rows + 1;
    this.pageNumber = pageIndex === 0 ? 1 : pageIndex;
    this.pageCount();
    if (event.rows !== 10) {
      this.dataTable.scrollHeight = '500px';
    } else {
      this.dataTable.scrollHeight = 'none';
    }
  }

  private updateGridScrollHeight(rowsPerPage: number) {
    if (rowsPerPage > 10) {
      this.dataTable.scrollHeight = '500px';
    } else {
      this.dataTable.scrollHeight = 'none';
    }
  }

  public resetGridData() {
    if (this.isLazyLoad && this.dataTable) {
      this.dataTable.sortField = null;
      this.dataTable.sortOrder = 1;
      this.dataTable.filteredValue = null;
      this.dataTable.filters = {};
      this.dataTable._first = 0;
      this.dataTable.updateTotalRecords();
      this.pageNumber = 1;

      if (this.rowPerPageList && this.rowPerPageList.length > 0) {
        this.dataTable.rows = this.rowPerPageList[0];
        this.updateGridScrollHeight(this.dataTable.rows);
      }

    }
  }

  /*Perform pagination on giving page number*/
  private onInputPaginate(page: number) {
    page = page ? page : 1;
    page = page === 0 ? 1 : page;
    this.setCurrentPage(page);
    if (page !== 1) {
      this.dataTable.scrollHeight = '500px';
    } else {
      this.dataTable.scrollHeight = 'none';
    }
  }
  /*Perform Navigation */
  public onRowUnselect(event) {
    console.log('Deselect', event.data);
    this.selectedUser = []
     this.selectedUser.push(event.data);  
     const selectedRecord = Object.assign({}, this.selectedUser[0]);
     selectedRecord.checked = !selectedRecord.checked;
    this.onCheckBoxClicked.emit(selectedRecord);
  }
  public performNavigation(event) {
    //this.selectedUser.memberAuthSearch = false;
    this.selectedUser = []
    this.selectedUser.push(event.data);  
    let selectedUser = this.selectedUser[0];
    console.log('performNavigation', event.data);
    const targetElement = event.originalEvent.target;
    const isCheckRoxInRowClicked = this.girdRowClickForCheckBox(targetElement);
    if (this.rowClicked && !isCheckRoxInRowClicked) { // Only Row click // Not For Check box click
      this.onRowClicked.emit(event.data);
    }

    if (this.isMapClicked) {
      this.isMapClicked = false;
      return;
    }
    if (this.addAuthNote) {
      return;
    }
    if ((this.currentPath === '/home/member/member-search' && !this.showProvider)
      || ((this.currentPath ? (this.currentPath.includes('/home/member/member-search') && !this.showProvider) :
        (this.currentPath === '/home/member/member-search' && !this.showProvider)))) { // Member Search && overal member search
      this.setTraversalDataInSession('member');
      this.setMemberDataInSession();
      this.router.navigate(['/home/member/member-details/', selectedUser.id], { skipLocationChange: true });
      this.cupService.getSelectedMember(selectedUser);
    } else if ((this.currentPath === '/home/provider/provider-search') ||
      (this.currentPath ? this.currentPath.includes('/home/provider/provider-search/true') :
        this.currentPath === '/home/provider/provider-search')) { // Provider Search
      this.setTraversalDataInSession('provider');
      this.setProviderDataInSession();
      this.router.navigate(['/home/provider/provider-details/', selectedUser.id], { skipLocationChange: true });
      this.cupService.getSelectedProvider(selectedUser);
    } else if ((this.currentPath === '/home/general/view-authorization' ||
      (this.currentPath && this.currentPath.indexOf('/home/general/search-authorization') !== -1)) ||
      (this.currentPath ? this.currentPath.includes('/home/general/search-authorization/over-all') :
        this.currentPath === '/home/general/search-authorization')) {
      // General Auth summary
      this.setTraversalDataInSession('authsummary');
      this.setAuthDataInSession();
      this.eventOutput.emit(selectedUser);
      this.authRecordSelected.emit(selectedUser);
    } else if (this.currentPath === '/home/member/authorization/viewmemberauth' ||
      this.currentPath &&
      this.currentPath.indexOf('/home/member/authorization/searchmemberauth') !== -1) {
      this.setTraversalDataInSession('authsummary');
      this.setAuthDataInSession();
      selectedUser.memberAuthSearch = true;
      this.eventOutput.emit(selectedUser);
      this.authRecordSelected.emit(selectedUser);
    } else if (this.currentPath === '/home/member/authorization/service/member-search') { // authorization Service Request
      this.setTraversalDataInSession('member');
      this.setMemberDataInSession();
      this.router.navigate(['/home/member/authorization/service/'], { skipLocationChange: true });
    } else if (this.currentPath === '/home/member/authorization/outpatient/member-search') { // authorization outpatient
      this.setTraversalDataInSession('member');
      this.setMemberDataInSession();
      this.router.navigate(['/home/member/authorization/outpatient/'], { skipLocationChange: true });
    } else if (this.currentPath === '/home/member/authorization/retro/member-search') { // authorization retro
      this.setTraversalDataInSession('member');
      this.setMemberDataInSession();
      this.router.navigate(['/home/member/authorization/retro/'], { skipLocationChange: true });
    } else if (this.currentPath === '/home/member/authorization/inpatient/member-search') {// authorization inpatient
      this.setTraversalDataInSession('member');
      this.setMemberDataInSession();
      this.router.navigate(['/home/member/authorization/inpatient/'], { skipLocationChange: true });
    } else if (this.currentPath &&
      this.currentPath.indexOf('/home/worklist/search-pendingworklist') !== -1) {// Pending Authorizations Grid From SearchWorklist
      this.setQueueDataInSession();
      this.eventOutput.emit(selectedUser);
      //  this.router.navigate(['/home/worklist/pending-authlist'],{ queryParamsHandling: 'preserve',skipLocationChange: true  });
    } else if (this.currentPath === '/home/worklist/view-pendingworklist') {// Pending Authorizations Grid From View Worklist
      this.setQueueDataInSession();
      this.router.navigate(['/home/worklist/pending-authlist'], { skipLocationChange: true });
    } else if (this.currentPath === '/home/worklist/view-casemanagementworklist') {
      this.setQueueDataInSession();
      this.router.navigate(['/home/worklist/casemanagement-authlist'], { skipLocationChange: true });
    } else if (this.currentPath && this.currentPath.indexOf('/home/worklist/pending-authlist') !== -1) {// Pending Auth Summary
      const pendingAuthlocked = this.checkWhetherAuthIsLocked(event);
      if (!pendingAuthlocked) {
        this.router.navigate([
          '/home/worklist/pending-authsummary/',
          selectedUser.authid,
          selectedUser.authtype], { queryParamsHandling: 'preserve', skipLocationChange: true });
      }
    } else if (this.currentPath &&
      this.currentPath.indexOf('/home/worklist/casemanagement-authlist') !== -1) {// Casemanagement Auth Summary
      this.router.navigate([
        '/home/worklist/casemanagement-authsummary/',
        selectedUser.authNumber,
        'Inpatient / Surgery'], { queryParamsHandling: 'preserve', skipLocationChange: true });
      // const urlDetails = { 'url': '/home/member/auth-summary', 'authid': this.selectedUser.authNumber, 'authType': 'Inpatient / Surgery' };
      // this.secureStorage.setSecureInfoInSessionlStorage('prviousURL', JSON.stringify(urlDetails));
    } else if (this.currentPath === '/home/worklist/denied-worklist') {
      this.setTraversalDataInSession('denied');
      this.setAuthDataInSession();
      const deniedAuthlocked = this.checkWhetherAuthIsLocked(event);
      if (!deniedAuthlocked) {
        this.router.navigate([
          '/home/worklist/deniedauth-details/',
          selectedUser.authType,
          selectedUser.authUid,
          selectedUser.aging], { skipLocationChange: true });
      }
    } else if (this.internalExternalUserAccess && !this.deniedReasonSearch) {
      this.eventinternalExternalUserEditOutput.emit(selectedUser);
    } else if (this.internalExternalUserAccess && this.deniedReasonSearch) {
      this.eventDenialReasonSearch.emit(selectedUser);
    } else {
      this.cupService.getSelectedProvider(selectedUser);
      this.eventOutput.emit(selectedUser);
    }
    if (this.showEditableCheckbox) {
          const selectedRecord = Object.assign({}, selectedUser);
          selectedRecord.checked = !selectedRecord.checked;
          this.onCheckBoxClicked.emit(selectedRecord);
    }
  }
  public girdRowClickForCheckBox(targetElement) {
    let isCheckRoxInRowClicked = true;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      isCheckRoxInRowClicked = (this.considerRowClickForCheckBox === false &&
        (targetElement.id &&
          (<string>targetElement.id).indexOf('label_checkbox_new') !== -1) ? true : false);
    } else {
      isCheckRoxInRowClicked = (this.considerRowClickForCheckBox === false && (targetElement.control !== undefined));
    }
    return isCheckRoxInRowClicked;
  }

  public setMemberDataInSession() {
    let selectedUser = this.selectedUser[0]
    this.secureStorage.setSecureInfoInSessionlStorage('memberDetails', JSON.stringify(selectedUser));
  }

  public setProviderDataInSession() {
    let selectedUser = this.selectedUser[0]
    this.secureStorage.setSecureInfoInSessionlStorage('providerDetails', JSON.stringify(selectedUser));
  }

  private setAuthDataInSession() {
    // this.secureStorage.setSecureInfoInSessionlStorage('authSummary', JSON.stringify(this.selectedUser));
  }

  public setQueueDataInSession() {
    let selectedUser = this.selectedUser[0]
    this.secureStorage.setSecureInfoInSessionlStorage('queueDetails', JSON.stringify(selectedUser));
  }

  public setTraversalDataInSession(screen) {
    const requiredRowDetails = [];
    for (const value of this.gridRows) {
      if (screen === 'member') {
        requiredRowDetails.push({
          rowNum: value.rowNum,
          id: value.id,
          memberId: value.memberId,
          planDesc: value.planDesc,
          planEffDate: value.planEffDate,
          planTermDate: value.planTermDate
        });
      } else if (screen === 'provider') {
        requiredRowDetails.push({ rowNum: value.rowNum, id: value.id, providerId: value.id });
      } else if (screen === 'authsummary') {
        const authType = value.authtype === 'Service Request' ? 'PROC'
          : value.authtype === 'Inpatient / Surgery' ? 'PRECRT'
            : value.authtype === 'CM Outpatient/Ancillary' ? 'REFER'
              : value.authtype === 'Retro' ? 'EMERG' : 'PROC';
        requiredRowDetails.push({ rowNum: value.rowNum, id: value.id, authid: value.authid, authtype: authType });
      } else if (screen === 'denied') {
        const authType = value.authtype === 'Service Request' ? 'PROC'
          : value.authtype === 'Inpatient / Surgery' ? 'PRECRT'
            : value.authtype === 'CM Outpatient/Ancillary' ? 'REFER'
              : value.authtype === 'Retro' ? 'EMERG' : 'PROC';
        requiredRowDetails.push({
          rowNum: value.rowNum,
          id: value.authUid,
          authid: value.authNumber,
          authtype: authType,
          aging: value.aging
        });
      }
    }
    // if (screen === 'member') {
    //   this.secureStorage.setSecureInfoInSessionlStorage('memberTraversal', JSON.stringify(requiredRowDetails));
    // } else if (screen === 'provider') {
    //   this.secureStorage.setSecureInfoInSessionlStorage('providerTraversal', JSON.stringify(requiredRowDetails));
    // } else if (screen === 'authsummary') {
    //   this.secureStorage.setSecureInfoInSessionlStorage('authTraversal', JSON.stringify(requiredRowDetails));
    // } else if (screen === 'denied') {
    //   this.secureStorage.setSecureInfoInSessionlStorage('authDenied', JSON.stringify(requiredRowDetails));
    // }

  }

  public reDirectAuthSummary(data) {
    const queryParamObj = { queryParams: { source: 'letterworklist' }, skipLocationChange: true };
    if (data.authType) {
      const authType = data.authtype === 'Service Request' ? 'PROC'
        : data.authtype === 'Inpatient / Surgery' ? 'PRECRT'
          : data.authtype === 'CM Outpatient/Ancillary' ? 'REFER'
            : data.authtype === 'Retro' ? 'EMERG' : 'PROC';
      this.router.navigate(['/home/member/auth-summary/', data.authUid, authType], queryParamObj);
    }
  }

  public rowStyleClass(gridRow) {
    let finalClass = "";
    if (!gridRow.isRead) {
      finalClass = 'unread ';
    }
     if (gridRow.isImportant) {
      finalClass = finalClass +  " " +'urgent';
    }  if (gridRow.isReadReceipt) {
      finalClass = finalClass + " " + 'receipt colourChange';
    }  if (gridRow.readRecipientUsers) {
      finalClass = finalClass + " " + 'noClass';
    } 
    return  finalClass;
  }

  /*Page Count */
  public pageCount() {
    if (this.gridRows) {
      this.dataTable.rows = this.dataTable.rows ? this.dataTable.rows : (this.rowPerPage ? this.rowPerPage : 10);
      this.totalPagesFromChild = Math.ceil(this.gridRows.length / this.dataTable.rows);
      this.calculateTotal();
    }
  }
  public viewClicked(gridRow) {
    this.eventOutput.emit(gridRow);
  }
  public replybtnClicked(gridRow, checkReply) {
    gridRow.checkReplyorReplyAll = checkReply;
    this.replyClicked.emit(gridRow);
    event.stopPropagation();
  }
  public mapViewClicked(gridRow) {
    this.isMapClicked = true;

    const selectedProviderAddress = gridRow.address + ' ' + gridRow.city;
    this.router.navigate(['/home/provider/provider-details/map', selectedProviderAddress]);
    // this.mapOutput.emit(gridRow);
  }
  public editClicked(gridRow, index) {
    gridRow.index = index;
    this.eventEditOutput.emit(gridRow);
  }
  /* Edit data list cliked for supplemental data list*/
  public editClickedDataList(gridRow, index) {
    gridRow.index = index;
    this.eventEditOutput.emit(gridRow);
  }
  /* Edit data list cliked for supplemental data list*/
  public printClickedDataList(gridRow) {
    this.printOutput.emit(gridRow);
  }
  /*On click of delete icon in supplemental section config*/
  public deleteClicked(gridRow, index) {
    if (this.supplementalDataListConfig) {
      gridRow.index = index;
    }
    this.supplementDeleteClicked.emit(gridRow);
    this.authStatusDeleteClicked.emit(gridRow);
  }
  /*On click of download in attachment*/
  public downloadAttachment(gridRow) {
    this.downloadAttachmentClicked.emit(gridRow);
  }

  /*On click of download in attachment*/
  public previewAttachment(gridRow) {
    this.previewAttachmentClicked.emit(gridRow);
  }

  /*On click of download in attachment*/
  public deleteAttachment(gridRow) {
    this.deleteAttachmentClicked.emit(gridRow);
  }

  public deleteRecord(gridRow, index) {
    if (this.isLazyLoad) {
      this.deleteRecordClicked.emit(gridRow);
    } else {
      this.deleteRecordClicked.emit(index);
    }
  }
  public addMemberNotesClicked() {
    this.eventAddMemberNotesOutput.emit();
  }

  public removeAuthLockClicked(gridRow) {
    this.removeLockClicked.emit(gridRow);
  }

  public providerSiteClick(gridRow) {
    this.siteViewClicked.emit(gridRow);
  }
  /*Calaculte the total count for view claims*/
  public calculateTotal() {
    this.totalBilled = 0.0;
    this.totalCoPay = 0.0;
    this.totalPayAmount = 0.0;
    if (this.isViewClaims) {
      for (let i = 0; i < this.gridRows.length; i++) {
        this.totalBilled += (this.gridRows[i].billed);
        this.totalCoPay += (this.gridRows[i].copay);
        this.totalPayAmount += (this.gridRows[i].payAmount);
      }
    }
  }

  /*Loading Spinner*/
  public loadingSpinner() {
    this.spinnerSubscription = this.cupService.loadSpinner.subscribe(data => {
      this.loading = data;
      setTimeout(x => {
        this.cdr.markForCheck();
      });
    });
  }

  public genericCheckBoxStatusChanged($event, gridRow, column) {
    gridRow[column.field] = $event.target.checked;
    if (column.checkboxStatusChange) {
      column.checkboxStatusChange(gridRow);
    }
  }
  /* Use this method when parent screen is having two grid in single page */
  public isDataLoading(dataLoading: boolean) {
    this.cupService.isShowSpinner(false); // To stop page whole page loading spinner
    this.loading = dataLoading;
    setTimeout(x => {
      this.cdr.markForCheck();
    });
  }

  /*Check auth lock before navigation*/
  private checkWhetherAuthIsLocked(event) {
    const user = this.cupService.getUserInfoFromJWT();
    if (user && user.id !== event.data.lockedUserUid && event.data.isLocked) {
      return true;
    }
  }

  /*Track by for ngfor*/
  public trackBy(index, value) {
    return index;
  }


  ngOnInit() {
    this.pageCount();
  }

  ngOnDestroy() {
    this.spinnerSubscription.unsubscribe();
    this.cdr.detach();
  }
  onTableHeaderCheckboxToggle(event: any) {
    if (event.checked === true) {
      for (let m of this.gridRows) {
        this.gridRows.map(i => i.checked = true);      
      }
      this.selectAllCheckbox.emit(this.gridRows);
    } else {
      for (let m of this.gridRows) {
        this.gridRows.map(i => i.checked = false);      
      }
      this.selectAllCheckbox.emit([]);
    }
  }
}
