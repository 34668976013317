import { Injectable } from '@angular/core';
import { HttpHeaders, HttpRequest, HttpClient, HttpParams } from '@angular/common/http';

import { urls, props, payloads } from '../common/model/properties';


import { SecureStorageService } from '../secure-storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ForgotpasswordquestionsService {
     usernameFromUrl: any = new BehaviorSubject("");
     
    setUserNameFromUrl(userName: any) {
        this.usernameFromUrl.next(userName);
    }

     private forgotUser: any = '';
     constructor(private http: HttpClient,
      private secureStorage:SecureStorageService) {

     }

     updateUserForQuestions(userName) {
          this.forgotUser = userName;
     }
     getSecurityQuestions(userName) {
          const headers = new HttpHeaders({
          });
          const payload = { userName: userName };
          return this.http.post(urls.getSecurityQuestionsUrl,payload, {headers: headers})
               
     }
     validateQuestion(questionResponse) {
          const validateReqModel = payloads.validateReqModel;
          validateReqModel.key = questionResponse.key;
          const answers = [];
          for (let index = 0; index < questionResponse.questions.length; index++) {
               const questionObj = questionResponse.questions[index];
               const answer = {
                    'question-set-id': questionObj['question-set-id'],
                    'answer': questionObj.answer
               };
               answers.push(answer);
          }
          validateReqModel.answers = answers;
          const token: any = this.secureStorage.getSecureInfoInLocalStorage('clientCredentials_token');
          const headers = new HttpHeaders({
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + token
            });
            
          return this.http.post(urls.validateSecurityQuestionsUrl, validateReqModel, { headers: headers });
     }
     getNews(callBack: (sucess: boolean, message: string) => void) {
        const headers = new HttpHeaders({
            'Accept': 'application/json',
          });
          this.http.get(urls.getNews, { headers: headers })
               .subscribe(
                    (resp:any) => {
                         callBack(true, resp);
                    }
               );
     }
}
