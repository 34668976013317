export class StringConstands {
public static General = 'general';
public static Member = 'member';
public static Auth = 'auth';
public static Claim = 'claim';
public static Standard = 'standard';
public static Expedited = 'expedited';

public static NotAllow_Create_OralLog = 'Cannot add oral logs as call status is \'Successful\'/\'GoodFaith\' ';
public static ServiceRequestKey = 'PROC';
public static InpatientKey = 'PRECRT';
public static RetroKey = 'EMERG';
public static OutPatientKey = 'REFER';

public static ErrorOccured = 'ERROR OCCURRED!!!';
public static ServerDown = 'server is down please try after some time';
public static InvalidURL = 'method is not found';
public static AccessDenied = 'access denied';

public static API_ERROR_MSG = 'Unable to retrieve data. Please either go to help section or contact IT help desk at (866) 755-2680. During after-hours ( Monday-Friday 5 p.m. - 5 a.m. (PST) and Weekend ), please reach at (888) 268-4368.';
public static BUSINESSLOGIC_ERROR_MSG = 'Error in processing business logic. Please either go to help section or contact IT help desk at (866) 755-2680. During after-hours ( Monday-Friday 5 p.m. - 5 a.m. (PST) and Weekend ), please reach at (888) 268-4368.';
public static SUBMIT_ERROR_MSG = 'Error in submitting the data. Please use help to contact the IT helpdesk.';

public static API_ERROR_MSG_WITHOUT_HELP = 'Unable to retrieve data.';
public static BUSINESSLOGIC_ERROR_MSG_WITHOUT_HELP = 'Error in processing business logic.';
public static SUBMIT_ERROR_WITHOUT_HELP = 'Error in submitting the data.';
public static ERROR_HELP_MESSAGE = 'Please either go to help section or contact IT help desk at (866) 755-2680. During after-hours ( Monday-Friday 5 p.m. - 5 a.m. (PST) and Weekend ), please reach at (888) 268-4368.';
public static Auth_Letter = 'AUTH LETTER';
public static No_Result_Found = 'No Records Found ';
public static Wait_For_Result = 'Please wait until the data is retrieved ';

public static PROVIDER_CANT_FIND = 'PROVIDER CAN\'T FIND';
public static UNABLE_TO_DETERMINE_NW_STATUS = 'Unable to determine';
}
