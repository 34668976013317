import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { LoadingModule } from "ngx-loading";
import { NgxPaginationModule } from 'ngx-pagination';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { MenubarModule } from 'primeng/menubar';
import { ProgressBarModule } from 'primeng/progressbar';

// import { DialogModule, DropdownModule, ProgressBarModule, FileUploadModule, MenubarModule, TooltipModule, DataTableModule } from 'primeng/primeng';
import { TooltipModule } from 'primeng/tooltip';
import { CommonComponent } from '../common_component/common.component';
import { customDateFilerPipe } from '../customDateFilter';
import { MemberFilterArrayPipe } from '../filterArray';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { Input1Component } from '../inputComponent/input1.component';
import { MemberSearchFilterPipe } from '../member-search-filter.pipe';
import { SearchResultsComponent } from '../search-results/search-results.component';
import { AttachmentComponent } from './attachment/attachment.component';
import { AutoCompleteComponent } from './autocomplete/autocomplete.component';
import { CardsComponent } from './cards/cards.component';
import { SortDirective } from './directive/sort.directive';
import { GridComponent } from './grid/grid.component';
import { SearchPipe } from "./pipes//searchFilter.pipe";
import { customDateTimeFormatPipe } from './pipes/customdatetimeformat.pipe';
import { customFormatPipe } from './pipes/customformat.pipe';
import { AutoCompletePipe } from './pipes/dropdownoptions.pipe';
import { FilterArrayPipe, NUmberPipe } from './pipes/number.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { CupDateService } from './services/cup-date.service';
import { PreferencesService } from './services/preferences.service';
import { CupService } from './services/cup.service';
import { UrlconstantsService } from './services/urlconstants.service';
import { TabSelectionPaneComponent } from './tab-selection-pane/tab-selection-pane.component';
import { TraversalComponent } from './traversal/traversal.component';
import { TableModule } from 'primeng/table';
import { TieredMenuModule } from 'primeng/tieredmenu';
import {  MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TableModule,
    DialogModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    ProgressBarModule,
    MenubarModule,
    AngularMultiSelectModule,
    TypeaheadModule,
    LoadingModule,
    NgxPaginationModule,
    MatTableModule,
    MatCheckboxModule,
    TieredMenuModule,
    MatTooltipModule,
    MaterialModule
  ],
  declarations: [
    AutoCompleteComponent,
    GridComponent,
    AttachmentComponent,
    AutoCompletePipe,
    customDateTimeFormatPipe,
    customFormatPipe,
    NUmberPipe,
    FilterArrayPipe,
    MemberFilterArrayPipe,   
    TraversalComponent,
    SortPipe,
    Input1Component,
    FooterComponent,
    HeaderComponent,
    CommonComponent,
    MemberSearchFilterPipe,
    customDateFilerPipe,
    SearchResultsComponent,
    SearchPipe,
    SortDirective,
    CardsComponent,
    TabSelectionPaneComponent
  ],
  exports: [
    AutoCompleteComponent,
    GridComponent,
    AttachmentComponent,
    AutoCompletePipe,
    customFormatPipe,
    customDateTimeFormatPipe,
    DialogModule,
    NUmberPipe,
    FilterArrayPipe,
    MemberFilterArrayPipe,
    TraversalComponent,
    SortPipe,
    Input1Component,
    FooterComponent,
    TooltipModule,
    HeaderComponent,
    CommonComponent,
    AngularMultiSelectModule,
    MemberSearchFilterPipe,
    customDateFilerPipe,
    SearchResultsComponent,
    SearchPipe,
    SortDirective,
    CardsComponent,
    TabSelectionPaneComponent,
    MaterialModule
  ]
})
export class SharedModules {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModules,
      providers: [CupService, UrlconstantsService, DatePipe, CupDateService, PreferencesService]
    };
  }
}
