import { Injectable } from '@angular/core';
import { urls, props, payloads } from '../common/model/properties';

import { HttpHeaders, HttpClient } from '@angular/common/http';
import { SecureStorageService } from '../secure-storage.service';

@Injectable()
export class ChangePasswordService {

    constructor( private http: HttpClient,
        private secureStorage:SecureStorageService ) { }

        submitPassword( oldCred, cred , callBack: ( sucess: boolean, response: any ) => void ) {
      
            const payload = {
                "oldPassword": oldCred,
                "newPassword": cred
            }

       
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});

        this.http.post( urls.changePasswordUrl, payload, { headers: headers} )
            .subscribe(
            ( resp ) => {
                callBack( true, resp );
            },
            err => {
                callBack( false, err );
            }
            );
    }

 updateUerPasswordChangeTime( changePasswordTimePayload, callBack: ( sucess: boolean, message: string ) => void ) {

       
            // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
            const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
            const headers = new HttpHeaders({
                'Accept': 'application/json',
                'Authorization': 'Bearer ' +  ppjwt.access_token
            });

        this.http.put( urls.updateDataUrl, changePasswordTimePayload, { headers: headers } )
        .subscribe(
                ( resp:any ) => {
                    callBack( true, resp);
                }
        );
    }
}
