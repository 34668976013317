import { Component, OnInit,OnDestroy, AfterViewInit } from '@angular/core';
import { Input1Model } from '../inputComponent/input1.model';
import { FrontdeskService } from './frontdesk.service';
import { CONFIG } from '../../config/index';
import { Router } from '@angular/router';
import { FieldValidateService } from '../common_services/fieldvalidate.service';

import { SignupFormService } from '../signup-form/signup-form.service';


import { Subject,Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { SecureStorageService } from '../secure-storage.service';
import { ProviderportalService } from '../providerportal/providerportal.service';
import {UserProfileModel} from '../providerportal/providersearch_model/providersearch.model'
import { AccountProfileService } from '../accountProfile/accountProfile.service';
import { CupService } from '../shared/services/cup.service';

declare var jQuery: any;

@Component({
  selector: 'app-frontdesk',
  templateUrl: './frontdesk.component.html',
  styleUrls: ['./frontdesk.component.scss'],
  providers: [FrontdeskService]
})
export class FrontdeskComponent implements OnInit {
  
  userProfileData: any;
  private userDataSubscription: Subscription;

  stateCodes:Array<any> =[];
  model: UserProfileModel;
  inputComps: Map<string, Input1Model>;

  uspsAddressModel: any;
  userName: any;
  firstNameValue: any = '';
  isFiledValid = {};
  errorMessage: string;
  loading = false;
  registerationTypeList = [];

  zipDataSource: Observable<any>;
  cityDataSource: Observable<any>;
  data = [];
  handleResults(searchObj) {
      this.data = searchObj;
  }

  constructor(private frontdeskService: FrontdeskService, private router: Router, private cupService: CupService,
    private fieldValidateService:FieldValidateService,
        private registerService: SignupFormService,
        private accountProfileService: AccountProfileService,
        private providerportalService:ProviderportalService,
        private secureStorage:SecureStorageService) { }
  

  ngOnInit() {
    this.accountProfileService.getUserProfile(this.userName, (success: boolean, data) => {
      if (success) {

        this.model={
          address1: data['addressLine1'],
          address2: data['addressLine2'],
          city: data['city'],
          zip: data['zip'],
          state: data['state'],
          phone: data['phone'],
          extn: data['extn'],
          fax: data['fax'],
          npi: data['npi'],
          taxId: data['taxId'],
          email: null,
          firstName: '',
          middleName: '',
          lastName: '',
          reg_type: ''
        };
  
        this.constructInputs(this.model);
      }});
    
    
    // this.userName = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'))[
    //   'userName'
    // ];
    this.userName = this.cupService.getUserNameFromJWT();
    this.inputComps = new Map<string, Input1Model>();
    this.zipDataSource = new Observable();
    this.cityDataSource = new Observable();
    this.getStatesListInfo();
    // this.constructInputs(this.model);

    this.zipDataSource = Observable.create((observer: any) => {
      this.registerService.lookupZiporCity(this.inputComps.get('zip').value).subscribe(resp => {
          let list = resp;
          observer.next(list);
      })
  });

  this.cityDataSource = Observable.create((observer: any) => {
      this.registerService.lookupZiporCity(this.inputComps.get('city').value).subscribe(resp => {
          let list = resp;
          observer.next(list);
      })
  });
  this.getRegistertationTypes();

  }

  myModalEventCallBack() { }

  // Do not remove below method
  uspsModelEventCallBack(address, model) {
    // this.addStaffData('');
  }

  private constructInputs(data) {
    const inputComps = this.inputComps;
    if(this.inputComps == undefined){
      return;
    }
    inputComps.set(
      'firstName',
      new Input1Model({
        // displayType: 'TWO',
        label: 'First Name',
        value: data['firstName'],
        type: Input1Model.TYPE_ALPHA,
        required: true
      })
    );
    inputComps.set(
      'lastName',
      new Input1Model({
        // displayType: 'TWO',
        label: 'Last Name',
        value: data['lastName'],
        type: Input1Model.TYPE_ALPHA,
        required: true
      })
    );
    inputComps.set(
      'middleName',
      new Input1Model({
        // displayType: 'TWO',
        label: 'Middle',
        value: data['middleName'],
        type: Input1Model.TYPE_ALPHA
      })
    );
    inputComps.set(
      'fax',
      new Input1Model({
        // displayType: 'TWO',
        label: 'Fax',
        value: data['fax'] !== 'null' ? data['fax'] : '',
        type: Input1Model.TYPE_PHONE
      })
    );
    inputComps.set(
      'phone',
      new Input1Model({
        // displayType: 'TWO',
        label: 'Phone',
        value: data['phone'],
        type: Input1Model.TYPE_PHONE,
        required: true
      })
    );
    inputComps.set(
      'email',
      new Input1Model({
        // displayType: 'TWO',
        label: 'Email',
        value: data['email'],
        type: Input1Model.TYPE_EMAIL,
        required: false
      })
    );
    inputComps.set(
      'address1',
      new Input1Model({
        // displayType: 'TWO',
        label: 'Street Address',
        value: data['address1'],
        required: true
      })
    );
    inputComps.set(
      'address2',
      new Input1Model({
        // displayType: 'TWO',
        label: 'Address Line 2',
        value: data['address2']
      })
    );
    inputComps.set(
      'city',
      new Input1Model({
        // displayType: 'TWO',
        label: 'City',
        value: data['city'],
        required: true,
        typeAheadSelect: (e) => {
            this.inputComps.get('zip').value = e.item.zipCode.split('/')[0];
            this.inputComps.get('city').value = e.item.zipCode.split('/')[1];
        },
        isTypeahead: true,
        typeField: 'zipCode'
      })
    );
    inputComps.set(
      'zip',
      new Input1Model({
        // displayType: 'TWO',
        label: 'Zip',
        value: data['zip'],
        type: Input1Model.TYPE_NUMERIC,
        required: true,
        minLength: 5,
        maxLength: 5,
        typeField: 'zipCode',
        typeAheadSelect: (e) => {
            this.inputComps.get('zip').value = e.value.split('/')[0];
            this.inputComps.get('city').value = e.value.split('/')[1];

        },
        isTypeahead: true,
        dataSource: this.zipDataSource
      })
    );
    inputComps.set(
      'extn',
      new Input1Model({
        // displayType: 'TWO',
        label: 'Extn',
        value: data['extn'] !== 'null' ? data['extn'] : '',
        type: Input1Model.TYPE_NUMERIC,
        minLength: 5,
        maxLength: 5
      })
    );
    inputComps.set(
      'npi',
      new Input1Model({
        // displayType: 'TWO',
        label: 'NPI',
        value: data['npi'] !== 'null' ? data['npi'] : '',
        type: Input1Model.TYPE_NUMERIC,
        minLength: 10,
        maxLength: 10
      })
    );
    inputComps.set(
      'taxId',
      new Input1Model({
        // displayType: 'TWO',
        label: 'Tax Id',
        value: data['taxId'] !== 'null' ? data['taxId'] : '',
        type: Input1Model.TYPE_NUMERIC,
        minLength: 9,
        maxLength: 9
      })
    );
    inputComps.set(
      'state',
      new Input1Model({
        // displayType: 'TWO',
        label: 'State',
        value: data['state'],
        required: true,
        isSelect: true,
        options: this.stateCodes
        // ['', 'AZ', 'CA', 'NV', 'VA']
      })
    );
    inputComps.set(
      'registrationType',
      new Input1Model({
        // displayType: 'TWO',
        label: 'User Type',
        value: data['registrationType'],
        required: true,
        isSelect: true,
        options: this.registerationTypeList.map(d => d.userTypeDescription)
      })
    );
  }

  addStaff() {
    this.constructInputs(this.model);
    this.getRegistertationTypes();
    setTimeout(()=>{
    jQuery('#addStaffModal').modal('show');

    },100)

  }

  validateChildInputs() {
    this.errorMessage = '';
    const inputComps = this.inputComps;
    const fieldNameArr = [
      'firstName',
      'middleName',
      'lastName',
      'city',
      'state',
      'zip',
      'phone',
      'extn',
      'fax',
      'email',
      'npi',
      'taxId',
      'addressLine1',
      'addressLine2',
      'registrationType'
    ];

    let isValid = true;
    for (const fieldName of fieldNameArr) {
      const inputField = inputComps.get(fieldName);
      if (inputField) {
        const isInputValid = inputField.isValid();
        isValid = isValid && isInputValid;
      }
    }

    if (
      inputComps.get('npi').value === '' &&
      inputComps.get('taxId').value === ''
    ) {
      inputComps.get('npi').fieldValidateMsg = 'NPI or Tax Id is required';
      inputComps.get('taxId').fieldValidateMsg = 'NPI or Tax Id is required';
      inputComps.get('taxId').isInputValid = false;
      inputComps.get('npi').isInputValid = false;
      isValid = false;
    } else {
      if (inputComps.get('taxId').value !== '') {
        if (this.fieldValidateService.validateSameNumber(inputComps.get('taxId').value)) {
          inputComps.get('taxId').fieldValidateMsg = 'Invalid Tax ID';
          inputComps.get('taxId').isInputValid = false;
          isValid = false;
        }
      }
      if (inputComps.get('npi').value !== '') {
        if (this.fieldValidateService.validateSameNumber(inputComps.get('npi').value)) {
          inputComps.get('npi').fieldValidateMsg = 'Invalid NPI';
          inputComps.get('npi').isInputValid = false;
          isValid = false;
        }
      }
    }
    return isValid;
  }

  addStaffData(address, uspsAddressModel) {
    const addStaffDataPayload = this.constructAddStaffDataPayload();
    if (address === 'USPS_ADDRESS') {
      addStaffDataPayload.address1 = uspsAddressModel.address1;
      addStaffDataPayload.address2 = uspsAddressModel.address2;
      addStaffDataPayload.city = uspsAddressModel.city;
      addStaffDataPayload.state = uspsAddressModel.state;
      addStaffDataPayload.zip = uspsAddressModel.zip;
    }
    this.loading = true;
    this.frontdeskService.addStaff(
      addStaffDataPayload,
      (success: boolean, message: string) => {
        if (success) {
          this.loading = false;
          jQuery('#addStaffModal').modal('hide');
          this.showAlertPopup('Staff added, Email has been sent.', undefined);
          this.inputComps.get('firstName').value = '';
          this.inputComps.get('middleName').value = '';
          this.inputComps.get('lastName').value = '';
          this.inputComps.get('email').value = '';
          this.inputComps.get('registrationType').value = '';
        } else {
          this.loading = false;
          this.showAlertPopup(
            'Something went wrong, please try again later.',
            undefined
          );
        }
      }
    );
  }
  private constructAddStaffDataPayload() {
    let regType = this.registerationTypeList.filter(d => d.userTypeDescription == this.inputComps.get('registrationType').value)
      .map(d => d.userType)[0];
    const inputComps = this.inputComps;
    const addStaffModel = {
      address1: inputComps.get('address1').value,
      address2: inputComps.get('address2').value,
      city: inputComps.get('city').value,
      email: inputComps.get('email').value,
      extn: inputComps.get('extn').value,
      fax: inputComps.get('fax').value,
      firstName: inputComps.get('firstName').value,
      guId: JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('userProfile'))['guId'],
      lastName: inputComps.get('lastName').value,
      middleName: inputComps.get('middleName').value,
      npi: inputComps.get('npi').value,
      phone: inputComps.get('phone').value,
      reg_type: regType,
      sysCreatedId: this.userName,
      state: inputComps.get('state').value,
      taxId: inputComps.get('taxId').value,
      zip: inputComps.get('zip').value
    };
    return addStaffModel;
  }

  private showAlertPopup(message: string, callBack) {
    this.errorMessage = message;
    this.showPopup('myModal', callBack);
    return;
  }

  private showPopup(popupName: string, callBack) {
    jQuery('#' + popupName).modal('show');
    this[popupName + 'EventCallBack'] = callBack;
  }

  validateNpiTaxID() {
    
    const validate = this.validateChildInputs();
    this.getRegistertationTypes();
    if (validate && this.inputComps.get('email').value) {
      let valid = true;
      this.loading = true;
      this.frontdeskService.validateNPI(
        this.inputComps.get('npi').value,
        (success: boolean, message: string) => {
          if (!success) {
            this.loading = false;
            this.inputComps.get('npi').isInputValid = false;
            this.inputComps.get('npi').fieldValidateMsg = 'NPI is not Valid';
            valid = false;
          } else {
            this.frontdeskService.validateTaxId(
              this.inputComps.get('taxId').value,
              (success: boolean, message: string) => {
                if (!success) {
                  this.loading = false;
                  this.inputComps.get('taxId').isInputValid = false;
                  this.inputComps.get('taxId').fieldValidateMsg =
                    'Invalid Tax ID';
                  valid = false;
                } else {
                  this.loading = false;
                  this.model.address1 = this.inputComps.get('address1').value;
                  this.model.address2 = this.inputComps.get('address2').value;
                  this.model.city = this.inputComps.get('city').value;
                  this.model.state = this.inputComps.get('state').value;
                  this.model.zip = this.inputComps.get('zip').value;

                  this.frontdeskService.doUspsValidation(
                    this.model,
                    (hasValidAddress: boolean, uspsAddressModel) => {
                      if (hasValidAddress) {
                        this.loading = false;
                        this.uspsAddressModel = uspsAddressModel;
                        this.showPopup(
                          'uspsModel',
                          (popupButtonEvent: string, selectedAddressModel) => {
                            if (popupButtonEvent === 'USPS_ADDRESS') {
                              this.model.address1 =
                                selectedAddressModel.address1;
                              this.model.address2 =
                                selectedAddressModel.address2;
                              this.model.city = selectedAddressModel.city;
                              this.model.state = selectedAddressModel.state;
                              this.model.zip = selectedAddressModel.zip;
                            }
                          }
                        );
                      } else {
                        this.loading = false;
                        this.showAlertPopup(
                          'The Address is not valid as per \'United States Postal Service\'. Please Verify',
                          undefined
                        );
                      }
                    }
                  );
                }
              }
            );
          }
        }
      );

    } else if (validate) {
      let data = JSON.parse(JSON.stringify(this.model));
      const path = window.location.protocol + '//' + window.location.host + '/#/sign-up';
      data.firstName = this.inputComps.get('firstName').value;
      data.middleName = this.inputComps.get('middleName').value;
      data.lastName = this.inputComps.get('lastName').value;
      data.email = this.inputComps.get('email').value;
      data.taxId = this.inputComps.get('taxId').value;
      data.fax = this.inputComps.get('fax').value;
      data.phone = this.inputComps.get('phone').value;
      data.extn = this.inputComps.get('extn').value;
      data.npi = this.inputComps.get('npi').value;
      data.address1 = this.inputComps.get('address1').value;
      data.address2 = this.inputComps.get('address2').value;
      data.city = this.inputComps.get('city').value;
      // data.reg_type = this.inputComps.get('reg_type').value;
      data.state = this.inputComps.get('state').value;
      data.zip = this.inputComps.get('zip').value;
      data.guId = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage("userProfile"))["guId"];
      // sessionStorage.clear();
      // localStorage.clear();
      this.secureStorage.setSecureInfoInLocalStorage('associateDetails', JSON.stringify(data))
      this.constructInputs(this.model);
      jQuery('#addStaffModal').modal('hide');
      setTimeout(() => {
        window.open(path, '_blank');
      }, 500);
    }
  }
  getRegistertationTypes() {
    this.frontdeskService.getRegistertationTypes(data => {
      if (data) {
        let regTypeData = [];
        this.registerationTypeList = data.userType;
        const list = [];
        this.registerationTypeList.map(d => {
          if(d.userClass =="E"){
            list.push(d.userTypeDescription)
            regTypeData.push(d);
          }
          });
          this.registerationTypeList = regTypeData;
        this.inputComps.get('registrationType').options =list;
      }
    });
  }

  resetForm() {
    this.constructInputs(this.model);
    this.getRegistertationTypes();
  }

  getStatesListInfo(){
    this.frontdeskService.getStatesList()
    .subscribe((data:any) =>{
        if(data && data.length){
            data.map(response => {
                this.stateCodes.push(response.code);
            })
        }
    })
}

  zipSelected(e) {
    // console.log(e);
    this.model.zip = e.value.split('/')[0];
    this.model.city = e.value.split('/')[1];
}
onCitySelect(e) {
    // console.log(e);
    this.model.zip = e.item.zipCode.split('/')[0];
    this.model.city = e.item.zipCode.split('/')[1];
}



}
