<div id="main-wrapper" style="margin-bottom: 40px; background-color: #eaf0f5;">
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>

  <div class="container-fluid">
    <app-header (found)="handleResults($event)"></app-header>

    <div class="row col-md-12 broadcastpage">
      <div class="col-md-1 col-xs-2"></div>
      <div class="col-md-10 col-xs-12">
        <div class="row pad0">
          <div class="col-12">
            <div class="searchContainerTopAdd">
             <app-search-results [resultGridList]="data"></app-search-results>
            </div>
            <h2 class="section__heading header">
              Broadcast History
            </h2>
          </div>
        </div>
        <div class="col-md-12 text-left messages broadPageText">
          <accordion>
            <accordion-group heading="{{broadcast.messageName }}" *ngFor="let broadcast of broadcast">
              <div class="broadcastBody broadcastHistoryBody" [innerHTML]="broadcast.message"></div>
              <div class="broadcastdate">
                Posted: {{broadcast.updatedTimestamp}}
              </div>
            </accordion-group>
          </accordion>
        </div>
      </div>
      <div class="col-md-1 col-xs-2"></div>
    </div>

  </div>
  <br><br><br><br><br>
  <app-footer ></app-footer>
</div>