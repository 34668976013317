<app-common></app-common>
<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
<meta http-equiv="Pragma" content="no-cache" />
<meta http-equiv="Expires" content="0" />

<div class="signup-container">
	<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px', fullScreenBackdrop: true }"></ngx-loading>

	<div class="signup-header">
		<ul>
			<li class="brand"><img src="./../../assets/images/logo.svg" alt="carelon-logo"></li>
		</ul>
	</div>

	<div class="d-flex justify-content-center homebanner ">
		<img src="./../../assets/signup_banner.jpg" alt="banner">	
	</div>

	<div class="row">
		<div class="signup-wrapper">
			<!-- <div class="left-panel">
				<div class="msg_wrapper" style="width: 50%;">
					<ng-container *ngFor="let new of news">
						<h2 [innerHTML]="new.messageName"></h2>	
						<p [innerHTML]="new.message"></p>
						
					</ng-container>
				</div>

			</div> -->
			<div class="col-md-12">
				<div class="">

					<div class="">

						<div class="wrap-signup100">
							<div class="title">
								<h3>Sign Up</h3> <br>
								<h5>Already Have an Account? <a [routerLink]="['/home']">Sign In</a></h5>
							</div>
							<div class="login100-form validate-form">
								<!-- <span class="login100-form-title">
									<h3>Sign Up Form</h3>
									<span class="txt2" *ngIf="(currentStep == 0)"> Personal Details </span>
									<span class="txt2" *ngIf="(currentStep == 1)"> Address/Contact Details</span>
									<span class="txt2" *ngIf="(currentStep == 2)"> User Name and Password </span>
									<span class="txt2" *ngIf="(currentStep == 3)"> Security Questions</span>
									<span class="txt2" *ngIf="(currentStep == 4)"> Review and Submit</span>
								</span>
								<div class="f1-steps">
									<div class="f1-progress">
										<div class="f1-progress-line" data-now-value="16.66" data-number-of-steps="3" [ngStyle]="{'width': (20*(currentStep+1)) + '%' }"></div>
									</div>
									<div [ngClass]="(currentStep>=0)?'f1-step active':'f1-step'">
										<div class="f1-step-icon">1</div>
									</div>
									<div [ngClass]="(currentStep>=1)?'f1-step active':'f1-step'">
										<div class="f1-step-icon">2</div>
									</div>
									<div [ngClass]="(currentStep>=2)?'f1-step active':'f1-step'">
										<div class="f1-step-icon">3</div>
									</div>
									<div [ngClass]="(currentStep>=3)?'f1-step active':'f1-step'">
										<div class="f1-step-icon">4</div>
									</div>
									<div [ngClass]="(currentStep>=4)?'f1-step active':'f1-step'">
										<div class="f1-step-icon">5</div>
									</div>
								</div> -->
								<!--USERNAME AND OPTION-->
								<form action="#" class="validate-form" #appforgotpasswordform id="#forgotpasswordForm">
									
									<div class="heading">
										Associate Details
									</div>

									<fieldset data-section="usernameandoption" data-next-step="" >
										<div class="row wrap-row col-md-12">

											<div class="col-md-4">
												<span class="red-color">*</span><label>First Name</label>
												<div [ngClass]="(isFiledValid['firstName'])?'wrap-input45 validate-input':'wrap-input45 validate-input alert-validate'"
											 		data-validate="FirstName">
													<input (focus)="onInputFocus($event)" (blur)="getautoPopulateUserName()" maxlength="25" [(ngModel)]="model.firstName" #firstName="ngModel" id="firstName"
													name="firstName" type="text" [ngClass]="(model.firstName === '')?'input100':'input100 has-val'" required
													(keyup)="restrictToAlpha($event)" />
													<span class="focus-input100" data-placeholder="First Name"></span>
												</div>
											</div>

											<div class="col-md-4">
												<label>Middle Name</label>
												<div [ngClass]="(isFiledValid['middleName'])?'wrap-input15 validate-input':'wrap-input15 validate-input alert-validate'"
											 		data-validate="Enter Middle Initial">
													<input (focus)="onInputFocus($event)" (blur)="getautoPopulateUserName()" maxlength="10" [(ngModel)]="model.middleName" #middleName="ngModel"
													id="middleName" name="middleName" type="text" [ngClass]="(model.middleName === '')?'input100':'input100 has-val'"
													required (keyup)="restrictToAlpha($event)" />
													<span class="focus-input100" data-placeholder="Middle Name"></span>
												</div>
											</div>

											<div class="col-md-4">
												<span class="red-color">*</span><label>Last Name</label>
												<div [ngClass]="(isFiledValid['lastName'])?'wrap-input45 validate-input':'wrap-input45 validate-input alert-validate'"
											 		data-validate="LastName">
													<input (focus)="onInputFocus($event)" (blur)="getautoPopulateUserName()" maxlength="25" [(ngModel)]="model.lastName" #lastName="ngModel" id="lastName"
													name="lastName" type="text" [ngClass]="(model.lastName === '')?'input100':'input100 has-val'" required
													(keyup)="restrictToAlpha($event)" />
													<span class="focus-input100" data-placeholder="Last Name"></span>
												</div>
											</div>
										</div>

										<div class="row wrap-row col-md-12">
											<div class="col-md-4">
												<span class="red-color">*</span><label>NPI</label>
												<div [ngClass]="(isFiledValid['npi'])?'wrap-input50 validate-input':'wrap-input50 validate-input alert-validate'"
											 		[attr.data-validate]="isUndefinedOrEmpty(fieldValidateMsg['npi']) ? 'Invalid NPI' : fieldValidateMsg['npi']">
													<input (keyup)="restrictToNumber($event)" (keypress)="decimalRestrict($event)" maxlength="10" (focus)="onInputFocus($event)"
													[(ngModel)]="model.npi" #firstName="ngModel" id="npi" name="npi" type="text" [ngClass]="(model.npi === '')?'input100':'input100 has-val'"
													required />
													<span class="focus-input100" data-placeholder="NPI"></span>
												</div>
											</div>

											<div class="col-md-4">
												<span class="red-color">*</span><label>Tax ID</label>
												<div [ngClass]="(isFiledValid['taxId'])?'wrap-input50 validate-input':'wrap-input50 validate-input alert-validate'"
											 		[attr.data-validate]="isUndefinedOrEmpty(fieldValidateMsg['taxId']) ? 'Invalid Tax ID' : fieldValidateMsg['taxId']">
													<input (keyup)="restrictToNumber($event)" (keypress)="decimalRestrict($event)" maxlength="9" (focus)="onInputFocus($event)"
													[(ngModel)]="model.taxId" #taxId="ngModel" id="taxId" name="taxId" type="text" [ngClass]="(model.taxId === '')?'input100':'input100 has-val'"
													required />
													<span class="focus-input100" data-placeholder="Tax ID"></span>
												</div>
											</div>
										</div>
										<!-- <div class="container-contact100-form-btn">
											<button type="button" class="action-button contact100-form-btn next" (click)="nextStep(1)">Next</button>
										</div> -->
									</fieldset>

									<div class="heading">
										Contact Details
									</div>

									<fieldset data-section="addressSection" data-next-step="" >
										<div class="row wrap-row col-md-12">
											<div class="col-md-4">
												<span class="red-color">*</span><label>Street Address</label>
												<div [ngClass]="(isFiledValid['address1'])?'wrap-input100 validate-input':'wrap-input100 validate-input alert-validate'"
										 			data-validate="Enter Address">
													<input (focus)="onInputFocus($event)" [(ngModel)]="model.address1" #address1="ngModel" id="address1" name="address1"
													type="text" [ngClass]="(model.address1 === '')?'input100':'input100 has-val'" required />
													<span class="focus-input100" data-placeholder="Street Address"></span>
												</div>
											</div>

											<div class="col-md-4">
												<label>Address Line 2</label>
												<div [ngClass]="(isFiledValid['address2'])?'wrap-input100 validate-input':'wrap-input100 validate-input alert-validate'"
										 			data-validate="Enter address2">
													<input (focus)="onInputFocus($event)" [(ngModel)]="model.address2" #firstName="ngModel" id="address2" name="address2"
													type="text" [ngClass]="(model.address2 === '')?'input100':'input100 has-val'" required />
													<span class="focus-input100" data-placeholder="Address Line 2"></span>
												</div>
											</div>

											<div class="col-md-4">
												<span class="red-color">*</span><label>State</label>
												<div [ngClass]="(isFiledValid['state'])?'wrap-input15 validate-input':'wrap-input15 validate-input alert-validate'"
													data-validate="State">
												   <select maxlength="2" (focus)="onInputFocus($event)" [ngClass]="(model.state === '')?'input100 select100':'input100 select100 has-val'"
														name="state" [(ngModel)]="model.state">
													   <!-- <option value=""></option> -->
													   <option *ngFor = "let state of stateCodes" [value]="state">{{state}}</option>
													   <!-- <option value="CA">CA</option>
													   <option value="NV">NV</option>
													   <option value="VA">VA</option> -->
												   </select>
												   <span class="focus-input100" data-placeholder="State"></span>
											   </div>
											</div>
										</div>
										
										
										<div class="row wrap-row col-md-12">
											<div class="col-md-4">
												<span class="red-color">*</span><label>City</label>
												<div [ngClass]="(isFiledValid['city'])?'wrap-input60 validate-input':'wrap-input60 validate-input alert-validate'"
													data-validate="Enter City">
													<input (focus)="onInputFocus($event)" [(ngModel)]="model.city" #firstName="ngModel" id="city" name="city"
													type="text" [ngClass]="(model.city === '')?'input100':'input100 has-val'" required 
													autocomplete="'new-password"
													[typeaheadAsync]="true"
													[typeaheadScrollable]="true"
													container="body"
													[typeahead]="cityDataSource"
													typeaheadMinLength="3"
													typeaheadOptionsLimit="200"
													(typeaheadOnSelect)="onCitySelect($event)"
													typeaheadOptionField="zipCode"/>
													<span class="focus-input100" data-placeholder="City"></span>
												</div>
											</div>

											<div class="col-md-4">
												<span class="red-color">*</span><label>Zip Code</label>
												<div [ngClass]="(isFiledValid['zip'])?'wrap-input25 validate-input':'wrap-input25 validate-input alert-validate'"
													[attr.data-validate]="isUndefinedOrEmpty(fieldValidateMsg['zip']) ? 'Zip' : fieldValidateMsg['zip']">
													<input (keyup)="restrictToNumber($event)" (keypress)="decimalRestrict($event)" type="text" maxlength="5"
													(focus)="onInputFocus($event)" [(ngModel)]="model.zip" #zip="ngModel" id="zip" name="zip" [ngClass]="(model.zip === '')?'input100':'input100 has-val'"
													required  autocomplete="'new-password"
													[typeaheadAsync]="true"
													[typeahead]="zipDataSource"
													[typeaheadScrollable]="true"
													container="body"
													typeaheadMinLength="3"
													typeaheadOptionsLimit="200"
													(typeaheadOnSelect)="zipSelected($event)"
													typeaheadOptionField="zipCode"/>
													<span class="focus-input100" data-placeholder="Zip Code"></span>
												</div>
											</div>

											<div class="col-md-4">
												<span class="red-color">*</span><label>Phone Number</label>
												<div [ngClass]="(isFiledValid['phone'])?'wrap-input40 validate-input':'wrap-input40 validate-input alert-validate'"
													[attr.data-validate]="isUndefinedOrEmpty(fieldValidateMsg['phone']) ? 'Enter Phone' : fieldValidateMsg['phone']">
													<input (keyup)="onPhoneKeyUp($event)" (focus)="onInputFocus($event)" (keypress)="decimalRestrict($event)"
													[(ngModel)]="model.phone" #phone="ngModel" id="phone" name="phone" type="text" [ngClass]="(model.phone === '')?'input100':'input100 has-val'"
													required />
													<span class="focus-input100" data-placeholder="Phone Number"></span>
												</div>
											</div>

										</div>

										<div class="row wrap-row col-md-12">

											<div class="col-md-4">
												<label>Ext</label>
												<div [ngClass]="(isFiledValid['ext'])?'wrap-input20 validate-input':'wrap-input20 validate-input alert-validate'"
													[attr.data-validate]="isUndefinedOrEmpty(fieldValidateMsg['ext']) ? 'Enter Ext' : fieldValidateMsg['ext']">
													<input (focus)="onInputFocus($event)" maxlength="5" (keypress)="decimalRestrict($event)" [(ngModel)]="model.ext"
													#ext="ngModel" id="ext" name="ext" type="text" [ngClass]="(model.ext === '')?'input100':'input100 has-val'"
													required />
													<span class="focus-input100" data-placeholder="Ext"></span>
												</div>
											</div>

											<div class="col-md-4">
												<label>Fax</label>
												<div (keyup)="onPhoneKeyUp($event)" [ngClass]="(isFiledValid['fax'])?'wrap-input40 validate-input':'wrap-input40 validate-input alert-validate'"
													[attr.data-validate]="isUndefinedOrEmpty(fieldValidateMsg['fax']) ? 'Enter Fax' : fieldValidateMsg['fax']">
													<input (focus)="onInputFocus($event)" (keypress)="decimalRestrict($event)" [(ngModel)]="model.fax" #fax="ngModel"
													id="fax" name="fax" type="text" [ngClass]="(model.fax === '')?'input100':'input100 has-val'" required />
													<span class="focus-input100" data-placeholder="Fax"></span>
												</div>
											</div>

											<div class="col-md-4">
												<label>Email</label>
												<div [ngClass]="(isFiledValid['email'])?'wrap-input100 validate-input':'wrap-input100 validate-input alert-validate'"
													[attr.data-validate]="isUndefinedOrEmpty(fieldValidateMsg['email']) ? 'Enter Email' : fieldValidateMsg['email']">
													<input (focus)="onInputFocus($event)" [(ngModel)]="model.email" #email="ngModel" id="email" name="email"
													type="text" [ngClass]="(model.email === '')?'input100':'input100 has-val'" />
													<span class="focus-input100" data-placeholder="Email"></span>
												</div>
											</div>
																					
										</div>

										<div class="col-md-12 pl-0 row" align="end">
										
											<span class="address-error" *ngIf = "showValidateAddressError">Please validate your address</span>
											<button type="submit" class="btn btn-primary carelon-primary-btn" (click)="doUspsAddressValidation()"
											>Validate Address</button>
										</div>
										
									</fieldset>

									<div class="heading">
										Create Password
									</div>

									<fieldset data-section="addressSection" data-next-step="">
										<div class="col-md-12 row">

											<div class="col-md-4">
												<span class="red-color">*</span><label>User Name</label>
												<div [ngClass]="(isFiledValid['userName'])?'wrap-input100 validate-input':'wrap-input100 validate-input alert-validate'"
												[attr.data-validate]="isUndefinedOrEmpty(fieldValidateMsg['userName']) ? 'Provide UserName' : fieldValidateMsg['userName']">
												   <input (keypress)="passRestrict($event)" (focus)="onInputFocus($event)" [(ngModel)]="model.userName"
													#userName="ngModel" id="userName" name="userName" type="text" [ngClass]="(model.userName === '')?'input100':'input100 has-val'"
													required disabled />
												   <span class="focus-input100" data-placeholder="User Name"></span>
											   </div>
											   <span class="red-color">*</span><label>Create Password</label>
											   <div [ngClass]="(isFiledValid['cred'])?'wrap-input100 validate-input':'wrap-input100 validate-input alert-validate'"
												[attr.data-validate]="isUndefinedOrEmpty(fieldValidateMsg['cred']) ? 'Enter Password' : fieldValidateMsg['cred']">
												   <span class="btn-show-pass">
													   <i [ngClass]="((showCred !== undefined) && showCred)?'zmdi zmdi-eye':'zmdi zmdi-eye-off'" (click)="togglePasswordShow($event, 'showCred')"></i>
												   </span>
												   <input [attr.type]="((showCred !== undefined) && showCred)? 'text' : 'password'" (keypress)="passRestrict($event)"
													(focus)="onFocusPassword($event)" minlength="8" maxlength="15"  [(ngModel)]="model.cred"
													#firstName="ngModel" id="password" name="password" type="password" [ngClass]="(model.cred === '')?'input100':'input100 has-val'"
													required />
												   <span class="focus-input100" data-placeholder="Enter Password"></span>
											   </div>
											   <span class="red-color">*</span><label>Confirm Password</label>
											   <div [ngClass]="(isFiledValid['confirmCred'])?'wrap-input100 validate-input':'wrap-input100 validate-input alert-validate'"
												[attr.data-validate]="isUndefinedOrEmpty(fieldValidateMsg['confirmCred']) ? 'Confirm Password' : fieldValidateMsg['cred']">
												   <span class="btn-show-pass">
													   <i [ngClass]="((showConfirmCred !== undefined) && showConfirmCred)?'zmdi zmdi-eye':'zmdi zmdi-eye-off'"
														(click)="togglePasswordShow($event,'showConfirmCred')"></i>
												   </span>
												   <input [attr.type]="((showConfirmCred !== undefined) && showConfirmCred) ? 'text' : 'password'" (keypress)="passRestrict($event)"
													minlength="8" maxlength="15" (focus)="onFocusPassword($event)" [(ngModel)]="model.confirmCred" #firstName="ngModel"
													id="confirmPassword" name="confirmPassword" type="password" [ngClass]="(model.confirmCred === '')?'input100':'input100 has-val'"
													required />
												   <span class="focus-input100" data-placeholder="Confirm Password"></span>
											   </div>

											   <div class="form-check">
												   <input type="checkbox" class="form-check-input" [(ngModel)]="licenseAccpetedFlag" (change)="isLicenseAcceptedError = !licenseAccpetedFlag"
													name="licenseAccpetedFlag">
												   <label class="form-check-label" for="exampleCheck1">I have read and understand the <a class="form-check-label"
														(click)="getDocument()" style="cursor: pointer">End User License Agreement</a></label>	   
											   </div>

											   <div class="userAgreementMessage" *ngIf="isLicenseAcceptedError">
												Please check this box to proceed.
												</div>

											</div>
									   
										   <div class="col-md-6 passwordMessage">
											   <p class="note">Password Requirements </p>
											   <ul class="circle">
												   <li> Must be different than your previous three passwords</li>
												   <li> Must contain at least one lowercase letter, one uppercase letter, one numerical digit and any of the
													   following special characters (!,@,#,$,%,^,*,.,_)</li>
												   <li> Must be between 5 and 15 characters.</li>
											   </ul>
										   </div>
										</div>
									</fieldset>

									<div class="heading">
										Security Questions
									</div>

									<fieldset data-section="addressSection" data-next-step="" >
										<div class="col-md-12 row wrap-row">
											<div class="col-md-4">
												<span class="red-color">*</span><label>Security Question 1</label>
												<div [ngClass]="(isFiledValid['question1Selected'])?'wrap-input100 validate-input':'wrap-input100 validate-input alert-validate'"
												data-validate="Select Question1">
												   <select (focus)="onInputFocus($event)" [ngClass]="(question1Selected === '')?'input100 select100':'input100 select100 has-val'"
													name="question1Selected" #question1="ngModel" [(ngModel)]="question1Selected">
													   <option value=""></option>
													   <option *ngFor="let question1value of questions1" [value]="question1value.securityQuestion">{{
														   question1value.securityQuestion}}</option>
												   </select>
												   <span class="focus-input100" data-placeholder="Select Question"></span>
											   </div>
											</div>
											<div class="col-md-4">
												<span class="red-color">*</span><label>Answer</label>
												<div [ngClass]="(isFiledValid['answer1'])?'wrap-input100 validate-input':'wrap-input100 validate-input alert-validate'"
												data-validate="Enter Answer">
												   <input (focus)="onInputFocus($event)" [(ngModel)]="answer1" #firstName="ngModel" id="answer1" name="answer1"
													type="answer1" [ngClass]="(answer1 === '')?'input100':'input100 has-val'" required />
												   <span class="focus-input100" data-placeholder="Enter Your Answer"></span>
											   </div>
											</div>
										</div>
										<div class="col-md-12 row wrap-row">
											<div class="col-md-4">
												<span class="red-color">*</span><label>Security Question 2</label>
												<div [ngClass]="(isFiledValid['question2Selected'])?'wrap-input100 validate-input':'wrap-input100 validate-input alert-validate'"
												data-validate="Select Question2">
												   <select (focus)="onInputFocus($event)" [ngClass]="(question2Selected === '')?'input100 select100':'input100 select100 has-val'"
													name="question2Selected" #question2="ngModel" [(ngModel)]="question2Selected">
													   <option value=""></option>
													   <option *ngFor="let question2value of questions2" [value]="question2value.securityQuestion">{{
														   question2value.securityQuestion}}</option>
												   </select>
												   <span class="focus-input100" data-placeholder="Select Question"></span>
											   </div>
											</div>
											<div class="col-md-4">
												<span class="red-color">*</span><label>Answer</label>
												<div [ngClass]="(isFiledValid['answer2'])?'wrap-input100 validate-input':'wrap-input100 validate-input alert-validate'"
												data-validate="Enter Answer">
												   <input (focus)="onInputFocus($event)" [(ngModel)]="answer2" #firstName="ngModel" id="answer2" name="answer2"
													type="answer2" [ngClass]="(answer2 === '')?'input100':'input100 has-val'" required />
												   <span class="focus-input100" data-placeholder="Enter Your Answer"></span>
											   </div>
											   
											</div>
										</div>

										<div *ngIf="showSecurityQuestionErrorMsg" style="color: red; padding-left: 20px;">
											Question1 and Question2 should not be same.Please select different questions.
										</div>
										
										<!-- <div class="row captchaDiv">
											<div class="col-md-8 pt-30">
												<form [formGroup]="captchaForm">
													<re-captcha formControlName="captcha" siteKey="6Ld15IYUAAAAAHOKhx7I6-iWQNV7BNwyxdyaIsJQ"></re-captcha>
													<div class="captchaMessage" *ngIf="!isValidCaptcha">
														Captcha validation failed, try again please.
													</div>
												</form>
											</div>
											<div class="col-md-2"></div>
										</div> -->
									
									</fieldset>
									
									<div class="row col-md-12 pl-0 actions">
										<div class="col-md-12 pl-0" align="end">
											<button type="button" 
												class="btn carelon-secondary-btn">Cancel</button>
						
											<button type="submit" class="btn btn-primary carelon-primary-btn" (click)="onSubmit()"
											>Submit</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>

					<div>
						<div class="modal fade" id="myModal" role="dialog">
							<div class="modal-dialog" style="margin-top: 15%;">
								<div class="modal-content alertPopup">
									
									<div class="modal-header" *ngIf="showClose">
										<div class="row">
											<div class="col-md-9">
				
											</div>
											<div class="col-md-3">
												<button type="button" class="close"
													data-dismiss="modal"><img src="../../assets/images/Close.svg"
														alt="close"></button>
											</div>
										</div>
									</div>

									<div class="modal-body">
										<p class="text" style="text-align: center;">{{ errorMessage }}</p>
									</div>

									<div class="modal-footer" *ngIf="showClose">	
										<div class="col-md-12 pl-0" align="center">
											<button type="button" data-dismiss="modal" 
												class="btn carelon-primary-btn">Close</button>
										</div>		
									</div>
								</div>
							</div>
						</div>
					</div>

					<div>
						<div class="modal fade" id="finalPopup" role="dialog">
							<div class="modal-dialog" style="margin-top: 15%;">
								<div class="modal-content alertPopup">
									
									<div class="modal-header" *ngIf="showClose">
										<div class="row">
											<div class="col-md-9">
				
											</div>
											<div class="col-md-3">
												<button type="button" class="close" (click)="gotoLoginPage()"
													data-dismiss="modal"><img src="../../assets/images/Close.svg"
														alt="close"></button>
											</div>
										</div>
									</div>

									<div class="modal-body">
										<p class="text" style="text-align: center;">{{ errorMessage }}</p>
									</div>

									<div class="modal-footer" *ngIf="showClose">	
										<div class="col-md-12 pl-0" align="center">
											<button type="button" data-dismiss="modal" (click)="gotoLoginPage()"
												class="btn carelon-primary-btn">Close</button>
										</div>		
									</div>
								</div>
							</div>
						</div>
					</div>

					<div>
						<div class="modal fade" id="uspsModel" role="dialog">
							<div class="modal-dialog modal-lg" style="margin-top: 9%;">
								<div class="modal-content">
									<div class="modal-header addressPopup">
										<div class="row">
											<div class="col-md-9">
												<div class="row">Address Verification</div>
											</div>
											<div class="col-md-3">
												<button type="button" class="close" data-dismiss="modal"><img
														src="../../assets/images/Close.svg" alt="close"></button>
											</div>
										</div>
									</div>
									<div class="modal-body adressPopupBody">

										<div class="row text">
											Please select any one of the address below.
 										</div>

										 <div class="row col-md-12 pl-0">
											<div class="col-md-6" [ngClass]="{'selected': selectedUserAddressCard }" *ngIf="model">
												<div class="card" (click)="selectedUserAddressCard = true">
													<div class="card-header row m-0">
														<div class="col-md-9 ">
															You Entered
														</div>
														<div class="col-md-3 cardSelectedImage">
															<img src="../../assets/images/Tick.svg" alt="Tick Mark">
														</div>
		
													</div>
													<div class="card-body">
														<p class="card-text">{{model.address1}} <br>
															{{model.address2}} <br>
															{{model.city}}, {{model.state}}
															{{model.zip}} <br>
														</p>
													</div>
												</div>
											</div>
		
											<div *ngIf="uspsAddressModel">
												<div class="col-md-6" [ngClass]="{'selected': !selectedUserAddressCard }">
													<div class="card" (click)="selectedUserAddressCard = false ">
														<div class="card-header row m-0">
															<div class="col-md-9 ">
																USPS Recommends
															</div>
															<div class="col-md-3 cardSelectedImage">
																<img src="../../assets/images/Tick.svg" alt="Tick Mark">
															</div>
		
														</div>
														<div class="card-body">
															<p class="card-text">{{uspsAddressModel.address1}} <br>
																{{uspsAddressModel.address2}} <br>
																{{uspsAddressModel.city}}, {{uspsAddressModel.state}}
																{{uspsAddressModel.zip}} <br>
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>

										<!-- <div class="row" style="padding-top: 5px;">
											<div class="col-sm-6" *ngIf="model">
												<div class="row" style="font-family: Myriad Pro; font-size: 15px; font-weight: bold;">YOU ENTERED</div>
												<div class="row" style="font-family: Myriad Pro; font-size: 12px;">{{model.address1}}</div>
												<div class="row" style="font-family: Myriad Pro; font-size: 12px;">{{model.address2}}</div>
												<div class="row" style="font-family: Myriad Pro; font-size: 12px;">{{model.city}}, {{model.state}}
													{{model.zip}}</div>
												<div class="row" style="padding-top: 5px; font-family: Myriad Pro; font-size: 12px;">
													<button data-dismiss="modal" type="button" class="contact100-form-btn previous m-r-10" (click)="uspsModelEventCallBack('SAME_ADDRESS','')"
													 style="width: 80%">KEEP
														ADDRESS I ENTERED</button>
												</div>
											</div>
											<div class="col-sm-6" *ngIf="uspsAddressModel">
												<div class="row" style="font-family: Myriad Pro; font-size: 15px; font-weight: bold;">USPS RECOMMENDS</div>
												<div class="row" style="font-family: Myriad Pro; font-size: 12px;">{{uspsAddressModel.address1}}</div>
												<div class="row" style="font-family: Myriad Pro; font-size: 12px;">{{uspsAddressModel.address2}}</div>
												<div class="row" style="font-family: Myriad Pro; font-size: 12px;">{{uspsAddressModel.city}},
													{{uspsAddressModel.state}} {{uspsAddressModel.zip}}</div>
												<div class="row" style="padding-top: 5px; font-family: Myriad Pro; font-size: 12px;">
													<button data-dismiss="modal" type="button" class="contact100-form-btn previous m-r-10" (click)="uspsModelEventCallBack('USPS_ADDRESS', uspsAddressModel)"
													 style="width: 80%">USE
														NEW ADDRESS</button>
												</div>
											</div>
										</div> -->
									</div>
									<div class="modal-footer addressModalFooter">
										<div class="col-md-11 pl-0" align="end">
											<button type="button" data-dismiss="modal"
												class="btn carelon-secondary-btn">Cancel</button>
		
											<button data-dismiss="modal" class="btn btn-primary carelon-primary-btn"
												(click)="UpdateUserSelectedAddress()" type="button">Use Selected</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div>
						<div class="modal fade" id="uspsCityStateModel" role="dialog">
							<div class="modal-dialog modal-md" style="width: 30%;margin-top: 15%;">
								<div class="modal-content">
									<div class="modal-header">
										<button type="button" class="close" data-dismiss="modal">&times;</button>
										<div class="row" style="padding-right: 40px; font-family: Myriad Pro; font-size: 20px; padding-left: 28%; font-weight: bold;">ZIP
											VERIFICATION</div>
									</div>
									<div class="modal-body" style="padding-right: 25px; padding-left: 45px;">
										<div class="row" style="font-family: Myriad Pro; font-size: 12px;">USPS RECOMMENDED CITY, STATE for the
											selected ZIP are the following</div>
										<div class="row" style="padding-top: 5px;" *ngIf="uspsAddressModel">
											<div class="row">
												<div class="col-sm-3" style="font-family: Myriad Pro;">City :</div>
												<div class="col-sm-6" style="font-family: Myriad Pro;">{{uspsAddressModel.city}}</div>
											</div>
											<div class="row">
												<div class="col-sm-3" style="font-family: Myriad Pro;">State :</div>
												<div class="col-sm-6" style="font-family: Myriad Pro;">{{uspsAddressModel.state}}</div>
											</div>
											<div class="row">
												<div class="col-sm-3" style="font-family: Myriad Pro;">Zip :</div>
												<div class="col-sm-6" style="font-family: Myriad Pro;">{{uspsAddressModel.zip}}</div>
											</div>
										</div>
									</div>
									<div class="modal-footer">
										<div class="container-contact100-form-btn">
											<button type="button" class="contact100-form-btn previous m-r-10" data-dismiss="modal">Re-Enter Zipcode</button>
											<button data-dismiss="modal" type="button" class="contact100-form-btn previous m-r-10" (click)="uspsCityStateModelEventCallBack(uspsAddressModel)">Use
												Recommended</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						<div class="modal fade" id="errorMessageModal" role="dialog">
							<div class="modal-dialog modal-xl" style="margin-top: 10%;">
								<div class="modal-content" style="width: 106%;">
									<div class="modal-header" style="border-bottom: 0px;">
										<div>
											<div class="modal-body">
												<p class="text-center" style="font-weight: bold;font-size:16px;">Login failed</p>
												<p>Either the username you entered is invalid or the password you entered is not
													correct. Please try again.</p>
												<p style="font-weight: bold;">If needed, please use the Forgot Password link on the login form to reset
													your password.</p>
												<p>If the username is valid but there are 5 failed login attempts, the account
													will be locked.</p>
												<p>If the above doesn’t work, or you need help with a locked account, please
													contact the Account Operator for your site, or CareMore Provider Relations at 888-291-1358, option 3,
													option 5.</p>
												<br>
												<button type="button" class="contact100-form-btn center-block" data-dismiss="modal">Close</button>
											</div>
										</div>


									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>