import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ClaimnavigationService {
markedFlag: boolean = false;


constructor(private http: HttpClient) {
}

// private eventPublisher: Subject<Event[]> = new Subject<Event[]>();
saveddata: any;
receiveState(data){
  this.markedFlag = true;
  
  this.saveddata = data;
 
}

renderState(): Observable<Event[]>{
 let Observable = of(this.saveddata);
 return Observable;
}
}