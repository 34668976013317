import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { FooterFaqService } from './faq.service';


declare var jQuery: any;

@Component( {
    selector: 'app-FooterFaq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
    providers: [FooterFaqService]
} )
export class FooterFaqComponent implements OnInit {
    loading = false;
    // this.loading = false;
    faqs = [];
    data = [];
    handleResults(searchObj) {
        this.data = searchObj;
    }
    constructor( private router: Router, private currentService: FooterFaqService ) { }
    showSection = 'tabSection';

    ngOnInit() {
        this.loadFaqs();
    }

    loadFaqs() {
        this.loading = true;
        this.currentService.getFaqs(( success: boolean, faqs: any ) => {
            if ( success ) {
                this.loading = false;
                this.faqs = faqs['poviderPortalFaq'];
            } else {
                this.loading = false;
            }
        } );

    }

}
