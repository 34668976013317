import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DefaultUrlSerializer, RouterModule, UrlSerializer, UrlTree } from "@angular/router";
import { TreeModule } from 'angular-tree-component';
import { UserIdleModule } from "angular-user-idle";

import { FileUploadModule } from "ng2-file-upload";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { PopoverModule } from "ngx-bootstrap/popover";
import { SortableModule } from "ngx-bootstrap/sortable";
import { TabsModule } from "ngx-bootstrap/tabs";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { LoadingModule } from "ngx-loading";
import { NgxPaginationModule } from 'ngx-pagination';
import { AccountProfileComponent } from "./accountProfile/accountProfile.component";
import { AccountProfileService } from "./accountProfile/accountProfile.service";
import { AccountProfileEditComponent } from "./accountProfile/accountProfileEdit/accountProfileEdit.component";
import { AddtositeComponent } from "./addtosite/addtosite.component";
import { AddtositeService } from "./addtosite/addtosite.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BroadcastHistoryComponent } from "./broadcast-history/broadcast-history.component";

import { FieldValidateService } from "./common_services/fieldvalidate.service";
import { DocumentsComponent } from "./documents/documents.component";
import { DocumentsService } from "./documents/documents.service";
import { KeysPipe } from "./documents/key-value.pipe";
import { FooterConnectComponent } from "./footer/connect/connect.component";
import { FooterFaqComponent } from "./footer/faq/faq.component";
import { ForgotpasswordComponent } from "./forgotpassword/forgotpassword.component";
import { ForgotpasswordService } from "./forgotpassword/forgotpassword.service";
import { ForgotpasswordquestionsComponent } from "./forgotpasswordquestions/forgotpasswordquestions.component";
import { ForgotusernameComponent } from "./forgotusername/forgotusername.component";
import { FrontdeskComponent } from "./frontdesk/frontdesk.component";
import { HeaderService } from "./header/header.service";
import { HomeComponent } from "./home/home.component";
import { InboxComponent } from "./inbox/inbox.component";
import { ClaimnavigationService } from "./providerportal/claimsummary/claimnavigation.service";
import { PdfService } from "./providerportal/epahaf/epahaf-pdf.service";
import { EpahafService } from "./providerportal/epahaf/epahaf.service";
import { PcpScorecardService } from "./providerportal/pcp-scorecard/pcp-scorecard.service";
import { TrainingMaterialService } from "./providerportal/provider-training/trainingmaterial.service";
import { ProviderportalService } from "./providerportal/providerportal.service";
import { ResetpasswordComponent } from "./resetpassword/resetpassword.component";
import { AlertServiceService } from "./shared/services/alert-service.service";
import { ConfigProvider } from "./shared/services/configprovider";
import { CupService } from "./shared/services/cup.service";
import { GlobalService } from "./shared/services/global.service";
import { UrlconstantsService } from "./shared/services/urlconstants.service";
import { SharedModules } from "./shared/shared.module";
import { SigninComponent } from "./signin/signin.component";
import { SignupConfirmationComponent } from "./signup-form/confirmation/confirmation.component";
import { SignupFormComponent } from "./signup-form/signup-form.component";
import { SignupFormService } from "./signup-form/signup-form.service";
import { TruncatePipe } from "./truncate.pipe";
import { UploaddocumentComponent } from './uploaddocument/uploaddocument.component';
import { UploaddocumentService } from './uploaddocument/uploaddocument.service';
import { UserauthenticationComponent } from "./userauthentication/userauthentication.component";
import { ValidatedocumentComponent } from './validatedocument/validatedocument.component';
import { ValidatedocumentService } from './validatedocument/validatedocument.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressBarModule } from 'primeng/progressbar';
import { ChangePasswordComponent } from './changePassword/changePassword.component';
import { RsaService } from './common_services/encryptutil.service';
import { SecureStorageService } from './secure-storage.service';
import { MaterialModule } from './material.module';
import { PatientSurveyResultService } from "./providerportal/patient-survey-result/patient-survey-result.service";

import {MatCardModule} from '@angular/material/card';

import {RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { ForgotpasswordquestionsService } from "./forgotpasswordquestions/forgotpasswordquestions.service";
import { ResetpasswordService } from "./resetpassword/resetpassword.service";


export class CustomUrlSerializer implements UrlSerializer {
  private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  parse(url: string): UrlTree {
    // Encode "+" to "%2B"
    url = url.replace(/\+/gi, "%2B");
    // Use the default serializer.
    return this._defaultUrlSerializer.parse(url);
  }

  serialize(tree: UrlTree): string {
    return this._defaultUrlSerializer.serialize(tree).replace(/\+/gi, "%2B");
  }
}

export function configProviderFactory(provider: ConfigProvider) {
  return () => provider.load();
}

export function setAccessTokenFactory(globalService: GlobalService) {
  return () => globalService.setAccesToken();
}

@NgModule({
  declarations: [
    AppComponent,
    ForgotpasswordComponent,
    ForgotusernameComponent,
    ForgotpasswordquestionsComponent,
    SignupConfirmationComponent,
    // FooterComponent,
    FooterFaqComponent,
    FooterConnectComponent,
    ResetpasswordComponent,
    SigninComponent,
    AccountProfileComponent,
    AccountProfileEditComponent,
    ChangePasswordComponent,
    HomeComponent,
    SignupFormComponent,
    DocumentsComponent,
    KeysPipe,
    InboxComponent,
    BroadcastHistoryComponent,
    FrontdeskComponent,
    AddtositeComponent,
    UserauthenticationComponent,
    TruncatePipe,
    UploaddocumentComponent,
    ValidatedocumentComponent,
    // SinglesignonComponent,
    //MemberSearchFilterPipe
    // FilterArrayPipe
    //RegistrationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModules.forRoot(),
    LoadingModule,
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    UserIdleModule.forRoot({ idle: 28800, timeout: 61, ping: 61 }),
    TreeModule.forRoot(),
    // AuthMessagesModule,
    SortableModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
    TypeaheadModule.forRoot(),
    // ProviderModule,
    AppRoutingModule,
    NgxPaginationModule,
    FileUploadModule,
    ProgressBarModule,
    MaterialModule,
    MatCardModule
  ],
  exports: [MaterialModule],
  providers: [
    ConfigProvider,
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    {provide: APP_INITIALIZER, useFactory: configProviderFactory, deps: [ConfigProvider], multi: true},
    {provide: APP_INITIALIZER, useFactory: setAccessTokenFactory, deps: [GlobalService], multi: true},
    //     mockBackendProvider,
    //     MockBackend,
    // BaseRequestOptions,
    FieldValidateService,
    DocumentsService,
    HeaderService,
    SignupFormService,
    ProviderportalService,
    ForgotpasswordService,
    AccountProfileService,
    CupService,
    GlobalService,
    UrlconstantsService,
    RsaService,
    AddtositeService,
    UploaddocumentService,
    ValidatedocumentService,
    ClaimnavigationService,
    EpahafService,
    AlertServiceService,
    PdfService,
    PcpScorecardService,
    TrainingMaterialService,
    SecureStorageService,
    PatientSurveyResultService,
    ForgotpasswordquestionsService,
    ResetpasswordService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
