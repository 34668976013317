import { EventEmitter, Injectable } from "@angular/core";

import { Observable, Subject, forkJoin, of } from "rxjs";
import { setInterval } from "timers";
import { urls } from "../../common/model/properties";
import * as _ from "lodash";
import { BehaviorSubject } from "rxjs";
import { isArray } from "util";
import { catchError } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SecureStorageService } from "../../secure-storage.service";
import { CupService } from "./cup.service";


@Injectable()
export class AlertServiceService {
  compUsername: any;
  userName: any;
  copyCardData = [];
  saveAlertId: any;
  cardData = [];
  allCardData = [];
  alertCount = 0;
  headerCompFlag: any;
  public message = new Subject<any>();
  public count = new Subject<any>();
  unReadAlertCount: any;
  public countwithSev = new Subject<any>();
  unreadHighCount = 0;
  unreadMedCount = 0;
  unreadLowCount = 0;
  public allUnreadserviceAlertCount = new Subject<any>();
  allCount: any;
  unreadLowCountwithoutId: any;
  unreadMedCountwithoutId: any;
  unreadHighCountwithoutId: any;
  config:any;
  taskScheduler: any;
  constructor(private http: HttpClient,
    private cupService: CupService,
    private secureStorage:SecureStorageService) {
    // console.log("AlertService here");
     this.getDataFromDB();
    // this.getAllCounts();
   
 
  }
  setInterValCAllingForAlert() {
    let isInterValSet = this.secureStorage.getSecureInfoInLocalStorage("isInterValSet");
    if(!isInterValSet) {
      this.secureStorage.setSecureInfoInLocalStorage("isInterValSet", "true");
      this.taskScheduler = setInterval(() => {
        this.getDataFromDB();
      }, 60000);
    }
  }
  setCardData(incomingCardData) {
    this.allCardData  = incomingCardData;
  }
  getDataFromDB() {
    let finalServiceData = [];
    // if (this.secureStorage.getSecureInfoInSessionStorage("currentUser")) {
    //   this.userName = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage("currentUser"))[
    //     "userName"
    //   ];
    // }
    if (this.secureStorage.getSecureInfoInLocalStorage("currentUser")) {
      this.userName = this.cupService.getUserNameFromJWT();
    }
    this.getAlertDetails(this.userName).subscribe((res:any) => {
      if (res) {

        this.allCardData = res.slice().sort((a, b) => b.AlertId - a.AlertId);
        let newAlerts = _.differenceBy(this.allCardData, res, "AlertId");
        if (newAlerts.length) {
         // finalServiceData = [...newAlerts, ...this.allCardData];
          finalServiceData = newAlerts.concat(this.allCardData);
        } else {
          finalServiceData = this.allCardData;
        }
        this.message.next(finalServiceData);
        //
        this.getAllCounts();
      }
    });
  }
  emmitCardDataChange(CloneOfMainCardArray) {
    this.allCardData = CloneOfMainCardArray;
    this.message.next(this.allCardData);
  }

  public unReadAlertCounts() {
    this.getAlertCount(this.userName).subscribe((resCount:any) => {
      this.alertCount = resCount.Count ? resCount.Count : 0;
      this.secureStorage.setSecureInfoInSessionlStorage("count", this.alertCount.toString());
      this.count.next(resCount.Count ? resCount.Count : 0);
    });
  }
  getAlertDetails(userName: String) {
    
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
      "Accept": "application/json",
      "Authorization": "Bearer " + ppjwt.access_token
    });
    let alertDetails = urls.getAlerts as any;
    // alertDetails = alertDetails.replace("USER_NAME", userName);
    return this.http
      .get(alertDetails, { headers: headers });
  }

  getReadStatus(AlertId) {
    
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
      "Authorization": "Bearer " + ppjwt.access_token,
      "Content-Type": "application/json"
    }); 
    const payload = {
      alertId: AlertId,
    };
    // let alertStatusUrl = urls.getAlertReadStatus as any;
    // alertStatusUrl = alertStatusUrl.replace('alertId',AlertId );
    return this.http.post(urls.getAlertReadStatus, payload, {
      headers: headers,
    });
  }

  getAlertCount(userName: String) {
    
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
      "Accept": "application/json",
      "Authorization": "Bearer " + ppjwt.access_token
    });
    let alertcount = urls.getAlertCount as any;
    // alertcount = alertcount.replace("USER_NAME", userName);

    return this.http
      .get(alertcount, { headers: headers });
  }
  getAlertCountalongSev(userName: String) {
   
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
      "Accept": "application/json",
      "Authorization": "Bearer " + ppjwt.access_token
    });
    let alertcount = urls.getAlertCountSev as any;
    // alertcount = alertcount.replace("USER_NAME", userName);

    return this.http
      .get(alertcount, { headers: headers });
  }

  getAllCounts() {
    try {
      this.getAllCountsfromDB().subscribe((combinedResponses:any) => {
        if (combinedResponses && isArray(combinedResponses)) {
          let sevCountwithOutAppId:any = combinedResponses[0];
          let highwithoutId = 0;
            let medwithoutId = 0;
            let lowwithoutId = 0;
          sevCountwithOutAppId.forEach((elementwithSev, index) => {
            if(elementwithSev.Count !== 0){
            if (elementwithSev.Severity.toLowerCase() === "high") {
              this.unreadHighCountwithoutId = elementwithSev.Count
                ? this.unreadHighCountwithoutId+elementwithSev.Count
                :this.unreadHighCountwithoutId+ 0;
              highwithoutId = elementwithSev.Count ? highwithoutId+elementwithSev.Count :highwithoutId+ 0;
              // this.highCount.next(this.unreadHighCount );
            }
            if (elementwithSev.Severity.toLowerCase() === "medium") {
              this.unreadMedCountwithoutId = elementwithSev.Count
                ? this.unreadMedCountwithoutId+elementwithSev.Count
                : this.unreadMedCountwithoutId+0;
              // this.medCount.next(this.unreadMedCount);
              medwithoutId = elementwithSev.Count ?  medwithoutId+elementwithSev.Count : medwithoutId+0;
            }
            if (elementwithSev.Severity.toLowerCase() === "low") {
              this.unreadLowCountwithoutId = elementwithSev.Count
                ? this.unreadLowCountwithoutId+elementwithSev.Count
                :this.unreadLowCountwithoutId+ 0;
              // this.lowCount.next(this.unreadLowCount);
              lowwithoutId = elementwithSev.Count ?lowwithoutId+ elementwithSev.Count :lowwithoutId+ 0;
            }
          }else {
              highwithoutId = 0;
              lowwithoutId = 0;
              medwithoutId = 0;
            }
          });
          if (highwithoutId === 0) {
            this.unreadHighCountwithoutId = 0;
          }
          if (medwithoutId === 0) {
            this.unreadMedCountwithoutId = 0;
          }
          if (lowwithoutId === 0) {
            this.unreadLowCountwithoutId = 0;
          }
          let respd:any = combinedResponses[0]
          let sevCount = respd.filter((x) => {
            if (x.APPLICATION_ID && x.APPLICATION_ID !== null) {
              return x.APPLICATION_ID.toLowerCase() === "pahaf";
            } else {
              this.unreadMedCount = 0;
              this.unreadHighCount = 0;
              this.unreadLowCount = 0;
            }
          });
          let count:any = combinedResponses[1];
          let high = 0,
            mid = 0,
            low = 0;
          // console.log("sevCount", sevCount, count, sevCountwithOutAppId);
          if (sevCount && sevCount.length > 0) {
            sevCount.forEach((elementwithSev, index) => {
              if (elementwithSev.Severity.toLowerCase() === "high") {
                this.unreadHighCount = elementwithSev.Count
                  ? elementwithSev.Count
                  : 0;
                high = elementwithSev.Count ? elementwithSev.Count : 0;
                // this.highCount.next(this.unreadHighCount );
              }
              if (elementwithSev.Severity.toLowerCase() === "medium") {
                this.unreadMedCount = elementwithSev.Count
                  ? elementwithSev.Count
                  : 0;
                // this.medCount.next(this.unreadMedCount);
                mid = elementwithSev.Count ? elementwithSev.Count : 0;
              }
              if (elementwithSev.Severity.toLowerCase() === "low") {
                this.unreadLowCount = elementwithSev.Count
                  ? elementwithSev.Count
                  : 0;
                // this.lowCount.next(this.unreadLowCount);
                low = elementwithSev.Count ? elementwithSev.Count : 0;
              }
            });
            this.allCount = high + mid + low;
            if (high === 0) {
              this.unreadHighCount = 0;
            }
            if (mid === 0) {
              this.unreadMedCount = 0;
            }
            if (low === 0) {
              this.unreadLowCount = 0;
            }
          } else {
            this.unreadHighCount = 0;
            this.unreadMedCount = 0;
            this.unreadLowCount = 0;
            this.allCount = 0;
          }
          this.alertCount = count.Count ? count.Count : 0;
          this.secureStorage.setSecureInfoInSessionlStorage("count", this.alertCount.toString());
          this.secureStorage.setSecureInfoInSessionlStorage("appCount", this.allCount.toString());
          // this.count.next(count.Count ? count.Count : 0);

          this.allUnreadserviceAlertCount.next({
            mainCount: this.alertCount,
            allCountwithSev: this.allCount,
            highCount: high,
            medCount: mid,
            lowCount: low,
            highcountwithoutId: highwithoutId,
            medcountwithoutId: medwithoutId,
            lowCountwithoutId: lowwithoutId,
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
  public getAllCountsfromDB() {
    
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
      "Accept": "application/json",
      "Authorization": "Bearer " + ppjwt.access_token
    });
    let alertcountwithSev = urls.getAlertCountSev as any;
    // alertcountwithSev = alertcountwithSev.replace("USER_NAME", this.userName);

    
    let alertcount = urls.getAlertCount as any;
    // alertcount = alertcount.replace("USER_NAME", this.userName);

    return forkJoin([
      this.http
        .get(alertcountwithSev, { headers: headers })
        .pipe(catchError((err) => of(err.status))),
      this.http
        .get(alertcount, { headers: headers })
        .pipe(catchError((err) => of(err.status))),
    ]);
  }
  // ngOnDestroy() {
  //   console.log('ngOnDestroy: cleaning up...');
  //   this.clearData();
  // }
  clearData() {
    this.allCardData = [];
  }
  getFormattedDateStr(date: Date) {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " 00:00:00:000"
    );
  }
  clearFrequentCalling(){
   
    clearInterval(this.taskScheduler);
  }
}
