import { Injectable } from '@angular/core';
import { urls } from '../common/model/properties';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SecureStorageService } from '../secure-storage.service';

@Injectable()
export class AccountProfileService {

    private forgotUser: any = '';
    constructor( private http: HttpClient,
        private secureStorage:SecureStorageService ) { }

    getUserProfile( userName, callBack: ( sucess: boolean, message: string ) => void ) {

        // const headers = new Headers();
        //const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        // headers.set( 'Accept', 'application/json' );
        //headers.set( 'Authorization', 'Bearer ' +  ppjwt.access_token );
        
    //   headers.set( 'Accept', 'application/json;charset=UTF-8' );
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});

        this.http.get( urls.accountProfileUrl.replace('USER_NAME', userName), {headers: headers})
            .subscribe(
            ( resp ) => {
                callBack( true,  resp['user'][0]  );
            },
            err => {
                callBack( false, err.status );
            }
            );
    }
    getUserProfile2( userName): Promise<any> {
        // const headers = new Headers();
        //const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        // headers.set( 'Accept', 'application/json' );
        //headers.set( 'Authorization', 'Bearer ' +  ppjwt.access_token );
        // headers.set( 'Accept', 'application/json;charset=UTF-8' );
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Accept':'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + ppjwt.access_token
        });
        return this.http.get( urls.accountProfileUrl, {headers: headers}).toPromise();
    }

    getUserProfilePost( userName): Promise<any> {
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Accept':'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + ppjwt.access_token
        });
        const payload = {
            userName  
        };

        return this.http.post( urls.getProviderDetailsPost, payload, {headers: headers}).toPromise();
    }
}
