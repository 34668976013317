import { Component, OnInit, PipeTransform, Pipe } from '@angular/core';
import { DocumentsService } from './documents.service';
import { KeysPipe } from './key-value.pipe';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from "file-saver";
import * as _ from 'lodash';
import { TreeNode } from 'angular-tree-component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClassStmt } from '@angular/compiler';
import { ProviderportalService } from '../providerportal/providerportal.service';
import { SecureStorageService } from '../secure-storage.service';

declare var jQuery: any;

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
    providers: [DocumentsService]
})
export class DocumentsComponent implements OnInit {

    constructor(private documentsService: DocumentsService, private route: ActivatedRoute, private currentService: ProviderportalService,
        private secureStorage:SecureStorageService) { }
    documents: [any];
    documentCode = 'GEN_REF_DOC';
    loading = false;
    // this.loading = false;
    kvpl = [];
    userState: any;
    clientAccessStates: Array<any> = [];
    data = [];
    handleResults(searchObj) {
        this.data = searchObj;
    }
    selectedNodeChildren =[];
    parentNodeList =[];
    activeNode:any;

    ngOnInit() {
        this.route.queryParamMap.subscribe(params => {
            if (params && params['params'] && params['params']['documentCode']) {
                this.isTreeLoaded = false;
                this.documentCode = params['params']['documentCode'];
            }
            this.getDocuments();

        });
        if (this.secureStorage.getSecureInfoInSessionStorage('userProfile')) {
            this.userState = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('userProfile')).state;
        }
        this.getClientDetails();
    }
    getDocuments() {
        if (this.documentCode === 'PROV_COMM_DOC') {
            this.kvpl = [{ 'key': 'State', 'value': JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('userProfile'))['state'] }];
        } else {
            this.kvpl = [];
        }
        this.loading = true;
        this.documentsService.listDocuments(this.documentCode, this.kvpl, 1, 100).subscribe((res:any) => {
            this.documents = res.keyValuePairListWrapperlist;
            if (this.documents && this.documents.length) {
                this.generateTreeMap(this.documents);
            }
            this.loading = false;
        });
        this.loading = false;
    }
    getvalue(value, key) {
        for (let i = 0; i < value.keyvaluePairWr.length; i++) {
            if (value.keyvaluePairWr[i].key == key) {
                return value.keyvaluePairWr[i].value;
            }
        }
    }

    getTodayDate() {
        return new Date();
    }

    onEvent(event:any){

        if(event.eventName == "initialized"){
            this.selectedNodeChildren = event.treeModel.nodes.filter(child => !child.hasChildren);
           this.parentNodeList = this.selectedNodeChildren
        }
        
        if(event.eventName == "focus" || event.eventName == "loadNodeChildren" || event.eventName == "toggleExpanded"){   
            this.selectedNodeChildren = event.node.data.children?.filter(child => !child.hasChildren);
            this.activeNode = event.node;
        }    

        //code for if all node closed at last to display root node documents
        if(event.eventName == "toggleExpanded" || event.eventName == "activate"){
            let expandedNodeIds = event.treeModel.expandedNodeIds;
            let allNodesCollapsed = Object.values(expandedNodeIds).every(value => value === false);
            if(allNodesCollapsed){
                this.activeNode = null;
                this.selectedNodeChildren = [...this.parentNodeList];

            }
        }    
        
    }

   

    getDocument(doc) {
        this.loading = true;
        const docId = doc.id;//this.getvalue(doc, 'Id');
        const docClass = this.documentCode;//this.getvalue(doc, 'DocumentClass');
        this.documentsService.getDocument(docId, docClass, null).subscribe((res:any) => {
            const responseData = res;
            const state = _.find(responseData.keyValuePairList, ['key', 'State']);
            if (state && (state.value === this.userState || this.clientAccessStates.includes(state.value)) || this.documentCode === 'GEN_REF_DOC') {

                if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                    const blob = this.binary64ToBlob(responseData.docContent, responseData.fileType, 512);
                    saveAs(blob, responseData.filename);
                    this.loading = false;
                } else {
                    const blob = this.b64toBlob(responseData.docContent, responseData.fileType, 512);
                    const urlBlob = URL.createObjectURL(blob);
                    window.open(urlBlob, '_blank', 'location=yes,height=570,width=520');
                    this.loading = false;
                }
            } else {

                jQuery('#errorModal').modal('show');
                this.loading = false;
            }
        });
    }

    getClientDetails() {
        this.currentService.getClientDetails().subscribe((response:any) => {
            if (response && response.length) {
                response.map(data => {
                    if(data.market){
                        data.market.map(row =>{
                            this.clientAccessStates.push(row.stateCode)
                        })
                    }
                })
               // console.log(this.clientAccessStates)
            }


        })
    };

    onNodeClick(node, event) {

        this.activeNode = node;

         //code for if all node closed at last to display root node documents
         if(event.eventName == "toggleExpanded" || event.eventName == "activate" && event.isExpanded == false){
            let expandedNodeIds = event.treeModel.expandedNodeIds;
            let allNodesCollapsed = Object.values(expandedNodeIds).every(value => value === false);

            if(allNodesCollapsed){        
                this.selectedNodeChildren = [...this.parentNodeList];
                this.activeNode = null;
            }
        }
        else {
            this.selectedNodeChildren = node.data.children?.filter(child => !child.hasChildren);

        }
        node.toggleExpanded();
        event.stopPropagation();
    }

    binary64ToBlob(binary64String, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        const byteCharacters = atob(binary64String);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    nodes: any = [];

    generateTreeMap(data) {
        this.nodes = [];
        data.forEach(row => {
            const treeNode = getTreeNode(row);
            const isExits = _.find(this.nodes, ['id', treeNode.id]);
            if (!isExits) {
                this.nodes.push(treeNode);
            }
        });
        this.isTreeLoaded = true;
    }
    isTreeLoaded: boolean;
    timeout;
    options = {
        nodeClass: (node: TreeNode) => node.hasChildren ? 'has-children' : 'no-children',
        getChildren: (node: TreeNode): Promise<TreeNode[]> => {
            let array = []
            return this.documentsService.listDocumentsBId(this.documentCode, this.kvpl, 1, 100, node.id).then((res:any) => {
                const data = res.keyValuePairListWrapperlist;
                if (!data) return [{ id: 0, name: 'No Files Found' }];
                data.forEach(row => {
                    const treeNode = getTreeNode(row);
                    const isExits = _.find(array, ['id', treeNode.id]);
                    if (!isExits) {
                        array.push(treeNode);
                    }
                });
                return array;
            })
        }
    }
    
}

function getTreeNode(row) {
    const objectType = _.find(row.keyvaluePairWr, ['key', "ObjectType"]);
    if (objectType && objectType.value == "Folder") {
        const id = _.find(row.keyvaluePairWr, ['key', "FolderGUID"]);
        const title = _.find(row.keyvaluePairWr, ['key', "FolderName"]);
        return ({
            id: id.value,
            name: title.value,
            hasChildren: true
        })
    } else if (objectType && objectType.value == "Document") {
        const id = _.find(row.keyvaluePairWr, ['key', "DocumentGUID"]);
        const title = _.find(row.keyvaluePairWr, ['key', "DocumentName"]);
        const className = _.find(row.keyvaluePairWr, ['key', "DocumentClass"]);
        return ({
            id: id.value,
            name: title.value,
            class: className.value,
            hasChildren: false
        })
    } else {
        const id = _.find(row.keyvaluePairWr, ['key', 'Id']);
        const title = _.find(row.keyvaluePairWr, ['key', 'DocumentTitle']);
        const className = _.find(row.keyvaluePairWr, ['key', "DocumentClass"]);

        return ({
            id: id.value,
            name: title.value,
            class: className.value,

        })
    }
}


