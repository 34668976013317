<div class="footer-wrapper" [class]="className" [hidden]="isLoggedIn === false">
	<div class="row full-width">
		<div class="col-md-12 footer-links">
			<div>
				<a><img src="assets/images/logo-footer.svg" style="width: 100px;"></a>
				<!-- <ul>
					<li class="border-right"><a href="#">Privacy Policies</a></li>
					<li><a href="#">Terms of use</a></li>
					
				</ul> -->
				<ul>
					<li class="border-right" *ngIf = "!hideHelpFaq"><a href="#/providerportal/connect">Help</a></li>
					<li *ngIf = "!hideHelpFaq"><a href="#/providerportal/faq">FAQ</a></li>
				</ul>

				<span class="copy-write"> &copy; 2024 Carelon Health.  All rights reserved.</span>

				
			</div>
			
		</div>
		
		<!-- <div class="col-md-4 social-media-links">
			<ul>
				<li><a href="#"><img src="assets/images/icons/social-media/linkedin.jpg"></a></li>
				<li><a href="#"><img src="assets/images/icons/social-media/twitter.jpg"></a></li>
				<li><a href="#"><img src="assets/images/icons/social-media/youtube.jpg"></a></li>
				<li><a href="#"><img src="assets/images/icons/social-media/facebook.jpg"></a></li>
			</ul>
		</div> -->
		<!-- <div class="col-md-12">
			<ul class="nav nav-pills">
				<li role="presentation"><a href="#/providerportal/faq">FAQ</a></li>
				<li role="presentation"><a href="#/providerportal/connect">HELP</a></li>
			</ul>
		</div> -->
	</div>
</div>