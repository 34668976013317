import { Component, OnInit } from '@angular/core';
import { FooterConnectService } from './connect.service';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { FieldValidateService } from '../../common_services/fieldvalidate.service';
import { ConfigProvider } from '../../shared/services/configprovider';
import { SecureStorageService } from '../../secure-storage.service';

declare var jQuery: any;

@Component({
    selector: 'app-FooterConnect',
    templateUrl: './connect.component.html',
    styleUrls: ['./connect.component.scss'],
    providers: [FooterConnectService]
})
export class FooterConnectComponent implements OnInit {

    enquiryList: any;
    loading = false;
    // this.loading = false;
    displayOptions = [];
    config:any;
    data = [];
    handleResults(searchObj) {
        this.data = searchObj;
    }
    constructor(private router: Router, private currentService: FooterConnectService, 
        private fieldValidator: FieldValidateService,private configProvider:ConfigProvider,
        private secureStorage:SecureStorageService) { 
        this.config = this.configProvider.getConfig();
        this.displayOptions = this.config.helpDesk; }

    model = {
        name: this.secureStorage.getSecureInfoInSessionStorage('fullName'),
        phoneNumber: JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('userProfile'))['phone'],
        email: JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('userProfile'))['email'],
        selectedEnquiry: '',
        subject: '',
        message: ''
    };
    errorMessage = '';

    ngOnInit() {
        this.currentService.getEnquiryList((success: boolean, response: string) => {
            this.loading = true;
            if (success) {
                this.loading = false;
                this.enquiryList = response;
            } else {
                this.loading = false;
            }
        });
    }

    myModalEventCallBack() { }
    decimalRestrict(event) { }

    onSubmit() {
        if (this.model.name === '' ||
            this.model.phoneNumber === '' ||
            this.model.selectedEnquiry === '' ||
            this.model.subject === '' ||
            this.model.message === '') {
            this.showAlertPopup('All fields are required', () => { });
            return;
        } else if (!this.fieldValidator.validatePhone(this.model.phoneNumber)) {
            this.showAlertPopup('Phone is not valid', () => { });
            return;
        }
        this.loading = true;
        this.currentService.sendMailRequest(this.model, (success: boolean, message: any) => {

            if (success) {
                this.loading = false;
                this.resetForm();
                this.showAlertPopup('Successfully sent your message.', () => { this.router.navigate(['providerportal']); });
            } else {
                this.loading = false;
                this.showAlertPopup('Unable to send your Message. Please contact the Administrator', () => { this.router.navigate(['providerportal']); });
            }

        });
    }

    private showAlertPopup(message: string, callBack) {
        this.errorMessage = message;
        this.showPopup('myModal', callBack);
        return;
    }

    private showPopup(popupName: string, callBack) {
        jQuery('#' + popupName).modal('show');
        this[popupName + 'EventCallBack'] = callBack;
    }
    onPhoneKeyUp(event: any) {
        const inputValue: string = event.target.value;
        const numberValue = this.extractNumber(inputValue);
        const formattedPhone = this.constructPhoneFormat(numberValue);

        this.model[event.target.name] = formattedPhone;
        event.target.value = formattedPhone;
    }

    constructPhoneFormat(value: string) {
        let newVal = value;
        if (value.length >= 4) {
            newVal = '(' + value.substring(0, 3) + ')';
            newVal += ' ' + value.substring(3, (value.length <= 6) ? value.length : 6);
            if (value.length > 6) {
                newVal += '-' + value.substring(6, (value.length <= 10) ? value.length : 10);
            }
        }
        return newVal;
    }

    extractNumber(value: string) {
        let newVal = '';
        if (isNaN(Number(value))) {
            for (let i = 0; i < value.length; i++) {
                const s: string = value.charAt(i);
                if (!isNaN(Number(s)) && s !== ' ') {
                    newVal += s;
                }

            }
        } else {
            newVal = value;
        }

        return newVal;
    }
    ngViewAfterInit() {
        // ('[data-toggle="tooltip"]').tooltip()
        jQuery('#sujet').tooltip({ 'trigger': 'focus', 'title': 'Password tooltip' });
        jQuery('#sujetBtm').tooltip({ 'trigger': 'focus', 'title': 'Password tooltip' });

    }

    resetForm() {
        this.model.selectedEnquiry = '';
        this.model.subject = '';
        this.model.message = '';
    }
}
