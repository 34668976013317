export const TEST_CONFIG = {
        email: 'Mohan.Tagore@caremore.com',
        email_subject: 'Test-ignore:',
        cup_base_url: '',
        department: 'providerportal-tst.caremore.com/#',
        GeneralReference_GUID: '{1C60E615-AE6B-4758-8DF7-8465B819E3BB}',
        ProviderCommunications_GUID: '{6642BCBF-97DD-4206-8241-A5B0DEFEEE07}',
        registrationType: [
                'Primary Care Provider user',
                'Provider specialist user',
                'Ancillary Provider/Vendor user',
                'Provider Billing department user'],
        userIdleConfig: {
                idle: 7200,
                timeout: 60,
                ping: 60
        }
};
