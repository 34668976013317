import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CupService } from '../services/cup.service';
import { Router } from '@angular/router';

@Component({
  selector: 'cup-traversal',
  templateUrl: './traversal.component.html',
  styleUrls: ['./traversal.component.css']
})
export class TraversalComponent implements OnInit {
  @Input() traversalData: any;
  @Output() traverseTowards = new EventEmitter<any>();
  public getMemberData: any;
  public firstRecord: number;
  public lastRecord: number;

  constructor(private cupService: CupService, private router: Router) {
    this.getInitialMember();
    this.getUpdatedRowNumber();
  }

  /*Get First member on load*/
  public getInitialMember() {
    // if (this.router.url.indexOf('home/member/member-details') !== -1
    //   || this.router.url.indexOf('home/member/eligibility-request/view-eligibility') !== -1) {
    //   this.getMemberData = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('memberTraversal'));
    //   this.firstRecord = this.getMemberData[0].rowNum;
    //   this.lastRecord = this.getMemberData[this.getMemberData.length - 1].rowNum;
    // } else if (this.router.url.indexOf('/home/provider/provider-details') !== -1) {
    //   this.getMemberData = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('providerTraversal'));
    //   this.firstRecord = this.getMemberData[0].rowNum;
    //   this.lastRecord = this.getMemberData[this.getMemberData.length - 1].rowNum;
    // } else if (this.router.url.indexOf('/home/general/auth-summary') !== -1) {
    //   this.getMemberData = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('authTraversal'));
    //   this.firstRecord = this.getMemberData[0].rowNum;
    //   this.lastRecord = this.getMemberData[this.getMemberData.length - 1].rowNum;
    // }
  }

  /* Subscribe to changes in row number*/
  public getUpdatedRowNumber() {
    this.cupService.getCurrentRecord.subscribe(data => {
      this.traversalData.rowNum = data;
    });
    this.cupService.getLastRecord.subscribe(data => {
      this.traversalData.totalRecords = data;
    });
  }

  /* Emit on click*/
  public traverse(towards: string) {
    if (this.router.url.indexOf('home/member/member-details') !== -1
      || this.router.url.indexOf('home/member/eligibility-request/view-eligibility') !== -1) {
      const traversalObject = {
        traverseTo: towards,
        rowNum: this.traversalData.rowNum,
        id: this.traversalData.id,
        uniqueId: this.traversalData.memberId
      };
      this.traverseTowards.emit(traversalObject);

    } else if (this.router.url.indexOf('/home/provider/provider-details') !== -1) {
      const traversalObject = {
        traverseTo: towards,
        rowNum: this.traversalData.rowNum,
        id: this.traversalData.id,
        uniqueId: this.traversalData.providerId,
      };
      this.traverseTowards.emit(traversalObject);
    } else if (this.router.url.indexOf('/home/general/auth-summary') !== -1 ||
      this.router.url.indexOf('/home/member/auth-summary') !== -1) {
      const traversalObject = {
        traverseTo: towards,
        rowNum: this.traversalData.rowNum,
        id: this.traversalData.id,
        uniqueId: this.traversalData.authId,
      };
      this.traverseTowards.emit(traversalObject);
    } else if (this.router.url.indexOf('home/worklist/deniedauth-details') !== -1) {
      const traversalObject = {
        traverseTo: towards,
        rowNum: this.traversalData.rowNum,
        id: this.traversalData.id,
        uniqueId: this.traversalData.authUid,
        aging: this.traversalData.aging
      };
      this.traverseTowards.emit(traversalObject);
    }


  }


  ngOnInit() { }
}
