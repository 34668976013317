<div class="alertClass">
  <div class="headingStyle" *ngFor="let tab of tabData">
    <span
      [ngClass]="{
        highTab: tab.index === 0,
        medTab: tab.index === 1,
        lowTab: tab.index === 2,
        allTab: tab.index === 3
      }"
      (click)="onTabClick(tab)"
      >{{ tab.value }}
      <span *ngIf="tab.index === 0 && unReadHighAlertCount!== 0 " class="badge badge-secondary high-count">{{
        unReadHighAlertCount
      }}</span>
      <span *ngIf="tab.index === 1 && unReadMedAlertCount!== 0" class="badge badge-secondary med-count">{{
        unReadMedAlertCount
      }}</span>
      <span *ngIf="tab.index === 2 && unReadLowAlertCount!==0" class="badge badge-secondary low-count">{{
        unReadLowAlertCount
      }}</span>
      </span
    >
    <!-- <span [ngClass]="selectedTabIndex === tab.index ? 'indicatorStyle' : ''"></span> -->
    <span
      [ngClass]="{
        indicatorStyle2:
          selectedTabIndex === 0 && selectedTabIndex === tab.index,
        indicatorStyle3:
          selectedTabIndex === 1 && selectedTabIndex === tab.index,
        indicatorStyle4:
          selectedTabIndex === 2 && selectedTabIndex === tab.index,
        indicatorStyle: selectedTabIndex === 3 && selectedTabIndex === tab.index
      }"
    ></span>
  </div>
</div>
