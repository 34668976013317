/***************************************
 Modified BY : Kathiresan
 Last Modified Date : 29/06/2018
**************************************/
import { Injectable, ElementRef } from '@angular/core';



import { UrlconstantsService } from './urlconstants.service';
import * as xml2js from 'xml2js';
import { ConfigModel } from '../models/config.model';
import { ConfigProvider } from './configprovider';
import { urls } from '../../common/model/properties';
import { CupError } from '../models/cuperror.model';
import { ErrorType } from '../models/utility.model';
import { StringConstands } from '../models/utilitystrings.model';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SecureStorageService } from '../../secure-storage.service';
import { Subject, Observable, interval, forkJoin, of } from 'rxjs';

@Injectable()

export class CupService {
  public memberPCPName = new Subject<string>();
  public selectedMember = new Subject<string>();
  public authFormData = new Subject<string>();
  public selectedAuth = new Subject<string>();
  public selectedProvider = new Subject<string>();
  public getCurrentPath = new Subject<string>();
  public getCurrentRecord = new Subject<string>();
  public getLastRecord = new Subject<string>();
  public initiateSearch = new Subject<boolean>();
  public memberSearchAuth = new Subject<boolean>();
  public memberSearchAuthData = new Subject<boolean>();
  public getSearchData = new Subject<string>();
  public clearAllSearch = new Subject<boolean>();
  public initiateProviderSearch = new Subject<boolean>();
  public getProviderSearchData = new Subject<string>();
  public getPCPName = new Subject<any>();
  public getLookUp = new Subject<boolean>();
  public retainSearch = new Subject<boolean>();
  public clearAll = new Subject<any>();
  public searchFilterData = new Subject<any>();
  public loadSpinner = new Subject<any>();
  public overAllSearch = new Subject<any>();
  public overAllSearchResultSingle = new Subject<any>();
  public clearNameInOverAllSearch = new Subject<any>();
  public initiateGeneralAuthSearch = new Subject<any>();
  public getGeneralAuthSearchData = new Subject<string>();
  public getGeneralSearchAuthProviderFilter = new Subject<any>();
  public isGeneralSearchAuthClicked = new Subject<any>();
  public invokeGeneralSearchAuthProviderFilter = new Subject<any>();
  public showSpinner = new Subject<boolean>();
  public progressObserver = new Subject<any>();
  public retainPanelOnClick = new Subject<any>();
  public retainProviderPanelOnClick = new Subject<any>();
  public retainAdminPanelOnClick = new Subject<any>();
  public getPanelOnClick = new Subject<any>();
  public getAdminPanelOnClick = new Subject<any>();
  public retainSearchPanel = new Subject<any>();
  public initiateWorkListSearch = new Subject<any>();
  public getWorkListData = new Subject<any>();
  public isAuthNoteViewReset = new Subject<any>();
  public isAdhocLetterViewReset = new Subject<any>();
  // public isAuthNoteReviewReset = new Subject<boolean>();
  public resetPendingWorkList = new Subject<any>();
  public resetCaseManagementWorkList = new Subject<any>();
  public resetLetterWorkList = new Subject<any>();
  public initiateUserAccessSearch = new Subject<any>();
  public getSearchAccessData = new Subject<any>();
  public clearSearchAccess = new Subject<any>();
  public initiateAuthTemplateSearch = new Subject<any>();
  public getSearchTemplateData = new Subject<any>();
  public clearSearchTemplate = new Subject<any>();
  public searchPendWorklistFieldVisibility = new Subject<boolean>();

  public topLevelMenuClick = new Subject<boolean>();

  public setauthNotesServiceArray: any;
  public setauthNotesServiceArrayIndex: number;
  public setauthNotesServiceJSONArray = [];
  public generalUnreadMessageCountValue: any;
  public generalUnreadMessageCountSubscription: any;
  public refreshTimerSubscription: any;

  public userTemplateId = new Subject<any>();
  public previewAttachment = new Subject<any>();
  public deleteAttachment = new Subject<any>();
  public clearMailAttachment = new Subject<any>();
  public replyClicked = new Subject<any>();
  public internalUserSearchInitiated = new Subject<any>();
  public searchedInternalUser = new Subject<any>();
  public isInternalUserSearch = new Subject<any>();
  public internalUserPanelDetails = new Subject<any>();
  public clearInternalUserSearch = new Subject<any>();
  public isResetInternalPanel = new Subject<any>();
  public initiateLogin = new Subject<any>();

  private config: ConfigModel;
  private applicationKeyHeader: string;
  private loggedInUserAccess: any;

  constructor(private http: HttpClient, private urlconstantsService: UrlconstantsService,
    private secureStorage:SecureStorageService) {
    // this.config = configProvider.getConfig();
    // this.applicationKeyHeader = 'Basic ' + this.config.applicationKey;
  }

  /*Get the PCP details*/
  public memberData = this.selectedMember.asObservable();
  public getSelectedMember(text: any) {
    this.selectedMember.next(text);
  }
  /*Get the PCP details*/
  public pcpName$ = this.memberPCPName.asObservable();
  public updatePCP(text: any) {
    this.memberPCPName.next(text);
  }

  public authSubmissionId$ = this.authFormData.asObservable();
  public updateAuthSubmissionData(text: any) {
    this.authFormData.next(text);
  }

  /*Get Selected Auth*/
  public authNames = this.selectedAuth.asObservable();
  public getSelectedAuth(text: any) {
    this.selectedAuth.next(text);
    this.authNames = text;
  }

  /*Get Selected Provider*/
  public providers = this.selectedProvider.asObservable();
  public getSelectedProvider(text: any) {
    this.selectedProvider.next(text);
  }

  /*Get Selected Auth Path*/
  public currentNavigation = this.getCurrentPath.asObservable();
  public getSelectedPath(text: any) {
    this.getCurrentPath.next(text);
  }
  /*Get traversal data*/
  public currentTraversal = this.getCurrentRecord.asObservable();
  public lastTraversal = this.getLastRecord.asObservable();
  public updateTraversalDetails(current: any, end) {
    this.getCurrentRecord.next(current);
    this.getLastRecord.next(end);
  }
  /*Share Member search panel details to load grid*/
  public memberSearch = this.initiateSearch.asObservable();
  public updateSearchPanelDetails(search: any, searchCriteria?: any, clear?: any) {
    this.initiateSearch.next(search);
    this.getSearchData.next(searchCriteria);
    this.clearAllSearch.next(clear);
  }
  /* get member search auth get details */
  public memberSearchAuthrization = this.memberSearchAuth.asObservable();
  public updateAuthSearchPanelDetails(search: any, searchCriteria?: any) {
    this.memberSearchAuth.next(search);
    this.memberSearchAuthData.next(searchCriteria);
  }
  /*Get diagnosis search panel details to load grid*/
  public initiateDiagnosisSearch = new Subject<boolean>();
  public getDiagnosisSearchData = new Subject<string>();
  public diagnosisSearch = this.initiateDiagnosisSearch.asObservable();
  public updateDiagnosisSearchPanelDetails(search: any, searchCriteria?: any) {
    this.initiateDiagnosisSearch.next(search);
    this.getDiagnosisSearchData.next(searchCriteria);
  }

  /*Get procedure search panel details to load grid*/
  public initiateProcedureSearch = new Subject<boolean>();
  public getProcedureSearchData = new Subject<string>();
  public procedureSearch = this.initiateProcedureSearch.asObservable();
  public updateProcedureSearchPanelDetails(search: any, searchCriteria?: any) {
    this.initiateProcedureSearch.next(search);
    this.getProcedureSearchData.next(searchCriteria);
  }

  /*Share Provider search panel details to load grid*/
  public providerSearch = this.initiateProviderSearch.asObservable();
  public updateProviderSearchPanelDetails(search: any, searchCriteria?: any) {
    this.initiateProviderSearch.next(search);
    this.getProviderSearchData.next(searchCriteria);
  }
  /*Share pending worklist details to load grid*/
  public updatePendingWorkListPanelDetails(search: any, searchCriteria?: any) {
    this.initiateWorkListSearch.next(search);
    this.getWorkListData.next(searchCriteria);
  }
  /*Share casemanagement worklist details to load grid*/
  public updateCaseManagementWorkListPanelDetails(search: any, searchCriteria?: any) {
    this.initiateWorkListSearch.next(search);
    this.getWorkListData.next(searchCriteria);
  }
  public refineSearchClearAll = this.clearAll.asObservable();
  public isRefineSearchClearAll(clear: boolean) {
    this.clearAll.next(clear);
  }

  public searchFilterDataObj = this.searchFilterData.asObservable();
  public updateSearchFilterDataInPanel(data: any) {
    this.searchFilterData.next(data);
  }

  /*Share PCP lookup details*/
  public pcpName = this.getPCPName.asObservable();
  public selectedProviderPCP(showLookUp: boolean, search?: any) {
    this.getPCPName.next(search);
    this.getLookUp.next(showLookUp);
  }

  /*Share refine Search details*/
  public retain = this.retainSearch.asObservable();
  public isSearchRetained(search: boolean, panel?: any) {
    this.retainSearch.next(search);
    this.retainSearchPanel.next(panel);
  }

  /*Share loading spinner details*/
  public spin = this.loadSpinner.asObservable();
  public isLoading(search: boolean) {
    this.loadSpinner.next(search);
  }

  public spinTest = this.showSpinner.asObservable();
  public isShowSpinner(search: boolean) {
    this.showSpinner.next(search);
  }

  public isAddAuthNoteView(data) {
    this.isAuthNoteViewReset.next(data);
  }

  public isAdhocLetterView(data: any) {
    this.isAdhocLetterViewReset.next(data);
  }

  // public isAddAuthNoteReview(reViewFlag:boolean) {
  //     this.isAuthNoteReviewReset.next(reViewFlag);
  // }

  /*Share upload progress bar details*/
  public progress = this.progressObserver.asObservable();
  public progressBarObserver(progress) {
    this.progressObserver.next(progress);
  }

  /*First level menu clicked*/
  public isTopLevelMenuCliked(clicked: boolean) {
    this.topLevelMenuClick.next(clicked);
  }


  public getAddAuthNoteArray() {
    if (this.setauthNotesServiceArray != null && this.setauthNotesServiceArray != undefined) {
      this.setauthNotesServiceJSONArray.push(this.setauthNotesServiceArray);
      this.setauthNotesServiceJSONArray.push(this.setauthNotesServiceArrayIndex);
      return this.setauthNotesServiceJSONArray;
    } else {
      return this.setauthNotesServiceJSONArray;
    }

  }

  /*Share General Auth search panel details to load grid*/
  public generalSearch = this.initiateGeneralAuthSearch.asObservable();
  public updateGeneralAuthSearchPanelDetails(search: any, searchCriteria?: any) {
    this.initiateGeneralAuthSearch.next(search);
    this.getGeneralAuthSearchData.next(searchCriteria);
  }
  public generalSearchAuthPCP = this.getGeneralSearchAuthProviderFilter.asObservable();
  public selectedGeneralSearchAuthPCP(showHideLookup: boolean, pcp?: any) {
    this.invokeGeneralSearchAuthProviderFilter.next(showHideLookup);
    this.getGeneralSearchAuthProviderFilter.next(pcp);
  }

  public generalSearchAuth = this.isGeneralSearchAuthClicked.asObservable();
  public updateGeneralSearchAuth(click: boolean) {
    this.isGeneralSearchAuthClicked.next(click);
  }

  /*Share search panel input clicks for member module*/
  public retainPanel = this.retainPanelOnClick.asObservable();
  public panelName = this.getPanelOnClick.asObservable();
  public isretainPanelOnClick(retain: boolean, panel?: any) {
    this.retainPanelOnClick.next(retain);
    this.getPanelOnClick.next(panel);
  }

  /*Share search panel input clicks for provider module*/
  public retainProviderPanel = this.retainProviderPanelOnClick.asObservable();
  public isretainProviderPanelOnClick(retain: boolean, panel?: any) {
    this.retainProviderPanelOnClick.next(retain);
  }

  /*Share search panel input clicks for member module*/
  public retainAccessPanel = this.retainAdminPanelOnClick.asObservable();
  public panelNames = this.getAdminPanelOnClick.asObservable();
  public isRetainAdminPanelsOnClick(retain: boolean, panel?: any) {
    this.retainAdminPanelOnClick.next(retain);
    this.getAdminPanelOnClick.next(panel);
  }

  /*Share Access permission panel details to load grid*/
  public accessSearch = this.initiateUserAccessSearch.asObservable();
  public updateSearchAccessPanelDetails(search: any, searchCriteria?: any, clear?: any) {
    this.initiateUserAccessSearch.next(search);
    this.getSearchAccessData.next(searchCriteria);
    this.clearSearchAccess.next(clear);
  }

  /*Share Auth Template panel details to load grid*/
  public templateSearch = this.initiateAuthTemplateSearch.asObservable();
  public updateSearchTemplatePanelDetails(search: any, searchCriteria?: any, clear?: any) {
    this.initiateAuthTemplateSearch.next(search);
    this.getSearchTemplateData.next(searchCriteria);
    this.clearSearchTemplate.next(clear);
  }

  /*Preview attachment*/
  public preview = this.previewAttachment.asObservable();
  public isPreviewClicked(text: any) {
    this.previewAttachment.next(text);
  }
  /*Delete attachment*/
  public delete = this.deleteAttachment.asObservable();
  public isDeleteClicked(text: any) {
    this.deleteAttachment.next(text);
  }
  /*Delete attachment*/
  public clearMail = this.clearMailAttachment.asObservable();
  public isClearMailAttachment(text: any) {
    this.clearMailAttachment.next(text);
  }
  /*Forward attachment*/
  public forward = this.replyClicked.asObservable();
  public forwardattach(text: any) {
    this.replyClicked.next(text);
  }
  /*Logged in User access token*/
  public getAccessToken() {
    return this.loggedInUserAccess;
  }
  public setAccessToken(val) {
    this.loggedInUserAccess = val;
  }
  /*Retrieve Data from Service */
  public retrieveData(method: string, url: string, body?: any): Observable<any> {
    let headers;
    let authHeader;
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    if (this.getAccessToken()) {
      authHeader = 'Bearer ' + this.loggedInUserAccess.access_token;
    } else {
      if (ppjwt && ppjwt != null) {
        authHeader = 'Bearer ' + ppjwt.access_token;
      }
    }

    headers = new HttpHeaders({
      'Accept': 'application/json',
      'Allow-Access-Control-Origin': '*',
      'Authorization': 'Bearer ' + ppjwt.access_token
    });

    if (!(method && (method.toUpperCase() === 'GET' || method.toUpperCase() === 'DELETE'))) {
      headers = new HttpHeaders({
        'Accept': 'application/json',
        'Allow-Access-Control-Origin': '*',
        'Authorization': 'Bearer ' + ppjwt.access_token,
        'Content-Type': 'application/json'
      });
    }
    const options = { 'headers': headers };
    if (method && (method.toUpperCase() == 'GET' || method && method.toUpperCase() == 'DELETE')) {
      return this.http[method](url, options);
    } else {
      return this.http[method](url, body, options);
    }
  }
  public refreshCountForMessages(urlsArray: Array<string>) {
    let headers;
    let authHeader;
    const apis = [];
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    if (this.getAccessToken()) {
      authHeader = 'Bearer ' + this.loggedInUserAccess.access_token;
    } else {
      if (ppjwt && ppjwt != null) {
        authHeader = 'Bearer ' + ppjwt.access_token;
      }
    }
    headers = new HttpHeaders({
      'Accept': 'application/json',
      'Allow-Access-Control-Origin': '*',
      'Authorization': 'Bearer ' + ppjwt.access_token,
      'Content-Type': 'application/json'
    });
    const options = { 'headers': headers };
    urlsArray.map(url => {
      apis.push(this.http.get(url, options)
      .pipe(catchError(err => of(err.status))));
   });
   return forkJoin(apis);
  }   
  /*Refresh JWT from CUP OAuth */
  public refreshJWT(method: string, url: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      // 'Content-Type': 'application/x-www-form-urlencoded',
      // 'Authorization': this.applicationKeyHeader
    });
    const jwt: any = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('jwt'));
    const httpParams = 
     {
        
        'refresh_token': jwt.refresh_token
      }
      
    const options = { 'headers': httpHeaders };
    return this.http[method](url, httpParams, options);
  }

  public initRefreshJWTTimer() {
    interval(30000)
      .subscribe(() => {
        this.refreshJWTCheck();
      });
  }

  public initRefreshJWTTimer2() {
    setInterval(() => {
      this.refreshJWTCheck();
    }, 30000);
  }

  public getMsgDetail(msgUID: any) {
   
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + ppjwt.access_token
    });
    
    const options = { 'headers': headers };
    return this.http['get'](urls.msgDetail + msgUID, { headers: headers });
    
  }
  public getClickHereForDetailURL(entityType: any) {
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + ppjwt.access_token
    });
    const options = { 'headers': headers }
    return this.http['get'](urls.getClickHereForDetailURL + entityType, options);
  }
  public refreshJWTCheck() {
    const jwt_expires_in: number = Number(this.secureStorage.getSecureInfoInLocalStorage('jwt_expires_in'));
    const jwt_expire_date = new Date(jwt_expires_in - 120000);
    const current_date = new Date();
    if (jwt_expire_date < current_date) {
      const jwt: any = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('jwt'));
      if (jwt) {
        this.refreshJWT('post', this.urlconstantsService.authUrl).subscribe(data => {
          if (data) {
            this.secureStorage.setSecureInfoInLocalStorage('jwt', JSON.stringify(data));
            const d = new Date();
            this.secureStorage.setSecureInfoInLocalStorage('jwt_expires_in', String(d.getTime() + (data.expires_in * 1000)));
          }
        });
      }
    }
  }

  /*Retrieve JWT from CUP OAuth */
  public retrieveJWTData(method: string, url: string, userName: string, cred: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': this.applicationKeyHeader
    });

    const httpParams = new HttpParams({fromObject:{
      'grant_type': 'password',
      'username': userName,
      'password': cred
    }});

    const options = { 'headers': httpHeaders };
    return this.http[method](url, httpParams, options);
  }

  public getUserInfoFromJWT() {
    try {
      // const jwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
      const jwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
      const user_info: any = JSON.parse(atob(jwt.access_token.split('.')[1]));
      return user_info.user_info;
    } catch (e) {
      return null;
    }
  }

  public getUserNameFromJWT() {
    try {
      // const jwt = this.secureStorage.getSecureInfoInSessionStorage('currentUser') ? JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser')) : null;
      const jwt = this.secureStorage.getSecureInfoInLocalStorage('currentUser') ? JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser')) : null;
      const user_info: any = jwt ? JSON.parse(atob(jwt.access_token.split('.')[1])) : null;
      return user_info ? user_info.user_info.userName : null;
    } catch (e) {
      return null;
    }
  }

  /*Method to initiate timer for the unread messages count for Bell icon in the header*/
  public initGeneralUnreadMessageCount() {
    this.generalUnreadMessageCountSubscription = interval(120000)
      .subscribe(() => {
        this.genericUnreadMessageCount();
      });
  }

  /*Method to get the unread messages count for Bell icon in the header*/
  public generalUnreadMessageCount() {
    const url = urls.unreadMessageConut.concat('?entity=' + '' + '&entityId=');
    this.retrieveData('get', url).subscribe(data => {
      if (data) {
        this.secureStorage.setSecureInfoInLocalStorage('generalMsgUnreadCount', data);
        this.generalUnreadMessageCountValue = this.secureStorage.getSecureInfoInLocalStorage('generalMsgUnreadCount');
      }
    }, error => {
    });
  }
/*Method to get the unread messages count for Bell icon in the header*/
  public genericUnreadMessageCount() {
    const url = urls.unreadMessageConut.concat('?entity=' + '' + '&entityId=');
    this.retrieveData('get', url).subscribe(data => {
      if (data) {
        this.secureStorage.setSecureInfoInLocalStorage('generalMsgUnreadCount', data);
        this.generalUnreadMessageCountValue = this.secureStorage.getSecureInfoInLocalStorage('generalMsgUnreadCount');
      }
    }, error => {
    });
  }
  /*Download File*/
  public downloadFile(path: string): Observable<any> {
    let headers;
    // const jwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const jwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const authHeader: string = 'Bearer ' + jwt.access_token;
    headers = new HttpHeaders({
      'Authorization': authHeader
    });
    const options = { 'headers': headers };
    return this.http.get(path, options);
  }
  /*Download PDF*/
  public downloadPDF(path: string, body): Observable<any> {
    let headers;
    // const jwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const jwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const authHeader: string = 'Bearer ' + jwt.access_token;
    headers = new HttpHeaders({
      'Accept': 'application/pdf',
      'Authorization': authHeader
    }
    );
    // const options = new RequestOptions({ 'headers': headers });
    return this.http.post(path, body, { responseType: 'blob', headers });
  }
  /*Make request to upload file*/
  public uploadFile(url: string, formData): Observable<any> {
    // const jwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const jwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const authHeader: string = 'Bearer ' + jwt.access_token;
    return Observable.create(observer => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const xml = xhr.response;
            // xml2js.parseString(xml, { explicitArray: false }, function (err, result) {
              observer.next( xhr.response);
            // });
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.upload.onprogress = (event) => {
        const progress = Math.round(event.loaded / event.total * 100);
        this.progressObserver.next(progress);
      };
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', authHeader);
      xhr.send(formData);
    });
  }

  public uploadFileWithContent(url: string, formData: FormData): Observable<any> {
    let headers;
    // const jwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const jwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    //headers.set( 'Accept', 'application/json' );
    const authHeader: string = 'Bearer ' + jwt.access_token;
    headers = new HttpHeaders({
      'Accept': 'application/json',
      'Allow-Access-Control-Origin': '*',
      'Authorization': authHeader,
      //'Content-Type': 'multipart/form-data'
    });
    const options = { 'headers': headers };
    return this.http.post(url, formData, options);

  }

  /*Check if refresh token is present*/
  // public refreshToken = this.isRefreshToken.asObservable();
  // public checkIfRefreshTokenPresent(token) {
  //   this.isRefreshToken.next(token);
  // }

  /*Setting flag for overall Search */
  public headerSearch = this.overAllSearch.asObservable();
  public isOverAllSearch(flag: boolean) {
    this.overAllSearch.next(flag);
  }

  /*Setting flag for overall Search result single*/
  public headerSearchResultSingle = this.overAllSearchResultSingle.asObservable();
  public isOverallSearchResultSingle(flag: boolean) {
    this.overAllSearchResultSingle.next(flag);
  }

  /*Setting flag for overall Search */
  public clearNameInOverall = this.clearNameInOverAllSearch.asObservable();
  public overAllSearchClearName(flag: boolean) {
    this.clearNameInOverAllSearch.next(flag);
  }

  // To reset to old values
  public resetPendingWLSearch$ = this.resetPendingWorkList.asObservable();
  public resetPendingWLSearch(text: any) {
    this.resetPendingWorkList.next(text);
  }

  /* To reset to old values */
  public resetCaseManagementWLSearch$ = this.resetCaseManagementWorkList.asObservable();
  public resetCaseManagementWLSearch(text: any) {
    this.resetCaseManagementWorkList.next(text);
  }

  // To reset to old values
  public resetLetterErrorWLSearch$ = this.resetLetterWorkList.asObservable();
  public resetLetterErrorWLSearch(text: any) {
    this.resetLetterWorkList.next(text);
  }

  public letterErrorListSearchPanelObservale = new Subject<any>();
  public letterErrorListSearchPanelBtnClick(eventData: any) {
    this.letterErrorListSearchPanelObservale.next(eventData);
  }

  public letterErrorGridResetObserable = new Subject<boolean>();
  public letterErrorGridResetClick(data: boolean) {
    this.letterErrorGridResetObserable.next(data);
  }

  // Preferred Reset
  public preferredCodeSearchPanelObservale = new Subject<any>();
  public preferredConfigSearchPanelBtnClick(data: any) {
    this.preferredCodeSearchPanelObservale.next(data);
  }

  public preferredConfigGridResetObserable = new Subject<boolean>();
  public preferredConfigGridResetClick(data: boolean) {
    this.preferredConfigGridResetObserable.next(data);
  }

  public viewSearchLetterErrorListDeactivateObserable = new Subject<boolean>();
  public viewSearchLetterErrorListDeactivate(data: boolean) {
    this.viewSearchLetterErrorListDeactivateObserable.next(data);
  }

  /*Share details of internal users lookup search*/
  public searchUser = this.internalUserSearchInitiated.asObservable();
  public searchedUser = this.searchedInternalUser.asObservable();
  public internalUserLookupSearch(search: boolean, user?: any) {
    this.internalUserSearchInitiated.next(search);
    this.searchedInternalUser.next(user);
  }

  /*Share details of internal users panel search*/
  public userPanel = this.isInternalUserSearch.asObservable();
  public userPanelDetails = this.internalUserPanelDetails.asObservable();
  public clearPanelDetails = this.clearInternalUserSearch.asObservable();
  public updateInternalUserSearchPanelDetails(search: boolean, clear: boolean, user?: any) {
    this.isInternalUserSearch.next(search);
    this.clearInternalUserSearch.next(clear);
    this.internalUserPanelDetails.next(user);
  }

  /*Reset to default in search internal panel users*/
  public resetInternalPanel = this.isResetInternalPanel.asObservable();
  public resetInternalUserPanel(value: boolean) {
    this.isResetInternalPanel.next(true);
  }

  /*Get User Template Details to Load */
  public initiateUserTemplate = new Subject<boolean>();
  public getUserTemplateDetails = new Subject<string>();
  public isUserConfigUpdateParam = new Subject<boolean>();
  public entityUidsList = new Subject<boolean>();
  public userTemplateDetails = this.initiateUserTemplate.asObservable();
  public sendUserTemplateDetails(isUserTemplateFromAddUserConfig: boolean, isUserConfigUpdate: boolean, templateUid?: any, entityuids?: any) {
    this.initiateUserTemplate.next(isUserTemplateFromAddUserConfig);
    this.getUserTemplateDetails.next(templateUid);
    this.isUserConfigUpdateParam.next(isUserConfigUpdate);
    this.entityUidsList.next(entityuids);

  }

  /*Initiate login after collecting template details*/
  public userLogin = this.initiateLogin.asObservable();
  public canUserLogin(canLogin: boolean) {
    this.initiateLogin.next(canLogin);
  }

  /*visibilityChangeForSearchPendWorkListFields*/
  public visibilityChangeForSearchPendWorkListFields(show: boolean) {
    this.searchPendWorklistFieldVisibility.next(show);
  }

  /*Deep copying an object*/
  public deepCopy(oldObj: any) {
    let newObj = oldObj;
    if (oldObj && typeof oldObj === 'object') {
      newObj = Object.prototype.toString.call(oldObj) === '[object Array]' ? [] : {};
      for (let i in oldObj) {
        newObj[i] = this.deepCopy(oldObj[i]);
      }
    }
    return newObj;
  }

  /*Check the logged in user type*/
  public isInternalUser() {
    const user = this.getUserInfoFromJWT();
    if (user && user.type === 'I') {
      return true;
    } else {
      return false;
    }
  }

  public getErrorObject(errorType: ErrorType, errorObj: any, failedOperation?: string): CupError {
    const obj: CupError = new CupError();
    obj.errorType = errorType;
    obj.errorObj = errorObj;
    obj.failedOperation = failedOperation;
    return obj;
  }

  public showError(errors: CupError[] | CupError, htmlElementRef?: ElementRef) {

    if (errors && !Array.isArray(errors) && errors instanceof CupError) {
      errors = [errors];
    }
    if (errors && Array.isArray(errors) && errors.length > 0) {
      const errorsByGroup = this.groupErrors(errors);
      if (htmlElementRef && htmlElementRef.nativeElement) {
        const errorMessage = this.formErrorMessage(errorsByGroup, true);
        htmlElementRef.nativeElement.innerHTML = errorMessage;
      } else {
        const errorMessage = this.formErrorMessage(errorsByGroup, false);
        this.logCupErrors(errors);
        this.notifyErrorFound(errorMessage);
      }

    }
  }

  public groupErrors(errorList: CupError[]) {
    const errors: { errorType: ErrorType, failedOperation: string }[] = [];
    if (errorList && errorList.length > 0) {
      // All API Errors
      const allApiErrors = errorList.filter(x => x.errorType === ErrorType.API_Error);
      const allFailedApiOperationsAsText = allApiErrors.map(x => {
        return x.failedOperation;
      }).join(', ');

      // All Business Errors
      const allBusinessErrors = errorList.filter(x => x.errorType === ErrorType.BusinessLogic_Error);
      const allFailedBusinessOperationAsText = allBusinessErrors.map(x => {
        return x.failedOperation;
      }).join(', ');

      // All Form Submission Errors
      const allFormSubmissionErrors = errorList.filter(x => x.errorType === ErrorType.FormSubmission_Error);
      const allFaileSubmissionAsText = allFormSubmissionErrors.map(x => {
        return x.failedOperation;
      }).join(', ');

      if (allApiErrors && allApiErrors.length > 0) {
        errors.push({
          errorType: ErrorType.API_Error,
          failedOperation: allFailedApiOperationsAsText
        });
      }
      if (allBusinessErrors && allBusinessErrors.length > 0) {
        errors.push({
          errorType: ErrorType.BusinessLogic_Error,
          failedOperation: allFailedBusinessOperationAsText
        });
      }
      if (allFormSubmissionErrors && allFormSubmissionErrors.length > 0) {
        errors.push({
          errorType: ErrorType.FormSubmission_Error,
          failedOperation: allFaileSubmissionAsText
        });
      }
    }
    return errors;
  }

  private getRequestHeader(method: string, canAddOnlyAuthHeader: boolean = false, acceptHeader?: string,
    canAddContentType : boolean = true) {
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    let headers = new HttpHeaders({
      'Authorization': ppjwt.access_token,
    });

    if(!canAddOnlyAuthHeader) {
      if(!acceptHeader) {
        headers = new HttpHeaders({
          'Authorization': ppjwt.access_token,
          'Accept': 'application/json',
          'Allow-Access-Control-Origin': '*'
        });
      } else {
        headers = new HttpHeaders({
          'Authorization': ppjwt.access_token,
          'Accept': acceptHeader,
          'Allow-Access-Control-Origin': '*'
        });
      }
     
    }

    if (!(method && (method.toUpperCase() === 'GET' || method.toUpperCase() === 'DELETE'))) {
      if(canAddContentType) {
        headers.append('Content-Type', 'application/json');
      } 
    }
    return headers;
  }

  public getErrorDescFromErrorResponse(error : any) {
    try {
      let errorData = error;
      if(error && error.body) {
        try {
          errorData = error;
        } catch(err) {
          errorData = error.body;
        }
      }
      return errorData;
    } catch(err) {
      throw err;
    }
  }


  public logError(errorDesc : any, requestInfo ?: any) {
    try {
      if (requestInfo && requestInfo instanceof Object) {
        requestInfo = JSON.stringify(requestInfo);
      }
      if (errorDesc && errorDesc instanceof Object) {
        errorDesc = JSON.stringify(errorDesc);
      }
      let inputJSON = [{
        errorDesc : errorDesc,
        requestInfo : requestInfo 
      }];
      const options = { 'headers': this.getRequestHeader('POST') };
      this.http.post(this.urlconstantsService.fetchUrl().errorLog, JSON.stringify(inputJSON),options).subscribe(data => {
        // console.log('ERROR LOGGED');
      }, err => {
        console.log("=========================================");
        console.error('ERROR LOG API FAILS FOR BELOW INPUT JSON');
        console.log(inputJSON);
        console.log(err);
        console.log("=========================================");
      });
    } catch(err) {
      
    }

  }


  public formErrorMessage(errorList: {
    errorType: ErrorType,
    failedOperation: string
  }[] | {
    errorType: ErrorType,
    failedOperation: string
  }, addHelpMessage: boolean) {
    let errorMessage = '';
    if (errorList && !Array.isArray(errorList)) {
      errorList = [errorList];
    }
    (<{ errorType: ErrorType, failedOperation: string }[]>errorList).forEach(error => {
      switch (error.errorType) {
        case ErrorType.BusinessLogic_Error: {
          errorMessage = errorMessage + StringConstands.BUSINESSLOGIC_ERROR_MSG_WITHOUT_HELP;
        }
          break;
        case ErrorType.API_Error: {
          errorMessage = errorMessage + StringConstands.API_ERROR_MSG_WITHOUT_HELP;
        }
          break;
        case ErrorType.FormSubmission_Error: {
          errorMessage = errorMessage + StringConstands.SUBMIT_ERROR_WITHOUT_HELP;
        }
          break;
      }
      if (error.failedOperation) {
        errorMessage = errorMessage + ' - ' + '<b>' + error.failedOperation + '</b>' + '.';
        errorMessage = errorMessage + '<br/>';

      }
    });
    if (addHelpMessage) {
      errorMessage = errorMessage + StringConstands.ERROR_HELP_MESSAGE;
    }
    return errorMessage;
  }


  public logCupErrors(errors: CupError[]) {
    if (errors && errors.length > 0) {
      errors.forEach(error => {
        if (error.errorType == ErrorType.BusinessLogic_Error) {
          console.error(error.errorObj);
        }
      });
    }
  }

  private errorFound = new Subject<any>();
  public notifyErrorFound(errorMessage: string) {
    this.errorFound.next(errorMessage);
  }

  public getErrorFoundObservable(): Observable<any> {
    return this.errorFound.asObservable();
  }

  getstartRating(url) {
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});
    
    
    return this.http.get(url, { headers: headers });
}

getMeasureDescriptionFromEdh(provID) {
  // const jwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const jwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    //headers.set( 'Accept', 'application/json' );
    const authHeader: string = 'Bearer ' + jwt.access_token;
  const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': authHeader
  });
  let myParams = new HttpParams()
  .append('providerId', provID);  
  const payload = {
    'providerId': provID
   };
   
  return this.http.post(urls.getMeasureDetails, payload, { headers: headers});
}

}
