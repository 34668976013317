<app-common></app-common>
<div class="top-panel">
	<div class="container-fluid top-panel bg-white">
		<div id="ucHeader_pnlBanner" class="row banner">

			<div class="col-9 col-sm-12">
				<div class="row align-items-center h-100 pl-3 pl-sm-0">
					<div class="col-12 col-sm-4 col-md-2 py-0 text-md-center">
						<img title="Logo" class="img img-fluid banner-logo" src="assets/images/logo.svg" alt="Logo">
					</div>

				</div>
			</div>
		</div>
	</div>
</div>
<div class=" signinContainer">
	<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>

	<div class="row" style="background-color: #f5f5f5; margin-left: 0px; margin-right: 0px;">
		<div class="col-md-8 col-lg-9 p-t-16 ht-500">
			<div class="cover-img full-width"></div>
		</div>
		<div class="col-md-4 col-lg-3" style="padding: 16px">
			<mat-card class="sign-card-container" style="height: 100%;min-height: 478px;">
				<div class="signin-label-header">Sign In</div>
				<!-- <div class="create-account-label" [routerLink]="['/sign-up']">
					Create an account? <span> <a>Sign Up</a></span>
				</div> -->
				<div>
					<div [ngClass]="(isValidUserName)?'wrap-input100 validate-input':'wrap-input100 validate-input alert-validate'"
					 data-validate="Enter username">
						<input tabindex="1" (keypress)="passRestrict($event)" (focus)="onInputFocus($event)" placeholder="USER NAME"
						 [(ngModel)]="UserName" id="userName" name="userName" type="text" [ngClass]="(UserName === '')?'input100':'input100 has-val'"
						 required />
						<!-- <span class="focus-input120" data-placeholder="User Name"></span> -->
					</div>

					<div [ngClass]="(isValidCred)?'wrap-input100 validate-input mb-0':'wrap-input100 validate-input mb-0 alert-validate'"
					 data-validate="Enter password">
						<span class="btn-show-pass">
							<a href="javascript:" (click)="togglePasswordShow()">{{ (showCred)?'Show':'Hide' }}</a>
							<!-- <i [ngClass]="(showCred)?'zmdi zmdi-eye-off':'zmdi zmdi-eye'" (click)="togglePasswordShow($event)"></i> -->
						</span>
						<input tabindex="2" [attr.type]="showCred ? 'password' : 'text'" (focus)="onInputFocus($event)" (keypress)="passRestrict($event)"
						 [(ngModel)]="UserCred" id="password" placeholder="PASSWORD" name="password" type="password" [ngClass]="(UserCred === '')?'input100':'input100 has-val'"
						 required />
						<!-- <span class="focus-input120" data-placeholder="Password"></span> -->
						<!-- refer: .focus-input120::after -->
					</div>
					<div style="display: flex; justify-content: space-between">
						<div [routerLink]="['/forgotusername']" class="forgot-username">
							Forgot username?
						</div>
						<div [routerLink]="['/forgotpassword']" class="forgot-username">
							Forgot Password?
						</div>
					</div>

					<div class="mt-3">
							<div class="form-check m-t-20 checkbox">
									<input width="1em" id="cbReadAgreement" type="checkbox" class="form-check-input custom-control-input" [(ngModel)]="licenseAccpetedFlag"
									 (change)="isLicenseAcceptedError = !licenseAccpetedFlag" name="licenseAccpetedFlag" required />
									
                                                                            <label for="cbReadAgreement">
                                                                                I have read and
										understand the <br/> <a (click)="$event.preventDefault();getDocument()" style="cursor: pointer; text-decoration: underline;">End User
											License Agreement</a></label>
									<div class="userAgreementMessage" *ngIf="isLicenseAcceptedError">
										Please check above box to proceed.
									</div>
								</div>
						<!-- <mat-checkbox [color]="'primary'"
				><span class="user-aggrement"
				  >I Have Read and Understand the
				  <a><b>End User Agreement</b></a></span
				></mat-checkbox
			  > -->
					</div>
					<div>
						<button class="signin-btn carelon-btn button-primary" (click)="login()">Sign In</button>
					</div>
					<div class=""></div>
					<div class="onboarding-label-btn">
						
					</div>
				</div>
			</mat-card>
		</div>
	</div>

	<div class="news-cont" >
		<mat-card style = "margin: 10px 15px;max-height: 250px;overflow-y: scroll;">
	
			<ng-container *ngFor="let new of news">
				<h2 style="width:50%" [innerHTML]="new.messageName"></h2>
				<!-- <p>Our gold standard is to provide care that we would want to give to our own loved ones. That's the difference we want to make in the lives
				 of those we care for --- that our patients would have the assurance that they are in good hands and will receive the right care,
				 which also gives profound peace of mind to them and their families.
			</p> -->
				<p style="width:50%" [innerHTML]="new.message"></p>
				<!-- <h2>Care you would give your family</h2> -->
			</ng-container>
	
	</mat-card>
	</div>
</div>

<!-- <app-footer [className]="'static-footer'" [hideHelpFaq]='true'></app-footer> -->
<div class="modal fade" id="myModal" role="dialog">
	<div class="modal-dialog modal-sm" style="margin-top: 300px;">
		<div class="modal-content">
			<div class="modal-header" style="border-bottom: 0px;">
				<div class="modal-body">
					<p style="text-align: center;">
						Authentication Failed,<br> Invalid Username/Password. <br>After 5 unsuccessful attempts the account will be
						locked
					</p>
					<br>
					<button type="button" class="carelon-btn contact100-form-btn center-block" data-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal fade" id="inactiveModal" role="dialog">
	<div class="modal-dialog modal-xl" style="margin-top: 300px;">
		<div class="modal-content">
			<div class="modal-header" style="border-bottom: 0px;">
				<button type="button" class="close" data-dismiss="modal">&times;</button>
				<p style="text-align: center;">
					<b>Verify Your Email Address</b>
				</p>
			</div>
			<div *ngIf="!mailSent">
				<div class="modal-body">
					<p>We have sent an confirmation email to your email address to verify your account, Please click on the link
						to continue. This will confirm you account information.</p>
					<br>
					<p>If you have not received the email, click on below link to re-send the confirmation email.</p>
					<br>
					<button type="button" class="btn btn-link" style="font-family: Myriad Pro;" (click)="resend() ">
						<a> Resend confirmation link </a>
					</button>
				</div>
			</div>
			<div *ngIf="mailSent">
				<div class="modal-body">
					<p>Confirmation email has been sent to your registered email, please click on the link to continue. This
						will confirm you account information.</p>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="exceptionModal" role="dialog">
	<div class="modal-dialog modal-xl" style="margin-top: 300px;">
		<div class="modal-content">
			<div class="modal-header" style="border-bottom: 0px;">
				<button type="button" class="close" data-dismiss="modal">&times;</button>
				<p style="text-align: center;">
					<b>Login Failure</b>
				</p>
			</div>

				<div class="modal-body">
					<p>'Sorry something went wrong, please try again later.'</p>					
					<button type="button" class="carelon-btn contact100-form-btn center-block " data-dismiss="modal">Close</button>
				</div>
		</div>
	</div>
</div>

<div class="modal fade" id="userMessageModal" role="dialog">
	<div class="modal-dialog modal-xl" style="margin-top: 300px;">
		<div class="modal-content" style="width: 106%;">
			<div class="modal-header" style="border-bottom: 0px;">
				<div *ngIf="userMessage">
					<div class="modal-body text-center">
						<p style="font-weight: bold;">{{userMessage.split('\n')[0]}}</p>
						<p style="font-weight: bold;">{{userMessage.split('\n')[1]}}</p>
						<br>
						<button type="button" class="carelon-btn contact100-form-btn center-block " data-dismiss="modal">Close</button>
					</div>
				</div>


			</div>

		</div>
	</div>
</div>
<div class="modal fade" id="errorMessageModal" role="dialog">
	<div class="modal-dialog modal-xl" style="margin-top: 10%;">
		<div class="modal-content" style="width: 106%;">
			<div class="modal-header" style="border-bottom: 0px;">
				<div>
					<div class="modal-body">
						<p class="text-center" style="font-weight: bold;font-size:16px;">Login failed</p>
						<p>Either the username you entered is invalid or the password you entered is not
							correct. Please try again.</p>
						<p style="font-weight: bold;">If needed, please use the Forgot Password link on the login form to reset
							your password.</p>
						<p>If the username is valid but there are 5 failed login attempts, the account
							will be locked.</p>
						<p>If the above doesn't work, or you need help with a locked account, please
							contact the Account Operator for your site, or CareMore Provider Relations at 888-291-1358, option 3,
							option 5.</p>
						<br>
						<button type="button" class="carelon-btn contact100-form-btn center-block" data-dismiss="modal">Close</button>
					</div>
				</div>


			</div>

		</div>
	</div>
</div>


<!-- <div class="visible-xs" style="clear:both;">

</div> -->
<!-- style="background-image: url('assets/images/caremore-hq.jpg');" -->



<div class="modal fade" id="timeOutModal" role="dialog">
	<div class="modal-dialog modal-sm" style="margin-top: 300px;">
		<div class="modal-content">
			<div class="modal-header" style="border-bottom: 0px;">
				<div class="modal-body">
					<p style="text-align: center;">
						connection timed out.Please try again later!!!
					</p>
					<br>
					<button type="button" class="carelon-btn contact100-form-btn center-block" data-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
</div>