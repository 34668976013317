<app-header (found)="handleResults($event)"></app-header>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
<div id="main-wrapper" class="documentsPage main-background topSpace main-container"
	style="margin-bottom: 40px; background-color: #fff;">
	<div class="tab inner-tab-container centerTabText">
		<app-search-results [resultGridList]="data"></app-search-results>
		<!-- Nav tabs -->
		<!-- <ul class="nav nav-tabs cup-detail-header" role="tablist">
			<li role="presentation" class="active">
					<div class="labels"> {{documentCode == 'CHANGE_HEALTH_CARE'? 'Provider Payments':documentCode == 'GEN_REF_DOC'?'General Reference Documents':'Provider Communication Documents'}}</div>
          <a href="#Section1" aria-controls="home" role="tab" data-toggle="tab" style="min-width: 19em"></a>
			</li>
		</ul> -->
		<div *ngIf="documentCode == 'CHANGE_HEALTH_CARE' ; else notchc">
			<br>
			<h2 class="provider-payment-title">Provider Payments (Change HealthCare)</h2>
			<br>

			<h3 class="newFontStyle">Welcome to the Provider Payment page!  Here you will find links to help you:</h3>
			<br>

			<ul>
				<li class="chc newLightGreyText">Find claims</li>
				<li class="chc newLightGreyText">Find checks</li>
				<li class="chc newLightGreyText">Find Draft numbers</li>
				<li class="chc newLightGreyText">Search by provider IDs</li>
				<li class="chc newLightGreyText">Find Explanation of payment (EOP)</li>
				<li class="chc newLightGreyText">E-check date ranges</li>
				<li class="chc newLightGreyText">View Explanation of benefits (EOB)</li>
				<li class="chc newLightGreyText">Opt out</li>
				<li class="chc newLightGreyText">835 - Electronic Remittance Advice (ERA)</li>
				<li class="chc newLightGreyText">Change HealthCare log In link:  <a class="cyan"
						href="https://ui.echohealthinc.com/Echo.aspx" target="_blank"
						rel="noopener noreferrer">https://ui.echohealthinc.com/Echo.aspx</a></li>
				<li class="chc newLightGreyText">Want to sign up for Electronic funds transfer (EFT)?  Click <a
						class="cyan"
						href="https://support.changehealthcare.com/customer-resources/enrollment-services/medical-hospital-eft-enrollment-forms"
						target="_blank" rel="noopener noreferrer">here!</a></li>
				<li class="chc newLightGreyText">Want different methods of payment?  Click <a class="cyan"
						href="https://support.changehealthcare.com/customer-resources/enrollment-services/medical-hospital-eft-enrollment-forms"
						target="_blank" rel="noopener noreferrer">here!</a></li>
			</ul>
		</div>
		<ng-template #notchc>
			<div *ngIf="documentCode == 'GEN_REF_DOC'">
				<br>
				<h2 class="provider-payment-title">General Reference Documents</h2>
				<br>
			</div>
			<div *ngIf="documentCode == 'PROV_COMM_DOC'">
				<br>
				<h2 class="provider-payment-title">Provider Reference Documents </h2>
				<br>
			</div>

			<div *ngIf="documentCode != 'CHANGE_HEALTH_CARE'">

				<div class="file-system-container">
					<div class="row col-md-12">
						<div class="col-md-4 no-padding-left-right">

							<div class="middle-line col-md-offset-1" *ngIf="isTreeLoaded">
								<tree-root [nodes]="nodes" [options]="options" (event)="onEvent($event)" class="test">

									<ng-template #treeNodeTemplate let-node let-index="index">
										<div class="">
											<span *ngIf="node.data.hasChildren && node !== activeNode"
												(click)="onNodeClick(node, $event)" aria-hidden="true"
												class="newFontStyle">
												<img class="folder-img" src="./../../assets/images/icons/Doc-Normal.svg"
													alt="file-img">{{ node.data.name }}</span>

											<span *ngIf="node.data.hasChildren && node === activeNode"
												(click)="onNodeClick(node, $event)" aria-hidden="true"
												class="selcted-folder-font">
												<img class="folder-img"
													src="./../../assets/images/icons/Doc-Selected.svg" 
													alt="file-img">{{node.data.name }}</span>
										</div>
									</ng-template>

								</tree-root>

							</div>
						</div>

						<div class="col-md-8">
							<div class="middle-line col-sm-12" class="" *ngIf="isTreeLoaded">
								<table cellspacing="0" cellpadding="0" class="providerDataTable">
									<thead>
										<tr>
											<th>Document Name</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										<ng-container *ngFor="let child of selectedNodeChildren">
											<tr *ngIf="child.name != 'No Files Found'">

												<td style="text-align: left;">
													<img class="file-icon" src="./../../assets/images/icons/Document.svg" alt="doc">
													{{child.name}}
												</td>

												<td style="text-align: left; cursor: pointer;">
													<i class="zmdi zmdi-eye eye-icon" (click)="getDocument(child)"></i>
												</td>

											</tr>
											<tr *ngIf="child.name == 'No Files Found'">
												<td colspan="2" style="text-align: center;">No documents available</td>
											</tr>
										</ng-container>

										<tr *ngIf="selectedNodeChildren?.length === 0 ">
											<td colspan="2" style="text-align: center;">No documents available</td>
										</tr>

									</tbody>
								</table>
							</div>

						</div>
					</div>
				</div>
			</div>
		</ng-template>
	</div>
</div>
<app-footer class="mt-auto"></app-footer>
<div class="modal fade documentsPageModal" id="errorModal" role="dialog">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header" style="background-color:#5009b5">
				<button type="button" class="close" data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<p style="text-align:center;">{{userState}} users can not access the documents.</p>
			</div>
			<div class="modal-footer">
				<div class="container-contact100-form-btn">
					<button type="button" class="btn btn-primary buttonInfo" data-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
</div>