import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { urls } from '../common/model/properties';
import { ConfigProvider } from '../shared/services/configprovider';
import { ConfigModel } from '../shared/models/config.model';
import { Hero } from './hero';
import { HEROES } from './mock-heros';
import { catchError } from 'rxjs/operators';
import { SecureStorageService } from '../secure-storage.service';
import { Observable, Subject, of, forkJoin } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { CupService } from '../shared/services/cup.service';

@Injectable()
export class ProviderportalService {

    public UserDataSubject = new BehaviorSubject<any>(null);
    public UserData = this.UserDataSubject.asObservable();
    public setUserData(data: any) {
        this.UserDataSubject.next(data);
    }
    messages: string[] = [];
    nameSubject = new Subject<any>();
    add(message: string) {
    this.messages.push(message);
  }

  clear() {
    this.messages = [];
  }
  getProviderBasicDetails(): Observable<any> {
    // TODO: send the message _after_ fetching the heroes
    
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + ppjwt.access_token
    });
    return this.http.post(urls.getSiteUsers ,null, { headers: headers });
    // this.add('HeroService: fetched heroes');
    // return of(HEROES);
  }
  getClaimDetails(id): Observable<any> {
    
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + ppjwt.access_token
    });
    return this.http.get(urls.getClaimdetail + id +
        '?pageNo=1&pageSize=100&sortColumn=claimLineSequenceNumber&sortOrder=ASC', {headers: headers});
   }
   
  async getClaimDataForPrivider(id): Promise<any> {
    
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + ppjwt.access_token
    });
    return this.http.get(urls.getClaimSummary + id +
        '?pageNo=1&pageSize=100&sortColumn=ClaimId&sortOrder=ASC', {headers: headers}).pipe(catchError(err => of(err.status))).toPromise();
 }
 getClaimDataForPrivider2(finalProviderArray){
    const details =[];
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + ppjwt.access_token
    });
    if(finalProviderArray.length > 0 ){
        finalProviderArray.map(e=>{
            if(e.fromDateValue !== null && e.endDateValue!== null){
                
                    details.push(this.http.get( urls.claimUrl + e.providerId + 
                        '?pageNo=1&pageSize=100&sortColumn=ClaimId&sortOrder=ASC&fromDate='+e.fromDateValue +' 00:00:00.000&toDate='+ e.endDateValue+' 00:00:00.000',
                         {headers: headers}).pipe(catchError(err => of(err.status))));

              
            } else {
                
                    details.push(this.http.get(urls.claimUrl + e.providerId +
                        '?pageNo=1&pageSize=100&sortColumn=ClaimId&sortOrder=ASC', 
                        {headers: headers}).pipe(catchError(err => of(err.status))));
        }
        })
    }
    
    return  [forkJoin(details),finalProviderArray];
    }
    async getClaimDataForPrividerWithDateRange(id,fromDate,toDate): Promise<any> {
        
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + ppjwt.access_token
        });
        return this.http.get(urls.getClaimSummary + id + 
            '?pageNo=1&pageSize=100&sortColumn=ClaimId&sortOrder=ASC&fromDate='+fromDate+' 00:00:00.000&toDate='+toDate+' 00:00:00.000', {headers: headers}).pipe(catchError(err => of(err.status))).toPromise();
     }
   getHero(id: number): Observable<Hero> {
    // TODO: send the message _after_ fetching the hero
    this.add(`HeroService: fetched hero id=${id}`);
    return of(HEROES.find(hero => hero.claimNo === id));
  }
    private config: ConfigModel;
    props: any;
    token_updated_already = "Token Updated Already";
    checkLogin = new Subject();
    constructor( private http: HttpClient,  private configProvider: ConfigProvider,
        private cupService: CupService,
        private secureStorage:SecureStorageService) {
        this.config = configProvider.getConfig();
        this.props = configProvider.getProps();
        //console.log(this.configProvider.getConfig().token);
    }
    public clearTokenTimeout = new Subject<boolean>();
    public closeModal = new Subject<boolean>();

    setTokenClearTimeOut(value: boolean) {
        this.clearTokenTimeout.next(value);
        // it is publishing this value to all the subscribers that have already subscribed to this message
        var interval_id = window.setInterval("", 9999); // Get a reference to the last
                                                // interval +1
                    for (var i = 1; i < interval_id; i++)
                            window.clearInterval(i);
                    //for clearing all intervals
    }
    getTokenClearTimeOut(): Observable<any> {
        return this.clearTokenTimeout.asObservable();
    }

    setCloseModal(value: boolean) {
        this.closeModal.next(value); // it is publishing this value to all the subscribers that have already subscribed to this message
    }
    getCloseModal(): Observable<any> {
        return this.closeModal.asObservable();
    }
    providerGetToken(userName, cred) {
        const body = new HttpParams({
            fromObject:
            {'grant_type': 'password',
            'username': userName,
            'password': cred
          }});
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': this.props.cup_login_authorization

        });
      
        return this.http.post(urls.tokenUrl, body.toString(), { headers: headers });
    }
    providerGetToken2(userName, cred): Promise<any> {
        const body = new HttpParams({
            fromObject:
            {'grant_type': 'password',
            'username': userName,
            'password': cred
          }});
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': this.props.cup_login_authorization

        });
        return this.http.post(urls.tokenUrl, body.toString(), { headers: headers }).toPromise();
    }
    providerRefreshToken() {
        
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        
        const body = {
            
            'refresh_token': ppjwt.refresh_token
        };
        const headers = new HttpHeaders({
            
           

        });
        return this.http.post(urls.tokenRefreshUrl, body, { headers: headers })
    }

    cupLoginJWTToken(userName, cred) {
        const body = new HttpParams({
            fromObject:
            {'grant_type': 'password',
            'username': userName,
            'password': cred
          }});
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': this.props.cup_login_JWT
        });
        
        return this.http.post(urls.jwtLogin, body.toString(), { headers: headers })
    }
    cupLoginJWTToken2(userName, cred): Promise<any> {
        const body = new HttpParams({
            fromObject:
            {'grant_type': 'password',
            'username': userName,
            'password': cred
          }});
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': this.props.cup_login_JWT
        });
        
        return this.http.post(urls.jwtLogin, body.toString(), { headers: headers }).toPromise();
    }
    cupLoginJWTRefreshToken() {
        
        
        //const cupjwt = JSON.parse(sessionStorage.jwt);
        const cupjwt = JSON.parse(localStorage.jwt);
        const body = {
            
            'refresh_token': cupjwt.refresh_token
        }
        const headers = new HttpHeaders({
            
           
        });
        const jwt_expires_in: number = Number(this.secureStorage.getSecureInfoInLocalStorage('jwt_expires_in'));
        // subtract 5 min (300000 sec) from jwt_expires_in
        const jwt_expire_date_before_5_min = new Date(jwt_expires_in - 300000);
        const current_date = new Date();
        if (jwt_expire_date_before_5_min < current_date) { // Going to expire in 5 min '
            return this.http.post(urls.jwtRefreshLogin, body, { headers: headers });
        } else {
            console.warn("Token API call skipped");
            return of(this.token_updated_already);
        }
    }

    setDocAttribute(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = 'expires=' + d.toUTCString();
        const http = 'http=true;';
        // let domain = "domain=ca47dwvcup001;"
        const domain = '';
        const path = 'path=/;';
        document.cookie = cname + '=' + cvalue + ';' + domain + path;
    }
    getDocAttribute(cname) {
        const name = cname + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
    checkDocAttribute() {
        let user = this.getDocAttribute('username');
        if (user !== '') {
            alert('Welcome again ' + user);
        } else {
            user = prompt('Please enter your name:', '');
            if (user !== '' && user != null) {
                this.setDocAttribute('username', user, 365);
            }
        }
    }

    deleteDocAttribute(cname) {
        this.setDocAttribute(cname, '', -1);
    }

    listDocuments(docCode, kvpl, page, size) {
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Authorization': 'Bearer ' + ppjwt.access_token
        });
        const myParams = new HttpParams()
        .set('appId', 'PP')
        .set('documentCode', docCode)
        .set('multiple', 'true')
        .set('page', page)
        .set('size', size)
        .set('keyValuePairList', JSON.stringify(kvpl))
        const options = { headers: headers, params: myParams };
        this.http.get(urls.filenetListURL, options).subscribe(res => {
        });
    }
    getDocument(docID, docCode, kvpl) {
        
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Authorization': 'Bearer ' + ppjwt.access_token
        });
        const myParams = new HttpParams()
        myParams.append('appId', 'PP');
        myParams.append('documentCode', docCode);
        
        if (kvpl !== null) {
            myParams.append('keyValuePairList', JSON.stringify(kvpl));
        }
        const options = { headers: headers, params: myParams };
        const urlCall = (docID === null) ? urls.filenetURL : urls.filenetURL + '/' + docID;
        this.http.get(urlCall, options).subscribe(res => {
            const responseData = res;
        });
    }
    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    download(url, filename) {
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        const event = document.createEvent('MouseEvents');
        event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
        link.dispatchEvent(event);
    }
    getBroadcast(callBack: (sucess: boolean, message: string) => void) {
        
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + ppjwt.access_token
        });
        
        this.http.get(urls.getBroadcast, { headers: headers })
            .subscribe(
                (resp:any) => {
                    callBack(true, resp);
                }
            );
    }

    getWarningMsgs(callBack: (sucess: boolean, message: string) => void) {
        
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + ppjwt.access_token
        });
        
        this.http.get(urls.getWarningMsg, { headers: headers })
            .subscribe(
                (resp:any) => {
                    callBack(true, resp);
                }
            );
    }
    
    addDND(messageNo: string, userId: string, callBack: (sucess: boolean, message: string) => void) {
        const body = {
            userId: userId,
            messageNo: messageNo
        };
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + ppjwt.access_token
        });
     
        this.http.post(urls.addDND, body, { headers: headers })
            .subscribe(
                (resp:any) => {
                    if (resp.status !== undefined && 'SUCCESS' === resp.status) {
                        callBack(true, resp);
                    } else {
                        callBack(false, 'Add DND was not succesful. Please try again later');
                    }
                },
                err => {
                    callBack(false, 'Error while adding DND. Please try again later');
                }
            );
    }
    getDND(messageNo: string, userId: string, callBack: (sucess: boolean, message: string) => void) {
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));  
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));     
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + ppjwt.access_token,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
        });
        let getDndUrl = urls.getDND;
        getDndUrl = getDndUrl.replace('USER_NAME', userId);
        getDndUrl = getDndUrl.replace('MESSAGE_NO', messageNo);
        this.http.get(getDndUrl, { headers: headers })
            .subscribe(
                (resp) => {
                    if (0 === resp) {
                        callBack(true, '');
                    } else {
                        callBack(false, '');
                    }
                },
                err => {
                    callBack(false, '');
                }
            );
    }
    getQmMemberList(id) {
       
        let getQmUrl = urls.getQM;
        return this.http.get(getQmUrl + id);
    }
    getClientDetails() {
        
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + ppjwt.access_token
        });
        const userName = this.cupService.getUserNameFromJWT();
        let path = urls.getClientDetails.replace('{userId}', userName);
        return this.http.post(path,null, { headers: headers });

    }
    setLogin() {
        this.checkLogin.next(true);
    }
    afterLogin() {
        return this.checkLogin.asObservable();
    }

    getSSOUsername(email: string, npiId: string, taxId: string, callBack: (sucess: boolean, message: string) => void) {
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));       
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');
        headers.set('Authorization', 'Bearer ' + ppjwt.access_token);
        let url = urls.ssoUsername;
        //url = url.replace('{EMAIL}', 'testemail@test.com');
        //url = url.replace('{NPIID}', '1306814868');
        //url = url.replace('{TAXID}', '270080037');
        this.http.get(url, { headers: headers })
            .subscribe(
                (resp:any) => {
                    callBack(true, resp);
                }
            );
    }

    doPostSSOClientUsername(client: string, clientUsername: string, userName: string) {
        let body = {};
        body['client'] = client;
        body['clientUsername'] = clientUsername;
        body['userName'] = userName;
        const token: any = this.secureStorage.getSecureInfoInLocalStorage('clientCredentials_token');
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        });
        return this.http.post(urls.postSSOClientUsername,  JSON.stringify(body), { headers: headers })
    }

    refreshName() {
        return this.nameSubject.asObservable();
    }
    setFullName(name) {
        this.nameSubject.next(name);
    }
    getAlertDetails(userName: String){
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + ppjwt.access_token
        });
        let alertDetails = urls.getAlerts as any;
        // alertDetails = alertDetails.replace('USER_NAME', userName);
        return this.http.get(alertDetails, {headers: headers})
      }

    getReadStatus(AlertId){
       
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Authorization': 'Bearer ' +  ppjwt.access_token,
            'Content-Type': 'application/json'
        });
        const payload = {
            "alertId":AlertId
        }
        // let alertStatusUrl = urls.getAlertReadStatus as any;
        // alertStatusUrl = alertStatusUrl.replace('alertId',AlertId );
        return this.http.post(urls.getAlertReadStatus,payload,{headers: headers});
    
    }

    submitTraining(payload){
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const userNameFromToken = this.cupService.getUserNameFromJWT();
            const {
                providers, docIds, user
            } = payload;
            const obj = []
            console.log(docIds)
            docIds.map(val => {
                providers.map(p => {
                    obj.push({
                        ...p,
                        docId: val,
                        userName: userNameFromToken
                    })
                })
            })
            const body = {
                attestations : obj,
                phone : user.phone,
                email : user.email,
                userName : userNameFromToken
            };
            console.log(body)
            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + ppjwt.access_token
            });
         
            return this.http.post(urls.submitTrainingAttestation, body, { headers: headers });
    }

    getAttestedProviders(userName:any): Observable<any> {
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + ppjwt.access_token
        });
        return this.http.get(urls.getProviderdIdsAttested , {headers: headers});
    }
 loginNew(username:string,password:string): Observable<any>{
     let body = {username,password};

     return this.http.post(urls.loginNew, body);
 }

 getPrefference(access_token : any) {
    const endPoint = urls.getPrefference

    const accessToken: string = 'Bearer ' + access_token;

    const securityHeaders = new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': accessToken,
    });

    const options = { headers: securityHeaders};

    return this.http.get(endPoint, options)  
  }

}
