import { Injectable } from '@angular/core';

var pdfMake = require('pdfmake/build/pdfmake');
var pdfFonts = require('pdfmake/build/vfs_fonts');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

var PDFLib = require('pdf-lib/dist/pdf-lib');
var download = require('downloadjs/download');

@Injectable()
export class PdfService {

  pdfObj: any;
  logo: any;
  systolic: any;
  cancerScreening: any;
  signature: any;
  Alogo: any;
  Dlogo: any;
  Ulogo: any;
  ADUlogo: any;
  diabetes: any;
  checkbox: any;
  rectBig: any;
  starMeasure : any;

  pageBreak: string = '';
  contentArr: any = [];
  break = true;

  //obj: any = [];
  finalpdf: any = [];
  notPrintedPdfList: any = [];

  async generate(pdf: any, img: any, pdfIndex: any, checkedList: any, year: string) {

    var totpage = 0;
    var pdfBuffer;
    this.logo = img.logo;
    this.systolic = img.systolic;
    this.cancerScreening = img.cancerScreening;
    this.signature = img.signature;
    this.Alogo = img.alogo;
    this.Dlogo = img.dlogo;
    this.Ulogo = img.ulogo;
    this.ADUlogo = img.adulogo;
    this.diabetes = img.diabetes;
    this.checkbox = img.checkbox;
    this.rectBig = img.rectBig;
    this.starMeasure = img.starMeasure;

    let formDataArray: any = pdf.formDataArray;
    let parentValue: any = pdf.parentValue;
    let previousCondition: any = pdf.previousCondition;
    let poteintialConditionData: any = pdf.poteintialConditionData;
    let memberMeasureDatas: any = pdf.memberMeasureDatas;


    if (formDataArray.length) {
    //var prevConditionBody = [];
    var prevConditionCKD = []

    if (Object.keys(previousCondition).length) {
      this.pageBreak = 'after'
      //console.log("--1---:", this.pageBreak)
      var row = new Array();
      row.push({ text: 'PREVIOUSLY REPORTED CONDITIONS', colSpan: 6, fontSize: 14, bold: true, fillColor: '#C0C0C0', alignment: 'center' });
      row.push({ text: ' ' });
      row.push({ text: ' ' });
      row.push({ text: ' ' });
      row.push({ text: ' ' });
      row.push({ text: ' ' });
      prevConditionCKD.push(row);

      var row = new Array();
      row.push({ text: 'Please document each diagnosis that is present in your assessment and support it with a plan in the progress note.', colSpan: 6, fontSize: 9, bold: true, alignment: 'left' });
      row.push({ text: ' ' });
      row.push({ text: ' ' });
      row.push({ text: ' ' });
      row.push({ text: ' ' });
      row.push({ text: ' ' });
      prevConditionCKD.push(row);

      var prevConditionHeaders: any = {
        fila_0: {
          col_1: { text: ' ', fillColor: '#E1E1E1', style: 'tableHeader1', rowSpan: 1, alignment: 'center', margin: [0, 8, 0, 0], border: [false, true, false, true] },
          col_2: { text: 'DIAGNOSIS', fillColor: '#E1E1E1', style: 'tableHeader1', rowSpan: 1, alignment: 'center', margin: [0, 8, 0, 0], border: [false, true, true, true] },
          col_3: { text: 'PCP RPT DT', fillColor: '#E1E1E1', style: 'tableHeader1', rowSpan: 1, alignment: 'center', margin: [0, 8, 0, 0], border: [true, true, true, true] },
          col_4: { text: 'LAST RPT DT', fillColor: '#E1E1E1', style: 'tableHeader1', rowSpan: 1, alignment: 'center', margin: [0, 8, 0, 0], border: [true, true, true, true] },
          col_5: { text: 'LAST PROVIDER', fillColor: '#E1E1E1', style: 'tableHeader1', rowSpan: 1, alignment: 'center', margin: [0, 8, 0, 0], border: [true, true, true, true] },
          col_6: { text: 'ASSESSMENT', fillColor: '#E1E1E1', style: 'tableHeader1', rowSpan: 1, alignment: 'center', margin: [0, 8, 0, 0], border: [true, true, false, true]},
        }
      }

      for (var key in prevConditionHeaders) {
        //console.log("--Keys---")
        if (prevConditionHeaders.hasOwnProperty(key)) {

          var header = prevConditionHeaders[key];
          var row = new Array();
          row.push(header.col_1);
          row.push(header.col_2);
          row.push(header.col_3);
          row.push(header.col_4);
          row.push(header.col_5);
          row.push(header.col_6);
          //prevConditionBody.push(row);
          prevConditionCKD.push(row);
        }
      }

      for (let item of previousCondition) {

        if (item.instruction != null) {
          var row = new Array();
          row.push({ text: ' ',fillColor: '#C0C0C0', border: [false, true, false, false] });
          row.push({ text: item.instruction, colSpan: 5, bold: true, fontSize: 10, fillColor: '#C0C0C0', alignment: 'left', border: [true, true, false, false] });
          row.push({ text: ' ' });
          row.push({ text: ' ' });
          row.push({ text: ' ' });
          row.push({ text: ' ' });
          prevConditionCKD.push(row);
        }
        var row = new Array();
        row.push({ text: item.dXCode, border: [false, false, false, true] });
        row.push({ text: item.dXDescription, border: [false, false, false, true] });
        row.push({ text: this.formatDate(item.pcpreportedDate), border: [false, false, false, true] });
        row.push({ text: this.formatDate(item.lastReportedDate), border: [false, false, false, true] });
        row.push({ text: item.lastProvider, border: [false, false, false, true] });
        row.push({ image: this.ADUlogo, width: 50, border: [false, false, false, true] });
        prevConditionCKD.push(row);
      }
    }
    else {
      this.pageBreak = '';
      prevConditionCKD.push([]);
      prevConditionCKD.push([]);
      prevConditionCKD.push([]);


    }

    /******* POTENTIAL CONDITIONS *******/
    var potentialConditionBody = []
    if (Object.keys(poteintialConditionData).length) {
      //for (let item of poteintialConditionData) {
      if (!previousCondition.length) {
        //this.pageBreak = 'before';
        this.pageBreak = '';
      }
      //this.pageBreak = '';        
      var row = new Array();
      row.push({ text: ' ', colSpan: 2, alignment: 'center' });
      row.push({ text: ' ' });
      row.push({ text: ' ' });
      potentialConditionBody.push(row);

      var row = new Array();
      row.push({ text: 'POTENTIAL CONDITIONS', colSpan: 3, fontSize: 14, bold: true, fillColor: '#C0C0C0', alignment: 'center' });
      row.push({ text: ' ' });
      row.push({ text: ' ' });
      potentialConditionBody.push(row);

      var row = new Array();
      row.push({ text: 'We have found data that could indicate the patient has a condition within the HCC listed below. Please assess what (if any) diagnosis the patient may have and for each diagnosis that is present, document in your assessment and support it with a plan in the progress note.', colSpan: 3, fontSize: 9, bold: true, alignment: 'left' });
      row.push({ text: ' ' });
      row.push({ text: ' ' });
      potentialConditionBody.push(row);

      for (let item of poteintialConditionData) {
        var row = new Array();
        row.push({ text: item[0], border: [true, true, true, true], colSpan: 2, fontSize: 10, fillColor: '#C0C0C0', alignment: 'left',margin: [0, 8, 0, 0]});        
        row.push({ text: ' ' });
        row.push({ image: this.ADUlogo, width: 50});        
        potentialConditionBody.push(row);

        var row = new Array();
        row.push({ text: 'INDICATION OF POTENTIAL CONDITION', fontSize: 8, style: 'tableHeader', rowSpan: 1, alignment: 'left', margin: [0, 8, 0, 0], fillColor: '#E1E1E1' });
        row.push({ text: 'PROVIDER', style: 'tableHeader', fontSize: 8, rowSpan: 1, alignment: 'left', margin: [0, 8, 0, 0], fillColor: '#E1E1E1' });
        row.push({ text: 'DOS', style: 'tableHeader', fontSize: 8, rowSpan: 1, alignment: 'left', margin: [0, 8, 0, 0], fillColor: '#E1E1E1' });

        potentialConditionBody.push(row);


        // for (let i of item[1]) {
        //   var row = new Array();
        //   row.push({ text: i.indicatorDescription });
        //   row.push({ text: i.indicatorProvider });
        //   row.push({ text: this.formatDate(i.indicatorDOS) });

        //   potentialConditionBody.push(row);
        // }

        if (item[1] && item[1].length) {
          for (let i of item[1]) {
            var row = new Array();
            row.push({ text: i.indicatorDescription, border: [false, false, true, true] });
            row.push({ text: i.indicatorProvider, border: [false, false, true, true] });
            row.push({ text: this.formatDate(i.indicatorDOS), border: [false, false, false, true] });
            potentialConditionBody.push(row);
          }
          // var row = new Array();
          // row.push({ text: item[1][0].indicatorDescription, border: [false, false, true, true] });
          // row.push({ text: item[1][0].indicatorProvider, border: [false, false, true, true] });
          // row.push({ text: this.formatDate(item[1][0].indicatorDOS), border: [false, false, false, true] });
        } 
        else {
          // var row = new Array();
          // row.push({ text: 'No Data Available.', colSpan: 3, alignment: 'center' });
          // potentialConditionBody.push(row);
        }

        var row = new Array();
        row.push({ text: ' ', colSpan: 3 });
        potentialConditionBody.push(row);

      }  
    }
    else {
      this.pageBreak = '';
      potentialConditionBody.push([])
      potentialConditionBody.push([])
      potentialConditionBody.push([])
     }

    // let memberMeasureData = []
    // if (Object.keys(memberMeasureDatas).length) {
    //   for (let item of memberMeasureDatas) {  
    //     console.log("inside item")
    //     memberMeasureDatas.filter((element) => {
    //       if(element !== item) 
    //       {
    //         console.log("inside measure:",element.measure,item.measure)
    //         var row = new Array();          
    //         row.push({ text: element.measure, colSpan: 1, bold: true, fontSize: 10, fillColor: '#C0C0C0', alignment: 'left' });          
    //         row.push({ text: ' ' });  
    //         memberMeasureData.push(row); 
    //       }
    //      });
    //     } 


    let uniqueIds = [];
    let memberMeasureData = []
    if (Object.keys(memberMeasureDatas).length) {
      const unique = memberMeasureDatas.filter(element => {
        const isDuplicate = uniqueIds.includes(element.measure);
        if (!isDuplicate) {
          uniqueIds.push(element.measure);
          return true;
        }
      });
      var row = new Array();
      row.push({ text: 'HEDIS MEASURES', colSpan: 2, fontSize: 14, bold: true, fillColor: '#C0C0C0', alignment: 'center' });
      row.push({ text: ' ' });
      memberMeasureData.push(row);

      var row = new Array();
      row.push({ text: 'Please ensure your patient completes the HEDIS services listed below. This may not be the most recent data.\nPlease refer to the Provider Portal for the most up to date HEDIS information', fontSize: 8, colSpan: 2, bold: true, alignment: 'left', margin: [20, 0, 0, 0] });
      row.push({ text: ' ' });
      memberMeasureData.push(row);

      for (let item of uniqueIds) {
        var row = new Array();
        row.push({ text: item, bold: true, colSpan: 1, fontSize: 10, fillColor: '#C0C0C0', alignment: "left", margin: [20, 0, 0, 0] });
        row.push({ text: ' ' });
        memberMeasureData.push(row);


        memberMeasureDatas.filter(eve => {
          if (eve.measure == item) {
            var row = new Array();
            row.push({ text: ' ' });
            row.push({ text: eve.eventDesc, fontSize: 10, alignment: "left", border: [false, true, false, true], margin: [0, 0, 40, 0] });
            memberMeasureData.push(row);
          }
        })

      }
    }
    else {
      memberMeasureData.push([])
    }
    //console.log("memberMeasureData display :", memberMeasureData);

    // if(pdfIndex > 0) {      
    //   this.pageBreak = 'before'      
    // } else {      
    //   this.pageBreak = ''      
    // }

    var contentData: any = [
      // { text: '', pageBreak: this.pageBreak, style: 'subheader'},
      { text: '', style: 'subheader' },
      {
        style: 'sectionTable',
        table: {
          widths: ['*'],
          body: [
            [{
              border: [false, false, false, false],
              text: 'INSTRUCTIONS FOR CONDITION ASSESSMENT'
            }]
          ]
        }
      },
      { text: 'During your face-to-face visit with the patient, please assess each previously reported and potential\n condition presented on this form and record your response for each condition using the legend', fontSize: 12, bold: true, alignment: 'center' },
      {
        table: {
          widths: ['auto', "auto"],
          body: [
            [{ border: [false, true, false, false], image: this.Alogo, width: 25, height: 25, }, { border: [false, true, false, false], text: ['Please check this box, if based on your assessment and independent clinical judgment, you ',{ text: 'AGREE', bold: true,fontSize: 10 },' the listed condition is currently active and present.'], fontSize: 8 }],
            [{ border: [false, false, false, false], image: this.Dlogo, width: 25, height: 25 }, { border: [false, false, false, false], text: ['Please check this box, if based on your assessment and independent clinical judgment, you ',{text: 'DISAGREE', bold: true,fontSize: 10},' the listed condition is currently active and present. You should ',{text: 'not',decoration: 'underline' },' document the reason you disagree in the progress note.'], fontSize: 8 }],
            [{ border: [false, false, false, false], image: this.Ulogo, width: 25, height: 25 }, { border: [false, false, false, false], text: ['Please check this box, if based on your assessment and independent clinical judgment, you are ',{text: 'UNSURE', bold: true,fontSize: 10},' whether or not the condition is currently active and present.'], fontSize: 8 }],
          ]
        },
      },
      {
        table: {
          //widths: ['auto'],
          body: [
            [{ border: [false, false, false, true], text: ['If the conditions that are marked agree are not documented in the progress note, the patient may need to be brought back for another visit to have the condition(s) assessed during the ', { text: 'calendar year.', bold: true }, ' Of note, please be sure to finalize your progress note and submit for processing no later than', { text: ' 90 days', bold: true }, ' from the date of service - including signature or electronic signature date. Progress notes submitted beyond', { text: ' 90 days', bold: true }, ' will not be accepted. \nThis form expires ',{text: '12/31/2024', bold: true,decoration: 'underline' },' and must be submitted no later than ',{text: '1/10/2025', bold: true,decoration: 'underline' }], fontSize: 9, alignment: 'center', margin: [20, 0, 20, 0] }],
          ]
        },
      },
      { text: ['If you choose to email or fax the PAHAF forms and medical record\ndocumentation, please only fax 3 patient documents at a time.\nEmail forms to: ',{text: 'CarelonHealth.PAHAFS@carelon.com', decoration: 'underline',color:'#44B8F3'},'  Fax:(562)207-3657'], fontSize: 11, bold: true, alignment: 'center' },

      {
        style: 'tableExample',
        pageBreak: this.pageBreak,
        table: {
          widths: ['10%', '30%', '9%', '10%', '25%', '16%'],
          headerRows: 3,
          heights:[,,,,40],
          //keepWithHeaderRows: 1,
          body: prevConditionCKD
        },
        layout: {
          defaultBorder: false,
        }
      },

      {
        style: 'tableExample',
        //pageBreak: this.pageBreak,   
        table: {
          widths: ['45%', '40%', '15%'],
          headerRows: 3,
          //keepWithHeaderRows: 1,
          body: potentialConditionBody
        },
        //layout: 'lightHorizontalLines'
        layout: {
          defaultBorder: false,
        }
      },

      {
        style: 'sectionTable',
        table: {
          widths: ['*'],
          body: [
            [{
              border: [false, false, false, false],
              text: 'STARS/HEDIS MEASURES', pageBreak: 'before'
            }]            
          ]
        }
      },
      {
        table: {
          //widths: ["auto"],
          body: [
            [{ image: this.starMeasure , width: 500, height: 575, alignment: 'center',margin: [50, 5, 0, 0] }],           
          ]
        },
        layout: 'noBorders'
      },
/********
      {
        text: 'To assist in your reporting for quality measures, you may use the information below to facilitate your assessment of the member and sign/date the bottom of the form.  Document the information in your progress note and submit the appropriate Category II CPT code(s) on your claim submission.  The Star Assessments are not required for payment of the PAHAF condition validation program.', fontSize: 8, margin: [10, 0, 10, 0], bold: true
      },
      {
        style: 'starSection',
        table: {
          widths: ['*'],
          body: [
            [
              { border: [false, false, false, false], text: 'Care for Older Adult - Pain Assessment (Category II Codes: 1125F, 1126F)' }
            ]
          ]
        }
      },
      {
        table: {
          body: [
            [{ text: "1. On a scale of 0 to 10, with 0 being no pain and 10 being the worst pain, what is the patient's level? (circle one).", fontSize: 7, margin: [10, 0, 0, 0], bold: true }],
            [{
              columns: [
                {
                  text: '0',
                },
                {
                  text: '1'
                },
                {
                  text: '2'
                },
                {
                  text: '3'
                },
                {
                  text: '4'
                },
                {
                  text: '5'
                },
                {
                  text: '6'
                },
                {
                  text: '7'
                },
                {
                  text: '8'
                },
                {
                  text: '9'
                },
                {
                  text: '10'
                },
              ], margin: [20, 0, 0, 0], fontSize: 7
            }
            ],
          ]
        },
        layout: 'noBorders'
      },
      {
        style: 'starSection',
        table: {
          widths: ['*'],
          body: [
            [
              { border: [false, false, false, false], text: 'Health Outcome Survey (HOS) - Monitoring Physical Activity (Category II Code: 1003F)' }
            ]
          ]
        }
      },
      {
        table: {
          body: [
            [{ text: "2a. In the past 7 days, how many days did patient exercise? (choose one).", fontSize: 7, bold: true, margin: [10, 0, 0, 0] }],
            [{
              columns: [
                {
                  text: '0',
                },
                {
                  text: '1'
                },
                {
                  text: '2'
                },
                {
                  text: '3'
                },
                {
                  text: '4'
                },
                {
                  text: '5'
                },
                {
                  text: '6'
                },
                {
                  text: '7'
                },
                {

                },
                {

                },
                {},
              ], margin: [20, 0, 0, 0], fontSize: 7
            }
            ],
            [{
              text: [
                { text: "2b. Did you advise the patient to start, increase, or maintain their physical activity? (choose one)", fontSize: 7, bold: true, margin: [20, 0, 0, 0] },
                { text: "     Yes    No", fontSize: 7 }
              ], margin: [10, 0, 0, 0]
            }]
          ]
        },
        layout: 'noBorders'
      },
      
      {
        style: 'starSection',
        table: {
          widths: ['*'],
          body: [
            [
              { border: [false, false, false, false], text: 'HOS - Mental Health Assessment (Please submit any of these codes: G8431, G8510, G0444, G8511, or 96127)' }
            ]
          ]
        }
      },
      {
        table: {
          body: [
            [{ text: "3a. Have physical or emotional problems interfered with the patient’s social activities (e.g., visiting with friends,relatives,etc.)? (choose one)", fontSize: 7, bold: true, margin: [10, 0, 0, 0] }],
            [{
              columns: [
                {
                  text: 'All of the time',
                },
                {
                  text: 'Most of the time'
                },
                {
                  text: 'Some of the time'
                },
                {
                  text: 'A little of the time'
                },
                {
                  text: 'None of the time'
                },
              ], margin: [20, 0, 0, 0], fontSize: 7
            }
            ],
            [
              { text: "3b. Over the last 2 weeks, how often has the patient been bothered by any of the following problems?", fontSize: 7, bold: true, margin: [10, 0, 0, 0] }

            ],
            [{
              columns: [
                {
                  text: 'a. Little interest or pleasure in doing things(circle one)',
                },
                {
                  text: '  Not at all'
                },
                {
                  text: 'Several days'
                },
                {
                  text: 'More than half the days'
                },
                {
                  text: 'Nearly every day'
                },
              ], margin: [20, 0, 0, 0], fontSize: 7
            }
            ],
            [{
              columns: [
                {
                  text: 'b. Feeling down, depressed, or hopeless(circle one)',
                },
                {
                  text: '  Not at All',
                },
                {
                  text: 'Several days',
                },
                {
                  text: 'More than half the day',
                },
                {
                  text: 'Nearly every day',
                },
              ], margin: [20, 0, 0, 0], fontSize: 7
            }
            ],

            [{
              text: [
                { text: "3c. If positive PHQ-9 or PHQ-2 findings, was patient offered counseling, medication or referred to behavioral health provider?(circle one)", fontSize: 7, bold: true, margin: [20, 0, 0, 0] },
                { text: "     Yes    No    N/A", fontSize: 7 }
              ], margin: [10, 0, 0, 0]
            }]
          ]
        },
        layout: 'noBorders'
      },

      
      {
        style: 'starSection',
        table: {
          widths: ['*'],
          body: [
            [
              { border: [false, false, false, false], text: 'HOS - Improving Bladder Control (Category II Codes: 1090F, 0509F, 1091F)' }
            ]
          ]
        }
      },
      {
        table: {
          widths: ["auto"],
          body: [
            [
              {
                text: [
                  { text: "4a. Did the patient report urinary incontinence? (circle one)", fontSize: 7, bold: true, margin: [20, 0, 0, 0] },
                  { text: "     Yes    No", fontSize: 7 }
                ], margin: [10, 0, 0, 0]
              }
            ],
            [
              {
                text: [
                  { text: "4b. If you answered yes, did you discuss treatment options like bladder training exercises, medication, and/or surgery? (circle one)", fontSize: 7, bold: true, margin: [20, 0, 0, 0] },
                  { text: "     Yes    No", fontSize: 7 }
                ], margin: [10, 0, 0, 0]
              }

            ],
          ]
        },
        layout: 'noBorders'
      },

      
      {
        style: 'starSection',
        table: {
          widths: ['*'],
          body: [
            [
              { border: [false, false, false, false], text: 'HOS - Reducing the Risk of Falling (Category II Codes: 1100F, 1101F, 3288F, 0518F)' }
            ]
          ]
        }
      },
      {
        table: {
          widths: ["auto"],
          body: [
            [
              {
                text: [
                  { text: "5a. In the past 12 months, did you perform a fall risk assessment? (circle one)", fontSize: 7, bold: true, margin: [20, 0, 0, 0] },
                  { text: "     Yes    No    N/A", fontSize: 7 }
                ], margin: [10, 0, 0, 0]
              }
            ],
            [
              {
                text: [
                  { text: "5b. For patients at risk of falling, did you refer them to Nifty after Fifty, physical therapy, order a Bone Density Test, or discuss other options? (circle one)", fontSize: 7, bold: true, margin: [20, 0, 0, 0] },
                  { text: "     Yes    No    N/A", fontSize: 7 }
                ], margin: [10, 0, 0, 0]
              }
            ],
          ]
        },
        layout: 'noBorders'
      },

      
      {
        style: 'starSection',
        table: {
          widths: ['*'],
          body: [
            [
              { border: [false, false, false, false], text: 'Control of Blood Pressure (Category II Codes: 3074F, 3075F, 3079F, 3078F, 3077F, 3080F)' }
            ]
          ]
        }
      },
      {
        table: {
          //widths: ["auto"],
          body: [
            [
              { text: '6a.', margin: [10, 0, 0, 0], fontSize: 7, bold: true },{ text: 'Blood', margin: [5, 0, 0, 0], fontSize: 7, bold: true },{ text: 'Pressure', margin: [0, 0, 0, 0], fontSize: 7, bold: true },[{ text: 'Systolic\n', fontSize: 8 }, { image: this.rectBig, width: 30, height: 13 }], { text: "/" }, [{ text: 'Diastolic\n', fontSize: 8 }, { image: this.rectBig, width: 30, height: 13 }], { text: 'mm/HG (check systolic and diastolic CPT-II box below if compliant; Note - most recent BP reading/value counts towards measure.)', fontSize: 7, bold: true }

            ],
          ]
        },
        layout: 'noBorders'
      },
      {
        table: {
          widths: ['auto', "*", 'auto', "*"],
          body: [
            [{ image: this.checkbox, width: 10, height: 10, margin: [10, 0, 0, 0] }, { text: '6b. Most recent systolic <130 (3074F)', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: '6c. Most recent systolic 130-139 (3075F)', fontSize: 7, bold: true }],
            [{ image: this.checkbox, width: 10, height: 10, margin: [10, 0, 0, 0] }, { text: '6d. Most recent diastolic 80-89 (3079F)', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: '6e. Most recent diastolic <80 (3078F)', fontSize: 7, bold: true }],
            [{ image: this.checkbox, width: 10, height: 10, margin: [10, 0, 0, 0] }, { text: '6f. Most recent systolic >140 (3077F)', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: '6g. Most recent diastolic >90 (3080F)', fontSize: 7, bold: true }],
          ]
        },
        layout: 'noBorders'
      },

      
      {
        style: 'starSection',
        table: {
          widths: ['*'],
          body: [
            [
              { border: [false, false, false, false], text: 'Breast Cancer Screening (Display measure; Payment upon receipt of claim or encounter)' }
            ]
          ]
        }
      },
      {
        table: {
          //widths: ["auto"],
          body: [
            [{ text: '7.', margin: [10, 0, 0, 0], fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'Completed', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'Referred', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'Refused', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'N/A', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'Bilateral/Unilateral Mastectomy', fontSize: 7, bold: true }],
          ]
        },
        layout: 'noBorders'
      },
      
      {
        style: 'starSection',
        table: {
          widths: ['*'],
          body: [
            [
              { border: [false, false, false, false], text: 'Colorectal Cancer Screening (Display measure; Payment upon receipt of claim or encounter)' }
            ]
          ]
        }
      },
      {
        table: {
          //widths: ["auto"],
          body: [
            [{ text: '8.', margin: [10, 0, 0, 0], fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'Completed', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'Referred', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'Refused', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'N/A', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'Meets Exclusion Criteria', fontSize: 7, bold: true }],
          ]
        },
        layout: 'noBorders'
      },
      {
        table: {
          widths: ['auto', "*", '*', "auto", "auto"],
          body: [
            [{ image: this.checkbox, width: 10, height: 10, margin: [10, 0, 0, 0] }, { text: 'Had colonoscopy in last 10 years', fontSize: 7, bold: true }, { text: 'Date:________________', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'Colorectal cancer (code: G0213-5, G0231)', fontSize: 7, bold: true }],
            [{ image: this.checkbox, width: 10, height: 10, margin: [10, 0, 0, 0] }, { text: 'Had flex sigmoidoscopy in last 5 year', fontSize: 7, bold: true }, { text: 'Date:________________', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'Hx of neoplasm of large intestine (code: Z85.038)', fontSize: 7, bold: true }],
            [{ image: this.checkbox, width: 10, height: 10, margin: [10, 0, 0, 0] }, { text: 'Had CT colonography in last 5 years', fontSize: 7, bold: true }, { text: 'Date:________________', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'Hx of rectal, rectosigmoid, and anus neoplasm (Z85.0)', fontSize: 7, bold: true }],
            [{ image: this.checkbox, width: 10, height: 10, margin: [10, 0, 0, 0] }, { text: 'Had Fit-DNA in last 3 years', fontSize: 7, bold: true }, { text: 'Date:________________', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'Total colectomy (code: 44150-3, 44155-8, 44210-2)', fontSize: 7, bold: true }],
          ],
        },
        layout: 'noBorders'
      },


      
      {
        style: 'starSection',
        table: {
          widths: ['*'],
          body: [
            [
              { border: [false, false, false, false], text: 'Diabetes Mellitus (Display measure only; Payment upon receipt of claim or encounter for eligible member/measure)' }
            ]
          ]
        }
      },
      {
        table: {
          //widths: ['auto',"*",'*',"auto","*","*","*","*"],
          body: [
            [{ text: "9a. HbA1c control (</= 9%)", fontSize: 7, margin: [10, 0, 0, 0], bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'Yes', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'No', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'N/A', fontSize: 7, bold: true }, { text: '*Note - most recent A1c test/value is what counts for control measure', fontSize: 7, bold: true }],
            [{ text: "9b. Kidney Evaluation (labs to complete eGFR and uACR)", fontSize: 7, margin: [10, 0, 0, 0], bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'Yes', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'No', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'N/A', fontSize: 7, bold: true }, { text: '*Yes indicates completed or referred', fontSize: 7, bold: true }],
            [{ text: "9c. Retinal eye exam", fontSize: 7, margin: [10, 0, 0, 0], bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'Yes', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'No', fontSize: 7, bold: true }, { image: this.checkbox, width: 10, height: 10 }, { text: 'N/A', fontSize: 7, bold: true }, { text: '*Yes indicates completed or referred', fontSize: 7, bold: true }],
          ]
        },
        layout: 'noBorders'
      },
      ` `,
      {
        table: {
          //widths: ["auto"],
          body: [
            [{ text: "Provider Signature/Initials:", fontSize: 10, bold: true, margin: [40, 0, 0, 0] }, { text: "__________________________" }, { text: "Date:", fontSize: 10, bold: true }, { text: "_________________________" }],
          ]
        },
        layout: 'noBorders'
      }, */

      // {
      //   style: 'tableExample',
      //   table: {
      //     widths: ['50%', '50%'],
      //     headerRows: 1,
      //     //keepWithHeaderRows: 1,
      //     body: memberMeasureData
      //   },
      //   //layout: 'lightHorizontalLines'
      //   layout: {
      //     defaultBorder: false,
      //   }
      // },

      // {
      //   style: '',
      //   table: {
      //     widths: ['50%', '50%'],
      //     body: [
      //       [
      //         { text: `Copyright ${new Date().getFullYear()}  Carelon`, style: 'fontSize8', alignment: 'left' }, { text: `CDDTSTWEBV001 | ${(new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear()}`, style: 'fontSize8', alignment: 'right' }
      //       ]
      //     ]
      //   },
      //   layout: 'noBorders'
      // },

    ]

    //this.contentArr.push(contentData);    
    let logoImg = this.logo
    const documentDefinition = {
      pageOrientation: 'portrait',
      pageMargins: [20, 175, 20, 48],
      header: function (pageCount: any) {
        totpage = pageCount;

        return {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  columns: [
                    {
                      image: logoImg, width: 170, margin: [20, 20, 0, 0]
                    },
                    {
                      width: '*',
                      text: [`${year}`,
                      { text: ' Patient Annual\n Health Assessment\n' }
                      ],
                      fontSize: 24,
                      alignment: 'right',
                      margin: [0, 20, 20, 0]
                    }
                  ]
                }
              ],
              [
                {
                  style: 'headTable',
                  table: {
                    widths: ['*'],
                    body: [
                      [
                        {
                          border: [false, false, false, false],
                          fillColor: '#C0C0C0',
                          text: `${formDataArray[0].lastName}, ${formDataArray[0].firstName}`
                        }
                      ]
                    ],
                  },
                }
              ],
              [
                {
                  style: 'tableExample',
                  table: {
                    headerRows: 1,
                    widths: ['50%', '50%'],
                    body: [
                      [{ text: `${parentValue.memberHmoID}`, bold: true, fontSize: 12, margin: [20, 0, 0, 0] }, { text: `${formDataArray[0].providerName}`, bold: true, alignment: 'right', fontSize: 12, margin: [0, 0, 20, 0] }],
                      [{                         
                        text:[
                          {text:`${parentValue.memberDOB}`},{text:`${formDataArray[0].memberGender == 'F' ? ', FEMALE' : ', MALE'}`}]
                        , fontSize: 10, margin: [20, 0, 0, 0] }, { text: `${formDataArray[0].providerNetwork}`, alignment: 'right', fontSize: 10, margin: [0, 0, 20, 0]
                      }],
                      [{ text: `${formDataArray[0].cmProduct}`, fontSize: 10, margin: [20, 0, 0, 0] }, { text: `${formDataArray[0].pCPNeighborhood}`, alignment: 'right', fontSize: 10, margin: [0, 0, 20, 0] }]
                    ]
                  },
                  layout: 'noBorders'
                }

              ],
            ]
          },
          layout: 'noBorders'
        }

      },
      footer: function (currentPage: any, pageCount: any) {
        let d = new Date(Date.now()).toLocaleString();
        if (d) {
          d = d.split(',')[0];
        }
        let updatedPAgeCount = (pageCount % 2 == 0) ? pageCount : pageCount + 1
        //return [{ text: 'Page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'center' ,style:'fontSize8' }];
        return {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  columns: [
                    {
                      text: `${(new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear()}`, style: 'fontSize8', margin: [40, 0, 0, 0]
                    },
                    {
                      text: [{ text: '© ' }, `${new Date().getFullYear()}`, { text: ' Carelon Health. All Rights Reserved.' }], style: 'fontSize8'
                    },
                    {
                      text: 'Page ' + currentPage.toString() + ' of ' + updatedPAgeCount, alignment: 'center', style: 'fontSize8',
                      //  text: 'Page ' + currentPage.toString(), alignment: 'center' ,style: 'fontSize8', 
                    },


                  ]
                }
              ],
            ]
          },
          layout: 'noBorders'
        }
      },
      content: [contentData],

      //content: [this.contentArr], 
      styles: {
        blueTable: {
          fillColor: '#002266',
          bold: true,
          fontSize: 11,
          color: '#ffffff',
          alignment: 'center'
        },
        headTable: {
          margin: [20, 0, 20, 0],
          fontSize: 21,
          alignment: 'center',
        },
        starSection: {
          fillColor: '#C0C0C0',
          bold: true,
          fontSize: 8,
        },
        sectionTable: {
          fillColor: '#C0C0C0',
          bold: true,
          fontSize: 14,
          //color: '#ffffff',
          alignment: 'center'
        },
        // greyTable: {
        //   fillColor: '#4D4D4D',
        //   bold: true,
        //   color: '#ffffff',
        //   alignment: 'center'
        // },
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        boldText: {
          fontSize: 10,
          bold: true
        },
        tableHeader: {
          bold: true,
          fontSize: 9,
          color: 'black'
        },
        tableHeader1: {
          //bold: true,
          fontSize: 9,
          color: 'black'
        },
        logoHeader: {
          fillColor: '#1B456A',
          color: '#ffffff'
        },
        greyTableHeader: {
          fillColor: '#F5F5F5',
          bold: true,
          fontSize: 9
        },
        tableExample: {
          margin: [0, 0, 0, 0],
          fontSize: 8
        },
        fontSize8: {
          fontSize: 8
        },
        defaultStyle: {
          fontSize: 14
        },
        superMargin: {
          margin: [20, 0, 40, 0],
          fontSize: 15
        },
        fontSize10: {
          fontSize: 10
        }

      },

    };

    const docDef = {
      pageOrientation: 'portrait',
      pageMargins: [20, 175, 20, 48],
      header: function (pageCount: any) {

        return {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  columns: [
                    {
                      image: logoImg, width: 170, margin: [20, 20, 0, 0]
                    },
                    {
                      width: '*',
                      text: [`${year}`,
                      { text: ' Patient Annual\n Health Assessment\n' }
                      ],
                      fontSize: 24,
                      alignment: 'right',
                      margin: [0, 20, 20, 0]
                    }
                  ]
                }
              ],
              [
                {
                  style: 'headTable',
                  table: {
                    widths: ['*'],
                    body: [
                      [
                        {
                          border: [false, false, false, false],
                          fillColor: '#C0C0C0',
                          text: `${formDataArray[0].lastName}, ${formDataArray[0].firstName}`
                        }
                      ]
                    ],
                  },
                }
              ],
              [
                {
                  style: 'tableExample',
                  table: {
                    headerRows: 1,
                    widths: ['50%', '50%'],
                    body: [
                      [{ text: `${parentValue.memberHmoID}`, bold: true, fontSize: 12, margin: [20, 0, 0, 0] }, { text: `${formDataArray[0].providerName}`, bold: true, alignment: 'right', fontSize: 12, margin: [0, 0, 20, 0] }],
                      [{ text: `${parentValue.memberDOB},${formDataArray[0].memberGender == 'F' ? 'FEMALE' : 'MALE'}`, fontSize: 10, margin: [20, 0, 0, 0] }, { text: `${formDataArray[0].providerNetwork}`, alignment: 'right', fontSize: 10, margin: [0, 0, 20, 0] }],
                      [{ text: `${formDataArray[0].cmProduct}`, fontSize: 10, margin: [20, 0, 0, 0] }, { text: `${formDataArray[0].pCPNeighborhood}`, alignment: 'right', fontSize: 10, margin: [0, 0, 20, 0] }]
                    ]
                  },
                  layout: 'noBorders'
                }

              ],
            ]
          },
          layout: 'noBorders'
        }

      },
      footer: function (currentPage: any, pageCount: any) {
        let tPageCount = Number(totpage) + 1;
        let d = new Date(Date.now()).toLocaleString();
        if (d) {
          d = d.split(',')[0];
        }

        //return [{ text: 'Page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'center' ,style:'fontSize8' }];
        return {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  columns: [
                    {
                      text: `${(new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' + new Date().getFullYear()}`, style: 'fontSize8', margin: [40, 0, 0, 0]
                    },
                    {
                      text: [{ text: '© ' }, `${new Date().getFullYear()}`, { text: ' Carelon Health. All Rights Reserved.' }], style: 'fontSize8'
                    },
                    {
                      //text: 'Page ' + currentPage.toString() + ' of ' + pageCount, alignment: 'center' ,style: 'fontSize8', 
                      text: 'Page ' + tPageCount + ' of ' + tPageCount, alignment: 'center', style: 'fontSize8',
                    },


                  ]
                }
              ],
            ]
          },
          layout: 'noBorders'
        }
      },
      content: [],

      //content: [this.contentArr], 
      styles: {
        blueTable: {
          fillColor: '#002266',
          bold: true,
          fontSize: 11,
          color: '#ffffff',
          alignment: 'center'
        },
        headTable: {
          margin: [20, 0, 20, 0],
          fontSize: 21,
          alignment: 'center',
        },
        starSection: {
          fillColor: '#C0C0C0',
          bold: true,
          fontSize: 8,
        },
        sectionTable: {
          fillColor: '#C0C0C0',
          bold: true,
          fontSize: 14,
          //color: '#ffffff',
          alignment: 'center'
        },
        // greyTable: {
        //   fillColor: '#4D4D4D',
        //   bold: true,
        //   color: '#ffffff',
        //   alignment: 'center'
        // },
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        boldText: {
          fontSize: 10,
          bold: true
        },
        tableHeader: {
          bold: true,
          fontSize: 9,
          color: 'black'
        },
        tableHeader1: {
          //bold: true,
          fontSize: 9,
          color: 'black'
        },
        logoHeader: {
          fillColor: '#1B456A',
          color: '#ffffff'
        },
        greyTableHeader: {
          fillColor: '#F5F5F5',
          bold: true,
          fontSize: 9
        },
        tableExample: {
          margin: [0, 0, 0, 0],
          fontSize: 8
        },
        fontSize8: {
          fontSize: 8
        },
        defaultStyle: {
          fontSize: 14
        },
        superMargin: {
          margin: [20, 0, 40, 0],
          fontSize: 15
        },
        fontSize10: {
          fontSize: 10
        }

      },

    };
    
        var pdfBuffer = pdfMake.createPdf(documentDefinition);
        let dataBuffer = [];
        let tempdataBuffer;       
        pdfBuffer.getBuffer(async (buffer) => {
          const donorPdfDoc = await PDFLib.PDFDocument.load(buffer);
          dataBuffer.push(donorPdfDoc)
    
          totpage = donorPdfDoc.getPageCount();
    
          if (totpage % 2 != 0) {           
            var tempdata = await pdfMake.createPdf(docDef)
            tempdata.getBuffer(async (tBuffer) => {
              const donorPdfDoc = await PDFLib.PDFDocument.load(tBuffer);
              dataBuffer.push(donorPdfDoc);
    
              const pdfDoc = await PDFLib.PDFDocument.create();
    
              for (var j = 0; j < dataBuffer.length; j++) {                
                const docLength = dataBuffer[j].getPageCount();
    
                for (var k = 0; k < docLength; k++) {                  
                  const [donorPage] = await pdfDoc.copyPages(dataBuffer[j], [k]);
                  //console.log("Doc " + i+ ", page " + k);  
                  pdfDoc.addPage(donorPage);
                }
    
                if (j == dataBuffer.length - 1) {                  
                  const pdfBytes = await pdfDoc.save();
                  this.finalpdf.push(pdfDoc);
    
                  //download(pdfBytes, 'pahafDocument.pdf', 'application/pdf');
                }
              }            
              const pdfDoc1 = await PDFLib.PDFDocument.create();
          if(this.finalpdf.length == checkedList.length ) {            
            for (var a = 0; a < this.finalpdf.length; a++) {
              const docLength1 = this.finalpdf[a].getPageCount();
  
              for (var b = 0; b < docLength1; b++) {
                const [donorPage1] = await pdfDoc1.copyPages(this.finalpdf[a], [b]);
                //console.log("Doc " + i+ ", page " + k);  
                pdfDoc1.addPage(donorPage1);
              }
  
              if (a == this.finalpdf.length - 1) {                
                const pdfBytes1 = await pdfDoc1.save(); 
                await download(pdfBytes1, 'pahafDocument.pdf', 'application/pdf');
                this.finalpdf = [];
              }
            }
          }  
            })
          }
          else {           
            const pdfDoc = await PDFLib.PDFDocument.create();
    
            for (var j = 0; j < dataBuffer.length; j++) {              
              const docLength = dataBuffer[j].getPageCount();

              for (var k = 0; k < docLength; k++) {               
                const [donorPage] = await pdfDoc.copyPages(dataBuffer[j], [k]);
                //console.log("Doc " + i+ ", page " + k);  
                pdfDoc.addPage(donorPage);
              }
    
              if (j == dataBuffer.length - 1) {                
                const pdfBytes = await pdfDoc.save();
                this.finalpdf.push(pdfDoc);
                //download(pdfBytes, 'pahafDocument.pdf', 'application/pdf');
              }
            }
            const pdfDoc1 = await PDFLib.PDFDocument.create();
            if(this.finalpdf.length == checkedList.length ) {              
              for (var a = 0; a < this.finalpdf.length; a++) {
                const docLength1 = this.finalpdf[a].getPageCount();
    
                for (var b = 0; b < docLength1; b++) {
                  const [donorPage1] = await pdfDoc1.copyPages(this.finalpdf[a], [b]);
                  //console.log("Doc " + i+ ", page " + k);  
                  pdfDoc1.addPage(donorPage1);
                }
       
                if (a == this.finalpdf.length - 1) {                  
                  const pdfBytes1 = await pdfDoc1.save(); 
                  await download(pdfBytes1, 'pahafDocument.pdf', 'application/pdf');
                  this.finalpdf = [];
                }
              }
            }  
          }                     
        });
    }
    else {
      //this.notPrintedPdfList.push(parentValue.memberHmoID);      
      checkedList.length = checkedList.length - 1
    } 
  }

  // formatDate(date: any) {
  //   if (typeof date == "string") {      
  //     var dateObj = new Date(date)      
  //     return (dateObj.getMonth() + 1) + '/' + dateObj.getDate() + '/' + dateObj.getFullYear();
  //   } else {
  //     return 'NA'
  //   }
  // }
  
  formatDate(date: any) {
    if(!date)
      return '';
    //console.log("date :",date);
    date = date.replace(/-0+/g, '-');
    var array = (date || '').toString().split(/\-/g);
    let dateFormat = array[1] + '/' + array[2] + '/' + array[0].substr(2, 4);
   // array.push(array.shift());
    //console.log("dateFormat 2 :",dateFormat)
    return dateFormat;
  };

}


