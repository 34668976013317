import { Injectable } from '@angular/core';


import { urls, props } from '../common/model/properties';
import { CONFIG } from '../../../src/config';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SecureStorageService } from '../secure-storage.service';
import { Observable } from 'rxjs';

@Injectable()
export class DocumentsService {

    public generalReference: string;
    public providerCommunications: string;
    constructor( private http: HttpClient,
        private secureStorage:SecureStorageService ) { 
        this.generalReference = CONFIG.GeneralReference_GUID;
        this.providerCommunications = CONFIG.ProviderCommunications_GUID;
    }


    listDocuments( docCode, kvpl, page, size ): Observable<any> {
        
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});
        
        const myParams = new HttpParams({fromObject:{
            'appId': 'PP' ,
            'folderGUID': docCode == 'GEN_REF_DOC'?  this.generalReference : this.providerCommunications,
            'foldersOnly': 'true',
            'documentsOnly': 'true',
            'keyValuePairList': JSON.stringify( kvpl )
        }});
        // myParams.set( 'multiple', 'true' );
        // myParams.set( 'page', page );
        // myParams.set( 'size', size );
        // myParams.set( 'sortBy', 'CreatedDate' );
        // myParams.set( 'order', 'DESC' );
        
        const options =  { headers: headers, params: myParams } ;
      
        return this.http.get( urls.filenetFolderURL, options );
    }
    listDocumentsForSearch(userEnteredKeyword): Promise<any> {
        const kvpl = [{key: 'documenttitle', value: userEnteredKeyword, operator: 'like'}];
        
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Authorization': 'Bearer ' +  ppjwt.access_token 
        });
        const myParams = new HttpParams({fromObject:{
            'appId': 'PP',
            'keyValuePairList': JSON.stringify( kvpl )
        }});
        
        const options =  { headers: headers, params: myParams } ;
        return this.http.get( urls.filenetListURL, options ).toPromise();
    }
    getDocument( docID, docCode, kvpl ): Observable<any> {
        
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Authorization': 'Bearer ' +  ppjwt.access_token 
        });
        let myParams = new HttpParams({fromObject:{
            'appId': 'PP',
            'documentCode': docCode
        }});
        if ( kvpl !== null ) {
            myParams = new HttpParams({fromObject:{
                'appId': 'PP',
                'documentCode': docCode,
                'keyValuePairList': JSON.stringify( kvpl )
            }});
        }
        const options =  { headers: headers, params: myParams } ;
        const urlCall = ( docID === null ) ? urls.filenetURL : urls.filenetURL + '/' + docID;
        return this.http.get( urlCall, options );
    }

    listDocumentsBId( docCode, kvpl, page, size , folderGUID?){
        
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Authorization': 'Bearer ' +  ppjwt.access_token 
        });
        const myParams = new HttpParams({fromObject:{
            'appId': 'PP',
            'folderGUID': folderGUID || this.generalReference,
            'foldersOnly': 'true' ,
            'documentsOnly': 'true',
            'keyValuePairList': JSON.stringify( kvpl )
        }});
        // myParams.set( 'multiple', 'true' );
        // myParams.set( 'page', page );
        // myParams.set( 'size', size );
        // myParams.set( 'sortBy', 'CreatedDate' );
        // myParams.set( 'order', 'DESC' );
        myParams.set( 'keyValuePairList', JSON.stringify( kvpl ) );
        const options =  { headers: headers, params: myParams } ;
        return this.http.get( urls.filenetFolderURL, options ).toPromise().then(response => response);
    }
}