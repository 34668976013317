import { Injectable } from '@angular/core';

@Injectable()
export class Input1Model {

    public static TYPE_NUMERIC = 'TYPE_NUMERIC';
    public static TYPE_ALPHA = 'TYPE_ALPHA';
    public static TYPE_PHONE = 'TYPE_PHONE';
    public static TYPE_EMAIL = 'TYPE_EMAIL';
    public static TYPE_CRED = 'TYPE_PASSWORD';
    public static TYPE_OLD_CRED = 'TYPE_OLD_PASSWORD';

    label: string;
    value = '';
    type: string;
    textType: string;
    minLength = -1;
    maxLength = -1;
    required = false;
    fieldValidateMsg = '';
    isInputValid = true;
    isSelect = false;
    displayType = 'ONE';
    options;
    isTypeahead = false;
    typeAheadSelect:any;
    typeField:any;
    length = '';

    constructor(private model: any) {
        this.label = model.label ? model.label : '';
        this.value = model.value ? model.value.toString() : '';
        this.type = model.type ? model.type : '';
        if (model.type && (model.type === Input1Model.TYPE_CRED) || (model.type === Input1Model.TYPE_OLD_CRED)) {
            this.textType = 'password';
        } else {
            this.textType = 'text';
        }
        this.minLength = model.minLength ? model.minLength : -1;
        this.maxLength = model.maxLength ? model.maxLength : -1;
        this.required = model.required ? model.required : false;
        this.isSelect = model.isSelect ? model.isSelect : false;
        this.isTypeahead = model.isTypeahead ? model.isTypeahead : false;
        this.typeAheadSelect = model.typeAheadSelect ? model.typeAheadSelect : null;
        this.typeField = model.typeField ? model.typeField : null;
        this.options = model.options ? model.options : [];
        this.length = this.maxLength > -1 ? this.maxLength.toString() : '';
        this.displayType = model['displayType'] ? model['displayType'] : 'ONE';
    }

    togglePasswordShow() {
        if (this.textType === 'password') {
            this.textType = 'text';
        } else {
            this.textType = 'password';
        }
    }

    isValid() {
        this.isInputValid = true;

        if ('' === this.value) {
            if (this.required) {
                this.fieldValidateMsg = this.label + ' is required.';
                this.isInputValid = false;
                return false;
            } else {
                return true;
            }
        }

        this.validateType();

        if (!this.isInputValid) {
            return false;
        }

        this.validateLength();

        return this.isInputValid;
    }

    validateLength() {
        if (this.minLength !== this.maxLength) {

            if (this.minLength > -1 && this.value.length < this.minLength) {
                this.isInputValid = false;
                this.fieldValidateMsg = this.label + ' should be min length ' + this.minLength;
            } else if (this.maxLength > -1 && this.value.length > this.maxLength) {
                this.isInputValid = false;
                this.fieldValidateMsg = this.label + ' should be max length ' + this.maxLength;
            }
        } else if (this.minLength > -1 && this.value.length !== this.minLength) {
            this.isInputValid = false;
            this.fieldValidateMsg = this.label + ' should be length ' + this.minLength;
        }

    }

    validateType() {
        if (this.type === Input1Model.TYPE_NUMERIC) {
            this.validateNumeric();
        } else if (this.type === Input1Model.TYPE_ALPHA) {
            this.validateAlpha();
        } else if (this.type === Input1Model.TYPE_PHONE) {
            this.validatePhone();
        } else if (this.type === Input1Model.TYPE_EMAIL) {
            this.validateEmail();
        } else if (this.type === Input1Model.TYPE_CRED) {
            this.validatePassword();
        }
    }

    validateAlpha() {
        if (this.isAlpha(this.value)) {
            return;
        }

        this.isInputValid = false;
        this.fieldValidateMsg = this.label + ' should be Alphabetical.';
    }

    validateNumeric() {
        const numberPattern = /^0|[1-9]\d*$/;
        const regEx = new RegExp(numberPattern);
        if (this.value.match(regEx)) {
            return;
        }

        this.isInputValid = false;
        this.fieldValidateMsg = this.label + ' should be numeric.';
        return;
    }

    restrictKeys(event: any) {
        if (this.type === Input1Model.TYPE_NUMERIC) {
            this.restrictToNumber(event);
        } else if (this.type === Input1Model.TYPE_ALPHA) {
            this.restrictToAlpha(event);
        } else if (this.type === Input1Model.TYPE_PHONE) {
            this.restrictToPhone(event);
        }
    }

    keyPress(event: any) {
        if (this.type === Input1Model.TYPE_CRED || this.type === Input1Model.TYPE_OLD_CRED) {
            this.passRestrict(event);
        }
    }

    restrictToNumber(event: any) {
        const inputValue = event.target.value;
        const numberValue = this.extractNumber(inputValue);

        this.value = numberValue;
    }

    restrictToAlpha(event: any) {
        const inputValue = event.target.value;
        const numberValue = this.extractAlphaString(inputValue);

        this.value = numberValue;
    }

    extractNumber(value: string) {
        let newVal = '';
        if (isNaN(Number(value))) {
            for (let i = 0; i < value.length; i++) {
                const s: string = value.charAt(i);
                if (!isNaN(Number(s)) && s !== ' ') {
                    newVal += s;
                }

            }
        } else {
            newVal = value;
        }

        return newVal;
    }

    extractAlphaString(value: string) {
        let newVal = '';
        for (let i = 0; i < value.length; i++) {
            const s: string = value.charAt(i);
            if (this.isAlpha(s)) {
                newVal += s;
            }

        }

        return newVal;
    }

    isAlpha(str: string) {
        return /^[a-zA-Z]+$/.test(str);
    }

    validateEmail() {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.value)) {
            return;
        }
        this.isInputValid = false;
        this.fieldValidateMsg = this.label + ' should be in **@**.** format';
    }

    validatePassword() {
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*._])(?=.{8,15})/;
        if (passwordPattern.test(this.value)) {
            return;
        }
        this.isInputValid = false;
        this.fieldValidateMsg = this.label + ' does not meet requirements';
    }

    passRestrict(event: any) {
        const inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode === 32) {
            event.preventDefault();
        }
    }

    validatePhone() {
        let newVal = '';
        for (let i = 0; i < this.value.length; i++) {
            const s: string = this.value.charAt(i);
            if (!isNaN(Number(s)) && s !== ' ') {
                newVal += s;
            }
        }

        const phoneno = /^\d{10}$/;
        if (newVal.length !== 10) {
            this.isInputValid = false;
            this.fieldValidateMsg = this.label + ' should be of 10 digits.';
        }
    }

    constructPhoneFormat(value: string) {
        let newVal = value;
        if (value.length >= 4) {
            newVal = '(' + value.substring(0, 3) + ')';
            newVal += ' ' + value.substring(3, (value.length <= 6) ? value.length : 6);
            if (value.length > 6) {
                newVal += '-' + value.substring(6, (value.length <= 10) ? value.length : 10);
            }
        }
        return newVal;
    }

    restrictToPhone(event: any) {
        const inputValue: string = event.target.value;
        const numberValue = this.extractNumber(inputValue);
        const formattedPhone = this.constructPhoneFormat(numberValue);

        this.value = formattedPhone;
    }

}
