import {Injectable} from '@angular/core';
import { urls, props, payloads } from '../common/model/properties';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SecureStorageService } from '../secure-storage.service';

@Injectable()
export class ResetpasswordService {

     private forgotUser: any = '';
     constructor(private http: HttpClient,
        private secureStorage:SecureStorageService) {}

     updateUserForQuestions(userName) {
          this.forgotUser = userName;
     }
     submitPassword(key, cred) {
          const resetPasswordPayload = payloads.resetPasswordPayload;
          resetPasswordPayload.key = key;
          resetPasswordPayload.password = cred;
          const token: any = this.secureStorage.getSecureInfoInLocalStorage('clientCredentials_token');
        const headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        });

          return this.http.post(urls.resetPasswordUrl, resetPasswordPayload, {headers: headers});
     }
     getNews( callBack: ( sucess: boolean, message: string ) => void ) {
        //const headers = new HttpHeaders({'Accept': 'application/json'});
        const headers = new HttpHeaders({
            'Accept': 'application/json',
        });

         this.http.get( urls.getNews, { headers: headers } )
             .subscribe(
             ( resp:any ) => {
                 callBack( true, resp );
             }
             );
     }
     updateUerPasswordChangeTime( changePasswordTimePayload, callBack: ( sucess: boolean, message: string ) => void ) {
        //const headers = new HttpHeaders({'Accept': 'application/json'});
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});
        
          this.http.put( urls.updateLockedUerStatusUrl, changePasswordTimePayload, { headers: headers } )
          .subscribe(
                  ( resp :any) => {
                      callBack( true, resp);
                  }
          );
      }
}
