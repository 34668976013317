import { Injectable } from '@angular/core';

import { ConfigProvider } from '../../shared/services/configprovider';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SecureStorageService } from '../../secure-storage.service';
import { urls } from '../../common/model/properties';

@Injectable()
export class ConfirmationService {

    private forgotUser: any = '';
    props:any;
    constructor(private http: HttpClient, private configProvider:ConfigProvider,
        private secureStorage:SecureStorageService) {
        this.props = configProvider.getProps();
     }

    updateUserForQuestions(userName) {
        this.forgotUser = userName;
    }
    validateConfirmationCode(code: string, callBack: (sucess: boolean, message: string) => void) {
        const validateConfirmPayload = {
            code: '',
            properties: []
        };

        validateConfirmPayload['code'] = code;
        const headers = new HttpHeaders({
            'Authorization': this.props.wso_authorization
        });
        this.http.post(urls.validateConfirmUrl, validateConfirmPayload, { headers: headers ,observe:'response'})
            .subscribe(
                (resp) => {
                    if (resp.status === 202) {
                        callBack(true, '');
                    } else {
                        callBack(false, '');
                    }
                    return;
                },
                err => {
                    callBack(false, err);
                    return;
                }
            );
    }
    resendConfirmationCode(username: string, callBack: (sucess: boolean, message: string) => void) {
        const resendConfirmPayload = {
            user:
            {
                username: username,
                realm: 'PRIMARY'
            },
            properties: []
        };

        const headers = new HttpHeaders({
            'Authorization': this.props.wso_authorization,
            'Content-Type': 'application/json'
        });
        this.http.post(urls.resendConfirmUrl, resendConfirmPayload, { headers: headers ,observe:'response'})
            .subscribe(
                (resp) => {
                    if (resp.status === 201) {
                        callBack(true, '');
                    } else {
                        callBack(false, '');
                    }
                    return;
                },
                err => {
                    callBack(false, err);
                    return;
                }
            );
    }
    getNews(callBack: (sucess: boolean, message: string) => void) {
        const headers = new HttpHeaders({
            'Accept': 'application/json',
        });

        this.http.get(urls.getNews, { headers: headers })
            .subscribe(
                (resp:any) => {
                    callBack(true, resp);
                }
            );
    }
}
