<app-common></app-common>
<div class="top-panel">
	<div class="container-fluid top-panel bg-white">
		<div id="ucHeader_pnlBanner" class="row banner">

			<div class="col-9 col-sm-12">
				<div class="row align-items-center h-100 pl-3 pl-sm-0">
					<div class="col-12 col-sm-4 col-md-2 py-0 text-md-center">
						<img title="Logo" class="img img-fluid banner-logo" src="assets/images/Logo-carelon.svg"
							alt="Logo">
					</div>

				</div>
			</div>
		</div>
	</div>
</div>
<div class=" signinContainer">
	<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>

	<div class="row">
		<div class="col-md-8 col-lg-9 p-t-16 ht-500">
			<div class="cover-img full-width"></div>
		</div>

		<div class="col-md-4 col-lg-3" style="padding: 16px">
			<mat-card class="sign-card-container ht-100%">
				<div class="signin-label-header texfield-spacing">Forgot Username</div>

				<form action="#" class="validate-form frmForgotUsername" #appforgotpasswordform id="#forgotpasswordForm"
					(ngSubmit)="onSubmit()">
					<div [ngClass]="(isFiledValid['firstName'])?'wrap-input100 validate-input':'wrap-input100 validate-input alert-validate'"
						data-validate="FirstName">
						<input (focus)="onInputFocus($event)" placeholder="FIRST NAME" [(ngModel)]="model.firstName"
							#firstName="ngModel" id="firstName" name="firstName" type="text"
							[ngClass]="(model.firstName === '')?'input100':'input100 has-val'" required
							(keyup)="restrictToAlpha($event)" />
						<!-- <span class="focus-input100" data-placeholder="FirstName"></span> -->
					</div>
					<div [ngClass]="(isFiledValid['lastName'])?'wrap-input100 validate-input':'wrap-input100 validate-input alert-validate'"
						data-validate="LastName">
						<input (focus)="onInputFocus($event)" placeholder="LAST NAME" [(ngModel)]="model.lastName"
							#lastName="ngModel" id="lastName" name="lastName" type="text"
							[ngClass]="(model.lastName === '')?'input100':'input100 has-val'" required
							(keyup)="restrictToAlpha($event)" />
						<!-- <span class="focus-input100" data-placeholder="LastName"></span> -->
					</div>
					<div [ngClass]="(isFiledValid['email'])?'wrap-input100 validate-input':'wrap-input100 validate-input alert-validate'"
						[attr.data-validate]="isUndefinedOrEmpty(fieldValidateMsg['email']) ? 'Enter Email' : fieldValidateMsg['email']">
						<input (focus)="onInputFocus($event)" placeholder="EMAIL" [(ngModel)]="model.email"
							#email="ngModel" id="email" name="email" type="text"
							[ngClass]="(model.email === '')?'input100':'input100 has-val'" required />
						<!-- <span class="focus-input100" data-placeholder="Email"></span> -->
					</div>
					<div class="container-login100-form-btn  p-t-40">
						<button type="submit" class="carelon-btn action-button contact100-form-btn next">Submit</button>
					</div>
				</form>
				<div class="texfield-spacing texfield-spacing-cont">
					Go back to? <span [routerLink]="''" class="hyper-link">Sign in</span>
				</div>
			</mat-card>
		</div>


		
	</div>
	<div class="news-cont" >
			<mat-card style="margin: 10px 15px;max-height: 250px;overflow-y: scroll;">
	
				<ng-container *ngFor="let new of news">
					<h2 style="width:50%" [innerHTML]="new.messageName"></h2>
					<!-- <p>Our gold standard is to provide care that we would want to give to our own loved ones. That's the difference we want to make in the lives
						 of those we care for --- that our patients would have the assurance that they are in good hands and will receive the right care,
						 which also gives profound peace of mind to them and their families.
					</p> -->
					<p style="width:50%" [innerHTML]="new.message"></p>
					<!-- <h2>Care you would give your family</h2> -->
				</ng-container>
	
			</mat-card>
		</div>
</div>
	<!-- <app-footer [className]="'static-footer'"></app-footer> -->
	<div class="modal fade" id="myModal" role="dialog">
		<div class="modal-dialog" style="margin-top: 15%;">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="close" data-dismiss="modal">&times;</button>
				</div>
				<div class="modal-body">
					<p class="preLine">{{ errorMessage }}</p>
				</div>
				<div class="modal-footer">
					<div class="container-contact100-form-btn">
						<button type="button" (click)="myModalEventCallBack()"
							class="carelon-btn contact100-form-btn previous m-r-10" data-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>
	</div>
