import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, SimpleChanges } from '@angular/core';
import { CupService } from '../services/cup.service';
import { AutoCompletePipe } from '../pipes/dropdownoptions.pipe';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { ChangeDetectorRef, forwardRef, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Dropdown } from 'primeng/dropdown';
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AutoCompleteComponent),
  multi: true
};

@Component({
  selector: 'cup-autocomplete',
  templateUrl: './autocomplete.component.html',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  styleUrls: ['./autocomplete.component.css']
})
export class AutoCompleteComponent implements OnInit, ControlValueAccessor, AfterViewInit, OnChanges {
  @ViewChild('dynamicDropDown') dynamicDropDown: Dropdown;
  @ViewChild('staticDropDown') staticDropDown: Dropdown;
  @Input() autoCompleteId: any;
  @Input() colWidth: any;
  @Input() control: FormControl = new FormControl();
  public _autoCompleteOptions: any = [];
  public _autoCompleteModel: any;
  public currentValue: any;
  public colStyle = {};
  public dropDownStyles = [
    { id: 1, colWidth: '99%' },
    { id: 2, colWidth: '123px' },
    { id: 3, colWidth: '160px' },
    { id: 5, colWidth: '153px' },
    { id: 4, colWidth: '99%' },
    { id: 6, colWidth: '189px' },
    { id: 7, colWidth: '210px' },
    { id: 8, colWidth: '220px' },
    { id: 9, colWidth: '237px' },
    { id: 10, colWidth: '296px' },
    { id: 11, colWidth: '307px' },
    { id: 12, colWidth: '285px' },
    { id: 13, colWidth: '278px' },
    { id: 14, colWidth: '368px' },
    { id: 15, colWidth: '342px' },
    { id: 16, colWidth: '99%' },
  ];
  @Input() autoCompleteOptions: any = [];
  // set autoCompleteOptions(value) {
  //   console.log(value)
  //   this._autoCompleteOptions = [...value];
  //   console.log(this._autoCompleteOptions)
  //   this.setOptions(value);
  // }
  // get autoCompleteOptions() {
  //   return this._autoCompleteOptions;
  // }
  @Input()
  set autoCompleteModel(value) {
    this._autoCompleteModel = value;
    this.setSelectdModel(value);
  }
  get autoCompleteModel() {
    return this._autoCompleteModel;
  }

  @Input() autoCompleteDynamic: any;
  @Input() autoCompletePlaceholder: any;
  @Input() isDisabled: any;
  @Output() autoCompleteModelChange = new EventEmitter<any>();
  @Input() hideCloseBtn = false;
  constructor(private fb: FormBuilder, private cupService: CupService, private cdr: ChangeDetectorRef) { }

  /*Model Change*/
  private onModelChange(event) {
    console.log(event)
    this.autoCompleteModelChange.emit(event);
  }
  /*Set the options from the input*/
  private setOptions(value) {
    if (this._autoCompleteOptions && this._autoCompleteOptions.length > 0) {
      if (this._autoCompleteModel && typeof (this._autoCompleteModel) !== 'object') {
        const selectedValue = (<any[]>this._autoCompleteOptions).filter(x => {
          return x.value === this._autoCompleteModel;
        });
        if (selectedValue && selectedValue.length > 0) {
          this._autoCompleteModel = selectedValue[0];
        }
      } else if (this._autoCompleteModel &&
        typeof (this._autoCompleteModel) === 'object' &&
        this._autoCompleteModel.id &&
        this._autoCompleteModel.value === undefined) {
        const selectedValue = (<any[]>this._autoCompleteOptions).filter(x => {
          return x.id === this._autoCompleteModel.id;
        });
        if (selectedValue && selectedValue.length > 0) {
          this._autoCompleteModel = selectedValue[0];
          if (this.control != null) {
            this.control.patchValue(this._autoCompleteModel);
          }
        }
      }
    }
  }
  /*Set the model value from the input*/
  private setSelectdModel(value) {
    if (value && typeof (value) !== 'object') {
      const selectedValue = (<any[]>this._autoCompleteOptions).filter(x => {
        return x.value === this._autoCompleteModel;
      });
      if (selectedValue && selectedValue.length > 0) {
        this._autoCompleteModel = selectedValue[0];
        this.currentValue = this._autoCompleteModel;
        this.autoCompleteModelChange.emit(this.currentValue);
      }
    } else if (value && typeof (value) === 'object' &&
      (value.id !== undefined && value.id !== null && value.id !== '')
      && value.value === undefined) {
      const selectedValue = (<any[]>this._autoCompleteOptions).filter(x => {
        return x.id === this._autoCompleteModel.id;
      });
      if (selectedValue && selectedValue.length > 0) {
        this._autoCompleteModel = selectedValue[0];
        if (this.control != null) {
          this.control.patchValue(this._autoCompleteModel);
        }
        this.currentValue = this._autoCompleteModel;
        const placeHolder = this.autoCompletePlaceholder;
        this.autoCompletePlaceholder = '';

        this.autoCompletePlaceholder = placeHolder;

        // this.autoCompleteModelChange.emit(this.currentValue);
      }
    }
  }
  /*Clear Filter*/
  private clearFilter(dropDown: Dropdown) {
    dropDown.resetFilter();
    this.control.patchValue(null);
    this._autoCompleteModel = '';
    this.currentValue = '';
    this.autoCompleteModelChange.emit(this.currentValue);
  }

  ngOnInit() {
    this.dropDownStyles.forEach(element => {
      if (element.id === this.colWidth) {
        this.colStyle = element;
      }
    });
  }

  ngOnChanges(changes:SimpleChanges) {
    if(changes.autoCompleteOptions){
      this._autoCompleteOptions = changes.autoCompleteOptions.currentValue;
    this.setOptions(changes.autoCompleteOptions.currentValue);  
    }
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    Dropdown.prototype.onKeydown = function (event) { }; // to accept space
    if (this.dynamicDropDown) {
      try{
        this.dynamicDropDown.el.nativeElement.firstElementChild.children[4].
        children[0].childNodes[1].type = 'search';
      }catch(e){
      }
      
    }
    this.control.valueChanges.subscribe(
      () => {
        if (this.control.value === '' || this.control.value === null || this.control.value === undefined) {
          this.currentValue = '';
          if (this.dynamicDropDown) {
            this.dynamicDropDown.el.nativeElement.value = '';
          }
          this._autoCompleteModel = '';
        }
      }
    );
  }
  /*On change of value*/
  onChange(e: Event, value: any) {
    this.currentValue = value;
    this.propagateChange(this.currentValue);
  }
  /*Get the changed value*/
  get value(): any {
    return this.currentValue;
  }
  /*Set the changed value*/
  set value(val: any) {
    if (val !== this.currentValue) {
      this.currentValue = val;
    }
  }
  /*Propagate the change to the Parent*/
  propagateChange = (_: any) => { };

  writeValue(value: any) {
    this.currentValue = value;
  }

  registerOnChange(form: any) {
    this.propagateChange = form;
  }

  registerOnTouched(form: any) { }
}
