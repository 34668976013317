import { CONFIG } from "../../../config/index";

export let payloadProps = {
        changePasswordPayload: "<soap:Envelope xmlns:soap='http://www.w3.org/2003/05/soap-envelope' xmlns:xsd='http://org.apache.axis2/xsd'><soap:Header/><soap:Body><xsd:changePasswordByUser><xsd:userName>USER_NAME</xsd:userName><xsd:oldPassword>OLD_PASSWORD</xsd:oldPassword><xsd:newPassword>NEW_PASSWORD</xsd:newPassword></xsd:changePasswordByUser></soap:Body></soap:Envelope>",
        updateLockedPasswordPayload: "<soap:Envelope xmlns:soap='http://www.w3.org/2003/05/soap-envelope' xmlns:xsd='http://org.apache.axis2/xsd'><soap:Header/><soap:Body><xsd:changePassword><xsd:userName>USER_NAME</xsd:userName><xsd:newPassword>PASSWORD</xsd:newPassword></xsd:changePassword></soap:Body></soap:Envelope>",
        ulockUserPayload: "<soapenv:Envelope xmlns:soapenv='http://schemas.xmlsoap.org/soap/envelope/' xmlns:ser='http://service.ws.um.carbon.wso2.org' xmlns:xsd='http://common.mgt.user.carbon.wso2.org/xsd'><soapenv:Header/><soapenv:Body><ser:setUserClaimValues><ser:userName>USER_NAME</ser:userName><ser:claims><xsd:claimURI>http://wso2.org/claims/identity/accountLocked</xsd:claimURI><xsd:value>false</xsd:value></ser:claims><ser:profileName>default</ser:profileName></ser:setUserClaimValues></soapenv:Body></soapenv:Envelope>",
        sendEmailPayload: "<soapenv:Envelope xmlns:soapenv='http://schemas.xmlsoap.org/soap/envelope/' xmlns:car='http://caremore.com/wso2/esb/CaremoreEmailService'><soapenv:Header/><soapenv:Body><car:sendMail><car:consumerInfo>Provider Portal</car:consumerInfo><car:mailSubject>MAIL_SUBJECT</car:mailSubject><car:mailBody><![CDATA[<pre>MAIL_BODY</pre>]]></car:mailBody><car:To>MAIL_ID</car:To><car:Cc></car:Cc><car:Bcc></car:Bcc></car:sendMail></soapenv:Body></soapenv:Envelope>",
        loginPayload: "<soapenv:Envelope xmlns:soapenv='http://www.w3.org/2003/05/soap-envelope' xmlns:ser='http://service.ws.um.carbon.wso2.org'><soapenv:Header/><soapenv:Body><ser:authenticate><ser:userName>USER_NAME</ser:userName><ser:credential>PASSWORD</ser:credential></ser:authenticate></soapenv:Body></soapenv:Envelope>",
        setSecureQuestionPayload: "<soapenv:Envelope xmlns:soapenv='http://schemas.xmlsoap.org/soap/envelope/' xmlns:ser='http://services.recovery.identity.carbon.wso2.org' xmlns:xsd='http://dto.mgt.identity.carbon.wso2.org/xsd'><soapenv:Header/><soapenv:Body><ser:setChallengeQuestionsOfUser><ser:userName>USER_NAME</ser:userName>QUESTION1_PAYLOAD QUESTION2_PAYLOAD</ser:setChallengeQuestionsOfUser></soapenv:Body></soapenv:Envelope>",
        setSecQuestionsNewPayload: "<soapenv:Envelope xmlns:soapenv='http://schemas.xmlsoap.org/soap/envelope/' xmlns:ser='http://services.recovery.identity.carbon.wso2.org' xmlns:xsd='http://model.common.application.identity.carbon.wso2.org/xsd' xmlns:xsd1='http://model.recovery.identity.carbon.wso2.org/xsd'><soapenv:Header/><soapenv:Body><ser:setUserChallengeAnswers><ser:user><xsd:tenantDomain>carbon.super</xsd:tenantDomain><xsd:userName>USER_NAME</xsd:userName><xsd:userStoreDomain>PRIMARY</xsd:userStoreDomain></ser:user>QUESTION1_PAYLOAD QUESTION2_PAYLOAD</ser:setUserChallengeAnswers></soapenv:Body></soapenv:Envelope>",
        question1Payload: '<ser:userChallengeAnswers><xsd1:answer>USER_ANSWER</xsd1:answer><xsd1:question><xsd1:locale>en_US</xsd1:locale><xsd1:question>USER_QUESTION</xsd1:question><xsd1:questionSetId>http://wso2.org/claims/challengeQuestion1</xsd1:questionSetId></xsd1:question></ser:userChallengeAnswers>',
        //question1Payload: "<ser:userChallengeAnswers><xsd1:answer>USER_ANSWER</xsd1:answer><xsd1:question><xsd1:locale>en_US</xsd1:locale><xsd1:question>USER_QUESTION</xsd1:question><xsd1:questionId></xsd1:questionId><xsd1:questionSetId>http://wso2.org/claims/challengeQuestion1</xsd1:questionSetId></xsd1:question></ser:userChallengeAnswers>",
        //question2Payload: "<ser:userChallengeAnswers><xsd1:answer>USER_ANSWER</xsd1:answer><xsd1:question><xsd1:locale>en_US</xsd1:locale><xsd1:question>USER_QUESTION</xsd1:question><xsd1:questionId></xsd1:questionId><xsd1:questionSetId>http://wso2.org/claims/challengeQuestion2</xsd1:questionSetId></xsd1:question></ser:userChallengeAnswers>",
        question2Payload: '<ser:userChallengeAnswers><xsd1:answer>USER_ANSWER</xsd1:answer><xsd1:question><xsd1:locale>en_US</xsd1:locale><xsd1:question>USER_QUESTION</xsd1:question><xsd1:questionSetId>http://wso2.org/claims/challengeQuestion2</xsd1:questionSetId></xsd1:question></ser:userChallengeAnswers>',
        questionPayload : '<ser:challengesDTOs><xsd:answer>USER_ANSWER</xsd:answer><xsd:id>http://wso2.org/claims/challengeQuestionQUESTION_ID</xsd:id><xsd:order>0</xsd:order><xsd:primary>false</xsd:primary><xsd:question>USER_QUESTION</xsd:question><xsd:verfied>false</xsd:verfied></ser:challengesDTOs>',
        uspsPayLoad: "<AddressValidateRequest><Address ID='1'><Address1>#ADDRESS1#</Address1><Address2>#ADDRESS2#</Address2><City>#CITY#</City><State>#STATE#</State><Zip5>#ZIP#</Zip5><Zip4></Zip4></Address></AddressValidateRequest>",        recoverPasswordPayload: {
            "user": {
                "username": '',
                "realm": "PRIMARY",
                "tenant-domain": "carbon.super"
            },
            "properties": []
        },
        
        validateReqModel: { key: "", answers: [], properties: [] },
        
        recoverUserNamePayload :[
                                 {
                                     uri: "http://wso2.org/claims/givenname",
                                     value: ''
                                 },
                                 {
                                     uri: "http://wso2.org/claims/emailaddress",
                                     value: ''
                                 },
                                 {
                                     uri: "http://wso2.org/claims/lastname",
                                     value: ''
                                 }
                                 ],
                                 resetPasswordPayload : {key: '', password: '', properties: []},

                                 registerEmailPayload: "<soapenv:Envelope xmlns:soapenv='http://schemas.xmlsoap.org/soap/envelope/' xmlns:ser='http://service.ws.um.carbon.wso2.org' xmlns:xsd='http://common.mgt.user.carbon.wso2.org/xsd'><soapenv:Header/><soapenv:Body><ser:setUserClaimValues><ser:userName>USER_NAME</ser:userName><ser:claims><xsd:claimURI>http://wso2.org/claims/emailaddress</xsd:claimURI><xsd:value>EMAIL_ID</xsd:value></ser:claims><ser:profileName>default</ser:profileName></ser:setUserClaimValues></soapenv:Body></soapenv:Envelope>"                                 
}