import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'customDateTimeFormat' })
export class customDateTimeFormatPipe implements PipeTransform {
  transform(value: any, selector: string): string {
      if ((value ? value.indexOf('-') === -1 : '') || !(Date.parse(value))) {
        return value;
      } else {
      const dt = new Date(value);
      const month = dt.getMonth() + 1;
      const day = dt.getDate();
      const year = dt.getFullYear();
      const hour = ('0' + dt.getHours()).slice(-2) ;
      const minute = ('0' + dt.getMinutes()).slice(-2) ;
      return month + '/' + day + '/' + year + ' ' + hour + ':' + minute;
    }
  }
}
