import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpRequest } from '@angular/common/http';
import { urls, props, payloads } from '../common/model/properties';
import { SecureStorageService } from '../secure-storage.service';

@Injectable()
export class ForgotusernameService {

    constructor(private http: HttpClient,
        private secureStorage:SecureStorageService) {
    }

    requestRecoverUserName(firstName, lastName, email, callBack: (sucess: boolean, message: string) => void) {
        const recoverUserNamePayload = {
            firstName: firstName,
            lastName: lastName,
            email: email
        };
        //const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        this.http.post(urls.recoverUserNameUrl, recoverUserNamePayload, { headers: headers , responseType:'text'}).subscribe(

            (resp:any) => {
                
                    if(resp === 'The Entered FirstName (or) Email is not identified in the Database. Please contact Provider Portal Administrator')
                    {
                        callBack(false, resp); 
                    }
                    else{
                    callBack(true, 'Username has been successfully sent to your registered Email ID ');
                    }   
                
            },
            err => {
                callBack(false, 'Failed to send UserName. Please contact the Administrator');
            }
        );
    }
    
    getNews(callBack: (sucess: boolean, message: string) => void) {
        //const headers = new HttpHeaders({'Accept': 'application/json'});
        const headers = new HttpHeaders({
            'Accept': 'application/json',
        });
        
        this.http.get(urls.getNews, { headers: headers })
            .subscribe(
                (resp:any) => {
                    callBack(true, resp);
                }
            );
    }

}
