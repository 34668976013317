import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
  transform(items: Array<any>, key: string): any {
    return items.sort((a, b) => {
      const nameA = a[key].toLowerCase(),
        nameB = b[key].toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
}
