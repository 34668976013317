import { Component, OnInit, Input } from '@angular/core';
import { FooterService } from '../footer/footer.service';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';

declare var jQuery: any;

@Component( {
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    providers: [FooterService],
} )
export class FooterComponent implements OnInit {

    constructor( private currentService: FooterService, private router: Router ) { }
    showSection = 'tabSection';
    isLoggedIn = true;
    @Input() className: string = '';
    @Input() hideHelpFaq = false;

    ngOnInit() {
        this.showSection = '';
    }

}
