
import { Injectable } from '@angular/core';
import { urls, payloads } from '../common/model/properties';

import { FieldValidateService } from '../common_services/fieldvalidate.service';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Compiler } from '@angular/core';
import { OnInit } from '@angular/core';
import { ConfigProvider } from '../shared/services/configprovider';
import { SecureStorageService } from '../secure-storage.service';
import { Observable } from 'rxjs';
@Injectable()
export class SignupFormService {

    SKIP_NPI_VALIDATION = false;
    SKIP_TAXID_VALIDATION = false;
    SKIP_CITY_STATE_LOOKUP_VALIDATION = true;
    SKIP_USERNAME_VALIDATION = false;
    SKIP_USPS_VALIDATION = false;
    encodedAddress: string;
    encodedAddress1: any;
    encodedAddress2: any;
    props:any;
    constructor(private http: HttpClient, private fieldValidator: FieldValidateService, 
        private _compiler: Compiler, private configProvider:ConfigProvider,
        private secureStorage:SecureStorageService) {
            this.props = configProvider.getProps();
    }
    ngOnInit() {
        this._compiler.clearCache();
    }

    registerData(registerDataPayload, callBack: (sucess: boolean, message: string) => void) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-control': 'no-cache'
        });
        /*    headers.set( 'Accept', 'application/json' )
        */
        this.http.post(urls.registerDataUrl, registerDataPayload, { headers: headers,responseType:'text' })
            .subscribe(
                (resp) => {
                    callBack(true, resp);
                },
                err => {
                    callBack(true, err);
                }
            );
    }

    getAssociatedData(id: string, callBack: (sucess: boolean, message: string) => void) {
        const headers = new HttpHeaders({
             'Accept': 'application/json',
        });

        this.http.get(urls.getAssociatedData + id, { headers: headers })
            .subscribe(
                (resp:any) => {
                    callBack(true, resp);

                },
                err => {
                    callBack(false, '');
                }
            );
    }

    // lookupUspsCityState(model, callBack: (sucess: boolean, message) => void) {

    //     if (this.SKIP_CITY_STATE_LOOKUP_VALIDATION) {
    //         callBack(true, model);
    //         return;
    //     }

    //      let payLoad = payloads.uspsCityStateLookupPayload;
    //      payLoad = payLoad.replace('#ZIP#', model.zip);
 
    //     this.http.post(urls.uspsCityStateLookupUrl + payLoad, '',{responseType:'text'})
    //         .subscribe(
    //             (response) => {
    //                 const addressModel = this.parseUspsCityStateResponse(response);
             
    //                 if (addressModel) {
    //                     if (addressModel['zip'] === model['zip'] &&
    //                         addressModel['city'] === model['city'] &&
    //                         addressModel['state'] === model['state']) {
    //                         callBack(true, undefined);
    //                     } else {
    //                         const validStates = ['AZ', 'CA', 'NV', 'VA'];
    //                         if (validStates.indexOf(addressModel['state']) === -1) {
    //                             callBack(false, undefined);
    //                         } else {
    //                             callBack(false, addressModel);
    //                         }
    //                     }
    //                 } else {
    //                     callBack(false, undefined);
    //                 }
    //             },
    //             err => {
    //                 callBack(false, err);
    //             }
    //         );
    // }

    doUspsValidation(model, callBack: (sucess: boolean, message) => void) {
        if (this.SKIP_USPS_VALIDATION) {
            callBack(true, model);
            return;
        }

        // usps address validation for #
        // let payLoad = payloads.uspsPayLoad;

        // if (model.address1.indexOf('#') != -1 || model.address2.indexOf('#') != -1) {
        //     this.encodedAddress1 = model.address1.replace('#', '');
        //     this.encodedAddress2 = model.address2.replace('#', '');
        //     payLoad = payLoad.replace('#ADDRESS2#', this.encodedAddress1);
        //     payLoad = payLoad.replace('#ADDRESS1#', this.encodedAddress2);
        // }else{
        //     payLoad = payLoad.replace('#ADDRESS2#', model.address1);
        //     payLoad = payLoad.replace('#ADDRESS1#', model.address2);
        // }
        
        
        // payLoad = payLoad.replace('#CITY#', model.city);
        // payLoad = payLoad.replace('#STATE#', model.state);
        // payLoad = payLoad.replace('#ZIP#', model.zip);
        
        let payload = {
            "address1" :  model.address1.replace('#', ''),
            "address2" : model.address2.replace('#', ''),
            "city" : model.city,
            "state" : model.state,
            "zip5" : model.zip
            }
        const headers = new HttpHeaders(
            {
                // 'Content-Type': 'text/xml;charset=UTF-8',
                //  'Accept': 'application/xml'
             });
        
             this.http.post(urls.uspsValidateUrl , payload,{headers:headers,responseType:'text'})
            .subscribe(
                (response) => {
                    const addressModel = this.parseUspsAdress(response);
                    if (addressModel) {
                        callBack(true, addressModel);
                    } else {
                        callBack(false, addressModel);
                    }
                },
                err => {
                    callBack(false, err);
                }
            );
    }

    parseUspsAdress(uspsResponse) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(uspsResponse, 'text/xml');
        try {
            const addressModel = {};
            const addressElement = xmlDoc.getElementsByTagName('Address')[0];
            if (!addressElement.getElementsByTagName('Error')[0]) {
                addressModel['address1'] = this.extractValueFromNode(addressElement, 'Address2');
                addressModel['address2'] = this.extractValueFromNode(addressElement, 'Address1');
                addressModel['city'] = this.extractValueFromNode(addressElement, 'City');
                addressModel['state'] = this.extractValueFromNode(addressElement, 'State');
                addressModel['zip'] = this.extractValueFromNode(addressElement, 'Zip5');
                return addressModel;
            }
        } catch (e) { }
    }

    parseUspsCityStateResponse(uspsResponse) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(uspsResponse, 'text/xml');
        try {
            const addressModel = {};
            const addressElement = xmlDoc.getElementsByTagName('ZipCode')[0];
            if (!addressElement.getElementsByTagName('Error')[0]) {
                addressModel['city'] = this.extractValueFromNode(addressElement, 'City');
                addressModel['state'] = this.extractValueFromNode(addressElement, 'State');
                addressModel['zip'] = this.extractValueFromNode(addressElement, 'Zip5');
                return addressModel;
            }
        } catch (e) { }
    }

    extractValueFromNode(xmlDoc, key) {
        let value = '';
        try {
            value = xmlDoc.getElementsByTagName(key)[0].textContent;
        } catch (e) { }
        return value;
    }
    registerUser(registerUserPayload, callBack: (sucess: boolean, message: string) => void) {
        //const token: any = this.secureStorage.getSecureInfoInLocalStorage('clientCredentials_token');
       
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            //'Authorization': 'Bearer ',
            'Cache-control': 'no-cache'
        });
       
        this.http.post(urls.registerUrl, registerUserPayload, { headers: headers,responseType:'text' }).subscribe(
            (resp) => {
                
                callBack(true, resp);
            },
            err => {
                if (err.status === 409) {
                    callBack(false, ' Sorry this Username already taken by someone, Please try another. ');
                    return;
                }
                callBack(false, err);
            }
        );
    }

    validateNPI(npivalue, callBack: (success: boolean, message: string) => void) {

        if (npivalue === '') {
            callBack(true, '');
            return;
        }
        if (this.fieldValidator.validateNumeric(npivalue.toString(), 10)) {
            if (this.SKIP_NPI_VALIDATION) {
                callBack(true, '');
                return;
            }

            const headers = new HttpHeaders({
            });

            this.http.get(urls.altIdNews + npivalue, { headers: headers }).subscribe((data:any) => {
                if (data.validityFlag === 1) {
                    callBack(true, '');
                } else {
                    callBack(false, 'Invalid NPI');

                }
            }, (err) => {
                callBack(false, err);
            });

        } else {
            callBack(false, 'The length of NPI should be 10 digits');
        }
    }

    validateTaxId(taxId, callBack: (sucess: boolean, message: string) => void) {

        if (taxId === '') {
            callBack(true, '');
            return;
        }
        if (this.fieldValidator.validateNumeric(taxId, 9)) {
            if (this.SKIP_TAXID_VALIDATION) {
                callBack(true, '');
                return;
            }

            const headers = new HttpHeaders({
            });

            this.http.get(urls.altIdNews + taxId, { headers: headers }).subscribe((data:any) => {

                if (data.validityFlag === 1) {
                    callBack(true, '');
                } else {
                    // callBack(true, '');
                    callBack(false, 'Invalid Tax ID');
                }
            }, (err) => {
                callBack(false, err);
            });
        } else {
            callBack(false, 'The length of TaxId should be 9 digits');
        }
    }

  


    checkMail(emailId: any, callBack: (sucess: boolean, message: string) => void) {

        if (this.SKIP_USERNAME_VALIDATION) {
            callBack(true, '');
            return;
        }

        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        });


        this.http.post(urls.emailExistence, { 'email': emailId }, { headers: headers, responseType:'text'})
            .subscribe(
                (resp) => {
                    if (Number(resp) === 0) {
                        callBack(true, '');
                    } else {
                        callBack(false, 'Sorry, this emailid already exists. Please choose a different emailid');
                    }
                },
                err => {
                    callBack(false, '');
                }
            );
    }
    autoPopulateUserName(firstName: string, lastName: string, middleName: string, callBack: (sucess: boolean, message: string) => void) {
        const headers = new HttpHeaders({
        });

        let autoPopulateUserNameUrl = urls.autoPopulateUserName;
        // autoPopulateUserNameUrl = autoPopulateUserNameUrl.replace('FIRST_NAME', firstName);
        // autoPopulateUserNameUrl = autoPopulateUserNameUrl.replace('LAST_NAME', lastName);

        // if (middleName !== undefined && middleName !== '') {
        //     autoPopulateUserNameUrl = autoPopulateUserNameUrl + '&middleName=' + middleName;
        // }
        const payLoad={
            firstName:firstName,
            lastName:lastName,
            middleName:middleName
        }

        this.http.post(autoPopulateUserNameUrl, payLoad, { headers: headers,responseType:'text' })
            .subscribe(
                (resp) => {
                    callBack(true, resp);
                },
                err => {
                    callBack(false, '');
                }
            );
    }

    setSecurityQuestion(userName, question1, question2, answer1, answer2, cred, authToken,
        callBack: (sucess: boolean, message: string) => void) {
        //this.removeCookies();
        this._compiler.clearCache();
        let id = this.secureStorage.getSecureInfoInSessionStorage('jsessionid');
        sessionStorage.removeItem('id');
        const token: any = this.secureStorage.getSecureInfoInLocalStorage('clientCredentials_token');
        const headers = new HttpHeaders({
            'Accept': '*/*',
            'Content-Type': 'application/json',
            'SOAPAction': 'urn:setUserChallengeAnswers',
            'Access-Control-Allow-Credentials': 'false',
            'Cache-Control': 'no-cache,no-store',
            'Pragma': 'no-cache',
            'Expires': '0',
            'withCredentials': 'true'
        });
        /* let question1Payload = '';
        let question2Payload = '';

        if (question1 !== '' && answer1 !== '') {
            question1Payload = payloads.question1Payload;

            question1Payload = question1Payload.replace('QUESTION_ID', '1');
            question1Payload = question1Payload.replace('USER_QUESTION', question1);
            question1Payload = question1Payload.replace('USER_ANSWER', answer1);
        }

        if (question2 !== '' && answer2 !== '') {
            question2Payload = payloads.question2Payload;

            question2Payload = question2Payload.replace('QUESTION_ID', '2');
            question2Payload = question2Payload.replace('USER_QUESTION', question2);
            question2Payload = question2Payload.replace('USER_ANSWER', answer2);
        }

        let fullQuestionPayload = payloads.setSecQuestionsNewPayload.replace('USER_NAME', userName);
        fullQuestionPayload = fullQuestionPayload.replace('QUESTION1_PAYLOAD', question1Payload);
        fullQuestionPayload = fullQuestionPayload.replace('QUESTION2_PAYLOAD', question2Payload); */
        //this.removeCookies;
        //this.http.post(urls.setSecureQuestionsUrl, fullQuestionPayload, { headers: headers }).map(
        const fullQuestionPayload = {
            userName: userName,
            secret: cred,
            question1: question1,
            answer1: answer1,
            question2: question2,
            answer2: answer2
        };
        this.http.post(urls.postSecurityQuestion, fullQuestionPayload, { headers: headers,responseType:'text' })
            .subscribe(
                (resp) => {
                    callBack(true, resp.toString());
                },
                err => {
                    callBack(false, 'Error while persisting user challenges for user : ' + userName);
                }
            );
    }

    getNews(callBack: (sucess: boolean, message: string) => void) {
        //const headers = new HttpHeaders({'Accept': 'application/json'});
        const headers = new HttpHeaders({
            'Accept': 'application/json',
        });
        
        this.http.get(urls.getNews, { headers: headers })
            .subscribe(
                (resp:any) => {
                    callBack(true, resp);
                }
            );
    }

    getDocument(): Observable<any> {
        const payload = {
            'appId': 'PP',
            'documentCode': 'USER_AGREEMENT_DOC',
            'keyValuePairList': [{ 'key': 'FunctionType', 'value': 'ProviderPortal-UserAgreement' }, { 'key': 'UserType', 'value': 'All' }]
        };
        const headers = new HttpHeaders({
            'Accept': 'application/json',
         });

        const options = { headers: headers };
        
        return this.http.post(urls.getUserAgreementDoc, payload, options);
    }

    

    getSecurityQuestions() {
        const headers = new HttpHeaders({});
        return this.http.get(urls.registerSecurityQuestions, { headers: headers });
    }


    removeDocAttributes() {
        const cookies = document.cookie;

        for (let i = 0; i < cookies.split(';').length; ++i) {
            const myCookie = cookies[i];
            const pos = myCookie.indexOf('=');
            const name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    }

    getSecurityQuestionsSignup(id: string, callBack: (sucess: boolean, message: string) => void) {
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});

        this.http.get(urls.securityQuestions, { headers: headers })
            .subscribe(
                (resp:any) => {
                    callBack(true, resp);

                },
                err => {
                    callBack(false, '');
                }
            );
    }
    
    getStatesList() {
        const token: any = this.secureStorage.getSecureInfoInLocalStorage('clientCredentials_token');
        const headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        });
        let path = urls.getStates;
        return this.http.get(path, { headers: headers })
    }
    lookupZiporCity(searchKey){
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        return this.http.get(urls.lookupZiporCity + searchKey, { headers: headers });
    }

    getDecryptedUserRegistrationData( encryptedData: string): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const payload = {
            "reqString": encryptedData
        }
        return this.http.post(urls.getDecryptedUserRegInfo, payload,{ headers: headers,responseType:'text' });
      }
}
