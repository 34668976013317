import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, EmailValidator } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SignupFormService } from './signup-form.service';
import { ISignUp } from './signup-form.model';
import { FieldValidateService } from '../common_services/fieldvalidate.service';
import { Location } from '@angular/common';
import { RsaService } from '../common_services/encryptutil.service';
import { CONFIG } from '../../config/index';
// import { saveAs } from 'file-saver/FileSaver';
import { Observable } from 'rxjs';
import { SecureStorageService } from '../secure-storage.service';

declare var jQuery: any;
let saveAs = require('file-saver');
@Component({
    selector: 'app-signup-form',
    templateUrl: './signup-form.component.html',
    styleUrls: ['./signup-form.component.scss'],
    providers: [EmailValidator],
})
export class SignupFormComponent implements OnInit, OnDestroy {

    myModalEventCallBack() { }
    showLoginPage() { }
    uspsModelEventCallBack(address, model) {

    }
    uspsCityStateModelEventCallBack(address) {
    }

    zipDataSource: Observable<any>;
    cityDataSource: Observable<any>;
    signUp: ISignUp;
    endpoint: string;
    npiresponse = true;
    taxIdresponse = true;
    userNameresponse: boolean;
    emailExistence: boolean;
    validData: boolean;
    message = '';
    selected = 'option2';
    url: string;
    error: string;
    data: any = null;
    submitted = false;
    passwordMatch: boolean;
    regType: string;
    question1Selected = '';
    question2Selected = '';
    answer1 = '';
    answer2 = '';
    types = ['Licensed Care Provider', 'Provider Staff'];
    questions1 = [];
    questions2 = [];
    errorMessage: string;
    showClose = true;
    currentStep = 0;
    isFiledValid = {};
    fieldValidateMsg = {};
    news = [];
    isValidCaptcha = true;
    captchaForm: FormGroup;
    loading = false;
    isLicenseAcceptedError = false;
    licenseAccpetedFlag = false;
    stateCodes:Array<any> =[];
    showCred =false;
    showConfirmCred = false;
    isNPIValid = false;
    isTaxIdValid = false;
    showSecurityQuestionErrorMsg = false;
    selectedUserAddressCard : boolean = true;
    showValidateAddressError : boolean = false;
    isAddressValidated : boolean = false;


    public uspsAddressModel: any;
    public model =
        {
            firstName: '',
            middleName: '',
            lastName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            fax: '',
            email: '',
            ext: '',
            npi: '',
            taxId: '',
            userName: '',
            cred: '',
            confirmCred: '',
            reg_type: 'PS',
            provider: ''
        };


    constructor(private encService: RsaService, private route: ActivatedRoute, private fb: FormBuilder,
         private registerService: SignupFormService,
        private fieldValidator: FieldValidateService, private _router: Router, private secureStorage:SecureStorageService) {
    }

    ngOnInit() {
        this.resetInputValidations();
        this.getNews();
        this.getStatesListInfo();
        this.route.queryParamMap.subscribe(params => {
            if (params && params['params'] && (params['params']['id'] || params['params']['reg'])) {
                const id = params['params']['id'];
                const regType = params['params']['reg'];
                if (id) {
                    this.registerService.getDecryptedUserRegistrationData(id).subscribe((data: any) => {
                       let  decryptedId = data;
                        this.registerService.getAssociatedData(decryptedId, (success: boolean, data: any) => {

                            this.loading = true;
                            if (success) {
                                if (data['message'] === null) {
                                    this.model = Object.assign(this.model, data.user[0]);
                                    this.model['zip'] = this.model['zip'].toString();
                                    this.getautoPopulateUserName();
                                    this.loading = false;
                                    return;
                                } else {
                                    if (data['message'] === 'Already Registered') {
                                        this.showAlertPopup('This User already registered.', undefined, false);
                                        this.loading = false;
                                    } else {
                                        this.showAlertPopup(
                                            'This link has been expired, please contact your Account operator to resend Email Link.',
                                            undefined,
                                            false);
                                        this.loading = false;
                                    }
                                }
                            } else {
                                jQuery('#errorMessageModal').modal('show');
                                this.loading = false;
                            }
                        });
                    },
                    (error) => {
                        jQuery('#errorMessageModal').modal('show');
                        this.loading = false;
                    })
                    
                } else if (regType) {
                    this.registerService.getDecryptedUserRegistrationData(regType).subscribe((data: any) => {
                        let decryptedRegType = data;
                        let reg = '';
                        let timestamp = '';
                        const regSplit = decryptedRegType.split('&');
                        for (let i = 0; i < regSplit.length; i++) {
                            const paramSplit = regSplit[i].split('=');
                            if (paramSplit[0] === 'regType') {
                                reg = paramSplit[1];
                            } else if (paramSplit[0] === 'timestamp') {
                                timestamp = paramSplit[1];
                            }
                        }
                        const timeAndDate = String(timestamp).split(' ');
                        const date = timeAndDate[0].split('-');
                        const time = timeAndDate[1].split(':');
                        const timeStampDate = new Date(parseInt(date[0]), parseInt(date[1]) - 1, parseInt(date[2]),
                            parseInt(time[0]), parseInt(time[1]), parseInt(time[2]))
                        const linkLapseDays = (new Date().getTime() - timeStampDate.getTime()) / (1000 * 60 * 60 * 24);
                        if (linkLapseDays > 1) {
                            this.showAlertPopup(
                                'This link has been expired, Please contact Provider Relations team at (888)291-1358 or send an e-mail to ProviderRelations@carelon.com to obtain sign-up link access.',
                                undefined,
                                false);
                            this.loading = false;
                        }
                        console.log(reg);
                        this.model['reg_type'] = reg;

                    },(error) => {
                        jQuery('#errorMessageModal').modal('show');
                        this.loading = false;
                    })
     
                }
            }
        });

        this.captchaForm = this.fb.group({
            captcha: ['', Validators.required]
        });

        this.captchaForm.controls.captcha.valueChanges.subscribe(() => {
            if (this.captchaForm.controls.captcha.valid) {
                this.isValidCaptcha = true;
            }
        });
        // this.getQuestions();
        this.getSecurityQuestions();
        const associateDetails = this.secureStorage.getSecureInfoInLocalStorage('associateDetails');
        if (associateDetails) {
            this.setDetails(associateDetails);
            this.getautoPopulateUserName();
        }
        this.zipDataSource = Observable.create((observer: any) => {
                this.registerService.lookupZiporCity(this.model.zip).subscribe(resp => {
                    //console.log(resp.json());
                    let list = resp;
                    observer.next(list);
                });   
        });
        this.cityDataSource = Observable.create((observer: any) => {
                this.registerService.lookupZiporCity(this.model.city).subscribe(resp => {
                    //console.log(resp.json());
                    let list = resp;
                    observer.next(list);
                });
        });
    }
    onFocusPassword(event:any){
        this.isFiledValid[event.target.name] = true;
        this.setFieldValidationTrue(['cred','confirmCred'])
    }

    onInputFocus(event: any) {
        this.hideValidate(event.target.name);
    }

    hideValidate(inputName: string) {
        this.isFiledValid[inputName] = true;
    }

    togglePasswordShow(event: any,modelName) {
        this[modelName] = !this[modelName];
    }

    passRestrict(event: any) {
        if (event.keyCode === 32) {
            event.preventDefault();
        }
    }
    decimalRestrict(event: any) {
        const pattern = /[0-9]/;
        const inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode !== 8 && !pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    maskStr(value: string) {
        let maskedVal = '';
        for (let i = 0; i < value.length; i++) {
            maskedVal += '*';
        }
        return maskedVal;
    }

    extractNumber(value: string) {
        let newVal = '';
        if (isNaN(Number(value))) {
            for (let i = 0; i < value.length; i++) {
                const s: string = value.charAt(i);
                if (!isNaN(Number(s)) && s !== ' ') {
                    newVal += s;
                }
            }
        } else {
            newVal = value;
        }
        return newVal;
    }

    extractAlphaString(value: string) {
        let newVal = '';
        for (let i = 0; i < value.length; i++) {
            const s: string = value.charAt(i);
            if (this.isAlpha(s)) {
                newVal += s;
            }
        }
        return newVal;
    }

    isAlpha(str: string) {
        return /^[a-zA-Z]+$/.test(str);
    }

    constructPhoneFormat(value: string) {
        let newVal = value;
        if (value.length >= 4) {
            newVal = '(' + value.substring(0, 3) + ')';
            newVal += ' ' + value.substring(3, (value.length <= 6) ? value.length : 6);
            if (value.length > 6) {
                newVal += '-' + value.substring(6, (value.length <= 10) ? value.length : 10);
            }
        }
        return newVal;
    }

    onPhoneKeyUp(event: any) {
        const inputValue: string = event.target.value;
        const numberValue = this.extractNumber(inputValue);
        const formattedPhone = this.constructPhoneFormat(numberValue);
        this.model[event.target.name] = formattedPhone;
        event.target.value = formattedPhone;
    }

    restrictToNumber(event: any) {
        const inputValue = event.target.value;
        const numberValue = this.extractNumber(inputValue);
        event.target.value = numberValue;
    }

    restrictToAlpha(event: any) {
        const inputValue = event.target.value;
        const numberValue = this.extractAlphaString(inputValue);
        event.target.value = numberValue;
    }

    UpdateUserSelectedAddress(){

        if(this.selectedUserAddressCard){
            this.uspsModelEventCallBack('SAME_ADDRESS','')
            this.isAddressValidated = true;
            this.showValidateAddressError = false;
        }
        else{    
            this.uspsModelEventCallBack('USPS_ADDRESS', this.uspsAddressModel)
            this.isAddressValidated = true;
            this.showValidateAddressError = false;
        }
    }


    checkRequiredFieldValidation(){
        // console.log("inside required feild")
        if (this.model.firstName === '' || this.model.lastName === '' || this.model.city === '' || 
        this.model.address1 === '' || this.model.state === '' || this.model.zip === '' || this.model.phone === '') {
            this.checkForEmptyField(['firstName', 'lastName', 'address1', 'state', 'zip' , 'city','phone']);
            this.loading= false;
            return false;
        }
        // else if (!this.fieldValidator.validateEmail(this.model.email)) {
        //     this.fieldValidateMsg['email'] = 'Email is not Valid. Please verify.';
        //     this.setFieldValidates(['email']);
        //     return false;
        // }
        return true;
    }

    checkNPIorTaxIdValidation(){


        if (this.model.npi === '' && this.model.taxId === ''){
            this.fieldValidateMsg['npi'] = 'NPI or Tax Id is required';
            this.fieldValidateMsg['taxId'] = 'NPI or Tax Id is required'
            this.setFieldValidates(['npi' , 'taxId'])
            this.loading= false;
            return false;
        }

        
        return true;
    }

    checkPasswordValidation(){

        if(this.model.cred === '' || this.model.confirmCred === ''){
            this.checkForEmptyField(['cred' , 'confirmCred'])
            this.loading= false;
            return false;
        }

        else if (this.model.cred === this.model.confirmCred) {
                if (this.fieldValidator.validateCred(this.model.cred)) {
                    this.setFieldValidateMsg(['cred', 'confirmCred'])
                    this.setFieldValidationTrue(['cred','confirmCred'])
                    return true;
                } 
                else {
                    this.fieldValidateMsg['cred'] = 'Password does not meet requirements';
                    this.fieldValidateMsg['confirmCred'] = 'Password does not meet requirements';
                    this.setFieldValidates(['cred', 'confirmCred']);
                    this.loading= false;
                    return false;
                }
            } else {
                this.fieldValidateMsg['cred'] = 'Passwords Do not Match. Please verify';
                this.fieldValidateMsg['confirmCred'] = 'Passwords Do not Match. Please verify';
                this.setFieldValidates(['cred', 'confirmCred']);
                this.loading= false;
                return false;
            }
    
        
    }

    checkSecurityQuestionValidation(){

        if(this.question1Selected === '' || this.question2Selected ==='' || this.answer1 === '' || this.answer2 === ''){
            this.setFieldValidates(['question1Selected', 'question2Selected', 'answer1','answer2'])
            this.loading= false;
            return false;
        }
        else if(this.question1Selected == this.question2Selected){
            this.showSecurityQuestionErrorMsg = true;
            this.loading= false;
            return false;
        }
        this.showSecurityQuestionErrorMsg = false;
        return true;
    }

    checkLicenceAccepted(){
        if(this.licenseAccpetedFlag && !this.isLicenseAcceptedError){
            this.isLicenseAcceptedError = false;
            return true;
        }
        else{
            this.isLicenseAcceptedError = true;
            this.loading= false;
            return false;
        }
    }

    checkValidTaxId(){

        return new Promise((resolve, reject) => {
            this.registerService.validateTaxId(this.model.taxId, (success: boolean, message: string)=>{
                if(!success){
                    this.fieldValidateMsg['taxId'] = message;
                    this.setFieldValidates(['taxId']);
                    //added for removing error msg on taxid fields
                    this.setFieldValidateMsg(['npi'])
                    this.setFieldValidationTrue(['npi'])
                    this.isTaxIdValid =false;
                    resolve(true);
                    
                }
                else{
                    this.isTaxIdValid = true;
                    resolve(true);
                    
                }
               

            });
        });

    }

     checkValidNPI () {
        return new Promise<boolean>((resolve, reject) => {
            this.registerService.validateNPI(this.model.npi, (success: boolean, message: string)=>{
                if(!success){
                    this.fieldValidateMsg['npi'] = message;
                    this.setFieldValidates(['npi']);
                    this.isNPIValid = false;
                    //added for removing error msg on taxid fields
                    this.setFieldValidateMsg(['taxId'])
                    this.setFieldValidationTrue(['taxId'])
                    resolve(true);


                }
                else{
                    this.isNPIValid = true;
                    resolve(true);

                }
               
            });
        });
    }

   async onSubmit() {

        this.loading = true;

        let requiredFieldValidation : boolean = this.checkRequiredFieldValidation();
        let npiOrTaxIdValidation: boolean = this.checkNPIorTaxIdValidation();
        let passwordValidation: boolean = this.checkPasswordValidation();
        let securityQuestionValidation : boolean = this.checkSecurityQuestionValidation();
        let licenceAcceptedChecked : boolean = this.checkLicenceAccepted();

        

        // if (this.captchaForm.controls.captcha.invalid) {
        //     if (this.captchaForm.controls.captcha.invalid) {
        //         this.isValidCaptcha = false;
        //     }
        //     this.loading = false;
        //     return;
        // }

        if(this.model.npi){
            await this.checkValidNPI()
            if(!this.isNPIValid){
                window.scrollTo(0,100)
                this.loading = false;
                return
            }
        }

        if(this.model.taxId){
           await this.checkValidTaxId();
            if(!this.isTaxIdValid){
                window.scrollTo(0,100)
                this.loading = false;
                return;
            }
        }

        if(!this.isAddressValidated){          
            this.showAlertPopup("Please validate your Address ", undefined);
            window.scrollTo(0,300);
            this.loading = false;
                return;
        }
        

        if(requiredFieldValidation && npiOrTaxIdValidation && licenceAcceptedChecked && passwordValidation && securityQuestionValidation  ){
         
            
            const registerUserPayload = this.constructRegisterUserPayload();
            this.registerService.registerUser(registerUserPayload, (success: boolean) => {
                if (success) {

                    let registerDataPayload = this.constructRegisterDataPayload();
                    if (registerDataPayload.email == 'none@null.com') {
                        registerDataPayload.email = null;
                    }
                    registerDataPayload['status']= 0;
                    this.registerService.registerData(registerDataPayload, (success: boolean) => {
                        if (success) {
                            setTimeout(() => {
                                const authToken = btoa(this.model.userName + ':' + this.model.cred);
                                this.registerService.setSecurityQuestion(this.model.userName, this.question1Selected, this.question2Selected, this.answer1, this.answer2, this.model.cred, authToken, (success: boolean) => {
                                    if (success) {
                                        window.scrollTo(0,100)
                                        this.showFinalPopup("User registration is not completed yet. Please contact your provider office account operator or the Carelon Health Provider Relations team at (888) 291-1358 or send an e-mail to ProviderRelations@carelon.com to obtain access.",
                                            () => { this._router.navigate(['/login']); });
                                        this.loading = false;
                                        return;
                                    } else {
                                        window.scrollTo(0,100)
                                        this.showAlertPopup("Sorry something went Wrong, Please try again", undefined);
                                        this.loading = false;
                                        return;
                                    }
                                });
                                return;
                            }, 4000);
                        } else {
                            window.scrollTo(0,100)
                            this.loading = false;
                            this.showAlertPopup("Sorry something went Wrong, Please try again", undefined);

                        }
                    });
                } else {
                    window.scrollTo(0,100)
                    this.loading = false;
                    this.showAlertPopup('Sorry something went Wrong, Please try again', undefined);
                }
            });
        }
        else{
            // this.showAlertPopup(
            //     'Please ensure that the details entered are valid and then click on submit ',
            //     undefined);

            this.loading = false;
            return;
        }

        
    }


    gotoLoginPage(): void {
        this._router.navigate(['./login']);
    }

    doUspsAddressValidation() {
        this.loading = true;
        this.registerService.doUspsValidation(this.model, (hasValidAddress: boolean, uspsAddressModel) => {
            if (hasValidAddress) {
                this.uspsAddressModel = uspsAddressModel;
                this.loading = false;
                this.showPopup('uspsModel', (popupButtonEvent: string, selectedAddressModel) => {
                    this.loading = false;
                    if (popupButtonEvent === 'USPS_ADDRESS') {
                        this.model.address1 = selectedAddressModel.address1;
                        this.model.address2 = selectedAddressModel.address2;
                        this.model.city = selectedAddressModel.city;
                        this.model.state = selectedAddressModel.state;
                        this.model.zip = selectedAddressModel.zip;
                        this.loading = false;
                    }
                    // this.selectTab(nextStepId);
                    this.loading = false;

                });
            } else {
                this.showAlertPopup(
                    'The address you entered is not valid, As per United States Postal Service records. Please try again.',
                    undefined);
                this.loading = false;

            }
        });
    }

    private showFinalPopup(message: string, callBack, showClose = true){
        this.showClose = showClose;
        this.errorMessage = message;
        jQuery('#' + 'finalPopup').modal({ backdrop: 'static', keyboard: false });
        this.showPopup('finalPopup', callBack);
        return;
    }


    private showAlertPopup(message: string, callBack, showClose = true) {
        this.showClose = showClose;
        this.errorMessage = message;
        jQuery('#' + 'myModal').modal({ backdrop: 'static', keyboard: false });
        this.showPopup('myModal', callBack);
        return;
    }

    private showPopup(popupName: string, callBack) {

        this[popupName + 'EventCallBack'] = callBack;
        jQuery('#' + popupName).modal('show');
    }


    private selectTab(stepId) {
        jQuery('.nav-tabs li ').removeClass('active');
        jQuery('.tab-content div').removeClass('active');
        jQuery('.nav-tabs li').eq(stepId).removeClass('disabled');
        jQuery('.nav-tabs li').eq(stepId).addClass('active');
        jQuery('.tab-content div').eq(stepId).addClass('active');

        this.currentStep = stepId;
    }
    
    private isDisabledDiv(stepId) {
        const isDisabled = (jQuery('.nav-tabs li').eq(stepId).attr('class') === 'disabled' ? true : false);
        return isDisabled;
    }
  

    private resetInputValidations() {
        const fieldNameArr = ['userName', 'cred', 'confirmCred',
            'answer1', 'answer2', 'city', 'state', 'zip',
            'phone', 'ext', 'fax', 'email', 'firstName', 'middleName',
            'lastName', 'npi', 'taxId', 'address1', 'address2', 'provider',
            'question1Selected', 'question2Selected'
        ];
        for (const fieldName of fieldNameArr) {
            this.isFiledValid[fieldName] = true;
        }
    }

    private checkForEmptyField(fieldNameArr: string[]) {
        for (const fieldName of fieldNameArr) {
            this.isFiledValid[fieldName] = this.model[fieldName] !== '' ? true : false;
        }
    }

    private setFieldValidates(fieldNameArr: string[]) {
        for (const fieldName of fieldNameArr) {
            this.isFiledValid[fieldName] = false;
        }
    }

    private setFieldValidateMsg(fieldNameArr: string[]) {
        for (const fieldName of fieldNameArr) {
            this.fieldValidateMsg[fieldName] = '';
        }
    }

    private setFieldValidationTrue(fieldNameArr: string[]) {
        for (const fieldName of fieldNameArr) {
            this.isFiledValid[fieldName] = true ;
        }
    }

    getFormattedDateStr(date: Date) {
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' 00:00:00:000';
    }

    private constructRegisterDataPayload() {

        let current_date = new Date()

        let registerUserModel = {
            "addressLine1": this.model['address1'],
            "addressLine2": this.model['address2'],
            "city": this.model['city'],
            "email": this.model['email'] ? this.model['email'] : "none@null.com",
            "extn": this.model['ext'],
            "fax": this.model['fax'],
            "firstName": this.model['firstName'],
            "lastLogin": this.getFormattedDateStr(current_date),
            "lastName": this.model['lastName'],
            "lastPasswordChange": this.getFormattedDateStr(current_date),
            "middleName": this.model['middleName'],
            "npi": this.model['npi'],
            "phone": this.model['phone'],
            "regType": this.model['reg_type'],
            "securityQuestionsFlag": "1",
            "state": this.model['state'],
            // this.stateCodes,
            "taxId": this.model['taxId'],
            "userName": this.model['userName'].toLowerCase(),
            "zip": this.model['zip'].toString()
        }


        if (this.model['guId'] !== undefined) {
            registerUserModel['guId'] = this.model['guId'];
        }
        if (this.model['associateId'] !== undefined) {
            registerUserModel['associateId'] = this.model['associateId'];
        }
        return registerUserModel;
    }

    private constructRegisterUserPayload() {
        const baseUrl = location['origin'];
        const user = {
            'user':
            {
                'username': this.model.userName.toLowerCase(),
                'realm': 'PRIMARY',
                'password': this.model.cred,
                'claims': [
                    {
                        'uri': 'http://wso2.org/claims/givenname',
                        'value': this.model.firstName
                    },
                    {
                        'uri': 'http://wso2.org/claims/emailaddress',
                        'value': this.model.email
                    },
                    {
                        'uri': 'http://wso2.org/claims/lastname',
                        'value': this.model.lastName
                    },
                    {
                        'uri': 'http://wso2.org/claims/mobile',
                        'value': this.model.phone
                    },
                    {
                        'uri': 'http://wso2.org/claims/department',
                        'value': CONFIG.department
                    },
                    {
                        'uri': 'http://wso2.org/claims/url',
                        'value': baseUrl
                    }
                ]
            },
            'properties': []
        };
        return user;
    }
    isUndefinedOrEmpty(value: string) {
        if (value === undefined || value === '') {
            return true;
        }
        return false;
    }
    getNews() {
        this.loading = true;
        this.registerService.getNews((success: boolean, news: any) => {
            if (success) {
                this.news = news?.['providerPortalBroadcast'];
                this.loading = false;
            } else {
                this.loading = false;
            }
        });
    }
    getDocument() {
        this.registerService.getDocument().subscribe(res => {
            this.loading = true;
            const responseData = res;
            if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                const blob = this.binary64ToBlob(responseData.docContent, responseData.fileType, 512);
                saveAs(blob, responseData.filename);
                this.loading = false;
            } else {
                const blob = this.b64toBlob(responseData.docContent, responseData.fileType, 512);
                const urlBlob = URL.createObjectURL(blob);
                window.open(urlBlob, '_blank', 'location=yes,height=570,width=520');
                this.loading = false;
            }
        });
    }

    binary64ToBlob(binary64String, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        const byteCharacters = atob(binary64String);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    getautoPopulateUserName() {
        if(this.model.firstName !== '' && this.model.lastName !== ''){

            const firstName: string = this.model.firstName.toLowerCase();
            const lastName: string = this.model.lastName.toLowerCase();
            const middleName: string = this.model.middleName.toLowerCase();
            this.registerService.autoPopulateUserName(firstName, lastName, middleName, (success: boolean, userName: string) => {
                this.loading = true;
                if (success) {
                    this.model.userName = userName;
                    this.loading = false;
                }
            });
        }
        else{
            return;
        }
        
    }

   

    getSecurityQuestions() {
        this.registerService.getSecurityQuestions().subscribe((questionResponse:any) => {
          
                let questionResponseData: any;
                questionResponseData = questionResponse;
                if (questionResponseData.securityQuestionsResponse) {
                    const jsonText = {
                        'question1': [],
                        'question2': [],
                    };
                    questionResponseData.securityQuestionsResponse.forEach(data => {
                        if (data.setNumber === 'QuestionSet1') {
                            jsonText['question1'].push(data);
                        } else {
                            jsonText['question2'].push(data);
                        }
                    });
                    this.questions1 = jsonText['question1'];
                    this.questions2 = jsonText['question2'];
                }
            
        });
    }

    parseXml() {
        const win = window as any;
        if (win.DOMParser) {
            return function (xmlStr) {
                return (new win.DOMParser()).parseFromString(xmlStr, 'text/xml');
            };
        } else if (typeof win.ActiveXObject !== 'undefined' && new win.ActiveXObject('Microsoft.XMLDOM')) {
            return function (xmlStr) {
                const xmlDoc = new win.ActiveXObject('Microsoft.XMLDOM');
                xmlDoc.async = 'false';
                xmlDoc.loadXML(xmlStr);
                return xmlDoc;
            };
        } else {
            return function () { return null; };
        }

    }
    xmlToJson(xml) {
        const obj = {
            question1: [],
            question2: []
        };
        const soapRoot = xml.firstChild;
        const soapBody = soapRoot.firstChild;
        const questList = soapBody.firstChild;

        for (let i = 0; i < questList.childNodes.length; i++) {
            const child = questList.childNodes[i].childNodes;
            const question = {};
            for (let j = 0; j < child.length; j++) {
                const each = child[j];
                const key = each.nodeName.split(':')[1];
                question[key] = each.textContent;
            }
            if (question['questionSetId'].match('challengeQuestion1')) {
                obj.question1.push(question);
            } else if (question['questionSetId'].match('challengeQuestion2')) {
                obj.question2.push(question);
            }
        }
        return obj;
    }

    setDetails(associateDetails) {
        const details = JSON.parse(associateDetails);
        this.model.firstName = details.firstName && details.firstName != 'null' ? details.firstName : "";
        this.model.lastName = details.lastName && details.lastName != 'null' ? details.lastName : "";
        this.model.middleName = details.middleName && details.middleName != 'null' ? details.middleName : "";
        this.model.email = details.email && details.email != 'null' ? details.email : "";
        this.model.address1 = details.address1 && details.address1 != 'null' ? details.address1 : "";
        this.model.address2 = details.address2 && details.address2 != 'null' ? details.address2 : "";
        this.model.city = details.city && details.city != 'null' ? details.city : "";
        this.model.state = details.state && details.state != 'null' ? details.state : "";
        this.model.fax = details.fax && details.fax != 'null' ? details.fax : "";
        this.model.taxId = details.taxId && details.taxId != 'null' ? details.taxId : "";
        this.model.zip = details.zip && details.zip != 'null' ? String(details.zip) : "";
        this.model.phone = details.phone && details.phone != 'null' ? String(details.phone) : "";
        this.model.ext = details.ext && details.ext != 'null' ? details.ext : "";
        this.model.npi = details.npi && details.npi != 'null' ? details.npi : "";
        this.model['guId'] = details.guId && details.guId != 'null' ? details.guId : "";

    }
    getStatesListInfo(){
        this.registerService.getStatesList()
        .subscribe((data:any) =>{
            if(data && data.length){
                data.map(response => {
                    this.stateCodes.push(response.code);
                })
            }
        })
    }
    zipSelected(e){
        // console.log(e);
        this.model.zip = e.value.split('/')[0];
        this.model.city = e.value.split('/')[1];
    }
    onCitySelect(e){
        // console.log(e);
        this.model.zip = e.item.zipCode.split('/')[0];
        this.model.city = e.item.zipCode.split('/')[1];
    }
    ngOnDestroy() {
        localStorage.removeItem('associateDetails');
    }
}

