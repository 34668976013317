import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'customDateFilter' })

export class customDateFilerPipe implements PipeTransform {
  transform(value: any, selector: string): string {
    let data = value ? value.toString() : value === 0 ? value.toString() : value;
    let dateWithSlashSeparator = data;
    if (data && data.indexOf('-') != -1) {
      // dateWithSlashSeparator = data.replace('-', '/');
      dateWithSlashSeparator = data.replace(/-/gi, '/');
    }
    let isDateField = false;
    if (data) {
      let containsHyphenOrSlash = data.indexOf('-') != -1 || data.indexOf('/') != -1 ? true : false;
      let containsAlphapetics = data.match(/^[A-Z]/i) ? true : false;
      if (containsAlphapetics === false && containsHyphenOrSlash) { // Assumption is date value
        let isInvalidDate = (!(Date.parse(value)) && !(Date.parse(dateWithSlashSeparator)));
        if (isInvalidDate) { // this fix is only for IE.
          const dateWithTimestamp = value.replace(/ /g, 'T');
          if (dateWithTimestamp) {
            isInvalidDate = Date.parse(new Date(dateWithTimestamp).toString()) ? false : true;
          }
        }
        isDateField = !isInvalidDate;
      }
    }
    if (!isDateField) {
      return data;
    }
    else {
      //To Check value comes as the date
      if (data && data.match(/^[\ -\\\0-9]+$/g) == null) {
        return data;
      }

      if ((data && data.substring(0, 10).match(/^\d{2}\/\d{2}\/\d{4}$/))) {
        return data;
      } else {
        let str = data.substring(0, 10);
        //let date = str.split('-');
        // return padZero(date[1], 2) + "/" + padZero(date[2], 2) + "/" + padZero(date[0], 4);
        let dateObj = new Date(str);
        if (dateObj) {
          return padZero(dateObj.getUTCMonth() + 1, 2) + "/" + padZero(dateObj.getUTCDate(), 2) + "/" + padZero(dateObj.getUTCFullYear(), 4);
        } else {
          return data;
        }
      }
    }
  }
}


function padZero(number, length) {
  let str = '' + number;
  while (str.length < length) {
    str = '0' + str;
  }
  return str;
}