import { CONFIG } from '../../../src/config';
import { Buffer } from 'buffer/';
import * as crypto from 'crypto-browserify';
import * as CryptoJS from 'crypto-js'


export class RsaService {

  private enabled: boolean;
  private shaVal = 'sha256';
  private encodingFormat = 'base64';
  private charSet = 'utf8';


  private algorithm = 'aes-256-ecb';
   private cred: string;

  constructor() {
   
  }

  setKey(key: string) {
    this.cred = key;
  }

  getKey(): string { 
    return this.cred;
  } 

  isEnabled(): boolean {
    return this.enabled;
  }

  encrypt(data) {
    const iv = Buffer.from('');

    const decodeKey = crypto.createHash(this.shaVal).update(this.cred, 'utf8').digest();
    const cipher = crypto.createCipheriv(this.algorithm, decodeKey, iv);
    return cipher.update(data, 'utf8', 'base64') + cipher.final(this.encodingFormat);
  }

  decrypt(data) {
    const iv = Buffer.from('');
    const encodeKey = crypto.createHash(this.shaVal).update(this.cred, 'utf8').digest();
    const cipher = crypto.createDecipheriv(this.algorithm, encodeKey, iv);
    return cipher.update(data, 'base64', 'utf8') + cipher.final(this.charSet);
  }

  aesDecrypt(data) {
    const utf8 = CryptoJS.enc.Utf8.parse(this.cred)
    const hash = CryptoJS.SHA256(utf8)

    let cipher = CryptoJS.AES.decrypt(data, hash, {
      iv: Buffer.from(''),
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return cipher.toString(CryptoJS.enc.Utf8);
    
  }

  aesencryptForStore(data) {
   
    try{
      const utf8 = CryptoJS.enc.Utf8.parse(this.cred)
      const hash = CryptoJS.SHA256(utf8)
      const ciphertext = CryptoJS.AES.encrypt(data, hash, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      return ciphertext.toString();
    }
   catch(e){
      console.log(e);
    }
    
  }

  aesdecryptForStore(data) {
    
    try{
      const utf8 = CryptoJS.enc.Utf8.parse(this.cred)
      const hash = CryptoJS.SHA256(utf8)
    const bytes = CryptoJS.AES.decrypt(data, hash, {
      mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return bytes.toString(CryptoJS.enc.Utf8);
    }
    catch(e){
      console.log(e);
    }
    
    
  }
}
