export const STAGE_CONFIG = {
        email: 'DL-ProvExpProviderRelations@caremore.com',
        email_subject: 'Test-ignore:',
        cup_base_url: '',
        department: 'providerportalui-stg.caremore.com/#',
        GeneralReference_GUID: '{F2998F9C-BAEC-C733-845A-6BE03AA00000}',
        ProviderCommunications_GUID: '{68122FF6-5FFD-C1F6-8421-6BE03AE00000}',
        registrationType: [
                'Primary Care Provider user',
                'Provider specialist user',
                'Ancillary Provider/Vendor user',
                'Provider Billing department user'],
        userIdleConfig: {
                idle: 7200,
                timeout: 60,
                ping: 60
        }

};
