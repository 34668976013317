import {Injectable} from '@angular/core';
import {HttpHeaders, HttpClient,  HttpParams} from '@angular/common/http';
import { urls, props , payloads} from '../common/model/properties';


import { ConfigProvider } from '../shared/services/configprovider';
import { SecureStorageService } from '../secure-storage.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ForgotpasswordService {
     props:any;
     constructor(private http: HttpClient,private configProvider:ConfigProvider,
        private secureStorage:SecureStorageService) {
          this.props = configProvider.getProps();
     }

     getSecurityQuestions(userName) {
          const headers = new HttpHeaders({});
          const payload = { userName: userName };
          return this.http.post(urls.getSecurityQuestionsUrl,payload, {headers: headers});
     }
     validateUserName(userName){
          const headers = new HttpHeaders({
              
          });

          return this.http.get(urls.validateUserName+ userName, {headers: headers})
     }
     requestRecoverPassword(userName) {
          const headers = new HttpHeaders({
          });
         
          const recoverPasswordPost = payloads.recoverPasswordPayload;
          recoverPasswordPost.user.username = userName;

          return this.http.post(urls.recoverPasswordUrl, recoverPasswordPost, {headers: headers});
     }
     getNews( callBack: ( sucess: boolean, message: string ) => void ) {
         const headers = new HttpHeaders({
            'Accept': 'application/json',
         });
         this.http.get( urls.getNews, { headers: headers,responseType:'text' } ).pipe(map(
             response => {
                  const resp = response;
                  return resp ? JSON.parse(resp) : {};
               } ))
             .subscribe(
             ( resp ) => {
                 callBack( true, resp );
             }
             );
     }
     getInternalUserType(userName) {
         
          const headers = new HttpHeaders({
          });

          const myParams = new HttpParams()
          .append( 'userName', userName );
          return this.http.get( urls.getInternalUserType, {headers: headers,  params: myParams, responseType:'text' } );
     }

}
