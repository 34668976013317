import { Hero } from './hero';

export const HEROES: Hero[] = [
  { provider: 'RAMOS, ARTHUR', providerID: 100000000265, claimNo: 192750042900, firstDOS: '07/31/2019', diagnosis: 'Z682..', billed: '$135.00', payAmount: '$68.28', capitationCode: 'F', capitationDescription: 'Fully Capitated', status: 'Completed' , cpt: '99214-Office/Outpatient Visit Est', dos: '07/31/2019', copay: '$0.00', checkNumber: 1367089, checkDate: '10/03/2019', lineLevelDetails: 'Enable'},
  { provider: 'UNILAB QUEST DIAGNOSTICS', providerID: 100000000265, claimNo: 181410131600, firstDOS: '07/31/2019', diagnosis: 'Z682..', billed: '$135.00', payAmount: '$68.28', capitationCode: 'F', capitationDescription: 'Fully Capitated', status: 'Completed' , cpt: '99214-Office/Outpatient Visit Est', dos: '07/31/2019', copay: '$0.00', checkNumber: 1367089, checkDate: '10/03/2019', lineLevelDetails: 'Enable' },
   { provider: 'RAMOS, ARTHUR', providerID: 100000000265, claimNo: 192750042900, firstDOS: '07/31/2019', diagnosis: 'Z682..', billed: '$135.00', payAmount: '$68.28', capitationCode: 'F', capitationDescription: 'Fully Capitated', status: 'Completed' , cpt: '99214-Office/Outpatient Visit Est', dos: '07/31/2019', copay: '$0.00', checkNumber: 1367089, checkDate: '10/03/2019', lineLevelDetails: 'Enable'},
  { provider: 'UNILAB QUEST DIAGNOSTICS', providerID: 100000000265, claimNo: 181410131600, firstDOS: '07/31/2019', diagnosis: 'Z682..', billed: '$135.00', payAmount: '$68.28', capitationCode: 'F', capitationDescription: 'Fully Capitated', status: 'Completed' , cpt: '99214-Office/Outpatient Visit Est', dos: '07/31/2019', copay: '$0.00', checkNumber: 1367089, checkDate: '10/03/2019', lineLevelDetails: 'Enable' },
   { provider: 'RAMOS, ARTHUR', providerID: 100000000265, claimNo: 192750042900, firstDOS: '07/31/2019', diagnosis: 'Z682..', billed: '$135.00', payAmount: '$68.28', capitationCode: 'F', capitationDescription: 'Fully Capitated', status: 'Completed' , cpt: '99214-Office/Outpatient Visit Est', dos: '07/31/2019', copay: '$0.00', checkNumber: 1367089, checkDate: '10/03/2019', lineLevelDetails: 'Enable'},
  { provider: 'UNILAB QUEST DIAGNOSTICS', providerID: 100000000265, claimNo: 181410131600, firstDOS: '07/31/2019', diagnosis: 'Z682..', billed: '$135.00', payAmount: '$68.28', capitationCode: 'F', capitationDescription: 'Fully Capitated', status: 'Completed' , cpt: '99214-Office/Outpatient Visit Est', dos: '07/31/2019', copay: '$0.00', checkNumber: 1367089, checkDate: '10/03/2019', lineLevelDetails: 'Enable' },
   { provider: 'RAMOS, ARTHUR', providerID: 100000000265, claimNo: 192750042900, firstDOS: '07/31/2019', diagnosis: 'Z682..', billed: '$135.00', payAmount: '$68.28', capitationCode: 'F', capitationDescription: 'Fully Capitated', status: 'Completed' , cpt: '99214-Office/Outpatient Visit Est', dos: '07/31/2019', copay: '$0.00', checkNumber: 1367089, checkDate: '10/03/2019', lineLevelDetails: 'Enable'},
  { provider: 'UNILAB QUEST DIAGNOSTICS', providerID: 100000000265, claimNo: 181410131600, firstDOS: '07/31/2019', diagnosis: 'Z682..', billed: '$135.00', payAmount: '$68.28', capitationCode: 'F', capitationDescription: 'Fully Capitated', status: 'Completed' , cpt: '99214-Office/Outpatient Visit Est', dos: '07/31/2019', copay: '$0.00', checkNumber: 1367089, checkDate: '10/03/2019', lineLevelDetails: 'Enable' }
];