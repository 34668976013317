<div id="main-wrapper">
	<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
	<div class="container-fluid">
		<app-header (found)="handleResults($event)"></app-header>
		<!-- <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a routerLink="/providerportal" href="#" style="color: #5009B5;">Providerportal</a></li>
                  <li class="breadcrumb-item active" style= "font-family: Myriad Pro" aria-current="page">Connect</li>
                </ol>
              </nav> -->
		<div class="limiter">
				<div class="main-container topSpace">
					<app-search-results [resultGridList]="data"></app-search-results>
				</div>
			<div class="container-accountProfile100">
				<form style="margin: 0px">
					<h2>Contact Us</h2>
					<div class="contentform">
						<div id="sendmessage">Your message has been sent successfully. Thank you.</div>
						<div class="leftcontact">
							<div>

								<br>
								<ng-container *ngFor="let each of displayOptions">
								<br>
								<h3>{{each?.header}}</h3>
										<p class="options-para">
											<b *ngIf="each?.subHeader">{{each.subHeader}} <br></b> 
											<span *ngIf="each?.workingDay">{{each.workingDay}}<br></span>
											<span *ngIf="each?.workingHours">{{each.workingHours}}<br></span>
											<span *ngIf="each?.lunchTime">{{each.lunchTime}}<br></span>
	
											<span *ngIf="each?.email">
													Email: {{each.email}}
												<br>
											</span>

											
											<span *ngIf="each?.link" class="training-link">
													<a href="{{each.link}}" target="_blank"  rel="noopener noreferrer">
													Click here for training guides and videos.
												</a>
												<br>
											</span> 
										
	
											<span *ngIf="each?.contactNumber">
												Phone: {{each.contactNumber}}
												<br>
												<span *ngIf="each?.options"> {{each.options}}<br></span>
												
											</span>
											 
											<span *ngIf="each?.fax">Fax: {{each.fax}}.<br></span>

										</p>
							</ng-container>
							</div>
						</div>
						<div class="rightcontact">
							<div class="form-group">
								<p>
									Name <span>*</span>
								</p>
								 <input type="text" name="name" id="name" [(ngModel)]="model.name" disabled />
							</div>
							<div class="form-group">
								<p>
									Phone number <span>*</span>
								</p>
								 <input (keypress)="decimalRestrict($event)" (keyup)="onPhoneKeyUp($event)" type="text" name="phoneNumber"
								 id="phoneNumber" [(ngModel)]="model.phoneNumber" disabled />
							</div>
							<div class="form-group">
								<p>
									E-mail 
								</p>
								 <input type="email" name="email" id="email" [(ngModel)]="model.email" disabled />
							</div>
							<div class="form-group">
								<p>Select list: <span>*</span></p>
								<select class="dlusers" id="sel1" [(ngModel)]="model.selectedEnquiry" name="selectedEmail">
									<option value="" disabled>Please select</option>
									<option [ngValue]="enquiry" *ngFor="let enquiry of enquiryList">{{enquiry.enquiry}}</option>
								</select>
							</div>
							<div class="form-group">
								<p>
									Subject <span>*</span>
								</p> 
									<input class="tooltipTop" tooltip="Please do not include any Protected Health information (PHI) or Personally Identifiable information (PII)."
									 placement="right" type="text" name="sujet" id="sujet" [(ngModel)]="model.subject" />
									 <input class="tooltipBottom" tooltip="Please do not include any Protected Health information (PHI) or Personally Identifiable information (PII)."
									 placement="bottom" type="text" name="sujetBtm" id="sujetBtm" [(ngModel)]="model.subject" />
								</div>
								<div class="form-group">
									<p>
										Message <span>*</span>
									</p>
									<textarea class="tooltipTop"  tooltip="Please do not include any Protected Health information (PHI) or Personally Identifiable information (PII)."
									 placement="right" name="message" rows="14" [(ngModel)]="model.message"></textarea>
									 <textarea class="tooltipBottom" tooltip="Please do not include any Protected Health information (PHI) or Personally Identifiable information (PII)."
									 placement="bottom" name="message" rows="14" [(ngModel)]="model.message"></textarea>
								</div>
								<div class="form-group btnContainer">
									<button type="submit" class="bouton-contact" (click)="onSubmit()">Send</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
			<br><br><br><br>
		<app-footer ></app-footer>
		</div>
	</div>

	<div class="row contactModal">
		<div class="modal fade" id="myModal" role="dialog">
			<div class="modal-dialog" style="margin-top: 15%;">
				<div class="modal-content alertPopup">
					<div class="modal-header">
						<div class="row">
							<div class="col-md-9">

							</div>
							<div class="col-md-3">
								<button type="button" class="close"
									data-dismiss="modal"><img src="../../../assets/images/Close.svg"
										alt="close"></button>
							</div>
						</div>
					</div>
					<div class="modal-body">
						<div class="row imgContainer">
							<img src="../../../assets/images/icons/Error.svg" alt="Error">
						</div>
						<p class="text" style="text-align: center;">{{ errorMessage }}</p>
					</div>
					<div class="modal-footer">
						<div class="col-md-12 pl-0" align="center">
							<button type="button" data-dismiss="modal" (click)="myModalEventCallBack()"
								class="btn carelon-primary-btn">Close</button>
						</div>
						<!-- <div class="container-contact100-form-btn">
							<button type="button"  class="contact100-form-btn previous m-r-10" data-dismiss="modal">Close</button>
						</div> -->
					</div>
				</div>
			</div>
		</div>
		
	</div>