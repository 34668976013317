<div class="row mainStyles" [ngClass]="
    item.IsUnRead === '1' && !item.isCardOpened ? 'unReadStyle' : 'readStyle'
  ">
  <div [ngClass]="
  item.open ? 'opened' : 'notOpened'
">
    <div class="col-sm-1 col-md-1 avatarPR col-xs-1">
      <span class="photo">
        <div class="textInsideCircle">
          {{ item.AlertName ? item.AlertName.substring(0, 2) : "NA" }}
        </div>
      </span>
    </div>
    <div class="col-sm-9 col-md-9 col-xs-9 card-info-container">
      <div class="card-title cardTitle">
        {{ item.AlertName ? item.AlertName : "NA" }}
      </div>
      <div class="detailsstyle" *ngIf="item.APPLICATION_ID === 'PAHAF'" [ngClass]="item.IsUnRead === '1' && !item.isCardOpened ? 'unreadScrollbar' : 'readScrollbar'">
        <span class="fontboldStyle"> Year: </span>{{ item.AlertName ? item.AlertName.split(':')[1] : "" }}
      </div>
      <div class="detailsstyle" [ngClass]="
          item.IsUnRead === '1' && !item.isCardOpened
            ? 'unreadScrollbar'
            : 'readScrollbar'
        ">
        <span class="fontboldStyle"> Details: </span>{{ item.AlertDetails ? item.AlertDetails : "NA" }}
      </div>
      <div *ngIf="item.open">
        <div class="card-title" class="commonStyle">
          <span class="fontboldStyle"> Created Date:</span>{{ item.CreatedDate ? item.CreatedDate : "NA" }}
          <div class="commonStyle">
            <span class="fontboldStyle">Severity:</span>{{ item.Severity ? item.Severity : "NA" }}
          </div>
          <div class="commonStyle" style="padding-bottom: 5px;">
            <span class="fontboldStyle">Application id :</span>{{item.APPLICATION_ID ? item.APPLICATION_ID: "NA"}}
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-2 col-md-2 col-xs-2 cardClose" [ngClass]="item.open ? 'rotate' : ''">
      <i class="fa fa-angle-down" aria-hidden="true" style="padding: 38%; font-size: x-large" (click)="showfullContent()"></i>
    </div>
  </div>
</div>