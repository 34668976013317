import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as _ from 'lodash';

@Component({
  selector: "app-tab-selection-pane",
  templateUrl: "./tab-selection-pane.component.html",
  styleUrls: ["./tab-selection-pane.component.scss"],
})
export class TabSelectionPaneComponent implements OnInit {
  @Output() newTabValues = new EventEmitter<string>();
  @Output() flagValues = new EventEmitter<any>();
  public tabData = [
    { index: 0, value: "High" },
    { index: 1, value: "Medium" },
    { index: 2, value: "Low" },
    // { index: 3, value: "All Unread Notifications" },
  ];
  @Input() selectedTabIndex: string;
  @Input() cardData = [];
  @Input() unReadAlertCount: any;
  @Input() unReadHighAlertCount: any;
  @Input() unReadMedAlertCount: any;
  @Input() unReadLowAlertCount: any;
  mediumFlag: boolean;
  highFlag: boolean;
  lowFlag: boolean;
  tabValueAfterClick = "All";
  noMedFoundFlag: boolean;
  nolowFoundFlag: boolean;
  noHighFoundFlag: boolean;

  constructor() {}

  ngOnInit() {}

  onTabClick(value: any) {
    if (value.index === this.selectedTabIndex) {
      this.tabValueAfterClick = value.value;
      console.log("value", this.tabValueAfterClick);
    }
    this.newTabValues.emit(value);
    console.log("tabValue", value);
    console.log(this.cardData);
    if (value.value === "High") {
      this.highFlag = this.cardData.some((temp) => {
        return temp.Severity.toLowerCase() === "high";
      });
      console.log(this.highFlag);

      let filteredHighCardData = this.cardData.filter(
        (x) => x.Severity.toLowerCase() === "high"
      );
      let noCardFound = _.isEmpty(filteredHighCardData);
     
        this.noHighFoundFlag = filteredHighCardData.every((temp) => {
          return temp.IsUnRead === "0";
        });
     
      console.log("highnodatafoundflag", this.noHighFoundFlag);
      this.flagValues.emit({
        someFlag: this.highFlag,
        everyFlag: this.noHighFoundFlag,
        noCardFound:noCardFound
      });
    } else if (value.value === "Medium") {
      this.mediumFlag = this.cardData.some((temp) => {
        return temp.Severity.toLowerCase() === "medium";
      });
      console.log(this.mediumFlag);

      let filteredMedCardData = this.cardData.filter(
        (x) => x.Severity.toLowerCase() === "medium"
      );
      let noCardFound = _.isEmpty(filteredMedCardData);
    
        this.noMedFoundFlag = filteredMedCardData.every((temp) => {
          return temp.IsUnRead === "0";
        });
      
      console.log("highnodatafoundflag", this.noMedFoundFlag);
      this.flagValues.emit({
        someFlag: this.mediumFlag,
        everyFlag: this.noMedFoundFlag,
        noCardFound:noCardFound
      });
    } else if (value.value === "Low") {
      this.lowFlag = this.cardData.some((temp) => {
        return temp.Severity.toLowerCase() === "low";
      });
      console.log(this.lowFlag);
      let filteredlowCardData = this.cardData.filter(
        (x) => x.Severity.toLowerCase() === "low"
      );
      let noCardFound = _.isEmpty(filteredlowCardData);
      
        this.nolowFoundFlag = filteredlowCardData.every((temp) => {
          return temp.IsUnRead === "0";
        });
      
      this.flagValues.emit({
        someFlag: this.lowFlag,
        everyFlag: this.nolowFoundFlag,
        noCardFound:noCardFound
      });
    }
  }
}
