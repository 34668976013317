import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'customFormat' })
export class customFormatPipe implements PipeTransform {
  transform(value: any, selector: string): string {
    const data = value ? value.toString() : value === 0 ? value.toString() : value;

    let dateWithSlashSeparator = data;
    if (data && data.indexOf('-') != -1) {
      // dateWithSlashSeparator = data.replace('-', '/');
      dateWithSlashSeparator = data.replace(/-/gi, '/');
    }

    if ((data ? data.indexOf('-') === -1 : '') ||
      (data ? data.match(/^[A-Z]/i) : '') ||
      (!(Date.parse(value)) &&
        !(Date.parse(dateWithSlashSeparator)))) {
      return data;
    } else {
      // To Check value comes as the date
      if (data && data.match(/^[\ -\\\0-9]+$/g) == null) {
        return data;
      }

      const str = data.substring(0, 10);
      const date = str.split('-');
      return padZero(date[1], 2) + '/' + padZero(date[2], 2) + '/' + padZero(date[0], 4);
    }
  }
}

function padZero(number, length) {
  let str = '' + number;
  while (str.length < length) {
    str = '0' + str;
  }
  return str;
}
