<div *ngIf="model && (model.displayType === 'ONE')">
	<div class="form-group" *ngIf="model && !model.isSelect && !model?.isTypeahead">
		<span *ngIf = "isMandatory" class="red-color">*</span> <label>{{model.label}}</label>
		<div [ngClass]="model.isInputValid ? 'validate-input' : 'validate-input alert-validate'" [attr.data-validate]="model.fieldValidateMsg">
			<input (keyup)="model.restrictKeys($event)" (keypress)="model.keyPress($event)" (focus)="hideValidate($event)"
			 [attr.type]="model.textType" class="form-control" autocomplete="off" [(ngModel)]="model.value" [attr.maxlength]="model.length">
			<span class="btn-show-pass" *ngIf="model.type === 'TYPE_PASSWORD' || model.type === 'TYPE_OLD_PASSWORD'">
				<i [ngClass]="model.textType === 'text'?'zmdi zmdi-eye':'zmdi zmdi-eye-off'" (click)="model.togglePasswordShow()"></i>
			</span>
		</div>
	</div>

	<div class="form-group" *ngIf="model && model.isSelect">
		<span *ngIf = "isMandatory" class="red-color">*</span> <label>{{model.label}}</label>
		<div [ngClass]="model.isInputValid ? 'validate-input' : 'validate-input alert-validate'" [attr.data-validate]="model.fieldValidateMsg">
			<select (focus)="hideValidate($event)" type="text" class="form-control" [(ngModel)]="model.value" [attr.maxlength]="model.length">
				<option value=""></option>
				<option *ngFor="let option of model.options" [value]="option['value'] ? option['value'] : option['key'] ? option['key'] : option">
					{{option['key'] ? option['key'] : option}}
				</option>
			</select>
		</div>
	</div>
	<div class="form-group" *ngIf="model && model.isTypeahead">
		<span *ngIf = "isMandatory" class="red-color">*</span> <label>{{model.label}}</label>
		<div [ngClass]="model.isInputValid ? 'validate-input' : 'validate-input alert-validate'" [attr.data-validate]="model.fieldValidateMsg">
			<input (keyup)="model.restrictKeys($event)" (keypress)="model.keyPress($event)" (focus)="hideValidate($event)"
			 [attr.type]="model.textType" class="form-control"  [(ngModel)]="model.value" [attr.maxlength]="model.length"
			 autocomplete="'new-password" [typeaheadAsync]="true" [typeahead]="dataSource"  container="body" typeaheadMinLength="3"
			 typeaheadOptionsLimit="200"
			 [typeaheadOptionField]="model?.typeField" (typeaheadOnSelect)="onValueSelect($event)" [typeaheadScrollable]="true">
			<span class="btn-show-pass" *ngIf="model.type === 'TYPE_PASSWORD' || model.type === 'TYPE_OLD_PASSWORD'">
				<i [ngClass]="model.textType === 'text'?'zmdi zmdi-eye':'zmdi zmdi-eye-off'" (click)="model.togglePasswordShow()"></i>
			</span>
		</div>
	</div>
</div>
<div *ngIf="model && (model.displayType === 'TWO')">
	<div class="form-group" *ngIf="model && !model.isSelect && !model?.isTypeahead">
		<div [ngClass]="model.isInputValid ? ' wrap-input validate-input' : ' wrap-input validate-input alert-validate'"
		 [attr.data-validate]="model.fieldValidateMsg">
			<input (keyup)="model.restrictKeys($event)" (keypress)="model.keyPress($event)" (focus)="hideValidate($event)"
			 [attr.type]="model.textType" [(ngModel)]="model.value" [attr.maxlength]="model.length" [ngClass]="(model.value === '')?'input100':'input100 has-val'">
			<span class="focus-input100" [attr.data-placeholder]="model.label"></span>
			<span class="btn-show-pass" *ngIf="model.type === 'TYPE_PASSWORD'">
				<i [ngClass]="model.showConfirmCred?'zmdi zmdi-eye':'zmdi zmdi-eye-off'" (click)="model.togglePasswordShow()"></i>
			</span>
		</div>
	</div>
	<div class="form-group" *ngIf="model && model.isSelect">
		<div [ngClass]="model.isInputValid ? 'wrap-input validate-input' : 'wrap-input validate-input alert-validate'"
		 [attr.data-validate]="model.fieldValidateMsg">
			<select (focus)="hideValidate($event)" type="text" [ngClass]="(model.value === '')?'input100 select100':'input100 select100 has-val'"
			 [(ngModel)]="model.value" [attr.maxlength]="model.length">
				<option value="" style="display: none"></option>
				<option *ngFor="let option of model.options" [value]="option['value'] ? option['value'] : option['key'] ? option['key'] : option">
					{{option['key'] ? option['key'] : option}}
				</option>
			</select>
			<span class="focus-input100" [attr.data-placeholder]="model.label"></span>
		</div>
	</div>
	<div class="form-group" *ngIf="model && model.isTypeahead">
			<div [ngClass]="model.isInputValid ? ' wrap-input validate-input' : ' wrap-input validate-input alert-validate'"
			 [attr.data-validate]="model.fieldValidateMsg">
				<input (keyup)="model.restrictKeys($event)" (keypress)="model.keyPress($event)" (focus)="hideValidate($event)"
				 [attr.type]="model.textType" [(ngModel)]="model.value" [attr.maxlength]="model.length"
				  [ngClass]="(model.value === '')?'input100':'input100 has-val'"
				  autocomplete="'new-password" [typeaheadAsync]="true" [typeahead]="dataSource" typeaheadMinLength="3"
				  typeaheadOptionsLimit="200"
				  [typeaheadOptionField]="model?.typeField" (typeaheadOnSelect)="onValueSelect($event)" [typeaheadScrollable]="true">
				<span class="focus-input100" [attr.data-placeholder]="model.label"></span>
				<span class="btn-show-pass" *ngIf="model.type === 'TYPE_PASSWORD'">
					<i [ngClass]="model.showConfirmCred?'zmdi zmdi-eye':'zmdi zmdi-eye-off'" (click)="model.togglePasswordShow()"></i>
				</span>
			</div>
		</div>
</div>