import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from './confirmation.service'

declare var jQuery: any;

@Component({
    selector: 'app-FooterFaq',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.css'],
    providers: [ConfirmationService]
})
export class SignupConfirmationComponent implements OnInit {

    confirmationCode: string = '';
    isConfirmValid: boolean = false;
    showConfirm: boolean = false;
    errorMessage: any;
    mailSent: any;
    user: any;
    news = [];
    loading: boolean = false;

    constructor(private router: Router, private route: ActivatedRoute, private confirmationService: ConfirmationService) { }

    ngOnInit() {
        this.route.queryParamMap.subscribe(params => {
            if (params && params['params'] && params['params']['confirmation']) {
                this.confirmationCode = params['params']['confirmation'];
                this.user = params['params']['username'];
                let usernametemp = this.user.split(';');
                this.user = usernametemp[0];
                this.validateUserCode();
            }
        });
        this.getNews();
    }
    validateUserCode() {
        this.loading = true;
        this.confirmationService.validateConfirmationCode(this.confirmationCode, (success: boolean, message: string) => {
            this.showConfirm = true;
            if (success) {
                this.loading = false;
                this.isConfirmValid = true;
            }
            else {
                this.loading = false;
            }
        });
    }
    resend() {
        this.loading = true;
        this.confirmationService.resendConfirmationCode(this.user, (success: boolean, message: string) => {
            if (success) {
                this.loading = false;
                this.mailSent = true;
            }
            else {
                this.loading = false;
            }
        });
    }

    getNews() {
        this.loading = true;
        this.confirmationService.getNews((success: boolean, news: any) => {
            if (success) {
                this.loading = false;
                this.news = news["providerPortalBroadcast"];
            } else {
                this.loading = false;
            }
        })

    }
}

