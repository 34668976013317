import { EventEmitter, Injectable } from '@angular/core';
import { urls, payloads } from '../common/model/properties';
import { ConfigProvider } from '../shared/services/configprovider';
import { UrlconstantsService } from '../shared/services/urlconstants.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SecureStorageService } from '../secure-storage.service';
import { CupService } from '../shared/services/cup.service';



@Injectable()
export class UserauthenticationService {
  props:any;
  constructor(private http: HttpClient, private configProvider:ConfigProvider,private urlconstantsService:UrlconstantsService,
    private secureStorage:SecureStorageService,private cupService:CupService) {
    this.props = configProvider.getProps();
   }
   private activeUsers: any[] = [];
  private inactiveUsers: any[] = [];
  activeUsersChanged: any = new BehaviorSubject([]);
  inactiveUsersChanged: any = new BehaviorSubject([]);

  setActiveUsers(users: any[]): void {
    this.activeUsers = users;
    this.activeUsersChanged.next(this.activeUsers);
  }

  getActiveUsers(): any[] {
    return this.activeUsers;
  }

  setInactiveUsers(users: any[]): void {
    this.inactiveUsers = users;
    this.inactiveUsersChanged.next(this.inactiveUsers);
  }

  getInactiveUsers(): any[] {
    return this.inactiveUsers;
  }


   public urlConstant: any = this.urlconstantsService.fetchUrl();

  getUsers(guId: string, status: string, userRole:  string, callBack: (sucess: boolean, message: string) => void) {

    
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({'Authorization': 'Bearer ' + ppjwt.access_token});
    let authUserListUrl = urls.authUserList;
    //authUserListUrl = authUserListUrl.replace('GU_ID', guId);
    authUserListUrl = authUserListUrl.replace('STATUS', (status === '1' ? '1,3' : status));
    if (userRole !== '') {
      authUserListUrl = authUserListUrl + '&' + 'userRole=' + userRole;
    }

    this.http.get(authUserListUrl, { headers: headers })
      .subscribe(
        (resp:any) => {
          callBack(true, resp);
        },
        err => {
          callBack(false, null);
        }
      );
  }
  getUnregisterdUsers(guId: string, callBack: (sucess: boolean, message: string) => void) {

    
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + ppjwt.access_token
    });
    let getUnregisterdUsersUrl = urls.getUnregisterdUsers;
    //getUnregisterdUsersUrl = getUnregisterdUsersUrl.replace('GU_ID', guId);
    this.http.get(getUnregisterdUsersUrl, { headers: headers })
      .subscribe(
        (resp:any) => {
          callBack(true, resp);
        },
        err => {
          callBack(false, null);
        }
      );
  }

  updateStatus(user, callBack: (sucess: boolean, message: Array<any>) => void) {

    const payload = {
      'userName': user.userName,
      'userRole': user.userRole,
      'status': user.status,
    };
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({'Authorization': 'Bearer ' + ppjwt.access_token});

    const options = { headers: headers };

    this.http.put(urls.updateStatus, payload, { headers: headers,responseType:'text'})
      .subscribe(
        (resp) => {
          callBack(true, resp['user']);
        },
        err => {
          callBack(false, err);
        }
      );
  }
  updateAssociateURData(user, callBack: (sucess: boolean, message: Array<any>) => void) {

    const payload = {
      'associateId': user.associateId,
      'guIdTime': this.formatted_date(),
    };
    
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({'Authorization': 'Bearer ' + ppjwt.access_token});


    const options = { headers: headers };

    this.http.post(urls.updateAssociateURData, payload, { headers: headers, responseType:'text' })
      .subscribe(
        (resp) => {
          callBack(true, resp['user']);
        },
        err => {
          callBack(false, err);
        }
      );
  }
  updateAssociateURData2(user,userName): Observable<any>{

    const payload = {
      'associateId': user.associateId,
      'guId': "Pending",
      "sysUpdatedId": userName,
      "sysUpdatedTime": this.formatted_date(),
    };
    
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({'Authorization': 'Bearer ' + ppjwt.access_token});

    const options = { headers: headers };

    return this.http.put(urls.updateAssociateURDataPut, payload, { headers: headers, observe:'response',responseType:'text' });
     
  }

  updateProviderAdmin(guId: string, userName: string, callBack: (sucess: boolean, message: string) => void) {

    const payload = {
      guId: guId,
      provadmrUserName: userName
    };
    
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({'Authorization': 'Bearer ' + ppjwt.access_token});

    const options = { headers: headers ,responseType:'text'};

    this.http.put(urls.updateProviderAdmin, payload, { headers: headers ,responseType:'text'})
      .subscribe(
        (resp) => {
          if ('success' === resp) {
            callBack(true, resp);
          } else {
            callBack(false, resp);
          }
        },
        err => {
          callBack(false, err);
        }
      );
  }



  checkProviderAdmin(guId: string, userName: string, callBack: (sucess: boolean, message: string) => void) {

    const payload = {
      guId: guId,
      provadmrUserName: userName
    };
   
    // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({'Authorization': 'Bearer ' + ppjwt.access_token});

    const options = { headers: headers }

    this.http.get(urls.checkProviderAdmin + '/' + guId + '/' + userName, { headers: headers, responseType:'text' })
    .subscribe(
        (resp) => {
          if ('true' === resp) {
            callBack(true, resp);
          } else {
            callBack(false, resp);
          }
        },
        err => {
          callBack(false, err);
        }
      );
  }

  formatted_date() {
     let result = '';
     const d = new Date();
     result += d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() +
               ' ' + d.getHours() + ':' + d.getMinutes() + ':' +
               d.getSeconds();
     return result;
  }

  updateLockedPassword(userName, password , callBack: ( sucess: boolean, message: any ) => void ) {

    let payload = {
      "username": userName,
      "password": password
    }

    const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' +  ppjwt.access_token
    });

    this.http.post( urls.updateLockedPasswordUrl, payload, { headers: headers } )
        .subscribe(
        ( resp ) => {
            callBack( true, resp );
        },
        err => {
            callBack( false, err );
        }
        );
}
unlockUser(userName, callBack: ( sucess: boolean, message: string ) => void ) {
  const payload = {
    "username": userName
  }
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' +  ppjwt.access_token
  });

  this.http.post( urls.unlockUserUrl, payload, { headers: headers, responseType:'text' } )
      .subscribe(
      ( resp ) => {
          callBack( true, '' );
      },
      err => {
          callBack( false, 'Error while changing the password : ' );
      }
      );
}

updateLockedUerStatus( statusPayload, callBack: ( sucess: boolean, message: string ) => void ) {


  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' +  ppjwt.access_token
  });
     
  this.http.put(  urls.updateDataUrl, statusPayload, { headers: headers ,responseType:'text'} )
  .subscribe(
          ( resp ) => {
              callBack( true, resp );
          }
  );
}
updateUerPasswordChangeTime( changePasswordTimePayload, callBack: ( sucess: boolean, message: string ) => void ) {
  
      // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
      const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
      const headers = new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' +  ppjwt.access_token 
      });

      this.http.put( urls.updateDataUrl, changePasswordTimePayload, { headers: headers ,responseType:'text'} )
      .subscribe(
          ( resp ) => {
              callBack( true, resp );
          }
  );
}
getSiteUsers(userName: String,callBack: (sucess: boolean, message: string) => void){
  
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({'Authorization': 'Bearer ' + ppjwt.access_token});
  const userNameFromToken = this.cupService.getUserNameFromJWT()
  const siteDetailURL = this.urlConstant.userSiteProviders.replace(
    '{id}',
    userNameFromToken
    // JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser')).userName
  );
  
  //let siteUserListUrl = urls.getSiteUsers;
  //siteUserListUrl = siteUserListUrl + userName;

  this.http.post(siteDetailURL,null, {headers: headers})
  .subscribe(
    (resp:any) => {
      callBack(true,resp);
    },
    err => {
      callBack(false,null)
    }
  );
}
getUserDetails(userName: String, callBack: (sucess: boolean, message: string) => void){
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  // const headers = new HttpHeaders({
  //   'Authorization': 'Bearer ' + ppjwt.access_token
  // });
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});
  let userDetails = urls.accountProfileUrl as any;;
  userDetails = userDetails.replace('USER_NAME', userName)

  this.http.get(userDetails, {headers: headers})
  .subscribe(
    (resp:any) => {
      callBack(true,resp);
    },
    err => {
      callBack(false,null)
    }
  );

}

getUserDetailsPost(userName: String, callBack: (sucess: boolean, message: string) => void){
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});
  
  const payload  =  {
    'userName': userName
  }

  this.http.post(urls.accountProfileUrlforManageUser,payload,{headers: headers})
  .subscribe(
    (resp:any) => {
      callBack(true,resp);
    },
    err => {
      callBack(false,null)
    }
  );

}

}
