import {Injectable} from '@angular/core';

import { urls, payloads } from '../common/model/properties';
import { ConfigProvider } from '../shared/services/configprovider';
import { CONFIG } from '../../config';
import { Subject, forkJoin, of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaderResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { SecureStorageService } from '../secure-storage.service';

@Injectable()
export class SigninService {
  private headerValue = '';
  props:any;
  result = new Subject();
  notify = new Subject<any>();
  private memberdetailsData =[];
  private memberdetailsPdfData = [];
  
  constructor(private http: HttpClient, private configProvider:ConfigProvider,
    private secureStorage:SecureStorageService) {
    this.props = configProvider.getProps();
    //console.log(JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser')));
    // this.dataCreationEpahaf();
    this.dataCreationEpahaf=this.dataCreationEpahaf.bind(this) ;
    this.getResult = this.getResult.bind(this);
  }
getResult(){
  return this.memberdetailsPdfData;
}

setresult(result){
 this.memberdetailsPdfData = result;
 this.secureStorage.setSecureInfoInLocalStorage('memberdetailsPdfData',JSON.stringify(result));
if(result && result.length>0){
 this.secureStorage.setSecureInfoInLocalStorage('dataSaved',"true");
} else{
  this.secureStorage.setSecureInfoInLocalStorage('dataSaved',"false");
}

}
  setHeaderValue(headerVal) {
    this.headerValue = headerVal;
  }

  getHeaderValue() {
    return this.headerValue;
  }

  // login(userName, cred) {
  //   const headers = new HttpHeaders({'Authorization': this.props.wso_authorization});
  //   let payload = payloads.loginPayload.replace('USER_NAME', userName);
  //   payload = payload.replace('PASSWORD', function() {return cred;});
  //   return this.http.post(urls.loginServiceUrl, payload, {headers: headers,responseType:'text'});
  // }
  // login2(userName, cred): Promise<any> {
  //   const headers = new HttpHeaders({'Authorization': this.props.wso_authorization});
  //   let payload = payloads.loginPayload.replace('USER_NAME', userName);
  //   payload = payload.replace('PASSWORD', function() {return cred;});
  //   return this.http.post(urls.loginServiceUrl, payload, {headers: headers,responseType:'text'}).toPromise();
  // }
  resendConfirmationCode( username: string, callBack: ( sucess: boolean, message: string ) => void ) {
      const resendConfirmPayload = {
              user:
              {
                  username: username,
                  realm: "PRIMARY"
              },
              properties: []
          };

     // resendConfirmPayload['username'] = username;
     const token: any = this.secureStorage.getSecureInfoInLocalStorage('clientCredentials_token');
     const headers = new HttpHeaders({
         'Accept': 'application/json',
         'Authorization': 'Bearer ' + token
     });
     
     this.http.post( urls.resendConfirmUrl, resendConfirmPayload, {headers: headers,observe:'response'})

          .subscribe(
          ( resp ) => {
              if ( resp.status === 201 ) {
                  callBack( true, '' );
              } else {
                  callBack( false, '' );
              }
              return;
          },
          err => {
              callBack( false, err );
              return;
          }
          );
  }

  getNews() {
    //const headers = new HttpHeaders({'Accept': 'application/json;charset=UTF-8'});
    // const token: any = this.secureStorage.getSecureInfoInLocalStorage('clientCredentials_token');
    
    const headers = new HttpHeaders({
      'Accept': 'application/json;charset=UTF-8',
      // 'Authorization': 'Bearer ' + token,
    });

    return this.http.get( urls.getNews, { headers: headers } );
}

  getDocument(  ): Observable<any> {
    const payload = {
        'appId': 'PP',
        'documentCode': 'USER_AGREEMENT_DOC',
        'keyValuePairList':  [{'key':'FunctionType', 'value':'ProviderPortal-UserAgreement'}, {'key':'UserType', 'value': "All"}]
      };
      const headers = new HttpHeaders({});
    
      return this.http.post( urls.getUserAgreementDoc, payload, { headers: headers } );
}

updateLockedUerStatus( statusPayload, callBack: ( sucess: boolean, message: string ) => void ) {
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});

  this.http.put( urls.updateLockedUerStatusUrl, statusPayload, { headers: headers,responseType:'text' } )
  .subscribe(
            ( resp ) => {
                callBack( true, resp );
            }
    );
}

updateUerLoginTime( loginTimePayload, callBack: ( sucess: boolean, message: string ) => void ) {

  let headers = new HttpHeaders();
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        // const cupjwt=JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const cupjwt=JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        if(ppjwt){
          
          headers = new HttpHeaders({'Accept': 'application/json',
          'Authorization': 'Bearer ' +  ppjwt.access_token
        });
        }
        if(cupjwt){
          headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' +  cupjwt.access_token
          })
        }
    this.http.put( urls.updateDataUrl, loginTimePayload, { headers: headers ,responseType:'text'} )
    .subscribe(
            ( resp ) => {
              
                callBack( true, resp.toString() );
            }
    );
    
}
getUserCompleteStatus(): Observable<any> {
  
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
  const headers = new HttpHeaders({
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + ppjwt.access_token
  });
  let myParams = new HttpParams()
  .append('memberId', '');
   return this.http.get(urls.getEpahafStatus, { headers: headers, params: myParams }).pipe(catchError(err => of(err.status)));
  //  .subscribe((eachStatus:any)=>{

  //   let responseData = JSON.parse(eachStatus._body);
  //   this.result=(responseData.responseDatasArray);
    
  // });
  
 }
 dataCreationEpahaf2(userName):Observable<any>{
  // let userName = this.secureStorage.getSecureInfoInSessionStorage('currentUser') && JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'))?JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser')).userName: '';
  if(userName){
    try{
   return forkJoin([this.getUserCompleteStatus(),this.getMemberdatas(userName)]).pipe(map((status)=>{
    let temp =[];
    if(status && status.length){
      let responseData = status[0];
       let memberArray=(responseData.responseDatasArray);
       if (status[1].status === 200) {
        let responseData: any;
        responseData = status[1];
        if (responseData.length > 0) {
          responseData.map((element, index) => {
            let obj: any = {};
            obj.userName = element.userName;
            obj.memberHmoID = element.memberHmoID;
            obj.memberFirstName = element.member_NAME;
            // obj.memberLastName = element.memberLastName;
            obj.memberDOB = this.convertDateFormat(element.memberDOB);
            obj.pcpName = element.pcpName;
            obj.memberId = element.memberUid;
            obj.memberStatusCount = element.memberStatusCount;
            obj.memberContactNumber = element.memberContactNumber;
            //obj.memnerUid = element.member_ALTERNATE_ID_UID;
            obj.index = index;
            memberArray.forEach((eachMemberId) => {
              if (eachMemberId.memberId === element.memberHmoID) {
                obj.status = eachMemberId.status;
              }
            });
            if (obj.status) {
              temp.push(obj);
            }
            //  temp.push(obj);
          });
          
            // callBack( true, 'success' );
            this.setresult(temp);
          //   Object.assign(this.memberdetailsPdfData,temp)
          //  this.result.next({data:this.memberdetailsPdfData,status:200});
          
          
          
        } else {
           this.result.next({data:['no data found'],status:500});
          this.memberdetailsPdfData = ["No Data found "];
          // callBack( true, 'no data' );
        }
      }

    }

  }))
}catch(e){
  // callBack( false, 'error' );
}
  }
  
}
dataCreationEpahaf(userName){
  // let userName = this.secureStorage.getSecureInfoInSessionStorage('currentUser') && JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'))?JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser')).userName: '';
  if(userName){
    try{
 return  forkJoin([this.getMemberdatas(userName)]).pipe(map((status)=>{
    let temp =[];
    if(status && status.length){
       if (status[0].status === 200) {
        let responseData: any;
        responseData = status[0];
        if (responseData.length > 0) {
          responseData.map((element, index) => {
            let obj: any = {};
            obj.userName = element.userName;
            obj.memberHmoID = element.memberHmoID;
            obj.memberFirstName = element.member_NAME;
            // obj.memberLastName = element.memberLastName;
            obj.memberDOB = this.convertDateFormat(element.memberDOB);
            obj.pcpName = element.pcpName;
            obj.memberId = element.memberUid;
            obj.memberStatusCount = element.memberStatusCount;
            obj.memberContactNumber = element.memberContactNumber;
            //obj.memnerUid = element.member_ALTERNATE_ID_UID;
            obj.index = index;
            obj.status = element.status;
             
            if (obj.status) {
              temp.push(obj);
            }
            //  temp.push(obj);
          });
          
            // callBack( true, 'success' );
            this.setresult(temp);
          //   Object.assign(this.memberdetailsPdfData,temp)
          //  this.result.next({data:this.memberdetailsPdfData,status:200});
          
          
          
        } else {
           this.result.next({data:['no data found'],status:500});
          this.memberdetailsPdfData = ["No Data found "];
          this.setresult([]);
          // callBack( true, 'no data' );
        }
      }

    }

  }))
}catch(e){
  this.setresult([]);


  // callBack( false, 'error' );
}
  }
  
}
 getMemberdatas(name): Observable<any> {
  
  // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
  const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
    const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});

  
  let myParams = new HttpParams()
  .append('userName', name);
  //myParams.append('datelimit', date);
  return this.http.get(urls.getMemberPCP, { headers: headers, params: myParams }).pipe(catchError(err => of(err.status)));
}

public convertDateFormat(str) {
  try {
    if (str) {
      var month = str.slice(5, 7);
      var date = str.slice(8, 10);
      var year = str.slice(0, 4);
      var totalDate = month + "/" + date + "/" + year;
      return totalDate;
    } else {
      return "";
    }
  } catch (err) {
    throw err;
  }
}

}
