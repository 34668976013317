<app-common></app-common>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
<div class="container" style="width: 100%">
	<div class="row" style="background-image: url('assets/images/caremore-hq.jpg');">
		<div class="col-md-6" style="padding: 0px">
			<div class="left-content" style="margin-bottom: 0em;">
				<div style="display: table;  width : 100%">
					<div style="display: table-cell; vertical-align: middle" *ngFor="let new of news">
						<b>
							<p [innerHTML]="new.messageName"></p>
						</b>
						<br>
						<p [innerHTML]="new.message"></p>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-6" style="padding: 0px">
			<div class="limiter" style="background-color: #005B9C;">
				<div class="container-login100" style="background-color: #005B9C;">
					<div class="wrap-signup">
						<span class="login100-form-title p-b-26">
							<a href="https://www.caremore.com/" target="mynewwindow"><img src="assets/images/logo_new.png" width="200px"
								 height="65px" /></a>
						</span>
						<div class="wrap-signup100">
							<div class="login100-form validate-form" *ngIf="showConfirm">
								<fieldset data-section="thankyou" *ngIf="!isConfirmValid">
									<div *ngIf="!mailSent">
										<span class="login100-form-title p-b-48">
											<h3>Registration Confirmation</h3>
										</span>
										<div class="validate-input m-b-37" style="text-align: center;">
											<span class="txt2">Your account failed to be activated, please try again or contact Admin or click on below
												link to re-send the confirmation email.
												<br>
												<button type="button" class="btn btn-link" style="font-family: Myriad Pro;" (click)="resend() ">
													<a> Resend confirmation link </a>
												</button>
											</span>
										</div>
									</div>
									<div *ngIf="mailSent">
										<span class="login100-form-title p-b-48">
											<h3>Registration Confirmation</h3>
										</span>
										<div class="validate-input m-b-37" style="text-align: center;">
											<span class="txt2">Registration successful. Please wait for Provider-Relations team to activate your account.
											</span>
										</div>
									</div>
								</fieldset>
								<fieldset data-section="thankyou" *ngIf="isConfirmValid">
									<span class="login100-form-title p-b-48">
										<h3>Registration Confirmation</h3>
									</span>
									<div class="validate-input m-b-37" style="text-align: center;">
										<span class="txt2">Your account successfully activated, please <a [routerLink]="['/login']"> Login </a> here.
										</span>
									</div>
								</fieldset>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="modal fade" id="myModal" role="dialog">
							<div class="modal-dialog modal-sm" style="margin-top: 20%;">
								<div class="modal-content">
									<div class="modal-header" style="border-bottom: 0px;">
										<button type="button" class="close" data-dismiss="modal">&times;</button>
									</div>
									<div class="modal-body">
										<p>{{ errorMessage }}</p>
									</div>
									<div class="modal-footer" style="border-top: 0px;">
										<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>