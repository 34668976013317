<div class="row grid-row">
    <div class="col-md-16">
        <div class="tab" role="tabpanel">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs cup-detail-header grid-tabs" role="tablist" *ngIf="(!hideHeaderTab && !hideTabAlone)">
                <li role="presentation" class="active" *ngIf="!viewAuthLock && !authStatusConfig && !addAuthNote && !supplementalSectionConfig && !isAttachmentGrid && !viewAccessTemplate && !viewUserAccess && !internalExternalUserAccess && !providerAccessConfig && !isAuthAuditGrid">
                    <a href="#Section1" aria-controls="home" role="tab" data-toggle="tab">{{gridTitle || 'SEARCH
                        RESULT'}}</a>
                </li>
            </ul>
            <!-- Termed Members drop down START -->
            <div class="show-termed-member" *ngIf="showMemberActive" [ngClass]="{'access-denied':memberTypesList[1].value}">
                <form [formGroup]="termedMemberForm" novalidate autocomplete="off">
                    <label for="memberTypes">Show</label>
                    <cup-autocomplete class="form-control input-underline termed-member" [colWidth]="3"
                        [autoCompleteDynamic]="false" formControlName="memberTypeFormControl" [autoCompletePlaceholder]="'Select Member Type'"
                        [control]="termedMemberForm.controls.memberTypeFormControl" [autoCompleteOptions]="memberTypesList"
                        [autoCompleteModel]="termedMemberForm.controls.memberTypeFormControl?.value?.value"
                        (autoCompleteModelChange)="memberTypeChange($event)" [isDisabled]="memberType==memberTypesList[1].value ? true : false "></cup-autocomplete>
                </form>
            </div>

            <!-- onAddNewNote start -->
            <div *ngIf="isAddViewNote">
                <div class="add-new-note">
                    <button type="button" class="btn btn-primary" (click)="addMemberNotesClicked()">
                        <span class="glyphicon glyphicon-save-file"></span> Add New Note</button>
                </div>
                <div class="add-new-note">
                    <button type="button" class="btn btn-primary access-disabled">
                        <span class="glyphicon glyphicon-save-file"></span> Add New Note</button>
                </div>
            </div>
            <!-- Termed Members drop down END -->
            <!-- Tab panes -->
            <div class="tab-content" [ngClass]="{'grid-for-pcp': isViewEligibilityHist, 'pending-worklist-total': isPendingWorklist }">
                <!-- <div class="addnewNote" *ngIf="isAddViewNote">
                    <label>Note: </label>
                        
                </div> -->
                <div class="row" *ngIf="isAddViewNote">
                    <div class="col-md-16">
                        <p class="notes-label cup-disclaimer">NOTE:&nbsp;&nbsp;Information relayed directly from the
                            member or other undocumented sources should be used with caution until verified.</p>
                    </div>
                </div>

                <!-----Grid column changes---->

                <div role="tabpanel" class="tab-pane active" id="Section1">
                    <p-table class="carelon-table" [ngClass]="{'claim-details': isViewClaims , 'healthplan':isViewEligibilityHist || isAttachmentGrid ,'pcp':hideHeaderTab, 'general-view-auth':generalViewAuth,'member-notes':isAddViewNote,'add-auth-note':addAuthNote,
                    'pending-worklist':showAgingRedColor,'admin-section-config': authStatusConfig || supplementalSectionConfig || viewAuthLock, 'attachment-grid': isAttachmentGrid, 'external-provider': providerAccessExternalGrid}"
                        #dt [value]="gridRows" [rows]="rowPerPage" [paginator]="supplementalDataListConfig || isAttachmentGrid || internalExternalUserAccess || deniedReasonSearch || authMessageScreen || isLetterErrorList ? false : true"
                        (onPage)="paginate($event)" [pageLinks]="3" [lazy]="isLazyLoad" [totalRecords]="totalRecords"
                        (onLazyLoad)="loadGridRecords_Lazy($event)" [scrollable]="true" [selectionMode]="selectionType"
                        [(selection)]="selectedUser" (onRowSelect)="performNavigation($event)"  (onRowUnselect)="onRowUnselect($event)" [loading]="loading"
                        loadingIcon="fa-spinner" 
                        [sortField]="sortField" [columns]="gridColumns" (onFilter)="onFilterTable($event)" 
                        [sortOrder]="sortOrder" (onSort)="onSort($event)" [loadingIcon]="spinnerClass"
                        (onRowCollapse)="onRowCollapse($event)" (onRowExpand)="onRowExpand($event)" [dataKey]="dataKey" (onHeaderCheckboxToggle)="onTableHeaderCheckboxToggle($event)"> <!---newly added from sortField--->
                         <!-- [emptyMessage]="loading ? 'Please wait until the data is retrieved' : 'No Records Found'"
                        [rowGroupMode]="rowGroupMode" [groupField]="rowGroupField" [expandableRowGroups]="expandableRowGroups"
                        [sortableRowGroup]="false" [rowStyleClass]="rowStyleClass" > -->
                        <ng-template *ngIf="isLetterErrorList" pTemplate="rowgroupheader" let-rowData>
                            <label>Authorization No</label> <label class="rounded-color"> {{rowData[rowGroupField]}}</label>
                            <label class="row-group-retry-icon">Retry All &nbsp;<label class="fa fa-refresh"></label>
                            </label>
                        </ng-template>

 
                        <!-- Header Template -->
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th style="width : 15px" *ngIf="(showEditHeader || authStatusConfig || supplementalSectionConfig ||supplementalDataConfig || isAttachmentGrid || viewAccessTemplate || viewUserAccess || providerAccessConfig) && (!supplementalDataListConfig)"></th>
                                <th [style.width.px]="(viewUserAccess) ? 46 : 50" style="word-wrap: break-word" *ngIf="(showEditHeader || addAuthNote || authStatusConfig || supplementalSectionConfig || supplementalDataConfig || showViewEditHeader || viewAccessTemplate 
                                || viewUserAccess || providerAccessConfig) && (!supplementalDataListConfig) && (!internalExternalUserAccess)">
                                {{(showViewEditHeader || viewUserAccess || providerAccessConfig || authStatusConfig || showEditHeader ? 'View/Edit' :'Edit')}}
                            </th>
                            <th style="width : 50px" *ngIf="supplementalDataListConfig && internalExternalUserAccess">Edit</th>
                            <th style="width : 80px" *ngIf=" authStatusConfig ||supplementalSectionConfig || supplementalDataConfig || supplementalDataListConfig">Lock / Delete </th>
                            <th style="width : 50px" *ngIf="providerAccessConfig || viewAccessTemplate"> Delete </th>
                            <th style="width : 50px" *ngIf="isDeleteColumnPresent"> Delete </th>
                            <th header="Delete" style="width : 80px" *ngIf="isAttachmentGrid && showAttachmentDelete"> Delete </th>
                            <th header="Download" [style.width.px]="isAuthLetterGrid ? 60 : 80" *ngIf="isAttachmentGrid || isAttachmentWithServerSidePagniation">Download</th>
                            <th header="Preview" [style.width.px]="isAuthLetterGrid ? 60 : 80" *ngIf="isAttachmentGrid || isAttachmentWithServerSidePagniation">Preview</th>
                            <th style="width : 50px" *ngIf="showPrintIcon"> {{headerTextPrintIcon}} </th>
                            <th style="width : 30px; text-align: center;" *ngIf="showEditableCheckbox"> {{headerTextEditableCheckbox}} <p-tableHeaderCheckbox *ngIf="selectionType == 'multiple'"></p-tableHeaderCheckbox></th>
                            <th style="width : 75px; text-align: right" *ngIf="adhocLetter">Letter Type</th>
                            <ng-container *ngFor="let col of columns">
                                <th [ngStyle]="col.style" [pSortableColumn]="col.sortable? (col.sortField? col.sortField: col.field): null" [pSortableColumnDisabled]="col.sortable? false: true">                                
                                    <span>
                                        <span>{{col.header}}</span>
                                        <span *ngIf="col.sortable" 
                                        class="cup-sortable-column-icon fa fa-fw fa-sort" 
                                        [ngClass]="{'fa-sort-asc': this.dataTable.sortField == (col.sortField || col.field) && this.dataTable.sortOrder == 1, 
                                        'fa-sort-desc': this.dataTable.sortField == (col.sortField || col.field) && this.dataTable.sortOrder == -1}">
                                        </span> 
                                    </span>
                                </th>
                            </ng-container>
                            <th style="width : 50px" *ngIf="isLetterErrorList">Retry</th> 
                            <th style="width : 50px" *ngIf="isViewClaims">Line level Details</th>
                            <th style="width : 50px" *ngIf="showMapViewHeader && !showProviderMap">MAP</th>
                            <th style="width : 70px; text-align: center" *ngIf="pendingAuthorizations || viewDeniedAuth">Locked</th>
                            <th style="width : 70px; text-align: center" *ngIf="pendingAuthorizations">Attachment</th>
                            <th style="width : 100px; text-align: center" *ngIf="viewAuthLock">Remove Lock</th>
                            <th style="width : 50px;" *ngIf="(internalExternalUserAccess && !deniedReasonSearch) || providerAccessExternalGrid">Select</th>                          
                            </tr>
                        </ng-template>

                        <!-- Body template for column rendering -->
                        <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex" let-expanded="expanded">
                            <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="i" [ngClass]="isPendingWorklist || isMedicalWorklist  ? checkFlaggedQueues(rowData) : rowStyleClass(rowData)" class="abc"> <!--check for this row data---->
                                <td style="width : 15px" *ngIf="(showEditHeader || authStatusConfig || supplementalSectionConfig ||supplementalDataConfig || isAttachmentGrid || viewAccessTemplate || viewUserAccess || providerAccessConfig) && (!supplementalDataListConfig)"></td>

                                <td [style.width.px]="(viewUserAccess) ? 46 : 50" style="word-wrap: break-word" *ngIf="(showEditHeader || addAuthNote || authStatusConfig || supplementalSectionConfig || supplementalDataConfig || showViewEditHeader || viewAccessTemplate 
                                || viewUserAccess || providerAccessConfig) && (!supplementalDataListConfig) && (!internalExternalUserAccess)">
                                <span class="glyphicon glyphicon-edit pointer" [ngClass]="{'edit-icon': authStatusConfig || supplementalSectionConfig || supplementalDataConfig || providerAccessConfig, 'access-denied': denyEditMemberNotesAccess && cupUserAuthorizationService.memberNotesGridAccess.isAccessDenied,
                                'no-edit-access': (isAddViewNote && !rowData.isEditable) || (authStatusConfig && rowData.isLocked)}"
                                    (click)="editClicked(rowData,i)"></span>
                                </td>
                                <td style="width : 50px" *ngIf="supplementalDataListConfig && internalExternalUserAccess"> 
                                    <span class="glyphicon glyphicon-edit pointer" [ngClass]="{'edit-icon':supplementalDataListConfig}" (click)="editClickedDataList(rowData,i)"></span>
                                </td>
                                <td style="width : 80px" *ngIf=" authStatusConfig ||supplementalSectionConfig || supplementalDataConfig || supplementalDataListConfig">
                                    <span *ngIf="rowData.isLocked" class="fa fa-lock locked"></span>
                                    <span *ngIf="!rowData.isLocked" [ngClass]="{'locked': rowData.isActive == 0 && supplementalSectionConfig}">
                                        <span *ngIf="!rowData.isLocked" class="fa fa-trash-o delete-icon" [ngClass]="{'disable': rowData.isActive == 0 && supplementalSectionConfig}" (click)="deleteClicked(rowData, i)"></span>
                                    </span>
                                </td>
                                <td style="width : 50px" *ngIf="providerAccessConfig || viewAccessTemplate">
                                    <span class="fa fa-trash-o delete-icon" (click)="deleteRecord(rowData,i)"></span>
                                </td>
                                <td style="width : 50px" *ngIf="isDeleteColumnPresent">
                                    <span class="fa fa-trash-o delete-icon" (click)="deleteRecord(rowData,i)"></span>
                                </td>

                                <td style="width : 80px" *ngIf="isAttachmentGrid && showAttachmentDelete">
                                    <span *ngIf="!isMemberAttachments" [ngClass]="{'access-denied': rowData.authDeleteDenied}">
                                        <span class="fa fa-trash-o delete-icon" [ngClass]="{'no-edit-access': rowData.authDeleteDenied}" (click)="deleteAttachment(rowData)"></span>
                                    </span>
                                    <span *ngIf="isMemberAttachments" [ngClass]="{'access-denied': rowData.memberDeleteDenied}">
                                        <span class="fa fa-trash-o delete-icon" [ngClass]="{'no-edit-access': rowData.memberDeleteDenied}" (click)="deleteAttachment(rowData)"></span>
                                    </span>
                                </td>
                                <td [style.width.px]="isAuthLetterGrid  ? 60 : 80" *ngIf="isAttachmentGrid || isAttachmentWithServerSidePagniation">
                                    <span *ngIf="!isMemberAttachments && !isAuthLetterGrid" [ngClass]="{'access-denied': rowData.authDownloadPreviewDenied}">
                                        <span class="fa fa-download delete-icon" [ngClass]="{'no-edit-access': rowData.authDownloadPreviewDenied}"
                                            (click)="downloadAttachment(rowData)"></span>
                                    </span>
                                    <span *ngIf="isMemberAttachments" [ngClass]="{'access-denied': rowData.memberDownloadPreviewDenied}">
                                        <span class="fa fa-download delete-icon" [ngClass]="{'no-edit-access': rowData.memberDownloadPreviewDenied}"
                                            (click)="downloadAttachment(rowData)"></span>
                                    </span>
                                    <span *ngIf="isAuthLetterGrid">
                                        <span class="fa fa-download delete-icon" [ngClass]="{'disable': !rowData.fileArchivalDocumentId }"
                                            (click)="downloadAttachment(rowData)"></span>
                                    </span>
                                </td>
                                <td [style.width.px]="isAuthLetterGrid ? 60 : 80" *ngIf="isAttachmentGrid || isAttachmentWithServerSidePagniation">
                                    <span *ngIf="!isMemberAttachments && !isAuthLetterGrid" [ngClass]="{'access-denied': rowData.authDownloadPreviewDenied}">
                                        <span class="fa fa-search-plus delete-icon" [ngClass]="{'no-edit-access': rowData.authDownloadPreviewDenied}"
                                            (click)="previewAttachment(rowData)"></span>
                                    </span>
                                    <span *ngIf="isMemberAttachments" [ngClass]="{'access-denied': rowData.memberDownloadPreviewDenied}">
                                        <span class="fa fa-search-plus delete-icon" [ngClass]="{'no-edit-access': rowData.memberDownloadPreviewDenied}"
                                            (click)="previewAttachment(rowData)"></span>
                                    </span>
                                    <span *ngIf="isAuthLetterGrid">
                                        <span class="fa fa-search-plus delete-icon" [ngClass]="{'disable': !rowData.fileArchivalDocumentId}"
                                            (click)="previewAttachment(rowData)"></span>
                                    </span>
                                </td>
                                <td style="width : 50px" *ngIf="showPrintIcon">
                                    <div class="checkbox">
                                        <span class="fa fa-print" (click)="printClickedDataList(rowData)"></span>
                                    </div> 
                                </td>  
                                <td style="width : 30px" *ngIf="showEditableCheckbox">
                                    <div class="checkbox">
                                        <ng-container *ngIf="considerRowClickForCheckBox">
                                            <input type="checkbox" id="{{ 'checkbox' + i}}" class="appreance-none form-control input-underline"
                                                placeholder="Active" width="1em" height="1em" name="checkbox" [checked]="(rowData.checked === true )"
                                                (change)="rowData.checked = $event.target.checked;" />
                                            <label for="{{ 'checkbox' + i}}" id="{{ 'label_checkbox' + i}}"> </label>
                                        </ng-container>
                                        <ng-container *ngIf="!considerRowClickForCheckBox">
                                            <input type="checkbox" id="{{ 'checkbox_new' + i}}" class="appreance-none form-control input-underline"
                                                placeholder="Active" width="1em" height="1em" name="checkbox_new" [checked]="(rowData.checked === true )"
                                                (change)="rowData.checked = $event.target.checked;" />
                                            <label for="{{ 'checkbox_new' + i}}" id="{{ 'label_checkbox_new' + i}}">
                                            </label>
    
                                        </ng-container>
                                    </div>
                                </td>
                                <td style="width : 75px; text-align: right" *ngIf="adhocLetter">
                                    <span *ngIf="rowData.isAdhoc">Adhoc Letter</span>
                                <span *ngIf="!rowData.isAdhoc">Auto Letter</span>
                                </td>
                                <ng-container *ngFor="let col of columns; index as colIndex">
                                    <td *ngIf="col.fieldType == 'diagnosisCode'" [ngStyle]="col.style">
                                        <span title="{{rowData[col.field]}}" class="hoverMsgText">{{rowData[col.field]}}</span>
                                    </td>
                                    <td *ngIf="col.fieldType == 'checkbox'" [ngStyle]="col.style">
                                        <div class="checkbox">
                                            <input type="checkbox" id="{{ 'checkbox_' + col.field + colIndex + rowIndex }}"
                                                class="appreance-none form-control input-underline" placeholder="Active" width="1em"
                                                height="1em" [checked]="(rowData[col.field] === true )" (change)="genericCheckBoxStatusChanged($event,rowData,col)"
                                                [disabled]="rowData[col.disable] === true" />
                                            <label for="{{ 'checkbox_' + col.field + colIndex + rowIndex }}" id="{{ 'label_checkbox_' + col.field + colIndex + rowIndex }}"></label>
                                        </div>
                                    </td>
                                    <td *ngIf="col.fieldType == 'inboxTo'" [ngStyle]="col.style" class="unreadInbox">
                                        <p-menubar [model]="[{label:rowData[col.field]?.substring(0,10),items:[{label:rowData[col.field]}]}]" class="pm">
                                        </p-menubar>
                                    </td>
                                    <td [ngStyle]="col.style" *ngIf="!col.fieldType && !generalViewAuth && !isAuthLetterGrid && !viewAuthLock">
                                    <span class="fa fa-eye provider-access-icon" *ngIf="providerAccessConfig && col.field=='view'"
                                    (click)="providerSiteClick(rowData)"></span>
                                    <span [attr.title]="addAuthNote ? rowData[col.field] : null" [ngClass]="{'pending-auth-aging': (pendingAuthorizations && row[col.field]>=12 && col.field == 'aging') ||
                                     (showAgingRedColor && rowData[col.field]>=12 && col.field == 'aging'),
                                     'pending-auth-center':(showAgingRedColor && rowData[col.field]>=12 && col.field == 'aging')}">
                                    <span *ngIf="rowData.attchmentFiles&&col.field=='Icon'"> <i class="fa fa-fw fa-paperclip"></i></span>
                                    {{rowData[col.field] | customFormat:col.field }}</span>
                                    </td>
                                    <td [ngStyle]="col.style" *ngIf="viewAuthLock">
                                        <span>{{rowData[col.field] | customDateTimeFormat:col.field }}</span>
                                    </td>
                                    <td [ngStyle]="col.style" *ngIf="generalViewAuth">
                                        <span [ngClass]="{'general-view-auth-pended':generalViewAuth && rowData[col.field] =='Pended' && col.field == 'authstatus',
                                                'general-view-auth-approved':generalViewAuth && rowData[col.field]=='Approved' && col.field == 'authstatus', 
                                                'general-view-auth-denied':generalViewAuth && rowData[col.field]=='Denied' && col.field == 'authstatus',
                                                'general-view-auth-modified':generalViewAuth && rowData[col.field]=='Modified' && col.field == 'authstatus',
                                                'general-view-auth-cancelled':generalViewAuth && rowData[col.field]=='Cancelled' && col.field == 'authstatus'}">
                                    {{rowData[col.field] | customFormat:col.field }}</span>
                                    </td>
                                    <td [ngStyle]="col.style" *ngIf="!col.fieldType && isAuthLetterGrid">
                                        <span [ngClass]="{'letter-generation-inprogress':isAuthLetterGrid && rowData[col.field] =='LETTER_GENERATION_INITIATED' && col.field == 'documentStatus',
                                                'letter-generation-success-download':isAuthLetterGrid && (rowData[col.field]=='LETTER_ARCHIVAL_SUCCESS' || rowData[col.field] == 'LETTER_DELIVERED_TO_RECIPIENT') && col.field == 'documentStatus', 
                                                'letter-generation-failed':isAuthLetterGrid && (rowData[col.field]=='LETTER_GENERATION_FAILURE' || rowData[col.field]=='LETTER_ARCHIVAL_FAILURE') && col.field == 'documentStatus',
                                                'letter-generation-success':isAuthLetterGrid && rowData[col.field]=='LETTER_GENERATION_SUCCESS' && col.field == 'documentStatus' }">
                                    {{rowData[col.field] | customFormat:col.field }}</span>
                                    </td>
                                    <td [ngStyle]="col.style" *ngIf="protocolGrid">
                                        <span [ngClass]="{'protocol-red-color':protocolGrid && col.field == 'terminationDate' && cupDateService.compareTwoDates(cupDateService.getValidDateTime(currentDate), rowData[col.field] ? rowData[col.field] : null),
                                        'protocol-show-color':protocolGrid && col.field == 'terminationDate' && !cupDateService.compareTwoDates(cupDateService.getValidDateTime(currentDate), rowData[col.field] ? rowData[col.field] : null)}">
                                        {{rowData[col.field] | customFormat:col.field }}</span>
                                    </td>
                                </ng-container>
                                <td style="width : 50px" *ngIf="isLetterErrorList">
                                    <div class="row-retry">
                                    <label class="fa fa-refresh" (click)="retryClicked(rowData)"></label>
                                    </div>
                                </td>
                                <td style="width : 50px" *ngIf="isViewClaims">
                                    <a class="grid-eye-view-icon"><i class="fa fa-eye" aria-hidden="true"></i></a>
                                </td>
                                <td style="width : 50px" *ngIf="showMapViewHeader && !showProviderMap">
                                    <span class="glyphicon glyphicon glyphicon-map-marker map-view" (click)="mapViewClicked(rowData)"></span>
                                </td>

                                <td style="width : 70px; text-align: center" *ngIf="pendingAuthorizations || viewDeniedAuth">
                                    <span title="Locked: {{rowData.lockedUserName}}" *ngIf="rowData.isLocked && rowData.lockedUserName"
                                    class="fa fa-lock lock-auth"></span>
                                </td>
                                <td style="width : 70px; text-align: center" *ngIf="pendingAuthorizations">
                                    <span *ngIf="rowData.hasAttachment"><i class="fa fa-paperclip"></i></span>
                                </td>
                                <td style="width : 100px; text-align: center" *ngIf="viewAuthLock">
                                    <button type="button" class="btn btn-secondary auth-lock-btn" (click)="removeAuthLockClicked(rowData)">Remove
                                        Lock Now</button>
                                </td>
                                <td style="width : 50px;" *ngIf="(internalExternalUserAccess && !deniedReasonSearch) || providerAccessExternalGrid">
                                    <span class="select-text">Select</span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>

                </div>


            </div>
        </div>
    </div>
</div>