import { urlProps } from './urls';
import { payloadProps } from './payloads';
import { CONFIG } from '../../../config/index';
export let payloads = payloadProps;
export let urls = urlProps;

export let props = {
   
    
    cup_login_authorization: 'Basic ' + CONFIG.token,
    cup_login_JWT: 'Basic ' + CONFIG.cup_token,
    cupPortalHost: 'https://ca47dwvcup001/'
}
