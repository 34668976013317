
import { environment } from '../environments/environment';

import { DEV_CONFIG } from './dev.config';
import { PROD_CONFIG } from './prod.config';
import { TEST_CONFIG } from './test.config';
import { STAGE_CONFIG } from './stage.config';

export let CONFIG: any;

if (environment.production) {
    CONFIG = PROD_CONFIG;
} else if (environment.dev) {
    CONFIG = DEV_CONFIG;
} else if (environment.test) {
    CONFIG = TEST_CONFIG;
} else if (environment.stage) {
    CONFIG = STAGE_CONFIG;
}

