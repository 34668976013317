import { Component, OnInit } from '@angular/core';
import { ForgotpasswordService } from './forgotpassword.service';
import { ForgotpasswordquestionsService } from './../forgotpasswordquestions/forgotpasswordquestions.service';
import { HttpParams } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { SecureStorageService } from '../secure-storage.service';

declare var jQuery: any;

@Component({
     selector: 'app-forgotpassword',
     templateUrl: './forgotpassword.component.html',
     styleUrls: ['./forgotpassword.component.scss'],
     providers: [ForgotpasswordService, ForgotpasswordquestionsService]
})
export class ForgotpasswordComponent implements OnInit {
     loading = false;

     public model =
          {
               userName: ''
          };
     recoveryType = 'Email';
     errorMessage = '';
     news = [];
     sentEmail = false;
     types: any = [
          'Email',
          'SecurityQuestions'
     ];

     isValidUserName = true;

     constructor(
          private forgotService: ForgotpasswordService,
          private questionService: ForgotpasswordquestionsService,
          private router: Router,
          private secureStorage: SecureStorageService
     ) {

     }
     ngOnInit() {
          this.getNews();
     }

     radioChangeHandler(event: any) {
          this.recoveryType = event.target.value;
     }

     onInputFocus(event: any) {
          this.hideValidate(event.target.name);
     }

     hideValidate(inputName: string) {
          if (inputName === 'userName') {
               this.isValidUserName = true;
          }
     }
     onSubmit() {


          if (this.model.userName === '') {
               this.isValidUserName = false;
               return;
          }

          this.loading = true;
          this.forgotService.getInternalUserType(this.model.userName).subscribe((response: any) => {


               if (response === 'I') {
                    window.open('https://myprofile.antheminc.com');
                    this.loading = false;
               } else {

                    if (this.recoveryType === 'Email') {
                         this.forgotService.validateUserName(this.model.userName).subscribe(
                              (resp) => {
                                   if (resp === 1) {
                                        this.forgotService.requestRecoverPassword(this.model.userName).
                                             subscribe(
                                                  (resp : any) => {
                                                       if(resp.status === "SUCCESS_STATUS"){
                                                       this.sentEmail = true;
                                                       this.loading = false;
                                                       }
                                                       else{
                                                            this.errorMessage = this.model.userName + ' is not a valid User ';
                                                            jQuery('#myModal').modal('show');
                                                            this.loading = false;
                                                            return;
                                                       }
                                                  },
                                                  err => {
                                                       this.errorMessage = this.model.userName + ' is not a valid User ';
                                                       jQuery('#myModal').modal('show');
                                                       this.loading = false;
                                                       return;
                                                  }
                                             );
                                   }
                                   else {
                                        this.errorMessage = this.model.userName + ' is invalid. Please enter a valid Username ';
                                        jQuery('#myModal').modal('show');
                                        this.loading = false;
                                        return;
                                   }

                              }
                         );
                         /* this.forgotService.requestRecoverPassword(this.model.userName).
                              subscribe(
                                   (resp) => {
                                        this.sentEmail = true;
                                        this.loading = false;
                                   },
                                   err => {
                                        this.errorMessage = this.model.userName + ' is not a valid User ';
                                        jQuery('#myModal').modal('show');
                                        this.loading = false;
                                        return;
                                   }
                              ); */
                         const data = this.model.userName;
                    } else if (this.recoveryType === 'SecurityQuestions') {
                         // console.log("Forget password here");
                         this.forgotService.validateUserName(this.model.userName).subscribe(
                              (resp) => {
                                   //console.log(resp);
                                   if (resp === 1) {
                                        this.forgotService.getSecurityQuestions(this.model.userName).
                                             subscribe(
                                                  (resp) => {
                                                       // this.secureStorage.setSecureInfoInSessionlStorage('forgotPasswordUserName', this.model.userName);
                                                       this.showQuestionsPage(this.model.userName);
                                                       this.loading = false;
                                                  },
                                                  err => {
                                                       this.errorMessage = this.model.userName + ' is not a valid User ';
                                                       jQuery('#myModal').modal('show');
                                                       this.loading = false;
                                                       return;
                                                  }
                                             );
                                   }
                                   else {
                                        this.errorMessage = this.model.userName + ' is invalid. Please enter a valid Username ';
                                        jQuery('#myModal').modal('show');
                                        this.loading = false;
                                        return;
                                   }
                              }
                         );
                         /*  this.forgotService.getSecurityQuestions(this.model.userName).
                               subscribe(
                                    (resp) => {
                                         this.secureStorage.setSecureInfoInSessionlStorage('forgotPasswordUserName', this.model.userName);
                                         this.showQuestionsPage(this.model.userName);
                                         this.loading = false;
                                    },
                                    err => {
                                         this.errorMessage = this.model.userName + ' is not a valid User ';
                                         jQuery('#myModal').modal('show');
                                         this.loading = false;
                                         return;
                                    }
                               ); */
                         const data = this.model.userName;
                    } else {
                         this.errorMessage = 'Please select one of the Recovery Types';
                         jQuery('#myModal').modal('show');
                         return;
                    }
               }



          })





     }
     showQuestionsPage(userName): void {
          this.questionService.updateUserForQuestions(userName);
          this.router.navigate(['./forgotpasswordquestions', { userName: userName }]);
     }

     showLoginPage(userName): void {
          this.router.navigate(['./login']);
     }
     getNews() {
          this.loading = true;
          this.forgotService.getNews((success: boolean, news: any) => {
               if (success) {
                    this.news = news['providerPortalBroadcast'];
                    this.loading = false;

               } else {
                    this.loading = false;
               }
          });

     }
}
