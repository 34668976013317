<div class="top-panel">
	<div class="container-fluid top-panel bg-white">
		<div id="ucHeader_pnlBanner" class="row banner">

			<div class="col-9 col-sm-12">
				<div class="row align-items-center h-100 pl-3 pl-sm-0">
					<div class="col-12 col-sm-4 col-md-2 py-0 text-md-center">
						<img title="Logo" class="img img-fluid banner-logo" src="assets/images/Logo-carelon.svg"
							alt="Logo">
					</div>

				</div>
			</div>
		</div>
	</div>
</div>

<div class=" signinContainer">
	<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>

	<div class="row" >
		<div class="col-md-8 col-lg-8 p-t-16 ht-500">
			<div class="cover-img full-width"></div>
		</div>

		<div class="col-md-4 col-lg-4" style="padding: 16px">
			<mat-card class="sign-card-container ht-100%" >
				<div class="signin-label-header texfield-spacing">Upload Documents for Onboarding</div>

				<form action="#" class="validate-form frmForgotUsername" #appforgotpasswordform
					id="#forgotpasswordForm">

					<div class="text-left p-t-20">
						<label>Document Type</label>
						<select class="form-control input-underline " [(ngModel)]="selectedDocumentType"
							name="selectedDocumentType" (change)="changeFileType($event)">
							<option [ngValue]="undefined" hidden selected>Select Document Type</option>
							<option *ngFor="let item of documentType" [value]="item.name">{{item.name}}</option>
						</select>

					</div>
					<div class="text-left p-t-20">
						<span class="txt3"> Select one file to upload </span>
					</div>
					<div class="form-group noFileChoosen">
						<input type="file" name="profile" [disabled]="!isFileTypeSelected"
							(change)="onSelectedFile($event)" ng2FileSelect [uploader]="uploader" multiple />
					</div>

				</form>
				<div class="fileNames">
					<table class="fileSpacing">
						<tbody>
							<tr *ngFor="let item of fileList; let i = index;">
								<th class="fileNameAlignment col-sm-8 col-md-8">
									<span>{{item.name}}</span>
									<textarea style="resize: none;" placeholder="Add Notes" maxlength="500"
										[(ngModel)]="fileList[i].notes"></textarea>
								</th>
								<th class="col-sm-1 col-md-1 leftRightPad0" (click)="remove(item.name)">
									<button type="button" class="close" data-dismiss="alert" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</th>
								<th class="col-sm-3 col-md-3">
									<button type="button" class="btn buttonColor"
										(click)="upload(item.file, $event);">Upload</button>
								</th>
							</tr>
							<tr *ngFor="let item of uploadedDocument">
								<th class="fileNameAlignment col-sm-10">
									<span class="itemName" style="color:cornflowerblue;"
										(click)="getUploadedDocument(item.id)">{{item.file}}</span>
									<span class="fileNameAlignment" style="font-style: italic;">Document Type:
										{{item.type}}</span>
									<span class="fileNameAlignment" style="font-style: italic;">Status:
										{{item.status}}</span>
									<span class="notes" *ngIf="item.notes">Notes: {{item.notes}} </span>
									<p *ngIf="item.reason" style="color: orangered;">Rejection Reason: {{item.reason}}
									</p>
								</th>

								<th class="col-sm-2">
									<button type="button" class="close" (click)="showDeleteModal(item.id)"
										data-dismiss="alert" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</th>

							</tr>


						</tbody>
					</table>
				</div>
				<div>
					<ul>
						<li>
							<a class="linkName" (click)="onBoardingChecklist()"><u>Click here for checklist </u> </a>

						</li>
						<li>
							<a class="linkName" (click)="caqhFormDocument()"><u>Click here for CAQH manual</u></a>
						</li>
						<li>
							<a class="linkName" (click)="pecosFormDocument()"><u>Click here for PECOS step by step
									guide</u></a>
						</li>
					</ul>
				</div>
				<div class="texfield-spacing texfield-spacing-cont">
					Go back to? <span [routerLink]="''" class="hyper-link">Sign in</span>
				</div>
			</mat-card>
		</div>

	</div>
</div>

<div class="modal fade documentsPageModal" id="docErrorModal" role="dialog">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header" style="background-color:#5009b5">
				<button type="button" class="close" data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<p style="text-align:center;">No content in this document.</p>
			</div>
			<div class="modal-footer">
				<div class="container-contact100-form-btn">
					<button type="button" class="btn btn-primary buttonInfo" data-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
</div>
<!--ProgressBar popup starts here-->
<div class="modal fade documentsPageModal" id="progressBarModal" role="dialog">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header" style="background-color:#5009b5">
				<h4>Upload Document</h4>
			</div>
			<div class="modal-body">
				<div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': progressPercent + '%' }"></div>
				<p>{{uploadedFileName}}</p>
			</div>
			<div class="modal-footer">
				<div class="container-contact100-form-btn">
					<button type="button" class="btn btn-primary buttonInfo" data-dismiss="modal"
						(click)="clearProgressBar()">Ok</button>
				</div>
			</div>
		</div>
	</div>
</div>
<!--Progress bar popup ends here-->
<!--Delele popup starts here-->
<div class="modal fade documentsPageModal" id="deleteModal" role="dialog">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header" style="background-color:#5009b5">
				<button type="button" class="close" data-dismiss="modal">&times;</button>
				<h4>Delete Document</h4>
			</div>
			<div class="modal-body">
				<p>Do you really want to delete the file?</p>
			</div>
			<div class="modal-footer">
				<div class="container-contact100-form-btn">
					<button type="button" class="btn btn-primary buttonInfo" data-dismiss="modal"
						(click)="deleteUploadedDocument(fileId, providerName)">Yes</button>
					<button type="button" class="btn btn-primary buttonInfo" data-dismiss="modal">No</button>
				</div>
			</div>
		</div>
	</div>
</div>
<!--Delete popup ends here-->