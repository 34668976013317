import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { SecureStorageService } from '../secure-storage.service';

declare var jQuery: any;

@Component( {
    selector: 'app-common',
    templateUrl: './common.component.html'
} )
export class CommonComponent implements OnInit {

    constructor(private router: Router,
        private secureStorage:SecureStorageService ) { }
    userJson: any = '';

    ngOnInit() {
        // this.userJson = JSON.parse( this.secureStorage.getSecureInfoInSessionStorage( 'currentUser' ) );
        this.userJson = JSON.parse( this.secureStorage.getSecureInfoInLocalStorage( 'currentUser' ) );
        const isProviderPortal = this.router.url.includes('providerportal');
        const isSignUp = this.router.url.includes('sign-up');

        if (this.userJson && !isProviderPortal && !isSignUp
            ) {
            this.router.navigateByUrl('providerportal');
        } else if (!this.userJson && isProviderPortal) {
            this.router.navigateByUrl('login');
        }
    }

}
