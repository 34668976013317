import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Input1Model } from './input1.model';

declare var jQuery: any;

@Component( {
    selector: 'input1-component',
    templateUrl: './input1.component.html',
    styleUrls: ['./input1.component.scss'],
} )
export class Input1Component implements OnInit {
    @Input() model: Input1Model;
    @Input() dataSource: any;
    @Input() isMandatory = false;

    ngOnInit() {

    }

    onInputFocus( event: any ) {
        this.hideValidate();
    }

    hideValidate() {
        this.model.isInputValid = true;
    }

    onValueSelect(e){
        this.model.typeAheadSelect(e);
    }
  }
