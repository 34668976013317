<div class="searchResultsContainer" *ngIf="resultGridList.length>0">

  <ng-container *ngIf="( keywordsMetaData | filter : {keywordName:resultGridList}) as result1">
    
    <div *ngIf="( otherLinks | filter : {keywordName:resultGridList}) as result2">
      <div *ngIf="( documentRetrivalMap | filter : {keywordName:resultGridList}) as result3">
        <div *ngIf="( fileNetDocuments | filter : {docName:resultGridList}) as result4">
        <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
          <p class="noResult" *ngIf="result1.length==0 && result2.length==0 && result3.length==0 && result4.length == 0 && !loading;
         else reultsFound">There are no search results. Please search with other keywords like - <span>Home </span>
            etc..</p>
          <ng-template #reultsFound>
            <h4>Search Results:</h4>
            <p *ngFor="let keyword of result1">
              <a (click)="navigate(keyword.link)" class="mouseCursor">{{keyword.keywordName}}</a>
            </p>
            <p *ngFor="let keyword of result2">
              <a (click)="template(keyword.clickNumber);" class="mouseCursor" href="../CUPPortal/#/singlesignon"
                target="_new">{{keyword.keywordName}}</a>
            </p>
            <p *ngFor="let keyword of result3">
              <a (click)="documents(keyword.clickNumber);" class="mouseCursor">{{keyword.keywordName}}</a>
            <p *ngFor="let keyword of result4">
              <a (click)="getUserDocument(keyword.DocumentGUID, keyword.DocumentClass);" class="mouseCursor">{{keyword.docName}}</a>
            </p>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal fade memberModal" id="viewMemberClaimsModal" role="dialog">
  <div class="modal-dialog" style="margin-top: 15%;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <p class="text-center margin0">Please select a member in the following screen to View Member Claims</p>
      </div>
      <div class="modal-footer" style="justify-content: center">
        <div class="container-contact100-form-btn">
          <button type="button" class="btn btn-primary buttonInfo" (click)="modalClose()"
            data-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade memberModal" id="submitMemberAuthModal" role="dialog">
  <div class="modal-dialog" style="margin-top: 15%;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <p class="text-center margin0">Please select a member in the following screen to Submit New Auth or Referral</p>
      </div>
      <div class="modal-footer" style="justify-content: center">
        <div class="container-contact100-form-btn">
          <button type="button" class="btn btn-primary buttonInfo" (click)="modalClose()"
            data-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade documentsPageModal" id="errorModal" role="dialog">
	<div class="modal-dialog">
	  <div class="modal-content">
		<div class="modal-header" style="background-color:#5009b5">
		  <button type="button" class="close" data-dismiss="modal">&times;</button>
		</div>
		<div class="modal-body">
		  <p style="text-align:center;">{{userState}} users can not access the documents.</p>
		</div>
		<div class="modal-footer">
		  <div class="container-contact100-form-btn">
			<button type="button"class="btn btn-primary buttonInfo" data-dismiss="modal">Close</button>
		  </div>
		</div>
	  </div>
	</div>
  </div>
  