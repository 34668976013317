<div id="main-wrapper" style="margin-bottom: 40px;">
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
  <div class="container-fluid" style="padding: 0;">
    <app-header (found)="handleResults($event)"></app-header>
    <div class="row">
      <div class="main-container topSpace">
        <app-search-results [resultGridList]="data"></app-search-results>
      </div>
      <div class="frontDeskPage">
      <div class="col-md-12 menu-wrapper">
        <div class="menu-title">
          <h2>Manage Users</h2>
          <p>You can Add, Modify and give Permissions to your associates here.</p>
        </div>
        <div class="flex-container">
          <div class="card" (click)="addStaff()">
            <img src="assets/images/icons/quick-links/add_associate.svg">
            <p>Add Associate</p>
          </div>
          <a routerLink="/providerportal/site_Administration/view_authenticate">
            <div class="card">
              <img src="assets/images/icons/quick-links/view_auth.svg">
              <p>View/ Auth Associate</p>
            </div>
          </a>
          <a routerLink="/providerportal/site_Administration/attach_to_site">
            <div class="card">
              <img src="assets/images/icons/quick-links/attach_to_site.svg">
              <p>Attach to site</p>
            </div>
          </a>
          <!-- <div>
            <div class="row card" (click)="addStaff()">
              <div class="bodyTile">
                <p class="bodyTile">Add Associate</p>
              </div>
            </div>
          </div>
          <div>
            <a routerLink="/providerportal/site_Administration/view_authenticate">
              <div class="row card">
                <div class="bodyTile">
                  <p class="bodyTile"> View / Auth Associate</p>
                </div>
              </div>
            </a>
          </div>
          <div>
            <a routerLink="/providerportal/site_Administration/attach_to_site">
              <div class="row card">
                <div class="bodyTile">
                  <p class="bodyTile">Attach to site</p>
                </div>
              </div>
            </a>
          </div> -->
        </div>
      </div>
    </div>
    </div>


    <!--
    <div class="modal fade addAssociate" id="addStaffModal" role="dialog">
      <div class="modal-dialog modal-xl" style="margin-top: 80px;">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 0px;">
            <button type="button" class="close" data-dismiss="modal" (click)="resetForm()">&times;</button>
            <p class="headerText">Please fill out following information</p>
            <br>
            <div>
              <div class="modal-body">
    -->

    <div class="modal fade addAssociate"  id="addStaffModal" role="dialog">

      <div class="modal-dialog modal-xl">
        <div class="modal-content alertPopup">
          <div class="modal-header" style="border-bottom: 0px;">
            <div class="row">
              <div class="col-md-9">
                <div class="row">Please fill out following information</div>
              </div>
              <div class="col-md-3">
                <button type="button" class="close" data-dismiss="modal"><img
                    src="../../assets/images/Close.svg" alt="close"></button>
              </div>
            </div>
          </div>
    
          <div class="modal-body alertsBody">
            <div class="add-form-wrapper">
              <div class="row">
                <div class="col-md-5">
                  <input1-component [model]="inputComps.get('firstName')" required></input1-component>
                </div>
                <div class="col-md-2">
                  <input1-component [model]="inputComps.get('middleName')"></input1-component>
                </div>
                <div class="col-md-5">
                  <input1-component [model]="inputComps.get('lastName')" required></input1-component>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <input1-component [model]="inputComps.get('address1')"></input1-component>
                </div>
                <div class="col-md-4">
                  <input1-component [model]="inputComps.get('address2')"></input1-component>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <input1-component [model]="inputComps.get('city')" [dataSource]="cityDataSource"></input1-component>
                </div>
                <div class="col-md-3">
                  <input1-component [model]="inputComps.get('state')"></input1-component>
                </div>
                <div class="col-md-3">
                  <input1-component [model]="inputComps.get('zip')" [dataSource]="zipDataSource"></input1-component>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <input1-component [model]="inputComps.get('phone')"></input1-component>
                </div>
                <div class="col-md-3">
                  <input1-component [model]="inputComps.get('extn')"></input1-component>
                </div>
                <div class="col-md-4">
                  <input1-component [model]="inputComps.get('fax')"></input1-component>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <input1-component [model]="inputComps.get('registrationType')"></input1-component>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <input1-component [model]="inputComps.get('npi')"></input1-component>
                </div>
                <div class="col-md-6">
                  <input1-component [model]="inputComps.get('taxId')"></input1-component>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <input1-component [model]="inputComps.get('email')"></input1-component>
                </div>
              </div>
              <div class="row col-md-12 text-right btn-wrapper">
                <button type="button" data-dismiss="modal" (click)="resetForm()"
                  class="btn carelon-secondary-btn">Close</button>
                <button type="submit" class="btn btn-primary carelon-primary-btn"
                  (click)="validateNpiTaxID()">Submit</button>
              </div>
          </div>
        </div>
    </div>
  </div>
</div>
    
    <div class="modal fade" id="myModal" role="dialog">
      <div class="modal-dialog" style="margin-top: 15%;">
        <div class="modal-content Popup">
          <div class="modal-header">
            <div class="row">
									<div class="col-md-9">

									</div>
									<div class="col-md-3">
										<button type="button" class="close"
											data-dismiss="modal"><img src="../../assets/images/Close.svg"
												alt="close"></button>
									</div>
							</div>
          </div>
          <div class="modal-body">
            <p class="text">{{ errorMessage }}</p>
          </div>
          <div class="modal-footer">
            <div class="col-md-12 pl-0" align="center">
              <button type="button" data-dismiss="modal" (click)="myModalEventCallBack()"
                class="btn carelon-primary-btn">Close</button>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="modal fade" id="uspsModel" role="dialog">
      <div class="modal-dialog modal-md" style="margin-top: 15%;">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <div class="row"
              style="padding-right: 40px; font-family: Myriad Pro; font-size: 20px; padding-left: 28%; font-weight: bold;">
              ADDRESS VERIFICATION</div>
          </div>
          <div class="modal-body" style="padding-right: 34px; padding-left: 72px;">

            <div class="col-sm-6" *ngIf="model">
              <div class="row" style="font-family: Myriad Pro; font-size: 15px; font-weight: bold;">YOU ENTERED</div>
              <div class="row" style="font-family: Myriad Pro; font-size: 12px;">{{inputComps.get('address1').value}}
              </div>
              <div class="row" style="font-family: Myriad Pro; font-size: 12px;">{{inputComps.get('address2').value}}
              </div>
              <div class="row" style="font-family: Myriad Pro; font-size: 12px;">{{inputComps.get('city').value}},
                {{inputComps.get('state').value}} {{inputComps.get('zip').value}}</div>
              <div class="row" style="padding-top: 5px; font-family: Myriad Pro; font-size: 12px;">
                <button data-dismiss="modal" type="button" class="btn btn-primary buttonInfo"
                  (click)="uspsModelEventCallBack('SAME_ADDRESS','');addStaffData('',uspsAddressModel);">KEEP ADDRESS I
                  ENTERED</button>
              </div>
            </div>
            <div class="col-sm-6" *ngIf="uspsAddressModel">
              <div class="row" style="font-family: Myriad Pro; font-size: 15px; font-weight: bold;">USPS RECOMMENDS
              </div>
              <div class="row" style="font-family: Myriad Pro; font-size: 12px;">{{uspsAddressModel.address1}}</div>
              <div class="row" style="font-family: Myriad Pro; font-size: 12px;">{{uspsAddressModel.address2}}</div>
              <div class="row" style="font-family: Myriad Pro; font-size: 12px;">{{uspsAddressModel.city}},
                {{uspsAddressModel.state}} {{uspsAddressModel.zip}}</div>
              <div class="row" style="padding-top: 5px; font-family: Myriad Pro; font-size: 12px;">
                <button data-dismiss="modal" type="button" class="btn btn-primary buttonInfo"
                  (click)="uspsModelEventCallBack('USPS_ADDRESS', uspsAddressModel);addStaffData('USPS_ADDRESS',uspsAddressModel);">USE
                  NEW ADDRESS</button>
              </div>

            </div>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
  </div>
  <br><br><br><br><br> <br>
  <app-footer></app-footer>
</div>