import {Injectable} from '@angular/core';

import { urls } from '../common/model/properties';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SecureStorageService } from '../secure-storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable()
export class HeaderService {
  hideMenuSubject = new BehaviorSubject(false);

     constructor(private http: HttpClient,
        private secureStorage:SecureStorageService) {
     }

     listDocuments( docCode, kvpl, page, size ): Observable<any> {
          
          // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
          const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
          const headers = new HttpHeaders({'Authorization': 'Bearer ' +  ppjwt.access_token});
          const myParams = new HttpParams({fromObject:{
            'appId': 'PP',
            'documentCode': docCode ,
            'keyValuePairList': JSON.stringify( kvpl )
          }});
         
          const options =  { headers: headers, params: myParams } ;
          return this.http.get( urls.filenetListURL, options );
      }
      getDocument( docID, docCode, kvpl ): Observable<any> {
        
          // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
          const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
          const headers = new HttpHeaders({
            'Authorization': 'Bearer ' +  ppjwt.access_token
          });
          let myParams = new HttpParams({fromObject:{
            'appId': 'PP',
            'documentCode': docCode
          }});
          if ( kvpl !== null ) {
            myParams = new HttpParams({fromObject:{
                'appId': 'PP',
                'documentCode': docCode,
                'keyValuePairList': JSON.stringify( kvpl )
              }});
          }
          const options =  { headers: headers, params: myParams } ;
          const urlCall = ( docID === null ) ? urls.filenetURL : urls.filenetURL + '/' + docID;
          return this.http.get( urlCall, options );
      }
      getDocumentForLicense(): Observable<any> {
        const payload = {
            'appId': 'PP',
            'documentCode': 'USER_AGREEMENT_DOC',
            'keyValuePairList': [{ 'key': 'FunctionType', 'value': 'ProviderPortal-UserAgreement' }, { 'key': 'UserType', 'value': 'All' }]
        };

         const headers = new HttpHeaders({
        });
        
        const options = { headers: headers }
        return this.http.post(urls.getUserAgreementDoc, payload, options);
    }
    getAlertDetails(userName: String){
       
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + ppjwt.access_token
        });
        let alertDetails = urls.getAlerts as any;;
        // alertDetails = alertDetails.replace('USER_NAME', userName)
      
        return this.http.get(alertDetails, {headers: headers});
        
 
      
      }
    
      getReadStatus(AlertId){
        // const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInSessionStorage('currentUser'));
        const ppjwt = JSON.parse(this.secureStorage.getSecureInfoInLocalStorage('currentUser'));
        const headers = new HttpHeaders({
            'Authorization': 'Bearer ' +  ppjwt.access_token,
            'Content-Type': 'application/json'
        });
        const payload = {
            "alertId":AlertId
        }
        // let alertStatusUrl = urls.getAlertReadStatus as any;
        // alertStatusUrl = alertStatusUrl.replace('alertId',AlertId );
        return this.http.post(urls.getAlertReadStatus,payload,{headers: headers});
    
    }

}
